import React, { Component } from "react";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "../../../common/ButtonWrapper";
import Grid from "../../../common/GridWrapper";
import Typography from "../../../common/TypographyWrapper";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import withStyles from "@mui/styles/withStyles";
import { Table, TableBody, TableRow } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import {
    IrrigationMethods,
    PumpPressures,
    PumpingDepths,
    FuelTypes,
    EnergySources,
    WaterSources,
    ActivityIrrigationWaterUseEvent
} from "./models";
import HelpLabel from "../../../common/HelpLabel";
import RadioGroup from "../../../common/RadioGroup";
import Select from "../../../common/Select";
import Snackbar from "../../../common/Snackbar";
import Switch from "../../../common/Switch";
import TextField from "../../../common/TextField";
import DatePicker from "../../../common/DatePicker";
import WarningDialog from "../../../common/WarningDialog";
import EnhancedTableHead from "../../../common/EnhancedTableHead";
import CustomTableCell from "../../../common/TableCell";
import { createSelector } from "../../../common/orm";
import SubquestionIcon from "../../../common/icons/Subquestion";
import IrrigationIcon from "../../../common/icons/operation/irrigation.png";
import cipolletti from "../../../common/icons/cipolletti.png";
import rectangular from "../../../common/icons/rectangular.png";
import triangular from "../../../common/icons/triangular.png";
import furrow from "../../../common/icons/furrow.jpg";
import furrowsurge from "../../../common/icons/furrowsurge.png";
import pivot from "../../../common/icons/pivot.png";
import sprinkler from "../../../common/icons/sprinkler.jpg";
import drip from "../../../common/icons/drip.png";
import submerged from "../../../common/icons/submerged.png";
import {
    YES_NO_OPTIONS,
    ELECTRICITY,
    FUEL,
    GROUNDWATER,
    SURFACEWATER,
    MAKE_OPTIONS,
    ALFALFA,
    RICE
} from "../../../../api/constants";
import { setValue, getValue } from "../../../../api/utils";

const allIrrigationMethods = IrrigationMethods.selectAll();
const allPumpPressures = PumpPressures.selectAll();
const allPumpingDepths = PumpingDepths.selectAll();
const allFuelTypes = FuelTypes.selectAll();
const allEnergySources = EnergySources.selectAll();
const allWaterSources = WaterSources.selectAll();
const getTimingEvents = createSelector(
    (state, ownProps) => ownProps.fieldActivity.id,
    (session, fieldId) => {
        return session.ActivityIrrigationWaterUseEvent.filter(f => f.activity === fieldId || f.activity_id === fieldId)
            .orderBy("id")
            .toModelArray();
    }
);

const styles = theme => ({
    helpimages: {
        maxWidth: 475
    },
    button: {
        marginRight: theme.spacing(1)
    },
    rightAlign: {
        textAlign: "right"
    },
    gutterTop: {
        marginTop: ".6em"
    },
    subQuestion: {
        //marginRight: theme.spacing(1),
        //marginTop: theme.spacing(3)
    },

    checkBoxIcon: {
        width: 50,
        height: 50,
        marginTop: -12,
        marginBottom: -12,
        marginRight: 8
    },

    checkBoxIcon2: {
        width: 70,
        height: 70,
        marginTop: -22,
        marginBottom: -22,
        marginRight: 8
    },
    closeIcon: {
        float: "right",
        color: "#eee",
        marginTop: -8,
        marginBottom: -8
    },
    titleContainer: {
        backgroundColor: "#f15d22"
    },
    termDialog: {
        width: 1000,
        maxWidth: "98%"
    },
    flex: {
        display: "inline-block",
        color: "#eee"
    },
    dialogTitle: {
        color: "#f15d22"
    },
    divider: {
        backgroundColor: "#f15d22",
        marginBottom: 16
    },
    divideButton: {
        border: "1px solid #0096d6"
    },
    inputStyle: {
        "& input": {
            width: 120
        }
    },
    centerAlign: {
        textAlign: "center"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34
    }
});

class TimingsTable extends Component {
    render() {
        const {
            classes,
            fieldActivity,
            timings,
            ormActivityIrrigationWaterUseEventCreate,
            ormActivityIrrigationWaterUseEventCreateRemoteFirst,
            authState,
            ormActivityIrrigationWaterUseEventDelete,
            ormActivityIrrigationWaterUseEventUpdate,
            updateTimings,
            calcedField
        } = this.props;

        var units = this.props.units;
        if (!units) units = "acre_inch";

        var defaultValues = {};
        timings.forEach(function (t, i) {
            defaultValues["event_" + i] = t.event;
            defaultValues["date_" + i] = t.date;
            defaultValues["per_acre_" + i] = t.per_acre;
        });

        const columnData = [
            { id: "actions", numeric: false, label: "", allowSort: false },
            { id: "event", numeric: false, label: "Irrigation Event (hours)", allowSort: false },
            { id: "date", numeric: false, label: "Event Date (optional)", allowSort: false },
            { id: "per_acre", numeric: false, label: "Per Acre (" + units + ")", allowSort: false }
        ];

        return (
            <Paper>
                <Form getApi={el => (this.form = el)} defaultValues={defaultValues}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Table className={classes.inputStyle}>
                                <EnhancedTableHead columnData={columnData} />
                                <TableBody>
                                    {timings.map((n, i) => {
                                        return (
                                            <TableRow hover key={n.id}>
                                                <CustomTableCell>
                                                    <Tooltip title="Delete">
                                                        <Button
                                                            onClick={() =>
                                                                ormActivityIrrigationWaterUseEventDelete(n.id)
                                                            }
                                                            className={classes.deleteWidth}>
                                                            <DeleteIcon color="primary" />
                                                        </Button>
                                                    </Tooltip>
                                                </CustomTableCell>
                                                <CustomTableCell>
                                                    Event {i + 1}&nbsp;&nbsp;&nbsp;
                                                    <TextField
                                                        type="number"
                                                        field={"event_" + i}
                                                        label={null}
                                                        margin="normal"
                                                        eventHandle={value => {
                                                            var unitcalc = 1;
                                                            if (units === "gallons") unitcalc = 60;
                                                            var effective_application_rate = getValue(
                                                                fieldActivity,
                                                                calcedField
                                                            );
                                                            var per_acre =
                                                                Math.round(
                                                                    effective_application_rate.split(" ")[0] *
                                                                        value.split(" ")[0] *
                                                                        unitcalc *
                                                                        100
                                                                ) / 100;
                                                            this.form.setValue("per_acre_" + i, per_acre);
                                                            ormActivityIrrigationWaterUseEventUpdate({
                                                                id: n.id,
                                                                event: value.split(" ")[0],
                                                                per_acre: per_acre
                                                            });
                                                            updateTimings(per_acre, n.id);
                                                        }}
                                                    />
                                                </CustomTableCell>
                                                <CustomTableCell>
                                                    <DatePicker
                                                        field={"date_" + i}
                                                        eventHandle={value => {
                                                            ormActivityIrrigationWaterUseEventUpdate({
                                                                id: n.id,
                                                                date: value
                                                            });
                                                        }}
                                                    />
                                                </CustomTableCell>
                                                <CustomTableCell>
                                                    <TextField
                                                        type="number"
                                                        field={"per_acre_" + i}
                                                        label={null}
                                                        disabled
                                                        margin="normal"
                                                    />
                                                </CustomTableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {timings.length < 1 && (
                                        <TableRow>
                                            <CustomTableCell colSpan={4} className={classes.centerAlign}>
                                                No Timing Events Entered
                                            </CustomTableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </form>
                    )}
                </Form>
                <Button
                    onClick={() => {
                        if (authState.user && !authState.user.guest)
                            ormActivityIrrigationWaterUseEventCreateRemoteFirst({ activity: fieldActivity.id });
                        else ormActivityIrrigationWaterUseEventCreate({ activity: fieldActivity.id });
                    }}
                    fullWidth
                    variant="raised"
                    color="primary">
                    <AddCircleOutlineIcon />
                    &nbsp;&nbsp;&nbsp;Add Event
                </Button>
            </Paper>
        );
    }
}
const nozzle_diameters = [
    { label: "1/16", value: "0.0625" },
    { label: "3/32", value: "0.09375" },
    { label: "7/64", value: "0.109375" },
    { label: "1/8", value: "0.125" },
    { label: "9/64", value: "0.140625" },
    { label: "5/32", value: "0.15625" },
    { label: "11/64", value: "0.171875" },
    { label: "3/16", value: "0.1875" },
    { label: "13/64", value: "0.203125" },
    { label: "7/32", value: "0.21875" },
    { label: "15/64", value: "0.234375" },
    { label: "1/4", value: "0.25" },
    { label: "5/16", value: "0.3125" },
    { label: "3/8", value: "0.375" },
    { label: "7/16", value: "0.4375" }
];

class Estimator extends Component {
    constructor(props) {
        super(props);
        this.timingsTable = React.createRef();
        this.state = {
            runcalc: false,
            runcalcDrip: false,
            runcalcFurrow: false,
            runcalcSurge: false,
            runcalcWeir: false
        };
    }
    methods = [];

    componentDidUpdate() {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly, cropYear } = this.props;
        const { id } = fieldActivity;
        const { runcalc, runcalcDrip, runcalcFurrow, runcalcSurge, runcalcWeir } = this.state;
        const parent_method = getValue(fieldActivity, "irrigation.waterquality.irrigation_method");
        var methods = [];
        if (parent_method === "2" || parent_method === "3") {
            //"Center Pivot" "Center Pivot with Polyacrylamide (PAM)"
            methods.push({ value: "Center Pivot", label: "Center pivot" });
        } else if (
            parent_method === "4" ||
            parent_method === "6" ||
            parent_method === "7" ||
            parent_method === "8" ||
            parent_method === "9"
        ) {
            //"Level basin / blocked end" & surfaces
            methods.push({ value: "Cipolletti (Trapezoidal) Weir", label: "Cipolletti (trapezoidal) weir" });
            methods.push({ value: "Rectangular Contracted Weir", label: "Rectangular contracted weir" });
            methods.push({ value: "90° Triangular Notch Weir", label: "90° triangular notch weir" });
            methods.push({ value: "Rectangular Submerged Orifices", label: "Rectangular submerged orifices" });
            methods.push({ value: "Furrow Polypipe, Pipe", label: "Furrow polypipe, pipe" });
            methods.push({ value: "Surge Irrigation", label: "Surge irrigation" });
        } else if (parent_method === "5") {
            //"Sprinkler"
            methods.push({ value: "Sprinkler", label: "Sprinkler" });
        } else if (parent_method === "10") {
            //"Trickle / Drip"
            methods.push({ value: "Drip Line", label: "Drip line" });
        }
        this.methods = methods;

        setValue(fieldActivity, "irrigation.wateruse.field_acre", cropYear.fieldObject.size);

        if (methods.length === 1) {
            setValue(fieldActivity, "irrigation.wateruse.irrigation_method", methods[0].value);
            ormFieldActivityUpdateLocalOnly({
                id: id,
                ...fieldActivity
            });
        } else {
            var irrigation_method = getValue(fieldActivity, "irrigation.wateruse.irrigation_method");
            var found = methods.find(function (e) {
                return e.value === irrigation_method;
            });
            if (!found) {
                setValue(fieldActivity, "irrigation.wateruse.irrigation_method", null);
                ormFieldActivityUpdateLocalOnly({
                    id: id,
                    ...fieldActivity
                });
            }
        }

        var timings_new;
        if (runcalc) {
            const val2 = getValue(fieldActivity, "irrigation.wateruse.effective_application_rate");
            if (val2) {
                timings_new = this.recalculateTimings(val2.split(" ")[0]);
                this.updateTimings(null, null, timings_new);
            }
            this.setState({ runcalc: false });
        }

        if (runcalcDrip) {
            const val2 = getValue(fieldActivity, "irrigation.wateruse.drip_application_rate");
            if (val2) {
                timings_new = this.recalculateTimings(val2.split(" ")[0]);
                this.updateTimingsDrip(null, null, timings_new);
            }
            this.setState({ runcalcDrip: false });
        }

        if (runcalcFurrow) {
            const val2 = getValue(fieldActivity, "irrigation.wateruse.rate_per_hour");
            if (val2) {
                timings_new = this.recalculateTimings(val2.split(" ")[0]);
                this.updateTimingsFurrow(null, null, timings_new);
            }
            this.setState({ runcalcFurrow: false });
        }

        if (runcalcSurge) {
            const val2 = getValue(fieldActivity, "irrigation.wateruse.rate");
            if (val2) {
                timings_new = this.recalculateTimings(val2.split(" ")[0]);
                this.updateTimingsSurge(null, null, timings_new);
            }
            this.setState({ runcalcSurge: false });
        }

        if (runcalcWeir) {
            const val2 = getValue(fieldActivity, "irrigation.wateruse.flow_rate_gpm");
            if (val2) {
                timings_new = this.recalculateTimings(val2.split(" ")[0], 60);
                this.updateTimingsWeir(null, null, timings_new);
            }
            this.setState({ runcalcWeir: false });
        }
    }

    updateFieldActivity = values => {
        const { fieldActivity, ormFieldActivityUpdate, handleUnsavedFields, closeEvent } = this.props;
        const { id } = fieldActivity;

        ormFieldActivityUpdate({
            id: id,
            ...values
        });
        handleUnsavedFields(false);
        closeEvent(getValue(values, "irrigation.wateruse.estimated_water_use"));
    };

    errorValidator = values => {
        const isRequired = path => {
            let val = getValue(values, path);
            setValue(valObj, path, val || val === false ? null : "Required");
        };
        var valObj = {};

        isRequired("irrigation.wateruse.irrigation_method");
        isRequired("irrigation.wateruse.estimated_water_use");

        return valObj;
    };

    clearTotals = () => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly } = this.props;
        const { id } = fieldActivity;

        setValue(fieldActivity, "irrigation.wateruse.subtotal", null);
        setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", null);
        this.form.setValue("irrigation.wateruse.subtotal", null);
        this.form.setValue("irrigation.wateruse.estimated_water_use", null);

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
        this.setState({ foo: "bar" });
    };

    updateSprinkler = () => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly } = this.props;
        const { id } = fieldActivity;

        const nozzle_diameter = getValue(fieldActivity, "irrigation.wateruse.nozzle_diameter");
        const pressure = getValue(fieldActivity, "irrigation.wateruse.pressure");
        const head_spacing = getValue(fieldActivity, "irrigation.wateruse.head_spacing");
        const line_spacing = getValue(fieldActivity, "irrigation.wateruse.line_spacing");

        if (nozzle_diameter && pressure) {
            var val = 28.9 * Math.pow(nozzle_diameter.split(" ")[0], 2) * Math.sqrt(pressure.split(" ")[0]);
            var val_round = Math.round(val * 100000) / 100000;
            setValue(fieldActivity, "irrigation.wateruse.nozzle_flow_rate", val_round + " gpm");
            this.form.setValue("irrigation.wateruse.nozzle_flow_rate", val_round + " gpm");

            if (head_spacing && line_spacing) {
                var val2 = 96.25 * ((val * 0.7) / (head_spacing.split(" ")[0] * line_spacing.split(" ")[0]));
                var val2_round = Math.round(val2 * 100000) / 100000;
                setValue(fieldActivity, "irrigation.wateruse.effective_application_rate", val2_round + " inch / hour");
                this.form.setValue("irrigation.wateruse.effective_application_rate", val2_round + " inch / hour");
            } else {
                setValue(fieldActivity, "irrigation.wateruse.effective_application_rate", null);
                this.form.setValue("irrigation.wateruse.effective_application_rate", null);
            }
        } else {
            setValue(fieldActivity, "irrigation.wateruse.nozzle_flow_rate", null);
            this.form.setValue("irrigation.wateruse.nozzle_flow_rate", null);
            setValue(fieldActivity, "irrigation.wateruse.effective_application_rate", null);
            this.form.setValue("irrigation.wateruse.effective_application_rate", null);
        }

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
        const use_individual_timings = getValue(fieldActivity, "irrigation.wateruse.use_individual_timings");
        if (use_individual_timings) {
            var timings = this.recalculateTimings(val2_round);
            this.updateTimings(null, null, timings);
        } else this.updateAverage("Sprinkler");
    };

    updateAverage = type => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly } = this.props;
        const { id } = fieldActivity;

        if (type === "Sprinkler") {
            const total_irrigation_events = getValue(fieldActivity, "irrigation.wateruse.total_irrigation_events");
            const average_time_irrigating = getValue(fieldActivity, "irrigation.wateruse.average_time_irrigating");
            var effective_application_rate = getValue(fieldActivity, "irrigation.wateruse.effective_application_rate");
            const recovery = getValue(fieldActivity, "irrigation.wateruse.recovery");

            if (total_irrigation_events && average_time_irrigating && effective_application_rate) {
                effective_application_rate = effective_application_rate.toString();
                var val =
                    total_irrigation_events *
                    average_time_irrigating.split(" ")[0] *
                    effective_application_rate.split(" ")[0];
                var val_round = Math.round(val * 10) / 10;
                setValue(fieldActivity, "irrigation.wateruse.subtotal", val_round + " acre_inch / acre");
                this.form.setValue("irrigation.wateruse.subtotal", val_round + " acre_inch / acre");

                if (recovery) {
                    var val2 = val * (1 - recovery.split(" ")[0] / 100);
                    var val2_round = Math.round(val2 * 10) / 10;
                    setValue(
                        fieldActivity,
                        "irrigation.wateruse.estimated_water_use",
                        val2_round + " acre_inch / acre"
                    );
                    this.form.setValue("irrigation.wateruse.estimated_water_use", val2_round + " acre_inch / acre");
                } else {
                    setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val_round + " acre_inch / acre");
                    this.form.setValue("irrigation.wateruse.estimated_water_use", val_round + " acre_inch / acre");
                }
            } else {
                setValue(fieldActivity, "irrigation.wateruse.subtotal", null);
                this.form.setValue("irrigation.wateruse.subtotal", null);
                setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", null);
                this.form.setValue("irrigation.wateruse.estimated_water_use", null);
            }

            ormFieldActivityUpdateLocalOnly({
                id: id,
                ...fieldActivity
            });
            this.setState({ foo: "bar" });
        }
    };

    recalculateTimings = (effective_application_rate, gals = 1) => {
        const { timings, ormActivityIrrigationWaterUseEventUpdate } = this.props;
        const timingForm = this.timingsTable.current.form;

        timings.forEach(function (t, i) {
            if (t.event) {
                var per_acre = Math.round(effective_application_rate * t.event * gals * 100000) / 100000;
                timingForm.setValue("per_acre_" + i, per_acre);
                t.per_acre = per_acre;
                ormActivityIrrigationWaterUseEventUpdate({
                    id: t.id,
                    per_acre: per_acre
                });
            }
        });
        return timings;
    };

    updateTimings = (per_acre, nid, timings) => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly } = this.props;
        const { id } = fieldActivity;

        if (!timings) timings = this.props.timings;

        var val = 0;
        timings.forEach(function (t) {
            if (per_acre !== undefined && t.id === nid) val += parseFloat(per_acre);
            else val += parseFloat(t.per_acre);
        });

        const recovery = getValue(fieldActivity, "irrigation.wateruse.recovery");
        var val_round = Math.round(val * 10) / 10;
        setValue(fieldActivity, "irrigation.wateruse.subtotal", val_round + " acre_inch / acre");
        this.form.setValue("irrigation.wateruse.subtotal", val_round + " acre_inch / acre");

        if (recovery) {
            var val2 = val * (1 - recovery.split(" ")[0] / 100);
            var val2_round = Math.round(val2 * 10) / 10;
            setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val2_round + " acre_inch / acre");
            this.form.setValue("irrigation.wateruse.estimated_water_use", val2_round + " acre_inch / acre");
        } else {
            setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val_round + " acre_inch / acre");
            this.form.setValue("irrigation.wateruse.estimated_water_use", val_round + " acre_inch / acre");
        }

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
        this.setState({ foo: "bar" });
    };

    update_irrigation = (value, input) => {
        const { id } = this.props.fieldActivity;
        const { ormFieldActivityUpdateLocalOnly } = this.props;

        const formValues = this.props.fieldActivity;
        setValue(formValues, input, value);

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...formValues
        });
        this.setState({ foo: "bar" });
    };

    updatePivot = () => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly } = this.props;
        const { id } = fieldActivity;

        const water_flow_rate = getValue(fieldActivity, "irrigation.wateruse.water_flow_rate");
        const pivot_hours = getValue(fieldActivity, "irrigation.wateruse.pivot_hours");
        const field_acre = getValue(fieldActivity, "irrigation.wateruse.field_acre");
        const recovery = getValue(fieldActivity, "irrigation.wateruse.recovery");

        if (water_flow_rate && pivot_hours && field_acre) {
            var val =
                (pivot_hours.split(" ")[0] * (water_flow_rate.split(" ")[0] * 60)) / 27154 / field_acre.split(" ")[0];
            var val_round = Math.round(val * 10) / 10;
            setValue(fieldActivity, "irrigation.wateruse.subtotal", val_round + " acre_inch / acre");
            this.form.setValue("irrigation.wateruse.subtotal", val_round + " acre_inch / acre");

            if (recovery) {
                var val2 = val * (1 - recovery.split(" ")[0] / 100);
                var val2_round = Math.round(val2 * 10) / 10;
                setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val2_round + " acre_inch / acre");
                this.form.setValue("irrigation.wateruse.estimated_water_use", val2_round + " acre_inch / acre");
            } else {
                setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val_round + " acre_inch / acre");
                this.form.setValue("irrigation.wateruse.estimated_water_use", val_round + " acre_inch / acre");
            }
        } else {
            setValue(fieldActivity, "irrigation.wateruse.subtotal", null);
            this.form.setValue("irrigation.wateruse.subtotal", null);
            setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", null);
            this.form.setValue("irrigation.wateruse.estimated_water_use", null);
        }

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
    };

    updateDrip = () => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly } = this.props;
        const { id } = fieldActivity;

        const emitter_flow = getValue(fieldActivity, "irrigation.wateruse.emitter_flow");
        const emitter_spacing = getValue(fieldActivity, "irrigation.wateruse.emitter_spacing");
        const drip_distance = getValue(fieldActivity, "irrigation.wateruse.drip_distance");

        if (emitter_flow && emitter_spacing && drip_distance) {
            var val =
                231 *
                ((emitter_flow.split(" ")[0] * 0.95) / (emitter_spacing.split(" ")[0] * drip_distance.split(" ")[0]));
            var val_round = Math.round(val * 100000) / 100000;
            setValue(fieldActivity, "irrigation.wateruse.drip_application_rate", val_round + " acre_inch / hour");
            this.form.setValue("irrigation.wateruse.drip_application_rate", val_round + " acre_inch / hour");
        } else {
            setValue(fieldActivity, "irrigation.wateruse.drip_application_rate", null);
            this.form.setValue("irrigation.wateruse.drip_application_rate", null);
        }

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
        const use_individual_timings = getValue(fieldActivity, "irrigation.wateruse.use_individual_timings");
        if (use_individual_timings) {
            var timings = this.recalculateTimings(val_round);
            this.updateTimingsDrip(null, null, timings);
        } else this.updateAverageDrip();
    };

    updateAverageDrip = () => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly } = this.props;
        const { id } = fieldActivity;

        const total_irrigation_events = getValue(fieldActivity, "irrigation.wateruse.total_irrigation_events");
        const average_time_irrigating = getValue(fieldActivity, "irrigation.wateruse.average_time_irrigating");
        var drip_application_rate = getValue(fieldActivity, "irrigation.wateruse.drip_application_rate");

        if (total_irrigation_events && average_time_irrigating && drip_application_rate) {
            drip_application_rate = drip_application_rate.toString();

            var val =
                total_irrigation_events * average_time_irrigating.split(" ")[0] * drip_application_rate.split(" ")[0];
            var val_round = Math.round(val * 10) / 10;
            setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val_round + " acre_inch / acre");
            this.form.setValue("irrigation.wateruse.estimated_water_use", val_round + " acre_inch / acre");
        } else {
            setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", null);
            this.form.setValue("irrigation.wateruse.estimated_water_use", null);
        }

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
        this.setState({ foo: "bar" });
    };

    updateTimingsDrip = (per_acre, nid, timings) => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly } = this.props;
        const { id } = fieldActivity;

        if (!timings) timings = this.props.timings;

        var val = 0;
        timings.forEach(function (t) {
            if (per_acre !== undefined && t.id === nid) val += parseFloat(per_acre);
            else val += parseFloat(t.per_acre);
        });

        var val_round = Math.round(val * 10) / 10;
        setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val_round + " acre_inch / acre");
        this.form.setValue("irrigation.wateruse.estimated_water_use", val_round + " acre_inch / acre");

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
        this.setState({ foo: "bar" });
    };

    updateFurrow = () => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly } = this.props;
        const { id } = fieldActivity;

        const field_flow_rate = getValue(fieldActivity, "irrigation.wateruse.field_flow_rate");
        const number_rows = getValue(fieldActivity, "irrigation.wateruse.number_rows");
        const furrow_length = getValue(fieldActivity, "irrigation.wateruse.furrow_length");
        const furrow_spacing = getValue(fieldActivity, "irrigation.wateruse.furrow_spacing");

        if (field_flow_rate && number_rows && furrow_length && furrow_spacing) {
            var val =
                (1.6041 * (field_flow_rate.split(" ")[0] / number_rows.split(" ")[0]) * 60) /
                (furrow_length.split(" ")[0] * furrow_spacing.split(" ")[0]);
            var val_round = Math.round(val * 100000) / 100000;
            setValue(fieldActivity, "irrigation.wateruse.rate_per_hour", val_round + " acre_inch / hour");
            this.form.setValue("irrigation.wateruse.rate_per_hour", val_round + " acre_inch / hour");
        } else {
            setValue(fieldActivity, "irrigation.wateruse.rate_per_hour", null);
            this.form.setValue("irrigation.wateruse.rate_per_hour", null);
        }

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
        const use_individual_timings = getValue(fieldActivity, "irrigation.wateruse.use_individual_timings");
        if (use_individual_timings) {
            var timings = this.recalculateTimings(val_round);
            this.updateTimingsFurrow(null, null, timings);
        } else this.updateAverageFurrow();
    };

    updateAverageFurrow = () => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly } = this.props;
        const { id } = fieldActivity;

        const total_irrigation_events = getValue(fieldActivity, "irrigation.wateruse.total_irrigation_events");
        const average_time_irrigating = getValue(fieldActivity, "irrigation.wateruse.average_time_irrigating");
        var rate_per_hour = getValue(fieldActivity, "irrigation.wateruse.rate_per_hour");
        const recovery = getValue(fieldActivity, "irrigation.wateruse.recovery");

        if (total_irrigation_events && average_time_irrigating && rate_per_hour) {
            rate_per_hour = rate_per_hour.toString();
            var val = total_irrigation_events * average_time_irrigating.split(" ")[0] * rate_per_hour.split(" ")[0];
            var val_round = Math.round(val * 10) / 10;
            setValue(fieldActivity, "irrigation.wateruse.subtotal", val_round + " acre_inch / acre");
            this.form.setValue("irrigation.wateruse.subtotal", val_round + " acre_inch / acre");

            if (recovery) {
                var val2 = val * (1 - recovery.split(" ")[0] / 100);
                var val2_round = Math.round(val2 * 10) / 10;
                setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val2_round + " acre_inch / acre");
                this.form.setValue("irrigation.wateruse.estimated_water_use", val2_round + " acre_inch / acre");
            } else {
                setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val_round + " acre_inch / acre");
                this.form.setValue("irrigation.wateruse.estimated_water_use", val_round + " acre_inch / acre");
            }
        } else {
            setValue(fieldActivity, "irrigation.wateruse.subtotal", null);
            this.form.setValue("irrigation.wateruse.subtotal", null);
            setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", null);
            this.form.setValue("irrigation.wateruse.estimated_water_use", null);
        }

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
        this.setState({ foo: "bar" });
    };

    updateTimingsFurrow = (per_acre, nid, timings) => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly } = this.props;
        const { id } = fieldActivity;

        if (!timings) timings = this.props.timings;

        var val = 0;
        timings.forEach(function (t) {
            if (per_acre !== undefined && t.id === nid) val += parseFloat(per_acre);
            else val += parseFloat(t.per_acre);
        });

        const recovery = getValue(fieldActivity, "irrigation.wateruse.recovery");
        var val_round = Math.round(val * 10) / 10;
        setValue(fieldActivity, "irrigation.wateruse.subtotal", val_round + " acre_inch / acre");
        this.form.setValue("irrigation.wateruse.subtotal", val_round + " acre_inch / acre");

        if (recovery) {
            var val2 = val * (1 - recovery.split(" ")[0] / 100);
            var val2_round = Math.round(val2 * 10) / 10;
            setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val2_round + " acre_inch / acre");
            this.form.setValue("irrigation.wateruse.estimated_water_use", val2_round + " acre_inch / acre");
        } else {
            setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val_round + " acre_inch / acre");
            this.form.setValue("irrigation.wateruse.estimated_water_use", val_round + " acre_inch / acre");
        }

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
        this.setState({ foo: "bar" });
    };

    updateSurge = () => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly } = this.props;
        const { id } = fieldActivity;

        const field_flow_rate = getValue(fieldActivity, "irrigation.wateruse.field_flow_rate");
        const number_rows = getValue(fieldActivity, "irrigation.wateruse.number_rows");
        const furrow_length = getValue(fieldActivity, "irrigation.wateruse.furrow_length");
        const furrow_spacing = getValue(fieldActivity, "irrigation.wateruse.furrow_spacing");

        if (field_flow_rate) {
            var val = field_flow_rate.split(" ")[0] / 452;
            var val_round = Math.round(val * 100000) / 100000;
            setValue(fieldActivity, "irrigation.wateruse.rate_per_hour", val_round + " acre_inch / hour");
            this.form.setValue("irrigation.wateruse.rate_per_hour", val_round + " acre_inch / hour");

            if (number_rows && furrow_length && furrow_spacing) {
                var val2 =
                    (number_rows.split(" ")[0] * furrow_length.split(" ")[0] * furrow_spacing.split(" ")[0]) / 43560;
                var val2_round = Math.round(val2 * 100000) / 100000;
                setValue(fieldActivity, "irrigation.wateruse.acres_per_set", val2_round + " acres / set");
                this.form.setValue("irrigation.wateruse.acres_per_set", val2_round + " acres / set");

                var val3 = val / val2;
                var val3_round = Math.round(val3 * 100000) / 100000;
                setValue(fieldActivity, "irrigation.wateruse.rate", val3_round + " acre_inch / acre / hour");
                this.form.setValue("irrigation.wateruse.rate", val3_round + " acre_inch / acre / hour");
            } else {
                setValue(fieldActivity, "irrigation.wateruse.acres_per_set", null);
                this.form.setValue("irrigation.wateruse.acres_per_set", null);
                setValue(fieldActivity, "irrigation.wateruse.rate", null);
                this.form.setValue("irrigation.wateruse.rate", null);
            }
        } else {
            setValue(fieldActivity, "irrigation.wateruse.rate_per_hour", null);
            this.form.setValue("irrigation.wateruse.rate_per_hour", null);
            setValue(fieldActivity, "irrigation.wateruse.acres_per_set", null);
            this.form.setValue("irrigation.wateruse.acres_per_set", null);
            setValue(fieldActivity, "irrigation.wateruse.rate", null);
            this.form.setValue("irrigation.wateruse.rate", null);
        }

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
        const use_individual_timings = getValue(fieldActivity, "irrigation.wateruse.use_individual_timings");
        if (use_individual_timings) {
            var timings = this.recalculateTimings(val3_round);
            this.updateTimingsSurge(null, null, timings);
        } else this.updateAverageSurge();
    };

    updateAverageSurge = () => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly } = this.props;
        const { id } = fieldActivity;

        const total_irrigation_events = getValue(fieldActivity, "irrigation.wateruse.total_irrigation_events");
        const average_time_irrigating = getValue(fieldActivity, "irrigation.wateruse.average_time_irrigating");
        var rate = getValue(fieldActivity, "irrigation.wateruse.rate");
        const recovery = getValue(fieldActivity, "irrigation.wateruse.recovery");

        if (total_irrigation_events && average_time_irrigating && rate) {
            rate = rate.toString();
            var val = total_irrigation_events * average_time_irrigating.split(" ")[0] * rate.split(" ")[0];
            var val_round = Math.round(val * 10) / 10;
            setValue(fieldActivity, "irrigation.wateruse.subtotal", val_round + " acre_inch / acre");
            this.form.setValue("irrigation.wateruse.subtotal", val_round + " acre_inch / acre");

            if (recovery) {
                var val2 = val * (1 - recovery.split(" ")[0] / 100);
                var val2_round = Math.round(val2 * 10) / 10;
                setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val2_round + " acre_inch / acre");
                this.form.setValue("irrigation.wateruse.estimated_water_use", val2_round + " acre_inch / acre");
            } else {
                setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val_round + " acre_inch / acre");
                this.form.setValue("irrigation.wateruse.estimated_water_use", val_round + " acre_inch / acre");
            }
        } else {
            setValue(fieldActivity, "irrigation.wateruse.subtotal", null);
            this.form.setValue("irrigation.wateruse.subtotal", null);
            setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", null);
            this.form.setValue("irrigation.wateruse.estimated_water_use", null);
        }

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
        this.setState({ foo: "bar" });
    };

    updateTimingsSurge = (per_acre, nid, timings) => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly } = this.props;
        const { id } = fieldActivity;

        if (!timings) timings = this.props.timings;

        var val = 0;
        timings.forEach(function (t) {
            if (per_acre !== undefined && t.id === nid) val += parseFloat(per_acre);
            else val += parseFloat(t.per_acre);
        });

        const recovery = getValue(fieldActivity, "irrigation.wateruse.recovery");
        var val_round = Math.round(val * 10) / 10;
        setValue(fieldActivity, "irrigation.wateruse.subtotal", val_round + " acre_inch / acre");
        this.form.setValue("irrigation.wateruse.subtotal", val_round + " acre_inch / acre");

        if (recovery) {
            var val2 = val * (1 - recovery.split(" ")[0] / 100);
            var val2_round = Math.round(val2 * 10) / 10;
            setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val2_round + " acre_inch / acre");
            this.form.setValue("irrigation.wateruse.estimated_water_use", val2_round + " acre_inch / acre");
        } else {
            setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val_round + " acre_inch / acre");
            this.form.setValue("irrigation.wateruse.estimated_water_use", val_round + " acre_inch / acre");
        }

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
        this.setState({ foo: "bar" });
    };

    updateWeir = () => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly } = this.props;
        const { id } = fieldActivity;

        const length = getValue(fieldActivity, "irrigation.wateruse.length");
        const height = getValue(fieldActivity, "irrigation.wateruse.height");

        if (length && height) {
            var val =
                3.247 * length.split(" ")[0] * Math.pow(height.split(" ")[0], 1.48) -
                ((0.566 * Math.pow(length.split(" ")[0], 1.9)) / (1 + 2 * Math.pow(length.split(" ")[0], 1.8))) *
                    Math.pow(height.split(" ")[0], 1.9) +
                0.609 * Math.pow(height.split(" ")[0], 2.5);
            var val_round = Math.round(val * 100000) / 100000;
            setValue(fieldActivity, "irrigation.wateruse.flow_rate", val_round + " cfs");
            this.form.setValue("irrigation.wateruse.flow_rate", val_round + " cfs");

            var val2 = val * 448.83;
            var val2_round = Math.round(val2 * 100000) / 100000;
            setValue(fieldActivity, "irrigation.wateruse.flow_rate_gpm", val2_round + " gpm");
            this.form.setValue("irrigation.wateruse.flow_rate_gpm", val2_round + " gpm");
        } else {
            setValue(fieldActivity, "irrigation.wateruse.flow_rate", null);
            this.form.setValue("irrigation.wateruse.flow_rate", null);
            setValue(fieldActivity, "irrigation.wateruse.flow_rate_gpm", null);
            this.form.setValue("irrigation.wateruse.flow_rate_gpm", null);
        }

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
        const use_individual_timings = getValue(fieldActivity, "irrigation.wateruse.use_individual_timings");
        if (use_individual_timings) {
            var timings = this.recalculateTimings(val2_round, 60);
            this.updateTimingsWeir(null, null, timings);
        } else this.updateAverageWeir();
    };

    updateAverageWeir = () => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly, cropYear } = this.props;
        const { id } = fieldActivity;

        const total_irrigation_events = getValue(fieldActivity, "irrigation.wateruse.total_irrigation_events");
        const average_time_irrigating = getValue(fieldActivity, "irrigation.wateruse.average_time_irrigating");
        var flow_rate_gpm = getValue(fieldActivity, "irrigation.wateruse.flow_rate_gpm");
        const recovery = getValue(fieldActivity, "irrigation.wateruse.recovery");

        if (total_irrigation_events && average_time_irrigating && flow_rate_gpm) {
            flow_rate_gpm = flow_rate_gpm.toString();
            var val =
                total_irrigation_events * average_time_irrigating.split(" ")[0] * flow_rate_gpm.split(" ")[0] * 60;
            var val_round = Math.round(val);
            setValue(fieldActivity, "irrigation.wateruse.subtotal_gallons", val_round + " gallons");
            this.form.setValue("irrigation.wateruse.subtotal_gallons", val_round + " gallons");

            var val2 = val / 27150 / parseFloat(cropYear.fieldObject.size.split(" ")[0]);
            var val2_round = Math.round(val2 * 10) / 10;
            setValue(fieldActivity, "irrigation.wateruse.subtotal", val2_round + " acre_inch / acre");
            this.form.setValue("irrigation.wateruse.subtotal", val2_round + " acre_inch / acre");

            if (recovery) {
                var val3 = val2 * (1 - recovery.split(" ")[0] / 100);
                var val3_round = Math.round(val3 * 10) / 10;
                setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val3_round + " acre_inch / acre");
                this.form.setValue("irrigation.wateruse.estimated_water_use", val3_round + " acre_inch / acre");
            } else {
                setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val2_round + " acre_inch / acre");
                this.form.setValue("irrigation.wateruse.estimated_water_use", val2_round + " acre_inch / acre");
            }
        } else {
            setValue(fieldActivity, "irrigation.wateruse.subtotal_gallons", null);
            this.form.setValue("irrigation.wateruse.subtotal_gallons", null);
            setValue(fieldActivity, "irrigation.wateruse.subtotal", null);
            this.form.setValue("irrigation.wateruse.subtotal", null);
            setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", null);
            this.form.setValue("irrigation.wateruse.estimated_water_use", null);
        }

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
        this.setState({ foo: "bar" });
    };

    updateTimingsWeir = (per_acre, nid, timings) => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly, cropYear } = this.props;
        const { id } = fieldActivity;

        if (!timings) timings = this.props.timings;

        var val = 0;
        timings.forEach(function (t) {
            if (per_acre !== undefined && t.id === nid) val += parseFloat(per_acre);
            else val += parseFloat(t.per_acre);
        });

        const recovery = getValue(fieldActivity, "irrigation.wateruse.recovery");
        var val_round = Math.round(val);
        setValue(fieldActivity, "irrigation.wateruse.subtotal_gallons", val_round + " gallons");
        this.form.setValue("irrigation.wateruse.subtotal_gallons", val_round + " gallons");

        var val2 = val / 27150 / parseFloat(cropYear.fieldObject.size.split(" ")[0]);
        var val2_round = Math.round(val2 * 10) / 10;
        setValue(fieldActivity, "irrigation.wateruse.subtotal", val2_round + " acre_inch / acre");
        this.form.setValue("irrigation.wateruse.subtotal", val2_round + " acre_inch / acre");

        if (recovery) {
            var val3 = val2 * (1 - recovery.split(" ")[0] / 100);
            var val3_round = Math.round(val3 * 10) / 10;
            setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val3_round + " acre_inch / acre");
            this.form.setValue("irrigation.wateruse.estimated_water_use", val3_round + " acre_inch / acre");
        } else {
            setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", val2_round + " acre_inch / acre");
            this.form.setValue("irrigation.wateruse.estimated_water_use", val2_round + " acre_inch / acre");
        }

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
        this.setState({ foo: "bar" });
    };

    updateWeir2 = () => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly } = this.props;
        const { id } = fieldActivity;

        const length = getValue(fieldActivity, "irrigation.wateruse.length");
        const height = getValue(fieldActivity, "irrigation.wateruse.height");

        if (length && height) {
            var val =
                3.247 * length.split(" ")[0] * Math.pow(height.split(" ")[0], 1.48) -
                ((0.566 * Math.pow(length.split(" ")[0], 1.9)) / (1 + 2 * Math.pow(length.split(" ")[0], 1.87))) *
                    Math.pow(height.split(" ")[0], 1.9);
            var val_round = Math.round(val * 100000) / 100000;
            setValue(fieldActivity, "irrigation.wateruse.flow_rate", val_round + " cfs");
            this.form.setValue("irrigation.wateruse.flow_rate", val_round + " cfs");

            var val2 = val * 448.83;
            var val2_round = Math.round(val2 * 100000) / 100000;
            setValue(fieldActivity, "irrigation.wateruse.flow_rate_gpm", val2_round + " gpm");
            this.form.setValue("irrigation.wateruse.flow_rate_gpm", val2_round + " gpm");
        } else {
            setValue(fieldActivity, "irrigation.wateruse.flow_rate", null);
            this.form.setValue("irrigation.wateruse.flow_rate", null);
            setValue(fieldActivity, "irrigation.wateruse.flow_rate_gpm", null);
            this.form.setValue("irrigation.wateruse.flow_rate_gpm", null);
        }

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
        const use_individual_timings = getValue(fieldActivity, "irrigation.wateruse.use_individual_timings");
        if (use_individual_timings) {
            var timings = this.recalculateTimings(val2_round, 60);
            this.updateTimingsWeir(null, null, timings);
        } else this.updateAverageWeir();
    };

    updateWeir3 = () => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly } = this.props;
        const { id } = fieldActivity;

        const height = getValue(fieldActivity, "irrigation.wateruse.height");

        if (height) {
            var val = 2.49 * Math.pow(height.split(" ")[0], 2.48);
            var val_round = Math.round(val * 100000) / 100000;
            setValue(fieldActivity, "irrigation.wateruse.flow_rate", val_round + " cfs");
            this.form.setValue("irrigation.wateruse.flow_rate", val_round + " cfs");

            var val2 = val * 448.83;
            var val2_round = Math.round(val2 * 100000) / 100000;
            setValue(fieldActivity, "irrigation.wateruse.flow_rate_gpm", val2_round + " gpm");
            this.form.setValue("irrigation.wateruse.flow_rate_gpm", val2_round + " gpm");
        } else {
            setValue(fieldActivity, "irrigation.wateruse.flow_rate", null);
            this.form.setValue("irrigation.wateruse.flow_rate", null);
            setValue(fieldActivity, "irrigation.wateruse.flow_rate_gpm", null);
            this.form.setValue("irrigation.wateruse.flow_rate_gpm", null);
        }

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
        const use_individual_timings = getValue(fieldActivity, "irrigation.wateruse.use_individual_timings");
        if (use_individual_timings) {
            var timings = this.recalculateTimings(val2_round, 60);
            this.updateTimingsWeir(null, null, timings);
        } else this.updateAverageWeir();
    };

    updateWeir4 = () => {
        const { fieldActivity, ormFieldActivityUpdateLocalOnly } = this.props;
        const { id } = fieldActivity;

        const height = getValue(fieldActivity, "irrigation.wateruse.height");
        const area = getValue(fieldActivity, "irrigation.wateruse.area");

        if (area && height) {
            var val = 0.61 * area.split(" ")[0] * Math.sqrt(64.4 * height.split(" ")[0]);
            var val_round = Math.round(val * 100000) / 100000;
            setValue(fieldActivity, "irrigation.wateruse.flow_rate", val_round + " cfs");
            this.form.setValue("irrigation.wateruse.flow_rate", val_round + " cfs");

            var val2 = val * 448.83;
            var val2_round = Math.round(val2 * 100000) / 100000;
            setValue(fieldActivity, "irrigation.wateruse.flow_rate_gpm", val2_round + " gpm");
            this.form.setValue("irrigation.wateruse.flow_rate_gpm", val2_round + " gpm");
        } else {
            setValue(fieldActivity, "irrigation.wateruse.flow_rate", null);
            this.form.setValue("irrigation.wateruse.flow_rate", null);
            setValue(fieldActivity, "irrigation.wateruse.flow_rate_gpm", null);
            this.form.setValue("irrigation.wateruse.flow_rate_gpm", null);
        }

        ormFieldActivityUpdateLocalOnly({
            id: id,
            ...fieldActivity
        });
        const use_individual_timings = getValue(fieldActivity, "irrigation.wateruse.use_individual_timings");
        if (use_individual_timings) {
            var timings = this.recalculateTimings(val2_round, 60);
            this.updateTimingsWeir(null, null, timings);
        } else this.updateAverageWeir();
    };

    render() {
        const {
            classes,
            fieldActivity,
            handleUnsavedFields,
            open,
            closeEvent,
            ormActivityIrrigationWaterUseEventCreate,
            ormActivityIrrigationWaterUseEventCreateRemoteFirst,
            authState,
            ormActivityIrrigationWaterUseEventUpdate,
            ormActivityIrrigationWaterUseEventDelete,
            timings
        } = this.props;

        const irrigation_method = getValue(fieldActivity, "irrigation.wateruse.irrigation_method");
        const use_individual_timings = getValue(fieldActivity, "irrigation.wateruse.use_individual_timings");

        return (
            <Dialog open={open} classes={{ paper: classes.termDialog }}>
                <DialogTitle className={classes.titleContainer}>
                    <Typography variant="headline" className={classes.flex}>
                        <img src={IrrigationIcon} className={classes.checkBoxIcon2} alt="Estimator" />
                        Irrigation Estimator Tool
                    </Typography>
                    <IconButton onClick={() => closeEvent()} className={classes.closeIcon} size="large">
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Form
                        getApi={el => (this.form = el)}
                        defaultValues={fieldActivity}
                        dontValidateOnMount={true}
                        validateOnSubmit={true}
                        formDidUpdate={() => handleUnsavedFields(true)}
                        validateError={values => this.errorValidator(values)}
                        onSubmit={values => this.updateFieldActivity(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={16} style={{ marginTop: 8 }}>
                                    <Grid item xs={6}>
                                        <Typography className={classes.dialogTitle} variant="title">
                                            Water Delivery System
                                        </Typography>
                                        <Select
                                            field="irrigation.wateruse.irrigation_method"
                                            label={null}
                                            options={this.methods}
                                            eventHandle={value =>
                                                this.update_irrigation(value, "irrigation.wateruse.irrigation_method")
                                            }
                                            fullWidth
                                            margin="normal"
                                        />
                                        {irrigation_method === "Cipolletti (Trapezoidal) Weir" && (
                                            <>
                                                <Typography className={classes.dialogTitle} variant="title">
                                                    Cipolletti (Trapezoidal) Weir Rate
                                                </Typography>
                                                <Typography variant="body1">
                                                    The water discharge is directly related to the water height.
                                                </Typography>
                                            </>
                                        )}
                                        {irrigation_method === "Rectangular Contracted Weir" && (
                                            <>
                                                <Typography className={classes.dialogTitle} variant="title">
                                                    Rectangular Contracted Weir Rate
                                                </Typography>
                                                <Typography variant="body1">
                                                    This weir has a rectangular opening with straight sides. A
                                                    contracted weir means that the ditch leading up to the weir is wider
                                                    than the weir opening.
                                                </Typography>
                                            </>
                                        )}
                                        {irrigation_method === "90° Triangular Notch Weir" && (
                                            <>
                                                <Typography className={classes.dialogTitle} variant="title">
                                                    90° Triangular Notch Weir Rate
                                                </Typography>
                                                <Typography variant="body1">
                                                    The opening to this weir is a 90 degree triangular notch. Water
                                                    discharge is directly related to the water depth above the bottom of
                                                    the notch.
                                                </Typography>
                                            </>
                                        )}
                                        {irrigation_method === "Furrow Polypipe, Pipe" && (
                                            <>
                                                <Typography className={classes.dialogTitle} variant="title">
                                                    Furrow Polypipe, Pipe Application Rate
                                                </Typography>
                                                <Typography variant="body1">
                                                    This method is intended for estimating application rates in furrow
                                                    irrigation with solid pipes or polypipes.
                                                </Typography>
                                            </>
                                        )}
                                        {irrigation_method === "Surge Irrigation" && (
                                            <>
                                                <Typography className={classes.dialogTitle} variant="title">
                                                    Surge Irrigation Application Rate
                                                </Typography>
                                                <Typography variant="body1">
                                                    This method calculates the application rate for a surge furrow
                                                    irrigation system based on information for a single set.
                                                </Typography>
                                            </>
                                        )}
                                        {irrigation_method === "Center Pivot" && (
                                            <>
                                                <Typography className={classes.dialogTitle} variant="title">
                                                    Center Pivot Application Rate
                                                </Typography>
                                                <Typography variant="body1">
                                                    This method is for circular center pivots. For linear or lateral
                                                    irrigation equipment, please select the Sprinkler irrigation method.
                                                </Typography>
                                            </>
                                        )}
                                        {irrigation_method === "Sprinkler" && (
                                            <>
                                                <Typography className={classes.dialogTitle} variant="title">
                                                    Sprinkler
                                                </Typography>
                                                <Typography variant="body1">
                                                    This estimator works for calculating the application rate of
                                                    sprinklers spaced at uniform distances. If there is only one
                                                    sprinkler line, then line spacing should be the field length that
                                                    the sprinkler line will travel.
                                                </Typography>
                                            </>
                                        )}
                                        {irrigation_method === "Drip Line" && (
                                            <>
                                                <Typography className={classes.dialogTitle} variant="title">
                                                    Drip Line Application Rate
                                                </Typography>
                                                <Typography variant="body1">
                                                    Drip irrigation consists of tape, pipes, or tubing through which
                                                    water flows on or below the surface to deliver water to crops. The
                                                    irrigation application rate can be estimated if the flow rate from
                                                    the emitters, the spacing of the emitters along the drip line, and
                                                    the spacing between the drip lines is known.
                                                </Typography>
                                            </>
                                        )}
                                        {irrigation_method === "Rectangular Submerged Orifices" && (
                                            <>
                                                <Typography className={classes.dialogTitle} variant="title">
                                                    Rectangular Submerged Orifices
                                                </Typography>
                                                <Typography variant="body1">
                                                    This water delivery system consists of a well-defined underwater
                                                    opening in a wall or bulkhead through which water passes. The water
                                                    level on the upstream side is higher than the water level on the
                                                    downstream side.
                                                </Typography>
                                            </>
                                        )}
                                    </Grid>
                                    {irrigation_method === "Rectangular Submerged Orifices" && (
                                        <>
                                            <Grid item xs={6}>
                                                <img
                                                    src={submerged}
                                                    className={classes.helpimages}
                                                    alt="Rectangular Submerged Orifice"
                                                />
                                                <Typography variant="caption">
                                                    Diagram of Rectangular Submerged Orifice
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.area"
                                                    label="Area"
                                                    units="sqft"
                                                    eventHandle={value => {
                                                        const parsedInt = parseInt(value.split(" ")[0]);
                                                        if (parsedInt) {
                                                            this.update_irrigation(
                                                                parsedInt.toString() + " sqft",
                                                                "irrigation.wateruse.area"
                                                            );
                                                            this.form.setValue(
                                                                "irrigation.wateruse.area",
                                                                parsedInt.toString() + " sqft"
                                                            );
                                                        } else {
                                                            this.update_irrigation(value, "irrigation.wateruse.area");
                                                        }
                                                        this.updateWeir4();
                                                    }}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.height"
                                                    label="Height change"
                                                    units="ft"
                                                    eventHandle={value => {
                                                        const v = Math.round(parseFloat(value.split(" ")[0]) * 10) / 10;
                                                        this.update_irrigation(v + " ft", "irrigation.wateruse.height");
                                                        this.form.setValue("irrigation.wateruse.height", v + " ft");
                                                        this.updateWeir4();
                                                    }}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.flow_rate"
                                                    label="Flow rate"
                                                    units="cfs"
                                                    fullWidth
                                                    margin="normal"
                                                    disabled
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.flow_rate_gpm"
                                                    label="Flow rate"
                                                    units="gpm"
                                                    fullWidth
                                                    margin="normal"
                                                    disabled
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {irrigation_method === "Center Pivot" && (
                                        <>
                                            <Grid item xs={6}>
                                                <img src={pivot} className={classes.helpimages} alt="Center Pivot" />
                                                <Typography variant="caption">Picture of Center Pivot</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.water_flow_rate"
                                                    label="Water flow rate at field level"
                                                    units="gpm"
                                                    eventHandle={value => {
                                                        const parsedInt = parseInt(value.split(" ")[0]);
                                                        if (parsedInt) {
                                                            this.update_irrigation(
                                                                parsedInt.toString() + " gpm",
                                                                "irrigation.wateruse.water_flow_rate"
                                                            );
                                                            this.form.setValue(
                                                                "irrigation.wateruse.water_flow_rate",
                                                                parsedInt.toString() + " gpm"
                                                            );
                                                        } else {
                                                            this.update_irrigation(
                                                                value,
                                                                "irrigation.wateruse.water_flow_rate"
                                                            );
                                                        }
                                                        this.updatePivot();
                                                    }}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.pivot_hours"
                                                    label="Total hours of center pivot operation"
                                                    help="Please enter the total number of hours the center pivot was running wet during the growing season. Center pivot total operation hours typically vary across the nation from 500 to 2,500 hours per season depending on the crop, location, and annual climatic conditions."
                                                    units="hours"
                                                    eventHandle={value => {
                                                        const parsedInt = parseInt(value.split(" ")[0]);
                                                        if (parsedInt) {
                                                            this.update_irrigation(
                                                                parsedInt.toString() + " hours",
                                                                "irrigation.wateruse.pivot_hours"
                                                            );
                                                            this.form.setValue(
                                                                "irrigation.wateruse.pivot_hours",
                                                                parsedInt.toString() + " hours"
                                                            );
                                                        } else {
                                                            this.update_irrigation(
                                                                value,
                                                                "irrigation.wateruse.pivot_hours"
                                                            );
                                                        }
                                                        this.updatePivot();
                                                    }}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.field_acre"
                                                    label="Field acreage"
                                                    units="ac"
                                                    fullWidth
                                                    /*eventHandle={value => {
                                                        this.update_irrigation(value, "irrigation.wateruse.field_acre");
                                                        this.updatePivot();
                                                    }}*/
                                                    disabled
                                                    margin="normal"
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.subtotal"
                                                    label="Water applied per acre"
                                                    units="acre_inch / acre"
                                                    fullWidth
                                                    margin="normal"
                                                    disabled
                                                />
                                                <Typography style={{ textAlign: "left" }}>
                                                    Enter The Percent Of Irrigation Water Recovery, If Any.
                                                </Typography>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.recovery"
                                                    label="Water recovery"
                                                    help={
                                                        <div>
                                                            This applies when you have installed a water recovery
                                                            system, such as a tailwater return system, where runoff
                                                            water is stored, reused, or passed to downstream water
                                                            users.
                                                            <br />
                                                            <br />
                                                            Please indicate what percentage of applied irrigation water
                                                            is recovered by the system. The recovered water amount
                                                            usually does not exceed 25 percent of the irrigation rate.
                                                        </div>
                                                    }
                                                    units="%"
                                                    eventHandle={value => {
                                                        this.update_irrigation(value, "irrigation.wateruse.recovery");
                                                        this.updatePivot();
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.estimated_water_use"
                                                    label="Estimated water use"
                                                    units="acre_inch / acre"
                                                    margin="normal"
                                                    fullWidth
                                                    disabled
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {irrigation_method === "90° Triangular Notch Weir" && (
                                        <>
                                            <Grid item xs={6}>
                                                <img
                                                    src={triangular}
                                                    className={classes.helpimages}
                                                    alt="90 degree Triangular Notch Weir"
                                                />
                                                <Typography variant="caption">
                                                    Diagram of 90 degree Triangular Notch Weir
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.height"
                                                    label="Height"
                                                    units="ft"
                                                    eventHandle={value => {
                                                        const v = Math.round(parseFloat(value.split(" ")[0]) * 10) / 10;
                                                        this.update_irrigation(v + " ft", "irrigation.wateruse.height");
                                                        this.form.setValue("irrigation.wateruse.height", v + " ft");
                                                        this.updateWeir3();
                                                    }}
                                                    help="The height is determined by measuring the water height above the bottom of the notch."
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.flow_rate"
                                                    label="Flow rate"
                                                    units="cfs"
                                                    fullWidth
                                                    margin="normal"
                                                    disabled
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.flow_rate_gpm"
                                                    label="Flow rate"
                                                    units="gpm"
                                                    fullWidth
                                                    margin="normal"
                                                    disabled
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {irrigation_method === "Rectangular Contracted Weir" && (
                                        <>
                                            <Grid item xs={6}>
                                                <img
                                                    src={rectangular}
                                                    className={classes.helpimages}
                                                    alt="Rectangular Contracted Weir"
                                                />
                                                <Typography variant="caption">
                                                    Diagram of a Rectangular Contracted Weir
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.length"
                                                    label="Length"
                                                    units="ft"
                                                    eventHandle={value => {
                                                        const v = Math.round(parseFloat(value.split(" ")[0]) * 10) / 10;
                                                        this.update_irrigation(v + " ft", "irrigation.wateruse.length");
                                                        this.form.setValue("irrigation.wateruse.length", v + " ft");
                                                        this.updateWeir2();
                                                    }}
                                                    help="The length is determined by measuring the bottom width of the weir."
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.height"
                                                    label="Height"
                                                    help="The height is measured from the water height above the bottom of the weir."
                                                    units="ft"
                                                    eventHandle={value => {
                                                        const v = Math.round(parseFloat(value.split(" ")[0]) * 10) / 10;
                                                        this.update_irrigation(v + " ft", "irrigation.wateruse.height");
                                                        this.form.setValue("irrigation.wateruse.height", v + " ft");
                                                        this.updateWeir2();
                                                    }}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.flow_rate"
                                                    label="Flow rate"
                                                    units="cfs"
                                                    fullWidth
                                                    margin="normal"
                                                    disabled
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.flow_rate_gpm"
                                                    label="Flow rate"
                                                    units="gpm"
                                                    fullWidth
                                                    margin="normal"
                                                    disabled
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {irrigation_method === "Cipolletti (Trapezoidal) Weir" && (
                                        <>
                                            <Grid item xs={6}>
                                                <img
                                                    src={cipolletti}
                                                    className={classes.helpimages}
                                                    alt="Cipolletti Weir"
                                                />
                                                <Typography variant="caption">Diagram of a Cipolletti Weir</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.length"
                                                    label="Length"
                                                    units="ft"
                                                    eventHandle={value => {
                                                        const v = Math.round(parseFloat(value.split(" ")[0]) * 10) / 10;
                                                        this.update_irrigation(v + " ft", "irrigation.wateruse.length");
                                                        this.form.setValue("irrigation.wateruse.length", v + " ft");
                                                        this.updateWeir();
                                                    }}
                                                    help="The length is determined by measuring the bottom width of the weir."
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.height"
                                                    label="Height"
                                                    units="ft"
                                                    eventHandle={value => {
                                                        const v = Math.round(parseFloat(value.split(" ")[0]) * 10) / 10;
                                                        this.update_irrigation(v + " ft", "irrigation.wateruse.height");
                                                        this.form.setValue("irrigation.wateruse.height", v + " ft");
                                                        this.updateWeir();
                                                    }}
                                                    help="The height is measured from the water height above the bottom of the weir."
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.flow_rate"
                                                    label="Flow rate"
                                                    units="cfs"
                                                    fullWidth
                                                    margin="normal"
                                                    disabled
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.flow_rate_gpm"
                                                    label="Flow rate"
                                                    units="gpm"
                                                    fullWidth
                                                    margin="normal"
                                                    disabled
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {(irrigation_method === "Cipolletti (Trapezoidal) Weir" ||
                                        irrigation_method === "Rectangular Contracted Weir" ||
                                        irrigation_method === "90° Triangular Notch Weir" ||
                                        irrigation_method === "Rectangular Submerged Orifices") && (
                                        <>
                                            <Grid item xs={12}>
                                                <Divider className={classes.divider} />
                                                <Typography className={classes.dialogTitle} variant="title">
                                                    Irrigation Events
                                                </Typography>
                                                <Typography variant="body1">
                                                    Next, determine the amount of water in acre-inches per acre applied.
                                                    You can enter individual irrigation timing events or use an average
                                                    irrigation time.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    onClick={e => {
                                                        this.update_irrigation(
                                                            false,
                                                            "irrigation.wateruse.use_individual_timings"
                                                        );
                                                        this.clearTotals();
                                                        this.updateAverageWeir();
                                                    }}
                                                    color="secondary"
                                                    variant={!use_individual_timings ? "raised" : "text"}
                                                    className={classes.divideButton}>
                                                    Use Average Event Timing
                                                </Button>
                                                <Button
                                                    onClick={e => {
                                                        this.update_irrigation(
                                                            true,
                                                            "irrigation.wateruse.use_individual_timings"
                                                        );
                                                        this.clearTotals();
                                                        this.setState({ runcalcWeir: true });
                                                    }}
                                                    color="secondary"
                                                    variant={use_individual_timings ? "raised" : "text"}
                                                    className={classes.divideButton}>
                                                    Use Individual Event Timings
                                                </Button>
                                            </Grid>
                                            {!use_individual_timings && (
                                                <Paper
                                                    style={{ width: "100%", padding: 8, margin: 8 }}
                                                    className={classes.inputStyle}>
                                                    <Grid container spacing={16}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                type="number"
                                                                field="irrigation.wateruse.total_irrigation_events"
                                                                label="Total number of irrigation events"
                                                                margin="normal"
                                                                inlineLabel
                                                                eventHandle={value => {
                                                                    this.update_irrigation(
                                                                        value,
                                                                        "irrigation.wateruse.total_irrigation_events"
                                                                    );
                                                                    this.updateAverageWeir();
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right" }}>
                                                            <TextField
                                                                type="number"
                                                                field="irrigation.wateruse.average_time_irrigating"
                                                                label="Average time irrigating per event"
                                                                units="hours"
                                                                margin="normal"
                                                                inlineLabel
                                                                eventHandle={value => {
                                                                    this.update_irrigation(
                                                                        value,
                                                                        "irrigation.wateruse.average_time_irrigating"
                                                                    );
                                                                    this.updateAverageWeir();
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            )}
                                            {use_individual_timings && (
                                                <Paper style={{ width: "100%", padding: 8, margin: 8 }}>
                                                    <TimingsTable
                                                        classes={classes}
                                                        fieldActivity={fieldActivity}
                                                        timings={timings}
                                                        ormActivityIrrigationWaterUseEventCreate={
                                                            ormActivityIrrigationWaterUseEventCreate
                                                        }
                                                        ormActivityIrrigationWaterUseEventCreateRemoteFirst={
                                                            ormActivityIrrigationWaterUseEventCreateRemoteFirst
                                                        }
                                                        authState={authState}
                                                        ormActivityIrrigationWaterUseEventDelete={
                                                            ormActivityIrrigationWaterUseEventDelete
                                                        }
                                                        ormActivityIrrigationWaterUseEventUpdate={
                                                            ormActivityIrrigationWaterUseEventUpdate
                                                        }
                                                        updateTimings={this.updateTimingsWeir}
                                                        calcedField="irrigation.wateruse.flow_rate_gpm"
                                                        units="gallons"
                                                        ref={this.timingsTable}
                                                    />
                                                </Paper>
                                            )}
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.inputStyle}
                                                style={{ textAlign: "right" }}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.subtotal_gallons"
                                                    label="Total"
                                                    units="gallons"
                                                    margin="normal"
                                                    inlineLabel
                                                    disabled
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.subtotal"
                                                    label="Total"
                                                    units="acre_inch / acre"
                                                    margin="normal"
                                                    inlineLabel
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.inputStyle}
                                                style={{ textAlign: "right" }}>
                                                <Divider className={classes.divider} />
                                                <Typography
                                                    className={classes.dialogTitle}
                                                    variant="title"
                                                    style={{ textAlign: "left" }}>
                                                    Enter The Percent Of Irrigation Water Recovery, If Any.
                                                </Typography>
                                                <Typography variant="body1" style={{ textAlign: "left" }}>
                                                    If you utilize a water recovery system, indicate the percent of
                                                    recovery.
                                                </Typography>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.recovery"
                                                    label="Water recovery"
                                                    help={
                                                        <div>
                                                            This applies when you have installed a water recovery
                                                            system, such as a tailwater return system, where runoff
                                                            water is stored, reused, or passed to downstream water
                                                            users.
                                                            <br />
                                                            <br />
                                                            Please indicate what percentage of applied irrigation water
                                                            is recovered by the system. The recovered water amount
                                                            usually does not exceed 25 percent of the irrigation rate.
                                                        </div>
                                                    }
                                                    units="%"
                                                    margin="normal"
                                                    inlineLabel
                                                    style={{ textAlign: "right" }}
                                                    eventHandle={value => {
                                                        this.update_irrigation(value, "irrigation.wateruse.recovery");
                                                        if (use_individual_timings) this.updateTimingsWeir();
                                                        else this.updateAverageWeir();
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.inputStyle}
                                                style={{ textAlign: "right" }}>
                                                <Divider className={classes.divider} />
                                                <Typography
                                                    className={classes.dialogTitle}
                                                    variant="title"
                                                    style={{ textAlign: "left" }}>
                                                    Your Total Estimated Water Use
                                                </Typography>
                                                <Typography variant="body1" style={{ textAlign: "left" }}>
                                                    When finished, select Save Data and Close Estimator and the value
                                                    estimated will be copied to the previous page. If you selected two
                                                    irrigation water sources, you will need to allocate the total
                                                    estimated water across the sources.
                                                </Typography>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.estimated_water_use"
                                                    label="Estimated water use"
                                                    units="acre_inch / acre"
                                                    margin="normal"
                                                    inlineLabel
                                                    disabled
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {irrigation_method === "Surge Irrigation" && (
                                        <>
                                            <Grid item xs={6}>
                                                <img
                                                    src={furrowsurge}
                                                    className={classes.helpimages}
                                                    alt="Surge Irrigation"
                                                />
                                                <Typography variant="caption">Diagram of Surge Irrigation</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.field_flow_rate"
                                                    label="Water flow rate at the field level"
                                                    units="gpm"
                                                    eventHandle={value => {
                                                        const parsedInt = parseInt(value.split(" ")[0]);
                                                        if (parsedInt) {
                                                            this.update_irrigation(
                                                                parsedInt.toString() + " gpm",
                                                                "irrigation.wateruse.field_flow_rate"
                                                            );
                                                            this.form.setValue(
                                                                "irrigation.wateruse.field_flow_rate",
                                                                parsedInt.toString() + " gpm"
                                                            );
                                                        } else {
                                                            this.update_irrigation(
                                                                value,
                                                                "irrigation.wateruse.field_flow_rate"
                                                            );
                                                        }
                                                        this.updateSurge();
                                                    }}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.rate_per_hour"
                                                    label="Application rate"
                                                    units="acre_inch / hour"
                                                    fullWidth
                                                    disabled
                                                    margin="normal"
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.furrow_spacing"
                                                    label="Furrow spacing"
                                                    units="ft"
                                                    help="Furrow spacing expressed in feet. A 30-inch furrow spacing equals 2.5 feet."
                                                    fullWidth
                                                    eventHandle={value => {
                                                        const v = Math.round(parseFloat(value.split(" ")[0]) * 10) / 10;
                                                        this.update_irrigation(
                                                            v + " ft",
                                                            "irrigation.wateruse.furrow_spacing"
                                                        );
                                                        this.form.setValue(
                                                            "irrigation.wateruse.furrow_spacing",
                                                            v + " ft"
                                                        );
                                                        this.updateSurge();
                                                    }}
                                                    margin="normal"
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.number_rows"
                                                    label="Number of rows per set"
                                                    units="rows"
                                                    help="Please indicate the number of planted rows per irrigation set."
                                                    eventHandle={value => {
                                                        const parsedInt = parseInt(value.split(" ")[0]);
                                                        if (parsedInt) {
                                                            this.update_irrigation(
                                                                parsedInt.toString() + " rows",
                                                                "irrigation.wateruse.number_rows"
                                                            );
                                                            this.form.setValue(
                                                                "irrigation.wateruse.number_rows",
                                                                parsedInt.toString() + " rows"
                                                            );
                                                        } else {
                                                            this.update_irrigation(
                                                                value,
                                                                "irrigation.wateruse.number_rows"
                                                            );
                                                        }
                                                        this.updateSurge();
                                                    }}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.furrow_length"
                                                    label="Furrow length"
                                                    units="ft"
                                                    eventHandle={value => {
                                                        const parsedInt = parseInt(value.split(" ")[0]);
                                                        if (parsedInt) {
                                                            this.update_irrigation(
                                                                parsedInt.toString() + " ft",
                                                                "irrigation.wateruse.furrow_length"
                                                            );
                                                            this.form.setValue(
                                                                "irrigation.wateruse.furrow_length",
                                                                parsedInt.toString() + " ft"
                                                            );
                                                        } else {
                                                            this.update_irrigation(
                                                                value,
                                                                "irrigation.wateruse.furrow_length"
                                                            );
                                                        }
                                                        this.updateSurge();
                                                    }}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.acres_per_set"
                                                    label="Acres per set"
                                                    units="acres / set"
                                                    fullWidth
                                                    margin="normal"
                                                    disabled
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.rate"
                                                    label="Application amount per hour"
                                                    units="acre_inch / acre / hour"
                                                    fullWidth
                                                    margin="normal"
                                                    disabled
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {irrigation_method === "Surge Irrigation" && (
                                        <>
                                            <Grid item xs={12}>
                                                <Divider className={classes.divider} />
                                                <Typography className={classes.dialogTitle} variant="title">
                                                    Irrigation Events
                                                </Typography>
                                                <Typography variant="body1">
                                                    Next, determine the amount of water in acre-inches per acre applied.
                                                    You can enter individual irrigation timing events or use an average
                                                    irrigation time.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    onClick={e => {
                                                        this.update_irrigation(
                                                            false,
                                                            "irrigation.wateruse.use_individual_timings"
                                                        );
                                                        this.clearTotals();
                                                        this.updateAverageSurge();
                                                    }}
                                                    color="secondary"
                                                    variant={!use_individual_timings ? "raised" : "text"}
                                                    className={classes.divideButton}>
                                                    Use Average Event Timing
                                                </Button>
                                                <Button
                                                    onClick={e => {
                                                        this.update_irrigation(
                                                            true,
                                                            "irrigation.wateruse.use_individual_timings"
                                                        );
                                                        this.clearTotals();
                                                        this.setState({ runcalcSurge: true });
                                                    }}
                                                    color="secondary"
                                                    variant={use_individual_timings ? "raised" : "text"}
                                                    className={classes.divideButton}>
                                                    Use Individual Event Timings
                                                </Button>
                                            </Grid>
                                            {!use_individual_timings && (
                                                <Paper
                                                    style={{ width: "100%", padding: 8, margin: 8 }}
                                                    className={classes.inputStyle}>
                                                    <Grid container spacing={16}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                type="number"
                                                                field="irrigation.wateruse.total_irrigation_events"
                                                                label="Total number of irrigation events"
                                                                margin="normal"
                                                                inlineLabel
                                                                eventHandle={value => {
                                                                    this.update_irrigation(
                                                                        value,
                                                                        "irrigation.wateruse.total_irrigation_events"
                                                                    );
                                                                    this.updateAverageSurge();
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right" }}>
                                                            <TextField
                                                                type="number"
                                                                field="irrigation.wateruse.average_time_irrigating"
                                                                label="Average time irrigating per event"
                                                                units="hours"
                                                                margin="normal"
                                                                inlineLabel
                                                                eventHandle={value => {
                                                                    this.update_irrigation(
                                                                        value,
                                                                        "irrigation.wateruse.average_time_irrigating"
                                                                    );
                                                                    this.updateAverageSurge();
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            )}
                                            {use_individual_timings && (
                                                <Paper style={{ width: "100%", padding: 8, margin: 8 }}>
                                                    <TimingsTable
                                                        classes={classes}
                                                        fieldActivity={fieldActivity}
                                                        timings={timings}
                                                        ormActivityIrrigationWaterUseEventCreate={
                                                            ormActivityIrrigationWaterUseEventCreate
                                                        }
                                                        ormActivityIrrigationWaterUseEventCreateRemoteFirst={
                                                            ormActivityIrrigationWaterUseEventCreateRemoteFirst
                                                        }
                                                        authState={authState}
                                                        ormActivityIrrigationWaterUseEventDelete={
                                                            ormActivityIrrigationWaterUseEventDelete
                                                        }
                                                        ormActivityIrrigationWaterUseEventUpdate={
                                                            ormActivityIrrigationWaterUseEventUpdate
                                                        }
                                                        updateTimings={this.updateTimingsSurge}
                                                        calcedField="irrigation.wateruse.rate"
                                                        ref={this.timingsTable}
                                                    />
                                                </Paper>
                                            )}
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.inputStyle}
                                                style={{ textAlign: "right" }}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.subtotal"
                                                    label="Total"
                                                    units="acre_inch / acre"
                                                    margin="normal"
                                                    inlineLabel
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.inputStyle}
                                                style={{ textAlign: "right" }}>
                                                <Divider className={classes.divider} />
                                                <Typography
                                                    className={classes.dialogTitle}
                                                    variant="title"
                                                    style={{ textAlign: "left" }}>
                                                    Enter The Percent Of Irrigation Water Recovery, If Any.
                                                </Typography>
                                                <Typography variant="body1" style={{ textAlign: "left" }}>
                                                    If you utilize a water recovery system, indicate the percent of
                                                    recovery.
                                                </Typography>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.recovery"
                                                    label="Water recovery"
                                                    help={
                                                        <div>
                                                            This applies when you have installed a water recovery
                                                            system, such as a tailwater return system, where runoff
                                                            water is stored, reused, or passed to downstream water
                                                            users.
                                                            <br />
                                                            <br />
                                                            Please indicate what percentage of applied irrigation water
                                                            is recovered by the system. The recovered water amount
                                                            usually does not exceed 25 percent of the irrigation rate.
                                                        </div>
                                                    }
                                                    units="%"
                                                    margin="normal"
                                                    inlineLabel
                                                    style={{ textAlign: "right" }}
                                                    eventHandle={value => {
                                                        this.update_irrigation(value, "irrigation.wateruse.recovery");
                                                        if (use_individual_timings) this.updateTimingsSurge();
                                                        else this.updateAverageSurge();
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.inputStyle}
                                                style={{ textAlign: "right" }}>
                                                <Divider className={classes.divider} />
                                                <Typography
                                                    className={classes.dialogTitle}
                                                    variant="title"
                                                    style={{ textAlign: "left" }}>
                                                    Your Total Estimated Water Use
                                                </Typography>
                                                <Typography variant="body1" style={{ textAlign: "left" }}>
                                                    When finished, select Save Data and Close Estimator and the value
                                                    estimated will be copied to the previous page. If you selected two
                                                    irrigation water sources, you will need to allocate the total
                                                    estimated water across the sources.
                                                </Typography>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.estimated_water_use"
                                                    label="Estimated water use"
                                                    units="acre_inch / acre"
                                                    margin="normal"
                                                    inlineLabel
                                                    disabled
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {irrigation_method === "Furrow Polypipe, Pipe" && (
                                        <>
                                            <Grid item xs={6}>
                                                <img
                                                    src={furrow}
                                                    className={classes.helpimages}
                                                    alt="Furrow Irrigation"
                                                />
                                                <Typography variant="caption">Picture of Furrow Irrigation</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.field_flow_rate"
                                                    label="Water flow rate at the field level"
                                                    units="gpm"
                                                    eventHandle={value => {
                                                        const parsedInt = parseInt(value.split(" ")[0]);
                                                        if (parsedInt) {
                                                            this.update_irrigation(
                                                                parsedInt.toString() + " gpm",
                                                                "irrigation.wateruse.field_flow_rate"
                                                            );
                                                            this.form.setValue(
                                                                "irrigation.wateruse.field_flow_rate",
                                                                parsedInt.toString() + " gpm"
                                                            );
                                                        } else {
                                                            this.update_irrigation(
                                                                value,
                                                                "irrigation.wateruse.field_flow_rate"
                                                            );
                                                        }
                                                        this.updateFurrow();
                                                    }}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.number_rows"
                                                    label="Number of rows per set"
                                                    units="rows"
                                                    help="Please indicate the number of planted rows per irrigation set."
                                                    eventHandle={value => {
                                                        const parsedInt = parseInt(value.split(" ")[0]);
                                                        if (parsedInt) {
                                                            this.update_irrigation(
                                                                parsedInt.toString() + " rows",
                                                                "irrigation.wateruse.number_rows"
                                                            );
                                                            this.form.setValue(
                                                                "irrigation.wateruse.number_rows",
                                                                parsedInt.toString() + " rows"
                                                            );
                                                        } else {
                                                            this.update_irrigation(
                                                                value,
                                                                "irrigation.wateruse.number_rows"
                                                            );
                                                        }
                                                        this.updateFurrow();
                                                    }}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.furrow_length"
                                                    label="Furrow length"
                                                    units="ft"
                                                    eventHandle={value => {
                                                        const parsedInt = parseInt(value.split(" ")[0]);
                                                        if (parsedInt) {
                                                            this.update_irrigation(
                                                                parsedInt.toString() + " ft",
                                                                "irrigation.wateruse.furrow_length"
                                                            );
                                                            this.form.setValue(
                                                                "irrigation.wateruse.furrow_length",
                                                                parsedInt.toString() + " ft"
                                                            );
                                                        } else {
                                                            this.update_irrigation(
                                                                value,
                                                                "irrigation.wateruse.furrow_length"
                                                            );
                                                        }
                                                        this.updateFurrow();
                                                    }}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.furrow_spacing"
                                                    label="Furrow spacing"
                                                    units="ft"
                                                    help="Furrow spacing expressed in feet. A 30-inch furrow spacing equals 2.5 feet."
                                                    fullWidth
                                                    eventHandle={value => {
                                                        const v = Math.round(parseFloat(value.split(" ")[0]) * 10) / 10;
                                                        this.update_irrigation(
                                                            v + " ft",
                                                            "irrigation.wateruse.furrow_spacing"
                                                        );
                                                        this.form.setValue(
                                                            "irrigation.wateruse.furrow_spacing",
                                                            v + " ft"
                                                        );
                                                        this.updateFurrow();
                                                    }}
                                                    margin="normal"
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.rate_per_hour"
                                                    label="Application rate"
                                                    units="acre_inch / hour"
                                                    fullWidth
                                                    margin="normal"
                                                    disabled
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {irrigation_method === "Furrow Polypipe, Pipe" && (
                                        <>
                                            <Grid item xs={12}>
                                                <Divider className={classes.divider} />
                                                <Typography className={classes.dialogTitle} variant="title">
                                                    Irrigation Events
                                                </Typography>
                                                <Typography variant="body1">
                                                    Next, determine the amount of water in acre-inches per acre applied.
                                                    You can enter individual irrigation timing events or use an average
                                                    irrigation time.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    onClick={e => {
                                                        this.update_irrigation(
                                                            false,
                                                            "irrigation.wateruse.use_individual_timings"
                                                        );
                                                        this.clearTotals();
                                                        this.updateAverageFurrow();
                                                    }}
                                                    color="secondary"
                                                    variant={!use_individual_timings ? "raised" : "text"}
                                                    className={classes.divideButton}>
                                                    Use Average Event Timing
                                                </Button>
                                                <Button
                                                    onClick={e => {
                                                        this.update_irrigation(
                                                            true,
                                                            "irrigation.wateruse.use_individual_timings"
                                                        );
                                                        this.clearTotals();
                                                        this.setState({ runcalcFurrow: true });
                                                    }}
                                                    color="secondary"
                                                    variant={use_individual_timings ? "raised" : "text"}
                                                    className={classes.divideButton}>
                                                    Use Individual Event Timings
                                                </Button>
                                            </Grid>
                                            {!use_individual_timings && (
                                                <Paper
                                                    style={{ width: "100%", padding: 8, margin: 8 }}
                                                    className={classes.inputStyle}>
                                                    <Grid container spacing={16}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                type="number"
                                                                field="irrigation.wateruse.total_irrigation_events"
                                                                label="Total number of irrigation events"
                                                                margin="normal"
                                                                inlineLabel
                                                                eventHandle={value => {
                                                                    this.update_irrigation(
                                                                        value,
                                                                        "irrigation.wateruse.total_irrigation_events"
                                                                    );
                                                                    this.updateAverageFurrow();
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right" }}>
                                                            <TextField
                                                                type="number"
                                                                field="irrigation.wateruse.average_time_irrigating"
                                                                label="Average time irrigating per event"
                                                                units="hours"
                                                                margin="normal"
                                                                inlineLabel
                                                                eventHandle={value => {
                                                                    this.update_irrigation(
                                                                        value,
                                                                        "irrigation.wateruse.average_time_irrigating"
                                                                    );
                                                                    this.updateAverageFurrow();
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            )}
                                            {use_individual_timings && (
                                                <Paper style={{ width: "100%", padding: 8, margin: 8 }}>
                                                    <TimingsTable
                                                        classes={classes}
                                                        fieldActivity={fieldActivity}
                                                        timings={timings}
                                                        ormActivityIrrigationWaterUseEventCreate={
                                                            ormActivityIrrigationWaterUseEventCreate
                                                        }
                                                        ormActivityIrrigationWaterUseEventCreateRemoteFirst={
                                                            ormActivityIrrigationWaterUseEventCreateRemoteFirst
                                                        }
                                                        authState={authState}
                                                        ormActivityIrrigationWaterUseEventDelete={
                                                            ormActivityIrrigationWaterUseEventDelete
                                                        }
                                                        ormActivityIrrigationWaterUseEventUpdate={
                                                            ormActivityIrrigationWaterUseEventUpdate
                                                        }
                                                        updateTimings={this.updateTimingsFurrow}
                                                        calcedField="irrigation.wateruse.rate_per_hour"
                                                        ref={this.timingsTable}
                                                    />
                                                </Paper>
                                            )}
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.inputStyle}
                                                style={{ textAlign: "right" }}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.subtotal"
                                                    label="Total"
                                                    units="acre_inch / acre"
                                                    margin="normal"
                                                    inlineLabel
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.inputStyle}
                                                style={{ textAlign: "right" }}>
                                                <Divider className={classes.divider} />
                                                <Typography
                                                    className={classes.dialogTitle}
                                                    variant="title"
                                                    style={{ textAlign: "left" }}>
                                                    Enter The Percent Of Irrigation Water Recovery, If Any.
                                                </Typography>
                                                <Typography variant="body1" style={{ textAlign: "left" }}>
                                                    If you utilize a water recovery system, indicate the percent of
                                                    recovery.
                                                </Typography>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.recovery"
                                                    label="Water recovery"
                                                    help={
                                                        <div>
                                                            This applies when you have installed a water recovery
                                                            system, such as a tailwater return system, where runoff
                                                            water is stored, reused, or passed to downstream water
                                                            users.
                                                            <br />
                                                            <br />
                                                            Please indicate what percentage of applied irrigation water
                                                            is recovered by the system. The recovered water amount
                                                            usually does not exceed 25 percent of the irrigation rate.
                                                        </div>
                                                    }
                                                    units="%"
                                                    margin="normal"
                                                    inlineLabel
                                                    style={{ textAlign: "right" }}
                                                    eventHandle={value => {
                                                        this.update_irrigation(value, "irrigation.wateruse.recovery");
                                                        if (use_individual_timings) this.updateTimingsFurrow();
                                                        else this.updateAverageFurrow();
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.inputStyle}
                                                style={{ textAlign: "right" }}>
                                                <Divider className={classes.divider} />
                                                <Typography
                                                    className={classes.dialogTitle}
                                                    variant="title"
                                                    style={{ textAlign: "left" }}>
                                                    Your Total Estimated Water Use
                                                </Typography>
                                                <Typography variant="body1" style={{ textAlign: "left" }}>
                                                    When finished, select Save Data and Close Estimator and the value
                                                    estimated will be copied to the previous page. If you selected two
                                                    irrigation water sources, you will need to allocate the total
                                                    estimated water across the sources.
                                                </Typography>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.estimated_water_use"
                                                    label="Estimated water use"
                                                    units="acre_inch / acre"
                                                    margin="normal"
                                                    inlineLabel
                                                    disabled
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {irrigation_method === "Drip Line" && (
                                        <>
                                            <Grid item xs={6}>
                                                <img src={drip} className={classes.helpimages} alt="Drip Line" />
                                                <Typography variant="caption">Diagram of Drip Line</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.emitter_flow"
                                                    label="Emitter flow"
                                                    units="gph"
                                                    help="Please enter the emitter flow in gallons/hour (gph). The most common flow rates are 0.5, 1, and 2 gph."
                                                    eventHandle={value => {
                                                        const v = Math.round(parseFloat(value.split(" ")[0]) * 10) / 10;
                                                        this.update_irrigation(
                                                            v + " gph",
                                                            "irrigation.wateruse.emitter_flow"
                                                        );
                                                        this.form.setValue(
                                                            "irrigation.wateruse.emitter_flow",
                                                            v + " gph"
                                                        );
                                                        this.updateDrip();
                                                    }}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.emitter_spacing"
                                                    label="Emitter spacing along the line"
                                                    units="in"
                                                    help="The spacing between emitters in inches. Common emitter spacings are 6, 12, 18, or 24 inches."
                                                    eventHandle={value => {
                                                        const parsedInt = parseInt(value.split(" ")[0]);
                                                        if (parsedInt) {
                                                            this.update_irrigation(
                                                                parsedInt.toString() + " in",
                                                                "irrigation.wateruse.emitter_spacing"
                                                            );
                                                            this.form.setValue(
                                                                "irrigation.wateruse.emitter_spacing",
                                                                parsedInt.toString() + " in"
                                                            );
                                                        } else {
                                                            this.update_irrigation(
                                                                value,
                                                                "irrigation.wateruse.emitter_spacing"
                                                            );
                                                        }
                                                        this.updateDrip();
                                                    }}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.drip_distance"
                                                    label="Distance between drip lines"
                                                    help="The distance between drip lines in inches."
                                                    units="in"
                                                    fullWidth
                                                    eventHandle={value => {
                                                        const parsedInt = parseInt(value.split(" ")[0]);
                                                        if (parsedInt) {
                                                            this.update_irrigation(
                                                                parsedInt.toString() + " in",
                                                                "irrigation.wateruse.drip_distance"
                                                            );
                                                            this.form.setValue(
                                                                "irrigation.wateruse.drip_distance",
                                                                parsedInt.toString() + " in"
                                                            );
                                                        } else {
                                                            this.update_irrigation(
                                                                value,
                                                                "irrigation.wateruse.drip_distance"
                                                            );
                                                        }
                                                        this.updateDrip();
                                                    }}
                                                    margin="normal"
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.drip_application_rate"
                                                    label="Application rate"
                                                    units="acre_inch / hour"
                                                    fullWidth
                                                    margin="normal"
                                                    disabled
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {irrigation_method === "Drip Line" && (
                                        <>
                                            <Grid item xs={12}>
                                                <Divider className={classes.divider} />
                                                <Typography className={classes.dialogTitle} variant="title">
                                                    Irrigation Events
                                                </Typography>
                                                <Typography variant="body1">
                                                    Next, determine the amount of water in acre-inches per acre applied.
                                                    You can enter individual irrigation timing events or use an average
                                                    irrigation time.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    onClick={e => {
                                                        this.update_irrigation(
                                                            false,
                                                            "irrigation.wateruse.use_individual_timings"
                                                        );
                                                        this.clearTotals();
                                                        this.updateAverageDrip();
                                                    }}
                                                    color="secondary"
                                                    variant={!use_individual_timings ? "raised" : "text"}
                                                    className={classes.divideButton}>
                                                    Use Average Event Timing
                                                </Button>
                                                <Button
                                                    onClick={e => {
                                                        this.update_irrigation(
                                                            true,
                                                            "irrigation.wateruse.use_individual_timings"
                                                        );
                                                        this.clearTotals();
                                                        this.setState({ runcalcDrip: true });
                                                    }}
                                                    color="secondary"
                                                    variant={use_individual_timings ? "raised" : "text"}
                                                    className={classes.divideButton}>
                                                    Use Individual Event Timings
                                                </Button>
                                            </Grid>
                                            {!use_individual_timings && (
                                                <Paper
                                                    style={{ width: "100%", padding: 8, margin: 8 }}
                                                    className={classes.inputStyle}>
                                                    <Grid container spacing={16}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                type="number"
                                                                field="irrigation.wateruse.total_irrigation_events"
                                                                label="Total number of irrigation events"
                                                                margin="normal"
                                                                inlineLabel
                                                                eventHandle={value => {
                                                                    this.update_irrigation(
                                                                        value,
                                                                        "irrigation.wateruse.total_irrigation_events"
                                                                    );
                                                                    this.updateAverageDrip();
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right" }}>
                                                            <TextField
                                                                type="number"
                                                                field="irrigation.wateruse.average_time_irrigating"
                                                                label="Average time irrigating per event"
                                                                units="hours"
                                                                margin="normal"
                                                                inlineLabel
                                                                eventHandle={value => {
                                                                    this.update_irrigation(
                                                                        value,
                                                                        "irrigation.wateruse.average_time_irrigating"
                                                                    );
                                                                    this.updateAverageDrip();
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            )}
                                            {use_individual_timings && (
                                                <Paper style={{ width: "100%", padding: 8, margin: 8 }}>
                                                    <TimingsTable
                                                        classes={classes}
                                                        fieldActivity={fieldActivity}
                                                        timings={timings}
                                                        ormActivityIrrigationWaterUseEventCreate={
                                                            ormActivityIrrigationWaterUseEventCreate
                                                        }
                                                        ormActivityIrrigationWaterUseEventCreateRemoteFirst={
                                                            ormActivityIrrigationWaterUseEventCreateRemoteFirst
                                                        }
                                                        authState={authState}
                                                        ormActivityIrrigationWaterUseEventDelete={
                                                            ormActivityIrrigationWaterUseEventDelete
                                                        }
                                                        ormActivityIrrigationWaterUseEventUpdate={
                                                            ormActivityIrrigationWaterUseEventUpdate
                                                        }
                                                        updateTimings={this.updateTimingsDrip}
                                                        calcedField="irrigation.wateruse.drip_application_rate"
                                                        ref={this.timingsTable}
                                                    />
                                                </Paper>
                                            )}
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.inputStyle}
                                                style={{ textAlign: "right" }}>
                                                <Divider className={classes.divider} />
                                                <Typography
                                                    className={classes.dialogTitle}
                                                    variant="title"
                                                    style={{ textAlign: "left" }}>
                                                    Your Total Estimated Water Use
                                                </Typography>
                                                <Typography variant="body1" style={{ textAlign: "left" }}>
                                                    When finished, select Save Data and Close Estimator and the value
                                                    estimated will be copied to the previous page. If you selected two
                                                    irrigation water sources, you will need to allocate the total
                                                    estimated water across the sources.
                                                </Typography>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.estimated_water_use"
                                                    label="Estimated water use"
                                                    units="acre_inch / acre"
                                                    margin="normal"
                                                    inlineLabel
                                                    disabled
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {irrigation_method === "Sprinkler" && (
                                        <>
                                            <Grid item xs={6}>
                                                <img src={sprinkler} className={classes.helpimages} alt="Sprinkler" />
                                                <Typography variant="caption">Picture of Sprinkler</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select
                                                    field="irrigation.wateruse.nozzle_diameter"
                                                    label="Nozzle diameter"
                                                    units="in"
                                                    help="Please choose the nozzle diameter from the dropdown menu. If you do not see your nozzle diameter, please choose the nozzle diameter that best approximates the nozzles in your operation. Please notify Paul Hishmeh at phishmeh@fieldtomarket.org to add your nozzle diameter to the list."
                                                    options={nozzle_diameters}
                                                    eventHandle={value => {
                                                        this.update_irrigation(
                                                            value,
                                                            "irrigation.wateruse.nozzle_diameter"
                                                        );
                                                        this.updateSprinkler();
                                                    }}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.pressure"
                                                    label="Nozzle pressure"
                                                    help="Please enter the nozzle pressure."
                                                    units="psi"
                                                    eventHandle={value => {
                                                        this.update_irrigation(value, "irrigation.wateruse.pressure");
                                                        this.updateSprinkler();
                                                    }}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.nozzle_flow_rate"
                                                    label="Nozzle flow rate"
                                                    units="gpm"
                                                    fullWidth
                                                    margin="normal"
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.head_spacing"
                                                    label="Head spacing"
                                                    help="The head spacing is the distance between sprinkler heads along the water line."
                                                    eventHandle={value => {
                                                        this.update_irrigation(
                                                            value,
                                                            "irrigation.wateruse.head_spacing"
                                                        );
                                                        this.updateSprinkler();
                                                    }}
                                                    units="ft"
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.line_spacing"
                                                    label="Line spacing"
                                                    help="Line spacing is the distance between lines in the field."
                                                    units="ft"
                                                    eventHandle={value => {
                                                        this.update_irrigation(
                                                            value,
                                                            "irrigation.wateruse.line_spacing"
                                                        );
                                                        this.updateSprinkler();
                                                    }}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.effective_application_rate"
                                                    label="Effective application rate"
                                                    units="inch / hour"
                                                    fullWidth
                                                    margin="normal"
                                                    disabled
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {irrigation_method === "Sprinkler" && (
                                        <>
                                            <Grid item xs={12}>
                                                <Divider className={classes.divider} />
                                                <Typography className={classes.dialogTitle} variant="title">
                                                    Irrigation Events
                                                </Typography>
                                                <Typography variant="body1">
                                                    Next, determine the amount of water in acre-inches per acre applied.
                                                    You can enter individual irrigation timing events or use an average
                                                    irrigation time.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    onClick={e => {
                                                        this.update_irrigation(
                                                            false,
                                                            "irrigation.wateruse.use_individual_timings"
                                                        );
                                                        this.clearTotals();
                                                        this.updateAverage(irrigation_method);
                                                    }}
                                                    color="secondary"
                                                    variant={!use_individual_timings ? "raised" : "text"}
                                                    className={classes.divideButton}>
                                                    Use Average Event Timing
                                                </Button>
                                                <Button
                                                    onClick={e => {
                                                        this.update_irrigation(
                                                            true,
                                                            "irrigation.wateruse.use_individual_timings"
                                                        );
                                                        this.clearTotals();
                                                        this.setState({ runcalc: true });
                                                    }}
                                                    color="secondary"
                                                    variant={use_individual_timings ? "raised" : "text"}
                                                    className={classes.divideButton}>
                                                    Use Individual Event Timings
                                                </Button>
                                            </Grid>
                                            {!use_individual_timings && (
                                                <Paper
                                                    style={{ width: "100%", padding: 8, margin: 8 }}
                                                    className={classes.inputStyle}>
                                                    <Grid container spacing={16}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                type="number"
                                                                field="irrigation.wateruse.total_irrigation_events"
                                                                label="Total number of irrigation events"
                                                                margin="normal"
                                                                inlineLabel
                                                                eventHandle={value => {
                                                                    this.update_irrigation(
                                                                        value,
                                                                        "irrigation.wateruse.total_irrigation_events"
                                                                    );
                                                                    this.updateAverage(irrigation_method);
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} style={{ textAlign: "right" }}>
                                                            <TextField
                                                                type="number"
                                                                field="irrigation.wateruse.average_time_irrigating"
                                                                label="Average time irrigating per event"
                                                                units="hours"
                                                                margin="normal"
                                                                inlineLabel
                                                                eventHandle={value => {
                                                                    this.update_irrigation(
                                                                        value,
                                                                        "irrigation.wateruse.average_time_irrigating"
                                                                    );
                                                                    this.updateAverage(irrigation_method);
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            )}
                                            {use_individual_timings && (
                                                <Paper style={{ width: "100%", padding: 8, margin: 8 }}>
                                                    <TimingsTable
                                                        classes={classes}
                                                        fieldActivity={fieldActivity}
                                                        timings={timings}
                                                        ormActivityIrrigationWaterUseEventCreate={
                                                            ormActivityIrrigationWaterUseEventCreate
                                                        }
                                                        ormActivityIrrigationWaterUseEventCreateRemoteFirst={
                                                            ormActivityIrrigationWaterUseEventCreateRemoteFirst
                                                        }
                                                        authState={authState}
                                                        ormActivityIrrigationWaterUseEventDelete={
                                                            ormActivityIrrigationWaterUseEventDelete
                                                        }
                                                        ormActivityIrrigationWaterUseEventUpdate={
                                                            ormActivityIrrigationWaterUseEventUpdate
                                                        }
                                                        updateTimings={this.updateTimings}
                                                        calcedField="irrigation.wateruse.effective_application_rate"
                                                        ref={this.timingsTable}
                                                    />
                                                </Paper>
                                            )}
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.inputStyle}
                                                style={{ textAlign: "right" }}>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.subtotal"
                                                    label="Total"
                                                    units="acre_inch / acre"
                                                    margin="normal"
                                                    inlineLabel
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.inputStyle}
                                                style={{ textAlign: "right" }}>
                                                <Divider className={classes.divider} />
                                                <Typography
                                                    className={classes.dialogTitle}
                                                    variant="title"
                                                    style={{ textAlign: "left" }}>
                                                    Enter The Percent Of Irrigation Water Recovery, If Any.
                                                </Typography>
                                                <Typography variant="body1" style={{ textAlign: "left" }}>
                                                    If you utilize a water recovery system, indicate the percent of
                                                    recovery.
                                                </Typography>
                                                <TextField
                                                    type="number"
                                                    field="irrigation.wateruse.recovery"
                                                    label="Water recovery"
                                                    help={
                                                        <div>
                                                            This applies when you have installed a water recovery
                                                            system, such as a tailwater return system, where runoff
                                                            water is stored, reused, or passed to downstream water
                                                            users.
                                                            <br />
                                                            <br />
                                                            Please indicate what percentage of applied irrigation water
                                                            is recovered by the system. The recovered water amount
                                                            usually does not exceed 25 percent of the irrigation rate.
                                                        </div>
                                                    }
                                                    units="%"
                                                    margin="normal"
                                                    inlineLabel
                                                    style={{ textAlign: "right" }}
                                                    eventHandle={value => {
                                                        this.update_irrigation(value, "irrigation.wateruse.recovery");
                                                        if (use_individual_timings)
                                                            this.updateTimings(irrigation_method);
                                                        else this.updateAverage(irrigation_method);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.inputStyle}
                                                style={{ textAlign: "right" }}>
                                                <Divider className={classes.divider} />
                                                <Typography
                                                    className={classes.dialogTitle}
                                                    variant="title"
                                                    style={{ textAlign: "left" }}>
                                                    Your Total Estimated Water Use
                                                </Typography>
                                                <Typography variant="body1" style={{ textAlign: "left" }}>
                                                    When finished, select Save Data and Close Estimator and the value
                                                    estimated will be copied to the previous page. If you selected two
                                                    irrigation water sources, you will need to allocate the total
                                                    estimated water across the sources.
                                                </Typography>
                                                <TextField
                                                    type="number"
                                                    inputProps={{ step: 0.1 }}
                                                    field="irrigation.wateruse.estimated_water_use"
                                                    label="Estimated water use"
                                                    units="acre_inch / acre"
                                                    margin="normal"
                                                    inlineLabel
                                                    disabled
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12}>
                                        <Button
                                            color="primary"
                                            className={classes.button}
                                            style={{ width: 300, border: "1px solid" }}
                                            onClick={() => closeEvent()}>
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="raised"
                                            color="primary"
                                            style={{ width: 300, float: "right" }}
                                            className={classes.button}>
                                            Save Data and Close Estimator
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Form>
                </DialogContent>
            </Dialog>
        );
    }
}

class Irrigation extends Component {
    state = {
        snackbarOpen: false,
        surfaceFuelUnits: "",
        groundFuelUnits: "",
        openEstimateDialog: false,
        showWarning: false,
        helpOpen: false
    };

    updateFieldActivity(values) {
        const { id } = this.props.fieldActivity;
        var isCompleted = this.errorValidator(values, this.props.cropYear, false);

        // The estimator values aren't on the main form (values)
        // Combine them into a single object here
        var wateruse = Object.assign({}, values.irrigation.wateruse);
        values.irrigation.wateruse = this.props.fieldActivity.irrigation.wateruse;
        for (var p in wateruse) {
            // Only overwrite the values not present in the estimator
            if (p === "ground_water_applied" || p === "surface_water_applied" || p === "water_source")
                values.irrigation.wateruse[p] = wateruse[p];
        }

        this.props.ormFieldActivityUpdate({
            id: id,
            ...values,
            extrainfo: {
                completed: isCompleted[0] === 0,
                missing: isCompleted[0],
                required: isCompleted[1]
            }
        });
        this.props.ormCropYearUpdateLocalOnly({
            id: this.props.cropYear.id,
            metrics: null
        });
        this.props.handleUnsavedFields(false);

        // Reapply required label to any field not yet filled in by adding a nonexistant value
        this.setState({ submitClicked: false, snackbarOpen: true });
        this.form.addValue("foo", "bar");
    }

    update_irrigation = (value, input) => {
        const { id } = this.props.fieldActivity;
        const { cropYear } = this.props;

        const formValues = this.props.fieldActivity;
        setValue(formValues, input, value);

        if (input === "irrigation.wateruse.water_source") {
            setValue(formValues, "irrigation.wateruse.ground_water_applied", null);
            setValue(formValues, "irrigation.energyuse.ground_has_irrigation_energy", null);
            setValue(formValues, "irrigation.energyuse.ground_use_actual", null);
            setValue(formValues, "irrigation.energyuse.ground_pump_pressure", null);
            setValue(formValues, "irrigation.energyuse.ground_pump_depth", null);
            setValue(formValues, "irrigation.energyuse.ground_energy_source", null);
            setValue(formValues, "irrigation.energyuse.ground_electricity_amount", null);
            setValue(formValues, "irrigation.energyuse.ground_fuel_id", null);
            setValue(formValues, "irrigation.energyuse.ground_fuel_amount", null);
            setValue(formValues, "irrigation.biodiversity.irrigation_conservation", null);
            setValue(formValues, "irrigation.wateruse.surface_water_applied", null);
            setValue(formValues, "irrigation.energyuse.surface_has_irrigation_energy", null);
            setValue(formValues, "irrigation.energyuse.surface_use_actual", null);
            setValue(formValues, "irrigation.energyuse.surface_pump_pressure", null);
            setValue(formValues, "irrigation.energyuse.surface_pump_depth", null);
            setValue(formValues, "irrigation.energyuse.surface_energy_source", null);
            setValue(formValues, "irrigation.energyuse.surface_electricity_amount", null);
            setValue(formValues, "irrigation.energyuse.surface_fuel_id", null);
            setValue(formValues, "irrigation.energyuse.surface_fuel_amount", null);
            this.form.setValue("irrigation.wateruse.ground_water_applied", null);
            this.form.setValue("irrigation.energyuse.ground_has_irrigation_energy", null);
            this.form.setValue("irrigation.energyuse.ground_use_actual", null);
            this.form.setValue("irrigation.energyuse.ground_pump_pressure", null);
            this.form.setValue("irrigation.energyuse.ground_pump_depth", null);
            this.form.setValue("irrigation.energyuse.ground_energy_source", null);
            this.form.setValue("irrigation.energyuse.ground_electricity_amount", null);
            this.form.setValue("irrigation.energyuse.ground_fuel_id", null);
            this.form.setValue("irrigation.energyuse.ground_fuel_amount", null);
            this.form.setValue("irrigation.biodiversity.irrigation_conservation", null);
            this.form.setValue("irrigation.wateruse.surface_water_applied", null);
            this.form.setValue("irrigation.energyuse.surface_has_irrigation_energy", null);
            this.form.setValue("irrigation.energyuse.surface_use_actual", null);
            this.form.setValue("irrigation.energyuse.surface_pump_pressure", null);
            this.form.setValue("irrigation.energyuse.surface_pump_depth", null);
            this.form.setValue("irrigation.energyuse.surface_energy_source", null);
            this.form.setValue("irrigation.energyuse.surface_electricity_amount", null);
            this.form.setValue("irrigation.energyuse.surface_fuel_id", null);
            this.form.setValue("irrigation.energyuse.surface_fuel_amount", null);
            if (cropYear.crop === RICE && GROUNDWATER.includes(value)) {
                setValue(formValues, "irrigation.energyuse.ground_has_irrigation_energy", "true");
                this.form.setValue("irrigation.energyuse.ground_has_irrigation_energy", "true");
            }
            if (cropYear.crop === RICE && SURFACEWATER.includes(value)) {
                setValue(formValues, "irrigation.energyuse.surface_has_irrigation_energy", "true");
                this.form.setValue("irrigation.energyuse.surface_has_irrigation_energy", "true");
            }
        } else if (input === "irrigation.energyuse.ground_has_irrigation_energy") {
            setValue(formValues, "irrigation.energyuse.ground_use_actual", null);
            setValue(formValues, "irrigation.energyuse.ground_pump_pressure", null);
            setValue(formValues, "irrigation.energyuse.ground_pump_depth", null);
            setValue(formValues, "irrigation.energyuse.ground_energy_source", null);
            setValue(formValues, "irrigation.energyuse.ground_electricity_amount", null);
            setValue(formValues, "irrigation.energyuse.ground_fuel_id", null);
            setValue(formValues, "irrigation.energyuse.ground_fuel_amount", null);
            this.form.setValue("irrigation.energyuse.ground_use_actual", null);
            this.form.setValue("irrigation.energyuse.ground_pump_pressure", null);
            this.form.setValue("irrigation.energyuse.ground_pump_depth", null);
            this.form.setValue("irrigation.energyuse.ground_energy_source", null);
            this.form.setValue("irrigation.energyuse.ground_electricity_amount", null);
            this.form.setValue("irrigation.energyuse.ground_fuel_id", null);
            this.form.setValue("irrigation.energyuse.ground_fuel_amount", null);
        } else if (input === "irrigation.energyuse.surface_has_irrigation_energy") {
            setValue(formValues, "irrigation.energyuse.surface_use_actual", null);
            setValue(formValues, "irrigation.energyuse.surface_pump_pressure", null);
            setValue(formValues, "irrigation.energyuse.surface_pump_depth", null);
            setValue(formValues, "irrigation.energyuse.surface_energy_source", null);
            setValue(formValues, "irrigation.energyuse.surface_electricity_amount", null);
            setValue(formValues, "irrigation.energyuse.surface_fuel_id", null);
            setValue(formValues, "irrigation.energyuse.surface_fuel_amount", null);
            this.form.setValue("irrigation.energyuse.surface_use_actual", null);
            this.form.setValue("irrigation.energyuse.surface_pump_pressure", null);
            this.form.setValue("irrigation.energyuse.surface_pump_depth", null);
            this.form.setValue("irrigation.energyuse.surface_energy_source", null);
            this.form.setValue("irrigation.energyuse.surface_electricity_amount", null);
            this.form.setValue("irrigation.energyuse.surface_fuel_id", null);
            this.form.setValue("irrigation.energyuse.surface_fuel_amount", null);
        } else if (input === "irrigation.energyuse.ground_use_actual") {
            setValue(formValues, "irrigation.energyuse.ground_pump_pressure", null);
            setValue(formValues, "irrigation.energyuse.ground_pump_depth", null);
            setValue(formValues, "irrigation.energyuse.ground_electricity_amount", null);
            setValue(formValues, "irrigation.energyuse.ground_fuel_id", null);
            setValue(formValues, "irrigation.energyuse.ground_fuel_amount", null);
            this.form.setValue("irrigation.energyuse.ground_pump_pressure", null);
            this.form.setValue("irrigation.energyuse.ground_pump_depth", null);
            this.form.setValue("irrigation.energyuse.ground_electricity_amount", null);
            this.form.setValue("irrigation.energyuse.ground_fuel_id", null);
            this.form.setValue("irrigation.energyuse.ground_fuel_amount", null);
        } else if (input === "irrigation.energyuse.surface_use_actual") {
            setValue(formValues, "irrigation.energyuse.surface_pump_pressure", null);
            setValue(formValues, "irrigation.energyuse.surface_pump_depth", null);
            setValue(formValues, "irrigation.energyuse.surface_electricity_amount", null);
            setValue(formValues, "irrigation.energyuse.surface_fuel_id", null);
            setValue(formValues, "irrigation.energyuse.surface_fuel_amount", null);
            this.form.setValue("irrigation.energyuse.surface_pump_pressure", null);
            this.form.setValue("irrigation.energyuse.surface_pump_depth", null);
            this.form.setValue("irrigation.energyuse.surface_electricity_amount", null);
            this.form.setValue("irrigation.energyuse.surface_fuel_id", null);
            this.form.setValue("irrigation.energyuse.surface_fuel_amount", null);
        } else if (input === "irrigation.energyuse.ground_energy_source") {
            setValue(formValues, "irrigation.energyuse.ground_electricity_amount", null);
            setValue(formValues, "irrigation.energyuse.ground_fuel_id", null);
            setValue(formValues, "irrigation.energyuse.ground_fuel_amount", null);
            this.form.setValue("irrigation.energyuse.ground_electricity_amount", null);
            this.form.setValue("irrigation.energyuse.ground_fuel_id", null);
            this.form.setValue("irrigation.energyuse.ground_fuel_amount", null);
        } else if (input === "irrigation.energyuse.surface_energy_source") {
            setValue(formValues, "irrigation.energyuse.surface_electricity_amount", null);
            setValue(formValues, "irrigation.energyuse.surface_fuel_id", null);
            setValue(formValues, "irrigation.energyuse.surface_fuel_amount", null);
            this.form.setValue("irrigation.energyuse.surface_electricity_amount", null);
            this.form.setValue("irrigation.energyuse.surface_fuel_id", null);
            this.form.setValue("irrigation.energyuse.surface_fuel_amount", null);
        }

        this.props.ormFieldActivityUpdateLocalOnly({
            id: id,
            ...formValues
        });
        //FIXME: Component won't re-render in new syntax without it
        this.setState({ foo: "bar" });
    };

    errorValidator = (values, cY, hardRequire) => {
        var numberRequired = 0; // Keep track of the actual number of required fields
        const isRequired = path => {
            numberRequired++;
            let val = getValue(values, path);
            setValue(valObj, path, val || val === false ? null : "Required");
        };
        const validateNumber = path => {
            numberRequired++;
            let val = getValue(values, path);
            if (!val) {
                setValue(valObj, path, "Required");
                return;
            }
            const min = 0;
            var max = 55;
            if (cY.crop === RICE || cY.crop === ALFALFA) max = 80;
            const number = parseFloat(val);
            if (number < min || number > max) {
                setValue(valObj, path, "Must be between " + min.toString() + "-" + max.toString());
                return;
            }
            setValue(valObj, path, null);
        };
        const validateNumberNotRequired = (path, max, label) => {
            let val = getValue(values, path);
            const number = parseFloat(val);
            if (number < 0) {
                setValue(valObj, path, "Invalid");
                return;
            }
            const plantable = parseFloat(cY.fieldObject.size.split(" ")[0]);
            const valNumber = number / plantable;
            if (valNumber > max) {
                setValue(valObj, path, "This value cannot exceed " + max.toString() + " " + label);
                return;
            }
            setValue(valObj, path, null);
        };
        var valObj = {};

        isRequired("irrigation.waterquality.irrigation_method");
        isRequired("irrigation.wateruse.water_source");

        const water_source = getValue(values, "irrigation.wateruse.water_source");
        const ground_use_actual = getValue(values, "irrigation.energyuse.ground_use_actual");
        const ground_energy_source = getValue(values, "irrigation.energyuse.ground_energy_source");

        if (GROUNDWATER.includes(water_source)) {
            validateNumber("irrigation.wateruse.ground_water_applied");
            isRequired("irrigation.energyuse.ground_has_irrigation_energy");
            const ground_gravityonly = getValue(values, "irrigation.energyuse.ground_has_irrigation_energy");
            if (ground_gravityonly === "true" || ground_gravityonly === true) {
                if (ground_use_actual !== true) {
                    isRequired("irrigation.energyuse.ground_pump_pressure");
                    isRequired("irrigation.energyuse.ground_pump_depth");
                }
                isRequired("irrigation.energyuse.ground_energy_source");
                if (ground_use_actual === true) {
                    if (ground_energy_source === FUEL) {
                        isRequired("irrigation.energyuse.ground_fuel_id");
                        validateNumberNotRequired("irrigation.energyuse.ground_fuel_amount", 197, "gallons/acre/year");
                    }
                    if (ELECTRICITY.includes(ground_energy_source)) {
                        validateNumberNotRequired(
                            "irrigation.energyuse.ground_electricity_amount",
                            8000,
                            "kWh/acre/year"
                        );
                    }
                }
            }
        }

        const surface_use_actual = getValue(values, "irrigation.energyuse.surface_use_actual");
        const surface_energy_source = getValue(values, "irrigation.energyuse.surface_energy_source");

        if (SURFACEWATER.includes(water_source)) {
            isRequired("irrigation.biodiversity.irrigation_conservation");
            validateNumber("irrigation.wateruse.surface_water_applied");
            isRequired("irrigation.energyuse.surface_has_irrigation_energy");
            const surface_gravityonly = getValue(values, "irrigation.energyuse.surface_has_irrigation_energy");
            if (surface_gravityonly === "true" || surface_gravityonly === true) {
                if (surface_use_actual !== true) {
                    isRequired("irrigation.energyuse.surface_pump_pressure");
                    isRequired("irrigation.energyuse.surface_pump_depth");
                }
                isRequired("irrigation.energyuse.surface_energy_source");
                if (surface_use_actual === true) {
                    if (surface_energy_source === FUEL) {
                        isRequired("irrigation.energyuse.surface_fuel_id");
                        validateNumberNotRequired("irrigation.energyuse.surface_fuel_amount", 197, "gallons/acre/year");
                    }
                    if (ELECTRICITY.includes(surface_energy_source)) {
                        validateNumberNotRequired(
                            "irrigation.energyuse.surface_electricity_amount",
                            8000,
                            "kWh/acre/year"
                        );
                    }
                }
            }
        }

        if (hardRequire) {
            // hardRequire = actual validation
            // FIXME: v2.X of react-forms has a submitting attribute on the formapi, but doesn't appear to be functional
            // V3.X seems to be a lot of work to upgrade
            // We are simulating a custom state (submitClicked) to know if its actually submiting
            // If it is submitting ignore the validator, submit, and move on
            if (this.state.submitClicked) {
                Object.keys(valObj).forEach(function (key) {
                    if (valObj[key] !== null && typeof valObj[key] === "object") {
                        // Also check child objects
                        Object.keys(valObj[key]).forEach(function (childKey) {
                            if (valObj[key][childKey] !== null && typeof valObj[key][childKey] === "object") {
                                Object.keys(valObj[key][childKey]).forEach(function (childKey2) {
                                    valObj[key][childKey][childKey2] = null;
                                });
                            }
                        });
                    }
                });
            }
            return valObj;
        }

        // If we are doing the final save of the form track how many fields are missing
        var missing = 0;
        Object.keys(valObj).forEach(function (key) {
            if (valObj[key] !== null && typeof valObj[key] === "object") {
                // Also check child objects
                Object.keys(valObj[key]).forEach(function (childKey) {
                    if (valObj[key][childKey] !== null && typeof valObj[key][childKey] === "object") {
                        Object.keys(valObj[key][childKey]).forEach(function (childKey2) {
                            if (valObj[key][childKey][childKey2] !== null) missing++;
                        });
                    }
                });
            } else if (valObj[key] !== null) missing++;
        });

        return [missing, numberRequired];
    };

    warningValidator = (values, cY) => {
        const validate = (path, flag, max, label) => {
            let val = getValue(values, path);
            const number = parseFloat(val);
            const plantable = parseFloat(cY.fieldObject.size.split(" ")[0]);

            const valNumber = number / plantable;

            if (valNumber > flag && valNumber <= max) {
                setValue(
                    valObj,
                    path,
                    "Typically the amount does not exceed " +
                        flag.toString() +
                        " " +
                        label +
                        ". Please validate your amount is correct."
                );
                return;
            }
            setValue(valObj, path, null);
        };
        var valObj = {};

        const water_source = getValue(values, "irrigation.wateruse.water_source");
        const ground_use_actual = getValue(values, "irrigation.energyuse.ground_use_actual");
        const ground_energy_source = getValue(values, "irrigation.energyuse.ground_energy_source");
        const surface_use_actual = getValue(values, "irrigation.energyuse.surface_use_actual");
        const surface_energy_source = getValue(values, "irrigation.energyuse.surface_energy_source");

        if (GROUNDWATER.includes(water_source)) {
            const ground_gravityonly = getValue(values, "irrigation.energyuse.ground_has_irrigation_energy");
            if (ground_gravityonly === "true" || ground_gravityonly === true) {
                if (ground_use_actual === true) {
                    if (ground_energy_source === FUEL) {
                        validate("irrigation.energyuse.ground_fuel_amount", 123, 197, "gallons/acre/year");
                    }
                    if (ELECTRICITY.includes(ground_energy_source)) {
                        validate("irrigation.energyuse.ground_electricity_amount", 5000, 8000, "kWh/acre/year");
                    }
                }
            }
        }
        if (SURFACEWATER.includes(water_source)) {
            const surface_gravityonly = getValue(values, "irrigation.energyuse.surface_has_irrigation_energy");
            if (surface_gravityonly === "true" || surface_gravityonly === true) {
                if (surface_use_actual === true) {
                    if (surface_energy_source === FUEL) {
                        validate("irrigation.energyuse.surface_fuel_amount", 123, 197, "gallons/acre/year");
                    }
                    if (ELECTRICITY.includes(surface_energy_source)) {
                        validate("irrigation.energyuse.surface_electricity_amount", 5000, 8000, "kWh/acre/year");
                    }
                }
            }
        }

        return valObj;
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        this.props.onRef(this);
        setTimeout(function () {
            handleUnsavedFields(false);
        }, 100);

        const { fieldActivity } = this.props;
        const surfaceFuelId = getValue(fieldActivity, "irrigation.energyuse.surface_fuel_id");
        this.getSurfaceFuelUnits(surfaceFuelId);
        const groundFuelId = getValue(fieldActivity, "irrigation.energyuse.ground_fuel_id");
        this.getGroundFuelUnits(groundFuelId);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    getSurfaceFuelUnits = value => {
        const { fuelTypes } = this.props;
        const app = this;
        const formValues = this.form.values;

        var units = null;
        fuelTypes.forEach(function (f) {
            if (f.id === value) {
                units = f.default_units;
                app.setState({ surfaceFuelUnits: f.default_units });
            }
        });

        var current_value = getValue(formValues, "irrigation.energyuse.surface_fuel_amount");

        if (current_value) {
            //  need to update form value with units manually
            var new_value = current_value.split(" ")[0] + " " + units;
            setValue(formValues, "irrigation.energyuse.surface_fuel_amount", new_value);
            this.form.setValue("irrigation.energyuse.surface_fuel_amount", new_value);
        }
    };

    getGroundFuelUnits = value => {
        const { fuelTypes } = this.props;
        const app = this;
        const formValues = this.form.values;

        var units = null;
        fuelTypes.forEach(function (f) {
            if (f.id === value) {
                units = f.default_units;
                app.setState({ groundFuelUnits: f.default_units });
            }
        });

        var current_value = getValue(formValues, "irrigation.energyuse.ground_fuel_amount");

        if (current_value) {
            //  need to update form value with units manually
            var new_value = current_value.split(" ")[0] + " " + units;
            setValue(formValues, "irrigation.energyuse.ground_fuel_amount", new_value);
            this.form.setValue("irrigation.energyuse.ground_fuel_amount", new_value);
        }
    };

    setSurfaceFuelAmount = value => {
        const formValues = this.form.values;
        if (value) {
            setValue(formValues, "irrigation.energyuse.surface_fuel_amount", value);
            this.form.setValue("irrigation.energyuse.surface_fuel_amount", value);
        } else {
            setValue(formValues, "irrigation.energyuse.surface_fuel_amount", null);
            this.form.setValue("irrigation.energyuse.surface_fuel_amount", null);
        }
    };

    setGroundFuelAmount = value => {
        const formValues = this.form.values;
        if (value) {
            setValue(formValues, "irrigation.energyuse.ground_fuel_amount", value);
            this.form.setValue("irrigation.energyuse.ground_fuel_amount", value);
        } else {
            setValue(formValues, "irrigation.energyuse.ground_fuel_amount", null);
            this.form.setValue("irrigation.energyuse.ground_fuel_amount", null);
        }
    };

    clearEstimator = () => {
        const { timings, ormActivityIrrigationWaterUseEventDelete, fieldActivity, ormFieldActivityUpdateLocalOnly } =
            this.props;
        timings.forEach(function (t) {
            ormActivityIrrigationWaterUseEventDelete(t.id);
        });
        this.form.setValue("irrigation.wateruse.estimated_water_use", null);
        setValue(fieldActivity, "irrigation.wateruse.estimated_water_use", null);
        setValue(fieldActivity, "irrigation.wateruse.irrigation_method", null);
        setValue(fieldActivity, "irrigation.wateruse.length", null);
        setValue(fieldActivity, "irrigation.wateruse.height", null);
        setValue(fieldActivity, "irrigation.wateruse.flow_rate", null);
        setValue(fieldActivity, "irrigation.wateruse.flow_rate_gpm", null);
        setValue(fieldActivity, "irrigation.wateruse.emitter_flow", null);
        setValue(fieldActivity, "irrigation.wateruse.emitter_spacing", null);
        setValue(fieldActivity, "irrigation.wateruse.drip_distance", null);
        setValue(fieldActivity, "irrigation.wateruse.drip_application_rate", null);
        setValue(fieldActivity, "irrigation.wateruse.nozzle_diameter", null);
        setValue(fieldActivity, "irrigation.wateruse.pressure", null);
        setValue(fieldActivity, "irrigation.wateruse.nozzle_flow_rate", null);
        setValue(fieldActivity, "irrigation.wateruse.head_spacing", null);
        setValue(fieldActivity, "irrigation.wateruse.line_spacing", null);
        setValue(fieldActivity, "irrigation.wateruse.effective_application_rate", null);
        setValue(fieldActivity, "irrigation.wateruse.water_flow_rate", null);
        setValue(fieldActivity, "irrigation.wateruse.pivot_hours", null);
        setValue(fieldActivity, "irrigation.wateruse.field_acre", null);
        setValue(fieldActivity, "irrigation.wateruse.field_flow_rate", null);
        setValue(fieldActivity, "irrigation.wateruse.number_rows", null);
        setValue(fieldActivity, "irrigation.wateruse.furrow_length", null);
        setValue(fieldActivity, "irrigation.wateruse.furrow_spacing", null);
        setValue(fieldActivity, "irrigation.wateruse.rate_per_hour", null);
        setValue(fieldActivity, "irrigation.wateruse.rate", null);
        setValue(fieldActivity, "irrigation.wateruse.acres_per_set", null);
        setValue(fieldActivity, "irrigation.wateruse.use_individual_timings", false);
        setValue(fieldActivity, "irrigation.wateruse.total_irrigation_events", null);
        setValue(fieldActivity, "irrigation.wateruse.average_time_irrigating", null);
        setValue(fieldActivity, "irrigation.wateruse.subtotal", null);
        setValue(fieldActivity, "irrigation.wateruse.subtotal_gallons", null);
        setValue(fieldActivity, "irrigation.wateruse.recovery", null);

        ormFieldActivityUpdateLocalOnly({
            id: fieldActivity.id,
            ...fieldActivity
        });
    };

    render() {
        const {
            classes,
            fieldActivity,
            irrigationMethods,
            pumpPressures,
            pumpingDepths,
            fuelTypes,
            energySources,
            waterSources,
            handleUnsavedFields,
            cropYear,
            ormFieldActivityUpdateLocalOnly,
            ormFieldActivityUpdate,
            ormActivityIrrigationWaterUseEventCreate,
            ormActivityIrrigationWaterUseEventCreateRemoteFirst,
            authState,
            ormActivityIrrigationWaterUseEventUpdate,
            ormActivityIrrigationWaterUseEventDelete,
            timings
        } = this.props;
        const {
            snackbarOpen,
            surfaceFuelUnits,
            groundFuelUnits,
            openEstimateDialog,
            showWarning,
            oldValue,
            newValue,
            helpOpen
        } = this.state;

        // FIXME: Set inactive on server?
        const irrigationMethodsPop = irrigationMethods.filter(function (iM) {
            return iM.name !== "No Irrigation";
        });

        const ground_gravityonly = getValue(fieldActivity, "irrigation.energyuse.ground_has_irrigation_energy");
        const surface_gravityonly = getValue(fieldActivity, "irrigation.energyuse.surface_has_irrigation_energy");
        const water_source = getValue(fieldActivity, "irrigation.wateruse.water_source");
        const ground_use_actual = getValue(fieldActivity, "irrigation.energyuse.ground_use_actual");
        const ground_energy_source = getValue(fieldActivity, "irrigation.energyuse.ground_energy_source");
        const surface_use_actual = getValue(fieldActivity, "irrigation.energyuse.surface_use_actual");
        const surface_energy_source = getValue(fieldActivity, "irrigation.energyuse.surface_energy_source");

        const estimated_water_use = getValue(fieldActivity, "irrigation.wateruse.estimated_water_use");
        const irrigation_method = getValue(fieldActivity, "irrigation.waterquality.irrigation_method");

        const showGroundGravity = ground_gravityonly === "true" || ground_gravityonly === true;
        const showGroundUseActual = showGroundGravity && ground_use_actual !== true;
        const showGroundUseActualTrue = showGroundGravity && ground_use_actual === true;
        const showSurfaceGravity = surface_gravityonly === "true" || surface_gravityonly === true;
        const showSurfaceUseActual = showSurfaceGravity && surface_use_actual !== true;
        const showSurfaceUseActualTrue = showSurfaceGravity && surface_use_actual === true;

        // Only run validation if users have been here before (missing set)
        const miss = getValue(fieldActivity, "extrainfo.missing");
        const isMissing = miss > 0;

        return (
            <>
                <Form
                    getApi={el => (this.form = el)}
                    key={fieldActivity.id}
                    dontValidateOnMount={isMissing ? false : true}
                    validateOnSubmit={isMissing ? false : true}
                    defaultValues={fieldActivity}
                    formDidUpdate={() => handleUnsavedFields(true)}
                    validateError={values => this.errorValidator(values, cropYear, true)}
                    validateWarning={values => this.warningValidator(values, cropYear)}
                    onSubmitFailure={(errors, formApi) => {
                        // This only occurs when switching steppers from step 3
                        // The errorValidator is indeed returning null for every valobj however it still has the old errors in finishSubmission
                        // https://github.com/react-tools/react-form/blob/v2.16.3/src/components/ReduxForm.js
                        // Something to do with calling submitform from two parent components above? (3_FieldActivites -> Save Operations)
                        // Skip the validation and go straight to the orm update
                        this.updateFieldActivity(formApi.values);
                    }}
                    onSubmit={values => this.updateFieldActivity(values)}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={24}>
                                <Grid item xs={6} lg={5} xl={4} style={{ paddingRight: 22 }}>
                                    <Select
                                        field="irrigation.waterquality.irrigation_method"
                                        label="Irrigation method"
                                        help={
                                            <div>
                                                Select the option that best describes your irrigation system. <br />
                                                <br />
                                                Gravity irrigation: A system with an elevated reservoir that feeds water
                                                into a basic drip irrigation system with a rate control structure.
                                                <br />
                                                <br />
                                                Center pivot with polyacrylamide (PAM): A center-pivot system that
                                                utilizes polyacrylamide, a synthetic water-soluble polymer to enhance
                                                infiltration and control irrigation-induced erosion.
                                                <br />
                                                <br />
                                                Level basin/block end: A system consisting of a constructed basin that
                                                is flooded. For level flooded field production of rice, users should
                                                select this irrigation method.
                                                <br />
                                                <br />
                                                Surface - Graded border and surge: A system that uses graded strips of
                                                land to guide the flow of water though the field (graded border) or that
                                                uses intermittent application of water through a furrow (surge).
                                                <br />
                                                <br />
                                                Surface – Graded furrow irrigation: A system that uses small, parallel
                                                furrow channels to carry irrigation water. May also use Polyacrylamide
                                                (PAM) materials to enhance infiltration.
                                                <br />
                                                <br />
                                                Surface – Wild flood: A system where water is distributed by gravity
                                                with no mechanical pump or rate control structure.
                                                <br />
                                                <br />
                                                Trickle/drip: A system where irrigation water is delivered at or near
                                                the roots of the plant.
                                            </div>
                                        }
                                        options={MAKE_OPTIONS(irrigationMethodsPop)}
                                        eventHandle={value => {
                                            if (estimated_water_use) {
                                                this.setState({
                                                    showWarning: true,
                                                    newValue: value,
                                                    oldValue: irrigation_method
                                                });
                                            } else {
                                                this.update_irrigation(
                                                    value,
                                                    "irrigation.waterquality.irrigation_method"
                                                );
                                            }
                                        }}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <Select
                                        field="irrigation.wateruse.water_source"
                                        label="Water source for irrigation"
                                        help={
                                            <div>
                                                The options available are:
                                                <br />
                                                <br />
                                                Deep aquifer groundwater: an aquifer that does not connect to a natural
                                                surface water body/stream.
                                                <br />
                                                <br />
                                                Alluvial groundwater: a shallow aquifer that connects to a natural
                                                surface water body.
                                                <br />
                                                <br />
                                                Surface water: a surface water body such as a stream, canal or pond.
                                            </div>
                                        }
                                        options={MAKE_OPTIONS(waterSources)}
                                        eventHandle={value =>
                                            this.update_irrigation(value, "irrigation.wateruse.water_source")
                                        }
                                        fullWidth
                                        margin="normal"
                                    />
                                    <Typography>
                                        If you need help estimating your irrigation rate please open the estimator.
                                        Otherwise, enter your irrigation rate under water applied.
                                    </Typography>
                                    <Button
                                        variant="raised"
                                        color="secondary"
                                        style={{ color: "#eee", marginTop: 8, marginBottom: 8 }}
                                        onClick={() => {
                                            if (irrigation_method && water_source)
                                                this.setState({ openEstimateDialog: true });
                                            else this.setState({ helpOpen: true });
                                        }}>
                                        <img src={IrrigationIcon} className={classes.checkBoxIcon} alt="Estimator" />
                                        Open Estimator
                                    </Button>
                                    <TextField
                                        type="number"
                                        inputProps={{ step: 0.1 }}
                                        field="irrigation.wateruse.estimated_water_use"
                                        label="Estimated water use"
                                        units="acre_inch / acre"
                                        fullWidth
                                        margin="normal"
                                        disabled
                                    />
                                    {GROUNDWATER.includes(water_source) && (
                                        <div>
                                            <Typography variant="display3" gutterBottom className={classes.gutterTop}>
                                                Groundwater
                                            </Typography>
                                            <TextField
                                                type="number"
                                                inputProps={{ step: 0.1 }}
                                                field="irrigation.wateruse.ground_water_applied"
                                                label="Water applied"
                                                units="acre_inch / acre"
                                                help="The volume of irrigation water applied in acre-inches."
                                                fullWidth
                                                margin="normal"
                                            />
                                            <RadioGroup
                                                field="irrigation.energyuse.ground_has_irrigation_energy"
                                                name="ground_gravityonly"
                                                options={YES_NO_OPTIONS}
                                                alignment={true}
                                                eventHandle={value =>
                                                    this.update_irrigation(
                                                        value,
                                                        "irrigation.energyuse.ground_has_irrigation_energy"
                                                    )
                                                }
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Do you use energy to irrigate the field through pumping?"
                                                        helpText="Typically energy is used to pump water to irrigate the field. However there are a few scenarios where fields can be irrigated by only gravity and no energy is used in those scenarios. Only answer no if no energy is used to pump water to irrigate the field."
                                                    />
                                                }
                                            />
                                            {showGroundGravity && (
                                                <Switch
                                                    field="irrigation.energyuse.ground_use_actual"
                                                    name="calcordirect"
                                                    labelNo="Calculate energy"
                                                    labelYes="Enter energy amount"
                                                    eventHandle={value =>
                                                        this.update_irrigation(
                                                            value,
                                                            "irrigation.energyuse.ground_use_actual"
                                                        )
                                                    }
                                                    label="Choose if you would like to manually enter the amount of energy used in irrigation pumping or if you would like to enter additional information and have the tool calculate it for you. If you choose to enter a value, the units should be kilowatt-hours per year (kWh/yr) for electricity, or gallons per year for a liquid fuel."
                                                />
                                            )}
                                            {showGroundUseActual && (
                                                <Select
                                                    field="irrigation.energyuse.ground_pump_pressure"
                                                    label="Pumping pressure (psi)"
                                                    unitsVisual="psi"
                                                    help="Select the range that best represents the pressure rating for your irrigation pump, in pounds per square inch (psi)."
                                                    options={MAKE_OPTIONS(pumpPressures)}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            )}
                                            {showGroundUseActual && (
                                                <Select
                                                    field="irrigation.energyuse.ground_pump_depth"
                                                    label="Pumping depth (feet)"
                                                    unitsVisual="ft"
                                                    options={MAKE_OPTIONS(pumpingDepths)}
                                                    help="Select the range that best represents the average depth (in feet) water was pumped from over the growing season. This may be depth to a well or to a surface water source that required pumping water a vertical distance."
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            )}
                                            {showGroundGravity && (
                                                <Select
                                                    field="irrigation.energyuse.ground_energy_source"
                                                    label="Pumping energy source"
                                                    options={MAKE_OPTIONS(energySources)}
                                                    eventHandle={value =>
                                                        this.update_irrigation(
                                                            value,
                                                            "irrigation.energyuse.ground_energy_source"
                                                        )
                                                    }
                                                    help={
                                                        <div>
                                                            Select the option that best represents the energy source for
                                                            your irrigation pump:
                                                            <br />
                                                            <br />
                                                            Electricity - Grid: Electricity purchased from your utility
                                                            with no renewable specification.
                                                            <br />
                                                            <br />
                                                            Electricity - Solar: Electricity sourced from solar energy
                                                            production on farm, or explicitly purchased renewable energy
                                                            from the utility.
                                                            <br />
                                                            <br />
                                                            Electricity - Wind: Electricity sourced from wind energy
                                                            production on site or explicitly purchased renewable energy
                                                            from the utility.
                                                            <br />
                                                            <br />
                                                            Fuel: Energy for the pump is provided by a liquid fuel, such
                                                            as diesel.
                                                        </div>
                                                    }
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            )}
                                            {ELECTRICITY.includes(ground_energy_source) && showGroundUseActualTrue && (
                                                <Grid container wrap="nowrap">
                                                    <Grid xs={1} className={classes.subQuestion}>
                                                        <SubquestionIcon />
                                                    </Grid>
                                                    <Grid xs={11}>
                                                        <TextField
                                                            type="number"
                                                            inputProps={{ step: 0.1 }}
                                                            field="irrigation.energyuse.ground_electricity_amount"
                                                            label="Electric amount (kWh/yr)"
                                                            units="kwh"
                                                            help="Amount of electricity used in pumping in kWh/yr (kilowatt-hours per year). Enter the total amount for the year."
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {ground_energy_source === FUEL && showGroundGravity && (
                                                <Grid container wrap="nowrap">
                                                    <Grid xs={1} className={classes.subQuestion}>
                                                        <SubquestionIcon />
                                                    </Grid>
                                                    <Grid xs={11}>
                                                        <Select
                                                            field="irrigation.energyuse.ground_fuel_id"
                                                            label="Fuel type"
                                                            options={MAKE_OPTIONS(fuelTypes)}
                                                            help="Select the type of fuel used for your irrigation pump."
                                                            fullWidth
                                                            margin="normal"
                                                            eventHandle={this.getGroundFuelUnits}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {ground_energy_source === FUEL && showGroundUseActualTrue && (
                                                <Grid container wrap="nowrap">
                                                    <Grid xs={1} className={classes.subQuestion}>
                                                        <SubquestionIcon />
                                                    </Grid>
                                                    <Grid xs={11}>
                                                        <TextField
                                                            type="number"
                                                            inputProps={{ step: 0.1 }}
                                                            field="irrigation.energyuse.ground_fuel_amount"
                                                            label="Fuel amount (gal/yr)"
                                                            units={groundFuelUnits}
                                                            help="Amount of fuel used for irrigation pumping. Enter the total amount for the year."
                                                            fullWidth
                                                            margin="normal"
                                                            eventHandle={this.setGroundFuelAmount}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={6} lg={5} xl={4} style={{ paddingLeft: 22 }}>
                                    {SURFACEWATER.includes(water_source) && (
                                        <div>
                                            <Typography variant="display3" gutterBottom>
                                                Surface Water or Alluvial Groundwater
                                            </Typography>
                                            <RadioGroup
                                                field="irrigation.biodiversity.irrigation_conservation"
                                                name="conservationmethods"
                                                options={YES_NO_OPTIONS}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Do you practice any water conservation methods?"
                                                        helpText="Water conservation methods might include cover crops, irrigation schedules."
                                                    />
                                                }
                                            />
                                            <TextField
                                                type="number"
                                                inputProps={{ step: 0.1 }}
                                                field="irrigation.wateruse.surface_water_applied"
                                                label="Total volume of irrigation water applied to crop in acre-inches"
                                                units="acre_inch / acre"
                                                fullWidth
                                                margin="normal"
                                            />
                                            <RadioGroup
                                                field="irrigation.energyuse.surface_has_irrigation_energy"
                                                name="surface_gravityonly"
                                                options={YES_NO_OPTIONS}
                                                alignment={true}
                                                eventHandle={value =>
                                                    this.update_irrigation(
                                                        value,
                                                        "irrigation.energyuse.surface_has_irrigation_energy"
                                                    )
                                                }
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Do you use energy to irrigate the field through pumping?"
                                                        helpText="Typically energy is used to pump water to irrigate the field. However there are a few scenarios where fields can be irrigated by only gravity and no energy is used in those scenarios. Only answer no if no energy is used to pump water to irrigate the field."
                                                    />
                                                }
                                            />
                                            {showSurfaceGravity && (
                                                <Switch
                                                    field="irrigation.energyuse.surface_use_actual"
                                                    name="calcordirect2"
                                                    labelNo="Calculate energy"
                                                    labelYes="Enter energy amount"
                                                    eventHandle={value =>
                                                        this.update_irrigation(
                                                            value,
                                                            "irrigation.energyuse.surface_use_actual"
                                                        )
                                                    }
                                                    label="Choose if you would like to manually enter the amount of energy used in irrigation pumping or if you would like to enter additional information and have the tool calculate it for you. If you choose to enter a value, the unit should be in kWh/yr (kilowatt-hours per year) for electricity or gallons per year for a liquid fuel."
                                                />
                                            )}
                                            {showSurfaceUseActual && (
                                                <Select
                                                    field="irrigation.energyuse.surface_pump_pressure"
                                                    label="Pumping pressure (psi)"
                                                    unitsVisual="psi"
                                                    options={MAKE_OPTIONS(pumpPressures)}
                                                    help="Select the range that best represents the pressure rating for your irrigation pump, in pounds per square inch (PSI)."
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            )}
                                            {showSurfaceUseActual && (
                                                <Select
                                                    field="irrigation.energyuse.surface_pump_depth"
                                                    label="Pumping depth (feet)"
                                                    unitsVisual="ft"
                                                    options={MAKE_OPTIONS(pumpingDepths)}
                                                    help="Select the range that best represents the average depth (in feet) that water was pumped from over the growing season. This may be depth to a well or to a surface water source that required pumping water a vertical distance."
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            )}
                                            {showSurfaceGravity && (
                                                <Select
                                                    field="irrigation.energyuse.surface_energy_source"
                                                    label="Energy source"
                                                    options={MAKE_OPTIONS(energySources)}
                                                    eventHandle={value =>
                                                        this.update_irrigation(
                                                            value,
                                                            "irrigation.energyuse.surface_energy_source"
                                                        )
                                                    }
                                                    help={
                                                        <div>
                                                            Select the option that best represents the energy source for
                                                            your irrigation pump:
                                                            <br />
                                                            <br />
                                                            Electricity - Grid: Electricity purchased from your utility
                                                            with no renewable specification;
                                                            <br />
                                                            <br />
                                                            Electricity - Solar: Electricity sourced from solar energy
                                                            production on farm, or explicitly purchased renewable energy
                                                            from the utility;
                                                            <br />
                                                            <br />
                                                            Electricity - Wind: Electricity sourced from wind energy
                                                            production on site or explicitly purchased renewable energy
                                                            from the utility;
                                                            <br />
                                                            <br />
                                                            Fuel: Energy for the pump is provided by a liquid fuel, such
                                                            as diesel.
                                                        </div>
                                                    }
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            )}
                                            {ELECTRICITY.includes(surface_energy_source) &&
                                                showSurfaceUseActualTrue && (
                                                    <Grid container wrap="nowrap">
                                                        <Grid xs={1} className={classes.subQuestion}>
                                                            <SubquestionIcon />
                                                        </Grid>
                                                        <Grid xs={11}>
                                                            <TextField
                                                                type="number"
                                                                inputProps={{ step: 0.1 }}
                                                                field="irrigation.energyuse.surface_electricity_amount"
                                                                label="Electric amount"
                                                                units="kwh"
                                                                help="Amount of electricity used in pumping in kWh/yr (kilowatt-hours per year). Enter the total amount for the year."
                                                                fullWidth
                                                                margin="normal"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            {surface_energy_source === FUEL && showSurfaceGravity && (
                                                <Grid container wrap="nowrap">
                                                    <Grid xs={1} className={classes.subQuestion}>
                                                        <SubquestionIcon />
                                                    </Grid>
                                                    <Grid xs={11}>
                                                        <Select
                                                            field="irrigation.energyuse.surface_fuel_id"
                                                            label="Fuel type"
                                                            options={MAKE_OPTIONS(fuelTypes)}
                                                            help="Select the type of fuel used for your irrigation pump."
                                                            fullWidth
                                                            margin="normal"
                                                            eventHandle={this.getSurfaceFuelUnits}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {surface_energy_source === FUEL && showSurfaceUseActualTrue && (
                                                <Grid container wrap="nowrap">
                                                    <Grid xs={1} className={classes.subQuestion}>
                                                        <SubquestionIcon />
                                                    </Grid>
                                                    <Grid xs={11}>
                                                        <TextField
                                                            type="number"
                                                            inputProps={{ step: 0.1 }}
                                                            field="irrigation.energyuse.surface_fuel_amount"
                                                            label="Fuel amount"
                                                            units={surfaceFuelUnits}
                                                            help="Amount of fuel used in pumping in the volume units. Enter the total amount for the year."
                                                            fullWidth
                                                            margin="normal"
                                                            eventHandle={this.setSurfaceFuelAmount}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={12} className={classes.rightAlign}>
                                    <Button
                                        type="submit"
                                        variant="raised"
                                        color="primary"
                                        className={classes.button}
                                        onClick={() => this.setState({ submitClicked: true })}>
                                        Save Irrigation
                                    </Button>
                                </Grid>
                                <Snackbar
                                    success
                                    onClose={this.handleSnackbarClose}
                                    open={snackbarOpen}
                                    section="irrigation operation"
                                />
                            </Grid>
                        </form>
                    )}
                </Form>
                <Estimator
                    timings={timings}
                    ormActivityIrrigationWaterUseEventCreate={ormActivityIrrigationWaterUseEventCreate}
                    ormActivityIrrigationWaterUseEventCreateRemoteFirst={
                        ormActivityIrrigationWaterUseEventCreateRemoteFirst
                    }
                    authState={authState}
                    ormActivityIrrigationWaterUseEventUpdate={ormActivityIrrigationWaterUseEventUpdate}
                    ormActivityIrrigationWaterUseEventDelete={ormActivityIrrigationWaterUseEventDelete}
                    classes={classes}
                    fieldActivity={fieldActivity}
                    cropYear={cropYear}
                    open={openEstimateDialog}
                    handleUnsavedFields={handleUnsavedFields}
                    closeEvent={e => {
                        if (e) {
                            this.form.setValue("irrigation.wateruse.estimated_water_use", null);
                            this.form.setValue("irrigation.wateruse.estimated_water_use", e);
                            if (water_source === "1") {
                                this.form.setValue("irrigation.wateruse.ground_water_applied", null);
                                this.form.setValue("irrigation.wateruse.ground_water_applied", e);
                            } else if (water_source === "2" || water_source === "3") {
                                this.form.setValue("irrigation.wateruse.surface_water_applied", null);
                                this.form.setValue("irrigation.wateruse.surface_water_applied", e);
                            }
                        }
                        this.setState({ openEstimateDialog: false });
                    }}
                    ormFieldActivityUpdateLocalOnly={ormFieldActivityUpdateLocalOnly}
                    ormFieldActivityUpdate={ormFieldActivityUpdate}
                />
                <WarningDialog
                    confirmAction={() => {
                        this.setState({ showWarning: false });
                        this.clearEstimator();
                        this.update_irrigation(newValue, "irrigation.waterquality.irrigation_method");
                    }}
                    cancelAction={() => {
                        this.setState({ showWarning: false });
                        this.form.setValue("irrigation.waterquality.irrigation_method", oldValue);
                    }}
                    open={showWarning}
                    confirmText="Update Irrigation Method"
                    cancelText="Do Not Update"
                    title="Change Irrigation Method"
                    text="You are attemping to change the irrigation method after calculating the estimated water use. If you change the irrigation method you will lose your estimated water use value. Do you wish to proceed?"
                />
                <WarningDialog
                    noActions
                    open={helpOpen}
                    onClose={() => this.setState({ helpOpen: false })}
                    title="Warning"
                    text="You must pick a irrigation method and water source before opening the estimator"
                />
            </>
        );
    }
}

Irrigation = connect(
    (state, ownProps) => ({
        timings: getTimingEvents(state, ownProps),
        irrigationMethods: allIrrigationMethods(state),
        pumpPressures: allPumpPressures(state),
        pumpingDepths: allPumpingDepths(state),
        fuelTypes: allFuelTypes(state),
        energySources: allEnergySources(state),
        waterSources: allWaterSources(state),
        authState: state.auth
    }),
    ActivityIrrigationWaterUseEvent.actions
)(Irrigation);

export default withStyles(styles)(Irrigation);
