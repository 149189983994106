import React from "react";

import Button from "../common/ButtonWrapper";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "../common/TypographyWrapper";
import withStyles from "@mui/styles/withStyles";
import ErrorOutline from "@mui/icons-material/ErrorOutline";

const styles = {
    supportIcon: {
        width: "3em",
        height: "3em",
        color: "#fff",
        marginLeft: 24
    },
    titleContainer: {
        backgroundColor: "#ff7d32",
        textAlign: "center"
    },
    questionTitle: {
        marginTop: ".6em",
        color: "#ff7d32",
        fontWeight: 500
    },
    greyButton: {
        backgroundColor: "#808080",
        color: "#fff"
    },
    centerButtons: {
        justifyContent: "center",
        marginTop: 24
    },
    primaryButton: {
        backgroundColor: "#ff7d32",
        color: "#fff"
    },
    nooverflow: {
        overflow: "hidden"
    },
    closeIcon: {
        float: "right",
        color: "#fff",
        marginTop: -16,
        marginRight: -16
    }
};

const RotationSystemWarningDialog = ({
    title,
    text,
    classes,
    confirmAction,
    cancelAction,
    confirmText,
    cancelText,
    noActions,
    onClose,
    ...other
}) => (
    <Dialog {...other} onClose={onClose} classes={{ paper: classes.nooverflow }}>
        <DialogTitle className={classes.titleContainer}>
            <ErrorOutline className={classes.supportIcon} />
        </DialogTitle>
        <DialogContent>
            <Typography variant="title" className={classes.questionTitle} gutterBottom>
                {title}
            </Typography>
            <DialogContentText>{text}</DialogContentText>
            {!noActions && (
                <DialogActions className={classes.centerButtons}>
                    <Button onClick={confirmAction} color="primary" variant="raised">
                        {confirmText ? confirmText : "Delete Entry"}
                    </Button>
                    <Button onClick={cancelAction} variant="raised" className={classes.greyButton} autoFocus>
                        {cancelText ? cancelText : "Do Not Delete"}
                    </Button>
                </DialogActions>
            )}
        </DialogContent>
    </Dialog>
);

export default withStyles(styles)(RotationSystemWarningDialog);
