import React, { Component } from "react";
import { Form } from "react-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../common/withRouter";
import Prompt from "../common/Prompt";
import { getValue } from "../../api/utils";
import { dbFetch } from "../../api/fetch";
import apikey from "../../apikey.json";
import saveAs from "file-saver";

import { BarChart, Bar, Tooltip, XAxis, YAxis, ResponsiveContainer, ReferenceLine } from "recharts";
import Button from "../common/ButtonWrapper";
import Grid from "../common/GridWrapper";
import states from "../common/states.json";
import Divider from "@mui/material/Divider";
import { Stepper, Step, StepLabel, StepButton } from "@mui/material";
import MuiTooltip from "@mui/material/Tooltip";
import Typography from "../common/TypographyWrapper";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";

import withStyles from "@mui/styles/withStyles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FieldLibraryIcon from "../common/icons/FieldLibraryIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import CarbonIcon from "./icons/CarbonIcon";
import NitrousIcon from "./icons/NitrousIcon";
import DetailedResults from "../common/icons/DetailedResults";
import PrintableReport from "../common/icons/PrintableReport";

import { Scenario, ScenarioPractice } from "./models";
import { Field } from "../field/models";
import AppContainer from "../common/AppContainer";
import TextField from "../common/TextField";
import Select from "../common/Select";
import WarningDialog from "../common/WarningDialog";
import { MAKE_OPTIONS } from "../../api/constants";
import HelpLabel from "../common/HelpLabel";
import { createSelector } from "../common/orm";
import * as navActions from "../common/actions";

function combine(field) {
    var keys = ["farm.name", "name", "location", "size"];

    var name = "";
    keys.forEach((x, index) => {
        if (x === "location") {
            var arr = (getValue(field, x) || "").split(",");
            if (arr && arr.length === 2) {
                name += arr[0] + " / " + arr[1];
            } else {
                name += " / ";
            }
        } else {
            name += getValue(field, x);
        }

        if (index < keys.length - 1) {
            name += " / ";
        }
    });
    return name;
}

const allFields = Field.selectAll(field => ({
    ...field.ref,
    name: combine(field)
}));
const getField = createSelector(
    (state, ownProps) => ownProps.field_id,
    (session, field_id) => {
        return session.Field.filter({ id: field_id }).first();
    }
);
const getPractices = createSelector(
    (state, ownProps) => ownProps.scenario_id,
    (session, scenario_id) => {
        return session.ScenarioPractice.filter({ scenario: scenario_id })
            .all()
            .toModelArray()
            .map(practice => ({
                ...practice.ref,
                land_type: "Crop Land"
            }));
    }
);
const getScenario = Scenario.selectByUrlId(scenario => ({
    ...scenario.ref,
    activeStep: scenario.activeStep === undefined ? 1 : scenario.activeStep
}));
const newScenario = function () {
    return {
        isNew: true,
        activeStep: 0
    };
};

const styles = theme => ({
    pointer: {
        cursor: "pointer",
        "& > span:first-child": {
            cursor: "pointer"
        },
        borderBottom: "8px solid transparent",
        marginBottom: -8,
        paddingRight: "8px !important",
        "&:hover": {
            borderBottom: "8px solid lightgray"
        }
    },
    acresDiff: {
        "& input": {
            color: theme.palette.primary.main + " !important",
            border: "none",
            backgroundColor: "#efefef",
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    submitWrapper: {
        position: "relative",
        float: "right",
        height: 40
    },
    floatRight: {
        float: "right",
        marginRight: 16,
        height: 40
    },
    progressWrapper: {
        fontWeight: 700,
        marginTop: -32,
        marginLeft: 45
    },
    progress: {
        color: "#4CAF50",
        position: "absolute"
    },
    progressCounty: {
        color: "#4CAF50",
        position: "absolute",
        marginTop: 40,
        zIndex: 9999,
        marginLeft: 8
    },
    buttonWidth: {
        minWidth: "44px",
        width: "44px"
    },
    // Stepper
    stepRoot: {
        backgroundColor: "#eeeeee",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1)
    },
    stepColor: {
        "& svg path": {
            color: theme.palette.secondary.main // + " !important",
        }
    },
    downLoadImp: {
        position: "absolute",
        marginTop: 40,
        marginLeft: 24
    },
    lessMargin: {
        "& span": {
            marginTop: "8px !important"
        }
    },
    downLoadprogress: {
        top: "50%",
        left: "50%",
        color: "#4CAF50",
        position: "absolute",
        "margin-top": -12,
        "margin-left": -12
    },
    downloadButtons: {
        marginTop: 16,
        marginBottom: 16,
        textAlign: "right"
    },
    // Identification
    rightAlign: {
        textAlign: "right"
    },
    // Location
    grey: {
        backgroundColor: "#efefef",
        border: "12px solid #ffffff"
    },
    card: {
        border: "12px solid #ffffff"
    },
    cardActions: {
        position: "relative",
        backgroundColor: "#efefef",
        float: "right",
        "& div": {
            position: "absolute",
            top: 0,
            right: 0
        },
        "& svg": {
            color: "black"
        }
    },
    cardPadding: {
        padding: "10px 15px",
        backgroundColor: "#efefef",
        "& p": {
            color: "black"
        }
    },
    iconSize: {
        flexShrink: 0,
        width: "1em",
        height: "1em",
        fontSize: "24px"
    },
    noMargin: {
        margin: 0,
        marginTop: 4
    },
    drawButtons: {
        width: "100%",
        padding: 0
    },
    mapPadding: {
        paddingTop: "0 !important"
    },
    buttonSelected: {
        minWidth: "44px"
    },
    iconSelected: {
        color: "#fff",
        "& polyline, & rect, & line, & path, & polygon": {
            stroke: "#fff"
        }
    },
    noPadding: {
        paddingLeft: "0 !important",
        paddingRight: "0 !important"
    },
    stepThreePadding: {
        paddingBottom: "2px !important",
        paddingTop: "0 !important"
    },
    linkColor: {
        color: "#34a853"
    },
    iconSizeSmaller: {
        flexShrink: 0,
        width: "3em",
        height: "3em",
        fontSize: "24px"
    },
    go: {
        marginBottom: -7
    },
    breadCrumbs: {
        display: "flex",
        listStyleType: "none",
        paddingInlineStart: "12px",
        "& li": {
            paddingRight: 8,
            fontSize: ".9rem"
        },
        "& p": {
            fontSize: ".9rem"
        },
        "& a": {
            color: "#808080"
        }
    },
    black: {
        backgroundColor: "#353435",
        border: "12px solid #ffffff",
        "& p": {
            color: "#bab9b9 !important"
        },
        "& span": {
            color: "#bab9b9 !important",
            fontWeight: 300
        },
        "& h2": {
            color: "#ffffff",
            fontSize: 30
        }
    },
    alignCenter: {
        textAlign: "center"
    },
    whiteTextThick: {
        color: "#ffffff",
        fontWeight: "bold"
    },
    whiteText: {
        color: "#fcf8f8"
    },
    practice: {
        display: "inline-flex",
        "& div": {
            maxWidth: "%33.33333",
            marginRight: 10
        }
    },
    practiceSelect: {
        display: "inline-flex",
        "& div": {
            maxWidth: "%33.33333"
        }
    },
    optionButton: {
        border: "1px solid #979797",
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 12,
        paddingRight: 12,
        minHeight: 36,
        color: "#979797",
        backgroundColor: "#fff",
        boxShadow: "none",
        maxWidth: 400,
        "&:hover": {
            border: "1px solid #fff",
            backgroundColor: "#ff7d32",
            color: "#fff"
        }
    },
    optionButtonIcon: {
        marginRight: 10,
        fontSize: 30,
        padding: 4,
        borderRadius: 4,
        margin: 10,
        borderColor: "inherit",
        width: "1.5em",
        height: "1.5em"
    },
    graphInfo: {
        "& svg": {
            flexShrink: 0,
            width: "2em",
            height: "2em",
            fontSize: "24px",
            marginRight: 5
        },
        "& div": {
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            display: "flex"
        },
        "& h2": {
            fontSize: 30
        }
    },
    helpInline: {
        width: "20px",
        marginLeft: theme.spacing(1),
        marginBottom: "-7px",
        opacity: ".4",
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.secondary.dark
        }
    },
    tooltip: {
        minWidth: 150,
        padding: 5,
        backgroundColor: "#333333",
        "& td": {
            color: "white"
        },
        "& span": {
            "&::before": {
                content: "' \\25CF'",
                fontSize: 30
            }
        }
    },
    chip: {
        marginRight: 8
    },
    noButton: {
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            WebkitAppearance: "none"
        }
    }
});

const PracticeClasses = [
    {
        name: "Cropland Management",
        id: "Cropland Management"
    },
    {
        name: "Grazing Lands",
        id: "Grazing Lands"
    },
    {
        name: "Cropland to Herbaceous Cover",
        id: "Cropland to Herbaceous Cover"
    },
    {
        name: "Restoration of Disturbed Lands",
        id: "Restoration of Disturbed Lands"
    },
    {
        name: "Woody Plantings",
        id: "Woody Plantings"
    }
];

const ConservationPracticeCroplandManagement = [
    {
        name: "Conservation Crop Rotation (CPS 328)",
        id: "Conservation Crop Rotation (CPS 328)"
    },
    {
        name: "Residue and Tillage Management - No-Till (CPS 329)",
        id: "Residue and Tillage Management - No-Till (CPS 329)"
    },
    {
        name: "Cover Crop (CPS 340)",
        id: "Cover Crop (CPS 340)"
    },
    {
        name: "Residue and Tillage Management - Reduced Till (CPS 345)",
        id: "Residue and Tillage Management - Reduced Till (CPS 345)"
    },
    {
        name: "Combustion System Improvement (CPS 372)",
        id: "Combustion System Improvement (CPS 372)"
    },
    {
        name: "Mulching (CPS 484)",
        id: "Mulching (CPS 484)"
    },
    {
        name: "Stripcropping (CPS 585)",
        id: "Stripcropping (CPS 585)"
    },
    {
        name: "Nutrient Management (CPS 590)",
        id: "Nutrient Management (CPS 590)"
    },
    {
        name: "Multiple Conservation Practices",
        id: "Multiple Conservation Practices"
    }
];

const ConservationPracticeGrazingLands = [
    {
        name: "Multiple Conservation Practices",
        id: "Multiple Conservation Practices"
    },
    {
        name: "Nutrient Management (CPS 590)",
        id: "Nutrient Management (CPS 590)"
    },
    {
        name: "Prescribed Grazing (CPS 528)",
        id: "Prescribed Grazing (CPS 528)"
    },
    {
        name: "Range Planting (CPS 550)",
        id: "Range Planting (CPS 550)"
    },
    {
        name: "Silvopasture (CPS 381)",
        id: "Silvopasture (CPS 381)"
    }
];

const ConservationPracticeHerbaceous = [
    {
        name: "Conservation Cover (CPS 327)",
        id: "Conservation Cover (CPS 327)"
    },
    {
        name: "Contour Buffer Strips (CPS 332)",
        id: "Contour Buffer Strips (CPS 332)"
    },
    {
        name: "Field Border (CPS 386)",
        id: "Field Border (CPS 386)"
    },
    {
        name: "Filter Strip (CPS 393)",
        id: "Filter Strip (CPS 393)"
    },
    {
        name: "Forage and Biomass Planting (CPS 512)",
        id: "Forage and Biomass Planting (CPS 512)"
    },
    {
        name: "Grassed Waterway (CPS 412)",
        id: "Grassed Waterway (CPS 412)"
    },
    {
        name: "Herbaceous Wind Barriers (CPS 603)",
        id: "Herbaceous Wind Barriers (CPS 603)"
    },
    {
        name: "Riparian Herbaceous Cover (CPS 390)",
        id: "Riparian Herbaceous Cover (CPS 390)"
    },
    {
        name: "Vegetative Barriers (CPS 601)",
        id: "Vegetative Barriers (CPS 601)"
    }
];

const ConservationPracticeRestoration = [
    {
        name: "Critical Area Planting (CPS 342)",
        id: "Critical Area Planting (CPS 342)"
    },
    {
        name: "Land Reclamation - Abandoned Mined Land (CPS 543)",
        id: "Land Reclamation - Abandoned Mined Land (CPS 543)"
    },
    {
        name: "Land Reclamation - Currently Mined Land (CPS 544)",
        id: "Land Reclamation - Currently Mined Land (CPS 544)"
    },
    {
        name: "Land Reclamation - Landslide Treatment (CPS 453)",
        id: "Land Reclamation - Landslide Treatment (CPS 453)"
    },
    {
        name: "Riparian Restoration",
        id: "Riparian Restoration"
    }
];

const ConservationPracticeWoodyPlantings = [
    {
        name: "Alley Cropping (CPS 311)",
        id: "Alley Cropping (CPS 311)"
    },
    {
        name: "Hedgerow Planting (CPS 422)",
        id: "Hedgerow Planting (CPS 422)"
    },
    {
        name: "Multi-story Cropping (CPS 379)",
        id: "Multi-story Cropping (CPS 379)"
    },
    {
        name: "Riparian Forest Buffer (CPS 391)",
        id: "Riparian Forest Buffer (CPS 391)"
    },
    {
        name: "Tree/Shrub Establishment (CPS 612)",
        id: "Tree/Shrub Establishment (CPS 612)"
    },
    {
        name: "Windbreak/Shelterbelt Establishment (CPS 380)",
        id: "Windbreak/Shelterbelt Establishment (CPS 380)"
    },
    {
        name: "Windbreak/Shelterbelt Renovation (CPS 650)",
        id: "Windbreak/Shelterbelt Renovation (CPS 650)"
    }
];

const ScenarioHelp = {
    "Combustion System Improvement (CPS 372)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/combustion-system-improvement-no-372-conservation-practice",
    "Conservation Crop Rotation (CPS 328)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/conservation-crop-rotation-ac-328-conservation-practice-standard",
    "Cover Crop (CPS 340)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/cover-crop-ac-340-conservation-practice-standard",
    "Mulching (CPS 484)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/mulching-ac-484-conservation-practice-standard",
    "Nutrient Management (CPS 590)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/nutrient-management-ac-590-conservation-practice-standard",
    "Residue and Tillage Management - No-Till (CPS 329)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/residue-and-tillage-management-no-till-ac-329-conservation",
    "Residue and Tillage Management - Reduced Till (CPS 345)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/residue-and-tillage-management-reduced-till-ac-345-conservation",
    "Stripcropping (CPS 585)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/stripcropping-ac-585-conservation-practice-standard",
    "Conservation Cover (CPS 327)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/conservation-cover-ac-327-conservation-practice-standard",
    "Contour Buffer Strips (CPS 332)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/contour-buffer-strips-ac-332-conservation-practice-standard",
    "Field Border (CPS 386)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/field-border-ac-386-conservation-practice-standard",
    "Filter Strip (CPS 393)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/filter-strip-ac-393-conservation-practice-standard",
    "Forage and Biomass Planting (CPS 512)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/pasture-and-hay-planting-ac-512-conservation-practice-standard",
    "Grassed Waterway (412)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/grassed-waterway-ac-412-conservation-practice-standard",
    "Herbaceous Wind Barriers (CPS 603)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/herbaceous-wind-barriers-ft-603-conservation-practice-standard",
    "Riparian Herbaceous Cover (CPS 390)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/riparian-herbaceous-cover-ac-390-conservation-practice-standard",
    "Vegetative Barriers (CPS 601)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/vegetative-barrier-ft-601-conservation-practice-standard",
    "Prescribed Grazing (528)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/prescribed-grazing-ac-528-conservation-practice-standard",
    "Range Planting (CPS 550)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/range-planting-ac-550-conservation-practice-standard",
    "Silvopasture (CPS 381)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/silvopasture-ac-381-conservation-practice-standard",
    "Anaerobic Digester (CPS 366)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/anaerobic-digester-no-366-conservation-practice-standard",
    "Critical Area Planting (CPS 342)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/critical-area-planting-ac-342-conservation-practice-standard",
    "Land Reclamation - Abandoned Mined Land (CPS 543)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/land-reclamation-abandoned-mined-land-ac-543-conservation",
    "Land Reclamation - Currently Mined Land (CPS 544)":
        "http://www.nrcs.usda.gov/Internet/FSE_DOCUMENTS/stelprdb1253605.pdf",
    "Land Reclamation - Landslide Treatment (CPS 453)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/land-reclamation-landslide-treatment-no-and-ac-453-conservation",
    "Riparian Restoration": "https://www.nrcs.usda.gov/wps/portal/nrcs/main/national/water/manage/restoration/",
    "Alley Cropping (CPS 311)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/alley-cropping-ac-311-conservation-practice-standard",
    "Hedgerow Planting (CPS 422)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/hedgerow-planting-ft-422-conservation-practice-standard",
    "Multi-story Cropping (CPS 379)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/forest-farming-ac-379-conservation-practice-standard",
    "Riparian Forest Buffer (CPS 391)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/riparian-forest-buffer-ac-391-conservation-practice-standard",
    "Tree/Shrub Establishment (CPS 612)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/treeshrub-establishment-ac-612-conservation-practice-standard",
    "Windbreak/Shelterbelt Establishment (CPS 380)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/windbreakshelterbelt-establishment-ft-380-conservation-practice",
    "Windbreak/Shelterbelt Renovation (CPS 650)":
        "https://www.nrcs.usda.gov/resources/guides-and-instructions/windbreakshelterbelt-renovation-ft-650-conservation-practice",
    "Annual Crops (CDFA Compost Application)":
        "https://www.cdfa.ca.gov/oefi/healthysoils/docs/CompostApplicationRate_WhitePaper.pdf",
    "Grasslands (CDFA Compost Application)":
        "https://www.cdfa.ca.gov/oefi/healthysoils/docs/CompostApplicationRate_WhitePaper.pdf",
    "Perennials, Orchards and Vineyards (CDFA Compost Application)":
        "https://www.cdfa.ca.gov/oefi/healthysoils/docs/CompostApplicationRate_WhitePaper.pdf"
};

function HelpLink(str, link) {
    return (
        <a style={{ color: "#ff7d32" }} rel="noopener noreferrer" href={link} target="_blank">
            {str}
        </a>
    );
}

function GetHelpLink(link) {
    if (link in ScenarioHelp) {
        return HelpLink(link, ScenarioHelp[link]);
    } else {
        return link;
    }
}

function FindKey(str) {
    var link = null;
    Object.keys(ScenarioHelp).forEach(x => {
        if (x.includes(str)) {
            link = ScenarioHelp[x];
        }
    });
    if (link) {
        return HelpLink(str, link);
    } else {
        return str;
    }
}

function SplitImplementatio(str, add) {
    const re = /(\([^)]+\))/gm;
    var arr = str.split(re);
    var elements = [];
    if (Array.isArray(arr)) {
        arr.forEach(x => {
            if (x.includes("(") && x.includes(")")) {
                elements.push(FindKey(x));
            } else {
                elements.push(x);
            }
        });
    } else {
        elements.push(str);
    }

    if (add) {
        elements.push(<>{" + "}</>);
    }

    return elements;
}

function GetMultiHelpLink(cps, link) {
    if (cps !== "Multiple Conservation Practices") {
        return link;
    }
    var tmp = link.split(" + ");
    return <>{tmp.map((x, index) => SplitImplementatio(x, index + 1 !== tmp.length))}</>;
}

class PracticeForm extends Component {
    state = {
        cps_name: null,
        practice_value_id: null,
        year: null,
        practiceValues: [],
        cpsSelected: "",
        practiceSelected: "",
        implementationLoading: false
    };

    changeField = (name, value) => {
        this.setState({ [name]: !!value });
    };

    loadPractices = async value => {
        const { field, scenario } = this.props;
        this.setState({ cps_name: !!value, implementationLoading: true });

        var county, state;
        if (field) {
            const arr = field.location.split(",");
            county = arr[0];
            state = arr[1];
        } else {
            county = scenario.county;
            state = scenario.state;
        }

        // LOCALHOST = const response = await apiFetch("/api/v4/calc/Scenario", {
        // BUILD = const response = await dbFetch("/api/v4/calc/Scenario", {
        //
        const response = await dbFetch("/api/v4/calc/Scenario", {
            method: "POST",
            body: JSON.stringify({
                "county-name": county,
                "state-abbrev": state
            })
        });

        const implentations = await response.json();
        this.setState({ practiceValues: implentations, implementationLoading: false });
    };

    changeImplementation = (formApi, value) => {
        const { practiceValues } = this.state;
        const index = practiceValues.find(x => x.id.toString() === value);
        if (index) {
            //set for guest login since server isn't hit
            formApi.setValue("practice_class", index.practice__practice_class);
            formApi.setValue("cps_name", index.practice__cps_name);
            formApi.setValue("planner_implementation", index.practice__planner_implementation);
            formApi.setValue("carbon", index.carbon);
            formApi.setValue("nitrous", index.nitrous);
            formApi.setValue("methane", index.methane);
        }
        this.setState({ practice_value_id: !!value });
    };

    changeClass = (formApi, value) => {
        const values = formApi.values;
        if (values.practice_class && values.practice_class !== value) {
            formApi.setValue("practice_value_id", null);
            this.changeField("practice_value_id", null);
            formApi.setValue("cps_name", null);
            this.changeField("cps_name", null);
        } else {
            this.changeField("practice_class", value);
        }
        this.setState({ practice_class: !!value, practiceSelected: value });
    };

    changePractice = (formApi, value) => {
        const values = formApi.values;
        if (values.cps_name && values.cps_name !== value) {
            formApi.setValue("practice_value_id", null);
            this.changeField("practice_value_id", null);
        } else {
            this.changeField("cps_name", value);
        }
        this.setState({ cps_name: !!value, cpsSelected: value });
    };

    updatePractice = (values, step) => {
        if (Number.isInteger(step)) {
            values["activeStep"] = step;
        } else {
            values["activeStep"] = 1;
        }

        if (values.id) {
            this.props.ormScenarioPracticeUpdate({
                ...values
            });
        } else {
            this.props.ormScenarioPracticeCreate({
                ...values
            });
        }
        this.props.handleUnsavedFields(false);
        this.props.close();
    };

    componentDidMount() {
        const { selected, handleUnsavedFields } = this.props;
        this.changeField("practice_class", selected["practice_class"]);
        this.changeField("cps_name", selected["cps_name"]);
        this.changeField("practice_value_id", selected["practice_value_id"]);
        this.changeField("year", selected["year"]);

        this.setState({ practiceSelected: selected["practice_class"], cpsSelected: selected["cps_name"] });
        this.loadPractices(selected["cps_name"]);

        setTimeout(function () {
            handleUnsavedFields(false);
        }, 100);
    }

    errorValidator = values => {
        const validateYear = year => {
            if (!year) {
                return "Required";
            }
            if (year.toString().length !== 4) {
                return "Invalid year";
            }
            const number = parseFloat(year);
            if (number < 1900 || number > 2100) {
                return "Invalid year";
            }
            if (number % 1 !== 0) {
                return "Invalid year";
            }
            return null;
        };
        const isRequired = val => {
            return !val ? "Required" : null;
        };

        return {
            year: validateYear(values.year),
            practice_class: isRequired(values.practice_class),
            cps_name: isRequired(values.cps_name),
            practice_value_id: isRequired(values.practice_value_id)
        };
    };

    render() {
        const { classes, selected, scenario, practices } = this.props;
        const {
            cps_name,
            practice_value_id,
            year,
            practiceValues,
            cpsSelected,
            practiceSelected,
            implementationLoading
        } = this.state;

        // no duplicates implentation validation
        const Ids = practices
            .filter(x => x.cps_name === cpsSelected && x.practice_class === practiceSelected && x.id !== selected.id)
            .map(x => x.practice_value_id);
        const implementationChoices = practiceValues
            .filter(
                x =>
                    x.practice__cps_name === cpsSelected &&
                    x.practice__practice_class === practiceSelected &&
                    !Ids.includes(x.id)
            )
            .map(x => ({ id: x.id.toString(), name: x.practice__planner_implementation }));

        var ConservationPractice = [];
        if (practiceSelected === "Cropland Management") {
            ConservationPractice = ConservationPracticeCroplandManagement;
        } else if (practiceSelected === "Grazing Lands") {
            ConservationPractice = ConservationPracticeGrazingLands;
        } else if (practiceSelected === "Cropland to Herbaceous Cover") {
            ConservationPractice = ConservationPracticeHerbaceous;
        } else if (practiceSelected === "Restoration of Disturbed Lands") {
            ConservationPractice = ConservationPracticeRestoration;
        } else if (practiceSelected === "Woody Plantings") {
            ConservationPractice = ConservationPracticeWoodyPlantings;
        }

        let showSelect = true;
        if (implementationChoices.length === 0 && cps_name) showSelect = false;

        return (
            <Form
                key={selected.id}
                dontValidateOnMount="true"
                validateOnSubmit="true"
                defaultValues={{ ...selected, scenario: scenario.id }}
                formDidUpdate={() => this.props.handleUnsavedFields(true)}
                validateError={this.errorValidator}
                onSubmit={(values, fromStepper) => this.updatePractice(values, fromStepper)}
                getApi={el => (this.formApi = el)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={24} className={classes.grey}>
                            <Grid item xs={12}>
                                <Typography style={{ marginBottom: 10 }} variant="display2">
                                    Add a New Practice
                                </Typography>
                                <Divider />
                                <Grid container spacing={24}>
                                    <Grid item xs={12} md={6} xl={4} className={classes.practiceSelect}>
                                        <Select
                                            field="practice_class"
                                            label="Select Practice Class"
                                            options={MAKE_OPTIONS(PracticeClasses)}
                                            eventHandle={value => this.changeClass(formApi, value)}
                                            margin="normal"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} className={classes.practiceSelect}>
                                <Select
                                    field="cps_name"
                                    label="Select New Practice"
                                    options={MAKE_OPTIONS(ConservationPractice)}
                                    eventHandle={value => this.changePractice(formApi, value)}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} className={classes.practiceSelect}>
                                {showSelect && (
                                    <Select
                                        field="practice_value_id"
                                        label="Select Conservation Practice Implementation"
                                        options={MAKE_OPTIONS(implementationChoices)}
                                        eventHandle={value => this.changeImplementation(formApi, value)}
                                        fullWidth
                                        disabled={implementationLoading}
                                        margin="normal"
                                    />
                                )}
                                {implementationLoading && (
                                    <CircularProgress size={24} className={classes.downLoadImp} />
                                )}
                                {!showSelect && (
                                    <Typography style={{ color: "darkred" }}>
                                        There are no implementation choices for this practice. Please select a different
                                        practice and/or class.
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} xl={4} className={classes.practiceSelect}>
                                <TextField
                                    field="year"
                                    label={
                                        <HelpLabel
                                            inputLabel="Select Year Practice was Adopted or is Planned"
                                            helpText={
                                                <Typography>
                                                    Practice adoption is assumed it will be maintained for 10 years
                                                    after adoption
                                                </Typography>
                                            }
                                        />
                                    }
                                    fullWidth
                                    eventHandle={value => this.changeField("year", value)}
                                    type="number"
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    Learn more:{" "}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://storage.googleapis.com/comet_public_directory/planner50states/pdfs/NRCS_RankingTools.pdf">
                                        GHG and Carbon Sequestration Ranking Tool: NRCS Practice Standards for
                                        Greenhouse Gas Emission Reduction and Carbon Sequestration
                                    </a>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.rightAlign}>
                                <div>
                                    <Button onClick={this.props.close} variant="raised" type="submit">
                                        Cancel
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button
                                        variant="raised"
                                        type="submit"
                                        color="primary"
                                        disabled={!cps_name || !practice_value_id || !year}>
                                        {selected.id ? "Save" : "Add"} Practice and View Results
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

class PracticeProperties extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: props.practices.length === 0 && props.practices.length !== 7,
            selected: {},
            deletePracticeId: null,
            pdfLoading: false,
            excelLoading: false,
            defaultYear: null
        };
    }

    handleBack = () => {
        let { scenario, ormScenarioUpdateLocalOnly, updateParent } = this.props;
        if (scenario.isNew) {
            scenario = Object.assign(scenario, { activeStep: 0 });
            updateParent(); // Trigger render
        } else {
            ormScenarioUpdateLocalOnly({
                id: scenario.id,
                activeStep: 0
            });
        }
    };

    updateScenario = (values, step) => {
        const saved_step = values["activeStep"];
        if (Number.isInteger(step)) {
            values["activeStep"] = step;
        } else {
            values["activeStep"] = 1;
        }

        this.props.handleUnsavedFields(false);
        this.props.ormScenarioUpdate({
            ...values
        });
        if (step !== 0 && step !== saved_step) {
            this.props.navSelectPage("scenario");
            this.props.history.push(`/scenario`);
        }
    };

    errorValidator = values => {
        return {};
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        this.props.onRef(this.formApi);
        window.scrollTo(0, 0);

        setTimeout(function () {
            handleUnsavedFields(false);
        }, 100);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    cardContainer = ({ classes, title, emptyText, data }) => {
        if (data.length === 0) {
            return (
                <div style={{ marginTop: 30 }}>
                    <Typography style={{ fontSize: 21 }} variant="display2" gutterBottom>
                        <b>{title}</b>
                    </Typography>
                    <Grid container spacing={24} alignItems="center">
                        <Grid item xs={12} className={classes.grey}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} className={classes.alignCenter}>
                                    <Typography style={{ padding: 20 }}>
                                        <i>{emptyText}</i>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            );
        }

        function GetColor(data, id) {
            const colors = [
                "#8591ee",
                "#ffb93c",
                "#a85cd5",
                "#5a50af",
                "#3aaaaa",
                "#ff4fa0",
                "#e43e24",
                "#afd200",
                "#328282",
                "#ff7d32"
            ];

            const index = data.findIndex(x => x.id === id);
            if (index > -1) {
                return colors[index];
            } else {
                return "#efefef";
            }
        }

        function CoeffiecientLabel(c) {
            if (c === -999) {
                return "No Data";
            } else {
                return (
                    <>
                        {Math.round((c + Number.EPSILON) * 100) / 100} CO<sub>2</sub>e / ac / yr
                    </>
                );
            }
        }

        return (
            <div style={{ marginTop: 30 }}>
                <Typography style={{ fontSize: 21 }} variant="display2" gutterBottom>
                    <b>{title}</b>
                </Typography>
                <Typography style={{ margin: "10px 0px" }}>
                    Below are Conservation Practice Standards (CPS) and associated Conservation Practice Implementations
                    added to your scenario. Note that you can add a Multiple Conservation Practices. These practices
                    contain two or more individual practices. For example, a Multiple Conservation Practices might
                    include CPS 329 (Intensive Till to No Till or Strip Till) AND CPS 340 (Add Legume Seasonal Cover
                    Crop). To ensure the most accurate scenario results, if you add a Multiple Conservation Practice,
                    avoid adding any single Conservation Practice Standards containing the same practice standard (CPS
                    340 in this example). Also avoid duplicating any one CPS and note that many practices have an
                    irrigated and non-irrigated version.
                </Typography>
                <Grid container spacing={8} alignItems="center">
                    {data
                        .sort((a, b) => a - b)
                        .map((x, index) => (
                            <Grid key={index} item xs={12} xl={6} style={{ height: 280 }}>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={2}
                                        sm={2}
                                        md={1}
                                        lg={2}
                                        xl={1}
                                        className={classes.cardPadding}
                                        style={{ borderLeft: `.6rem solid ${GetColor(data, x.id)}` }}>
                                        <Typography className={classes.whiteTextThick}>Year</Typography>
                                        <Typography className={classes.whiteText}>{x.year}</Typography>
                                    </Grid>
                                    <Grid item xs={10} sm={10} md={11} lg={10} xl={11} className={classes.cardPadding}>
                                        <div className={classes.cardActions}>
                                            <MuiTooltip title="Edit">
                                                <Button
                                                    onClick={() => this.setState({ editing: true, selected: x })}
                                                    className={classes.buttonWidth}>
                                                    <EditIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="Delete">
                                                <Button
                                                    onClick={() => this.setState({ deletePracticeId: x.id })}
                                                    className={classes.buttonWidth}>
                                                    <DeleteIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                        </div>
                                        <Typography className={classes.whiteTextThick}>
                                            Convervation Practice Class
                                        </Typography>
                                        <Typography className={classes.whiteText}>{x.practice_class}</Typography>
                                        <Typography className={classes.whiteTextThick}>
                                            Convervation Practice Standard (CPS)
                                        </Typography>
                                        <Typography className={classes.whiteText}>{GetHelpLink(x.cps_name)}</Typography>
                                        <Typography className={classes.whiteTextThick}>
                                            Conservation Practice Implementation
                                        </Typography>
                                        <Typography className={classes.whiteText}>
                                            {GetMultiHelpLink(x.cps_name, x.planner_implementation)}
                                        </Typography>
                                        <Typography className={classes.whiteTextThick}>
                                            Practice Emission Reduction Coefficients
                                        </Typography>
                                        <Typography className={classes.whiteText}>
                                            Soil Carbon: {CoeffiecientLabel(x.carbon)}
                                        </Typography>
                                        <Typography className={classes.whiteText}>
                                            N<sub>2</sub>O: {CoeffiecientLabel(x.nitrous)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                </Grid>
            </div>
        );
    };

    setLoadingState = (value, defaultYear) => {
        this.setState({ pdfLoading: value, defaultYear });
    };
    setExcelLoadingState = (value, defaultYear) => {
        this.setState({ excelLoading: value, defaultYear });
    };

    graphContainer = ({ classes, setLoading, setExcelLoading, title, field, scenario, ...props }) => {
        const { pdfLoading, excelLoading, defaultYear } = this.state;
        this.carbonAPI = React.createRef();
        this.nitrousAPI = React.createRef();

        function reportYear(arr, cy) {
            var allYears = [];
            arr.forEach(x => {
                const min = parseInt(x);
                const max = min + 10;
                for (var i = min; i < max; i++) {
                    allYears.push(i.toString());
                }
            });
            allYears = allYears.sort((a, b) => a - b);
            if (allYears.includes(cy)) {
                return cy;
            } else {
                return allYears[0];
            }
        }

        async function downloadPdf() {
            const { yearList } = props;
            var yearValue = defaultYear;
            const cy = new Date().getFullYear().toString();
            if (!defaultYear) {
                yearValue = reportYear(yearList, cy);
            }
            setLoading(true, yearValue);

            const { authState, downLoadData } = props;
            var data = downLoadData;
            data.year = yearValue;

            let response;
            if (authState.user && authState.user.guest) {
                downLoadData.user = "Guest Account";
                response = await fetch("/scenario/reports/pdf", {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        Accept: "application/pdf",
                        Authorization: "Bearer " + apikey.apikey,
                        "Content-Type": "application/json"
                    }
                });
            } else {
                response = await dbFetch(`/scenario/reports/${data.scenario_id}/pdf?year=${yearValue}`);
            }

            saveAs(await response.blob(), data.name + ".pdf");
            setLoading(false, yearValue);
        }

        async function downloadExcel() {
            const { yearList } = props;
            var yearValue = defaultYear;
            const cy = new Date().getFullYear().toString();
            if (!defaultYear) {
                yearValue = reportYear(yearList, cy);
            }
            setExcelLoading(true, yearValue);

            const { authState, downLoadData } = props;
            var data = downLoadData;
            data.year = yearValue;

            let response;
            if (authState.user && authState.user.guest) {
                downLoadData.user = "Guest Account";
                response = await fetch("/scenario/reports/excel", {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        Authorization: "Bearer " + apikey.apikey,
                        "Content-Type": "application/json"
                    }
                });
            } else {
                response = await dbFetch(`/scenario/reports/${data.scenario_id}/excel?year=${yearValue}`);
            }

            saveAs(await response.blob(), data.name + ".xlsx");
            setExcelLoading(false, yearValue);
        }

        const data = [
            {
                stateKey: "carbon",
                color: "#34a853",
                icon: CarbonIcon,
                ref: this.carbonAPI
            },
            {
                stateKey: "nitrous",
                color: "#0096ff",
                icon: NitrousIcon,
                ref: this.nitrousAPI
            }
        ];

        function CustomizedToolTip(props) {
            const { payload } = props;
            const total = payload ? payload.reduce((a, b) => a + b.value, 0) : null;

            return (
                <table className={classes.tooltip}>
                    <tbody>
                        <tr>
                            <td>{props.label}</td>
                            <td />
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>{total ? total.toFixed(2) : ""}</td>
                        </tr>
                        {(payload || []).map((entry, index) => (
                            <tr key={index}>
                                <td>
                                    <span style={{ color: entry.color }} />
                                </td>
                                <td>{entry.value.toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        }

        function ChartContainer({ classes, x, acres, stateKey, props, ...rest }) {
            function changeYear(key, value) {
                setLoading(false, value);
                rest.all.forEach(chart => {
                    if (chart.stateKey !== key) {
                        chart.ref.setValue("year", value);
                    }
                });
            }

            function GetYear() {
                return new Date().getFullYear().toString();
            }

            function GetTitle(d, year) {
                var ac = "";
                var name = "";
                if (field) {
                    ac = field.size.replace("re", "");
                    name = field.name;
                } else {
                    ac = scenario.acres + " ac";
                    if (scenario.county) name = scenario.county;
                    if (scenario.county_name) name = scenario.county_name;
                }

                if (d.stateKey === "carbon") {
                    return `Total Soil Carbon Sequestration for ${name} (${ac}) in ${year}`;
                } else {
                    return (
                        <>
                            Total Annual Direct/Indirect Soil N<sub>2</sub>O Emissions Change for {name} ({ac}) in{" "}
                            {year}
                        </>
                    );
                }
            }

            function YearOptions(arr) {
                if (arr.length === 0) {
                    return [];
                }

                var list = [];
                arr.forEach(x => {
                    const min = parseInt(x.year);
                    const max = min + 10;
                    for (var i = min; i < max; i++) {
                        list.push(i.toString());
                    }
                });
                return [...new Set(list)]
                    .sort((a, b) => a - b)
                    .map(x => ({ value: x.toString(), label: x.toString() }));
            }

            var year_dict = {};
            var practice_dict = {};
            var emptyData = {};
            var hasNegative = false;

            props.data.forEach(x => {
                const min = parseInt(x.year);
                const max = min + 10;
                for (var i = min; i < max; i++) {
                    const totalPerAcres = acres * x[stateKey];
                    if (x[stateKey] !== -999) {
                        if (totalPerAcres < 0) {
                            hasNegative = true;
                        }

                        if (!(i.toString() in year_dict)) {
                            practice_dict[i.toString()] = {};
                        }
                        if (x.year in practice_dict[i.toString()]) {
                            practice_dict[i.toString()][x.id] += totalPerAcres;
                        } else {
                            practice_dict[i.toString()][x.id] = totalPerAcres;
                        }

                        if (i.toString() in year_dict) {
                            year_dict[i.toString()] += totalPerAcres;
                        } else {
                            year_dict[i.toString()] = totalPerAcres;
                        }
                    } else {
                        if (x.year in emptyData) {
                            const str = x.cps_name + ", " + x.planner_implementation;
                            if (str in emptyData[x.year]) {
                            }
                        } else {
                            emptyData[x.year] = {};
                            emptyData[x.year][x.cps_name + ", " + x.planner_implementation] = "";
                        }
                    }
                }
            });

            const colors = [
                "#8591ee",
                "#ffb93c",
                "#a85cd5",
                "#5a50af",
                "#3aaaaa",
                "#ff4fa0",
                "#e43e24",
                "#afd200",
                "#328282",
                "#ff7d32"
            ];

            var flattened = [];
            Object.keys(practice_dict).forEach(j => {
                var obj = {};
                obj.name = j;
                Object.keys(practice_dict[j]).forEach(z => {
                    obj[z] = practice_dict[j][z];
                });
                flattened.push(obj);
            });

            var emptyPractices = [];
            Object.keys(emptyData).forEach(x => {
                Object.keys(emptyData[x]).forEach(z => {
                    emptyPractices.push(x + ", " + z);
                });
            });

            var CurrentYear;
            if (rest.defaultYear) {
                CurrentYear = rest.defaultYear;
            } else {
                CurrentYear = GetYear();
            }
            var defaultYear = CurrentYear;
            if (!(CurrentYear in year_dict)) {
                defaultYear = props.yearList[0] || "";
            }

            return (
                <>
                    <Form
                        getApi={el => (x.ref = el)}
                        key={stateKey}
                        dontValidateOnMount="true"
                        defaultValues={{
                            year: defaultYear
                        }}
                        validateOnSubmit="true">
                        {formApi => (
                            <form style={{ flex: 1 }} onSubmit={formApi.submitForm}>
                                <Grid container spacing={8}>
                                    <Grid item xs={8}>
                                        <Typography variant="title" style={{ color: "#000" }}>
                                            <b>{GetTitle(x, formApi.values.year)}</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Select
                                            style={{ width: 100, float: "right", marginTop: 0 }}
                                            field="year"
                                            fullWidth
                                            options={YearOptions(props.data)}
                                            eventHandle={value => changeYear(stateKey, value)}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ display: "flex" }} className={classes.graphInfo}>
                                        <x.icon />
                                        <div>
                                            <Typography variant="title" style={{ color: x.color }}>
                                                {formApi.values.year && !isNaN(year_dict[formApi.values.year])
                                                    ? year_dict[formApi.values.year].toFixed(2)
                                                    : ""}
                                                <span
                                                    style={{
                                                        fontSize: 15,
                                                        marginLeft: 5,
                                                        color: "#666666"
                                                    }}>
                                                    {formApi.values.year && !isNaN(year_dict[formApi.values.year]) ? (
                                                        <>
                                                            t CO<sub>2</sub>e / yr
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                </span>
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Form>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart
                                stackOffset="sign"
                                data={flattened}
                                margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                {props.data.map((z, index) => (
                                    <Bar key={index} dataKey={z.id} stackId="a" fill={colors[index]} />
                                ))}
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip cursor={false} content={CustomizedToolTip} />
                                {hasNegative && <ReferenceLine y={0} stroke="#929292" strokeDasharray="5 3" />}
                            </BarChart>
                        </ResponsiveContainer>
                        {emptyPractices.length > 0 && (
                            <div style={{ marginTop: 15 }}>
                                <Typography>Practices With Empty Values:</Typography>
                                {emptyPractices.map(j => (
                                    <Typography key={j}>{j}</Typography>
                                ))}
                            </div>
                        )}
                    </Grid>
                </>
            );
        }

        return (
            <div style={{ marginTop: 30 }}>
                <Typography style={{ fontSize: 20 }} variant="display2" gutterBottom>
                    <b>{title}</b>
                </Typography>
                <Typography style={{ margin: "10px 0px" }}>
                    Results are total short tons of CO2 equivalent per year for the field based on practices selected in
                    each year and acreage provided for this field. Negative values indicate increasing emissions and
                    positive values indicate sequestration (soil carbon) or emissions reduction (N<sub>2</sub>O). Soil
                    Carbon change is assumed to occur at a constant rate for 10 years following the practice change; N
                    <sub>2</sub>O change is assumed to occur every year that the practice is implemented.
                </Typography>
                <Grid container spacing={8} alignItems="center">
                    {data.map((x, index) => (
                        <Grid key={index} item xs={12}>
                            <Grid container style={{ backgroundColor: "#efefef", padding: 15 }}>
                                <ChartContainer
                                    classes={classes}
                                    x={x}
                                    all={data}
                                    props={props}
                                    stateKey={x.stateKey}
                                    acres={props.acres}
                                    year={this.state[x.stateKey]}
                                    defaultYear={defaultYear}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Grid>

                <div className={classes.downloadButtons}>
                    <Button
                        onClick={evt => {
                            evt.preventDefault();
                            downloadPdf();
                        }}
                        disabled={pdfLoading}
                        fullWidth={true}
                        className={classes.optionButton}
                        color="primary">
                        <PrintableReport className={classes.optionButtonIcon} />
                        Generate PDF Report of Scenario
                        {pdfLoading && <CircularProgress size={24} className={classes.downLoadprogress} />}
                    </Button>
                    <Button
                        onClick={evt => {
                            evt.preventDefault();
                            downloadExcel();
                        }}
                        disabled={excelLoading}
                        fullWidth={true}
                        className={classes.optionButton}
                        style={{ marginLeft: 10 }}
                        color="primary">
                        <DetailedResults className={classes.optionButtonIcon} />
                        Download Scenario Detail
                        {excelLoading && <CircularProgress size={24} className={classes.downLoadprogress} />}
                    </Button>
                </div>
            </div>
        );
    };

    okDeletePractice = () => {
        this.props.ormScenarioPracticeDelete(this.state.deletePracticeId);
        this.setState({ deletePracticeId: null });
    };

    cancelDeletePractice = () => {
        this.setState({ deletePracticeId: null });
    };

    getData = () => {
        const { scenario, field, practices } = this.props;
        return {
            scenario_id: scenario.id,
            name: scenario.name,
            farm_name: field ? field.name : scenario.state,
            field_name: field ? field.farm.name : scenario.county,
            size: field ? parseFloat(field.size.split(" acre")) : scenario.acres,
            practices: practices.sort((a, b) => a.year - b.year)
        };
    };

    render() {
        const {
            authState,
            classes,
            scenario,
            field,
            practices,
            handleUnsavedFields,
            navSelectPage,
            ormScenarioPracticeUpdate,
            ormScenarioPracticeCreate
        } = this.props;
        const { editing, selected, deletePracticeId } = this.state;

        var yearList = [];
        practices.forEach(x => {
            yearList.push(x.year);
        });
        yearList = [...new Set(yearList)];
        yearList.sort((a, b) => a - b);

        return (
            <>
                <Grid container spacing={24} alignItems="center">
                    <Grid item xs={12} className={classes.black}>
                        <Grid container alignItems="center">
                            <Grid item xs={8}>
                                <Typography variant="title">
                                    Scenario: <span>{scenario.name}</span>
                                </Typography>
                                <Typography>
                                    {field
                                        ? "Field Acres: " +
                                          field.size.replace("acre", "") +
                                          " / County, State: " +
                                          field.location
                                        : "Field Acres: " +
                                          scenario.acres +
                                          " / County, State: " +
                                          scenario.county +
                                          ", " +
                                          scenario.state}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ border: "12px solid #ffffff", padding: 0 }}>
                        {!editing && (
                            <Button
                                onClick={() =>
                                    this.setState({
                                        editing: true,
                                        selected: {
                                            year: new Date().getFullYear(),
                                            scenario: scenario.id,
                                            land_type: "Crop Land"
                                        }
                                    })
                                }
                                style={{ marginRight: 0 }}
                                disabled={practices.length === 7}
                                className={classes.floatRight}
                                variant="raised"
                                color="primary"
                                fullWidth>
                                <AddCircleOutlineIcon />
                                &nbsp;&nbsp;&nbsp;Add New Practice
                            </Button>
                        )}
                    </Grid>
                </Grid>
                {editing && (
                    <PracticeForm
                        classes={classes}
                        selected={selected}
                        field={field}
                        scenario={scenario}
                        practices={practices}
                        handleUnsavedFields={handleUnsavedFields}
                        close={() => this.setState({ editing: false })}
                        ormScenarioPracticeUpdate={ormScenarioPracticeUpdate}
                        ormScenarioPracticeCreate={ormScenarioPracticeCreate}
                    />
                )}
                {!editing && (
                    <this.cardContainer
                        classes={classes}
                        data={practices}
                        title="Practices Selected for Scenario"
                        emptyText="No Results to Display"
                    />
                )}
                <br />
                {practices.length > 0 && !editing && (
                    <>
                        <this.graphContainer
                            yearList={yearList}
                            data={practices.sort((a, b) => a.year - b.year)}
                            classes={classes}
                            field={field}
                            scenario={scenario}
                            acres={field ? parseFloat(field.size.split(" acre")) : scenario.acres}
                            title="Results from Practice Adoption"
                            setLoading={this.setLoadingState}
                            setExcelLoading={this.setExcelLoadingState}
                            downLoadData={this.getData()}
                            authState={authState}
                        />
                    </>
                )}
                <Form
                    dontValidateOnMount="true"
                    validateOnSubmit="true"
                    defaultValues={scenario}
                    formDidUpdate={() => handleUnsavedFields(true)}
                    validateError={this.errorValidator}
                    onSubmit={(values, fromStepper) => this.updateScenario(values, fromStepper)}
                    getApi={el => (this.formApi = el)}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={24}>
                                <Grid item xs={12} className={classes.rightAlign}>
                                    <Button
                                        onClick={() => navSelectPage("scenario")}
                                        component={Link}
                                        to="/scenario"
                                        style={{ float: "left" }}
                                        variant="raised">
                                        Return to COMET Planner Homepage
                                    </Button>
                                    <Button onClick={this.handleBack} variant="raised">
                                        Back to Edit Scenario Details
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button
                                        onClick={() => navSelectPage("scenario")}
                                        component={Link}
                                        to="/scenario"
                                        variant="raised">
                                        Cancel
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button variant="raised" type="submit" color="primary">
                                        Save and Close
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
                <WarningDialog
                    confirmText={"Yes"}
                    confirmAction={this.okDeletePractice}
                    cancelText={"No"}
                    cancelAction={this.cancelDeletePractice}
                    open={!!deletePracticeId}
                    title="Delete Practice"
                    text={"Are you sure you want to delete this practice?"}
                />
            </>
        );
    }
}

PracticeProperties = withRouter(PracticeProperties);
PracticeProperties = connect(
    (state, ownProps) => ({
        authState: state.auth,
        field: getField(state, ownProps),
        practices: getPractices(state, ownProps)
    }),
    {
        ...ScenarioPractice.actions
    }
)(PracticeProperties);

class Details extends Component {
    state = {
        name: null,
        field: null,
        chipValue: "field",
        state: null,
        county: null,
        acres: null,
        countyList: [],
        countyLoading: false
    };

    updateScenario = (values, step) => {
        let { scenario } = this.props,
            scenarioId = scenario.id;

        if (Number.isInteger(step)) {
            values["activeStep"] = step;
        } else {
            values["activeStep"] = 1;
        }
        if (scenario.isNew) {
            delete values.isNew;
            this.props.handleUnsavedFields(false);
            scenarioId = this.props.ormScenarioCreate(values);
            this.props.history.push(`/scenario/${scenarioId}`);
            this.props.ormScenarioUpdateLocalOnly(scenario);
        } else {
            this.props.handleUnsavedFields(false);
            this.props.ormScenarioUpdate({
                ...values
            });
        }
    };

    errorValidator = values => {
        let { chipValue } = this.state;
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        const notZero = val => {
            if (!val) return "Required";
            if (val < 0) return "Value for acres cannot be negative.";
        };
        var vals = {
            name: isRequired(values.name)
        };
        if (chipValue === "manual") vals["acres"] = notZero(values.acres);
        return vals;
    };

    changeName = value => {
        this.setState({ name: !!value });
    };

    changeField = value => {
        this.setState({ field: !!value });
    };

    changeState = value => {
        this.setState({ state: !!value, county: null });
        const _this = this;

        if (value) {
            this.setState({ countyLoading: true });
            fetch("/lookup/county/" + value, {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + apikey.apikey,
                    "Content-Type": "application/json"
                }
            })
                .then(response => response.json())
                .then(data => _this.setState({ countyList: data, countyLoading: false }));
        }
    };

    changeCounty = value => {
        this.setState({ county: !!value });
    };

    changeAcres = value => {
        this.setState({ acres: !!value });
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        this.props.onRef(this.formApi);
        window.scrollTo(0, 0);
        this.changeName(this.props.scenario.name);
        this.changeField(this.props.scenario.field);
        this.changeState(this.props.scenario.state);
        this.changeCounty(this.props.scenario.county);
        this.changeAcres(this.props.scenario.acres);
        if (this.props.scenario.state) this.setState({ chipValue: "manual" });

        setTimeout(function () {
            handleUnsavedFields(false);
        }, 100);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    render() {
        const { classes, navSelectPage, scenario, fields, handleUnsavedFields } = this.props;
        const { name, field, chipValue, state, county, acres, countyList, countyLoading } = this.state;

        return (
            <Form
                dontValidateOnMount="true"
                validateOnSubmit="true"
                defaultValues={scenario}
                formDidUpdate={() => handleUnsavedFields(true)}
                validateError={this.errorValidator}
                onSubmit={(values, fromStepper) => this.updateScenario(values, fromStepper)}
                getApi={el => (this.formApi = el)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="display3">Scenario Details</Typography>
                            </Grid>
                            <Grid item xs={2} md={3} lg={3} xl={4}>
                                <TextField
                                    field="name"
                                    label="Scenario Name"
                                    eventHandle={this.changeName}
                                    fullWidth
                                    margin="normal"
                                    helperText="Give your scenario a name. For example, Cover Crop Adoption in 2022 or Conservation Tillage to No-Till on Windmill Farm."
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Chip
                                    key="field"
                                    label="Use Existing Field"
                                    color={chipValue === "field" ? "primary" : "default"}
                                    onClick={() => {
                                        this.setState({ chipValue: "field" });
                                    }}
                                    className={classes.chip}
                                />
                                <Chip
                                    key="manual"
                                    label="Enter County and Acres"
                                    color={chipValue === "manual" ? "primary" : "default"}
                                    onClick={() => {
                                        this.setState({ chipValue: "manual" });
                                    }}
                                    className={classes.chip}
                                />
                            </Grid>
                            {chipValue === "field" && (
                                <Grid item xs={6}>
                                    <Select
                                        field="field"
                                        label={
                                            <HelpLabel
                                                inputLabel="Select Existing Field"
                                                helpText={
                                                    <Grid container spacing={8}>
                                                        <Grid item={2}>
                                                            <FieldLibraryIcon className={classes.iconSizeSmaller} />
                                                        </Grid>
                                                        <Grid item={10}>
                                                            You must first add a field to the field Libray.
                                                            <br />
                                                            <Link
                                                                className={classes.linkColor}
                                                                to="/farm/add"
                                                                onClick={() => navSelectPage("farm_new")}>
                                                                <Typography className={classes.linkColor}>
                                                                    Go there now&nbsp;&nbsp;
                                                                    <ChevronRightIcon className={classes.go} />
                                                                </Typography>
                                                            </Link>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                        }
                                        options={MAKE_OPTIONS(fields)}
                                        eventHandle={this.changeField}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                            )}
                            {chipValue === "manual" && (
                                <Grid item xs={6}>
                                    <Typography>Select County/State and Total Acres</Typography>
                                    <Grid container spacing={16}>
                                        <Grid item xs={6}>
                                            <Select
                                                field="state"
                                                label="State"
                                                options={states}
                                                eventHandle={this.changeState}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            {countyLoading && (
                                                <CircularProgress size={24} className={classes.progressCounty} />
                                            )}
                                            <Select
                                                field="county"
                                                label="County"
                                                key={countyList}
                                                options={countyList}
                                                eventHandle={this.changeCounty}
                                                fullWidth
                                                margin="normal"
                                                disabled={countyLoading || !state}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                field="acres"
                                                label="Acres"
                                                fullWidth
                                                eventHandle={this.changeAcres}
                                                type="number"
                                                margin="normal"
                                                className={classes.noButton}
                                                helperText="Enter the total acres on which the practice change(s) wil be implemented on the form. This may include one or more fields."
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} className={classes.rightAlign}>
                                <div>
                                    <Button to="/scenario" component={Link} variant="raised" type="submit">
                                        Cancel
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button
                                        variant="raised"
                                        type="submit"
                                        color="primary"
                                        disabled={
                                            !name ||
                                            (chipValue === "field" && !field) ||
                                            (chipValue === "manual" && (!state || !county || !acres))
                                        }>
                                        Save Scenario and Continue <ChevronRightIcon />
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

Details = withRouter(Details);
Details = connect(
    state => ({
        fields: allFields(state)
    }),
    {
        ...navActions
    }
)(Details);

function getSteps() {
    return ["Scenario Details", "Practice Details"];
}

class ScenarioProperties extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unsavedFields: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleStep = step => {
        this.handleUnsavedFields(false);

        if (this.activeForm) {
            this.activeForm.submitForm(step);
        }
    };

    updateParent = () => {
        this.setState({ foo: "bar" });
    };

    handleUnsavedFields = unsavedFields => {
        this.setState({ unsavedFields });
    };

    render() {
        const { navSelectPage, classes, scenario, ormScenarioUpdate, ormScenarioUpdateLocalOnly, ormScenarioCreate } =
            this.props;
        const { unsavedFields } = this.state;
        const steps = getSteps();

        return (
            <AppContainer authenticated color="scenario" title={scenario.isNew ? "New Scenario" : scenario.name}>
                <Prompt
                    when={unsavedFields}
                    message="You have entered data that has not been saved in our system yet. Do you want to leave without finishing?"
                />
                <Grid container spacing={24}>
                    <div>
                        <ol className={classes.breadCrumbs}>
                            <li>
                                <Typography>
                                    <i>
                                        <Link onClick={() => navSelectPage("scenario")} to="/scenario">
                                            COMET-Planner Scenario Tool
                                        </Link>
                                    </i>
                                </Typography>
                            </li>
                            <li>
                                <i>&gt;</i>
                            </li>
                            <li>
                                <Typography>
                                    <i>{scenario.isNew ? "New Scenario" : scenario.name}</i>
                                </Typography>
                            </li>
                        </ol>
                    </div>
                    <Grid item xs={12}>
                        {scenario.isNew && (
                            <Stepper
                                className={classes.stepRoot}
                                alternativeLabel
                                activeStep={scenario.activeStep || 0}>
                                {steps.map((label, index) => {
                                    return (
                                        <Step key={label}>
                                            <StepLabel
                                                classes={{
                                                    labelContainer: classes.lessMargin,
                                                    iconContainer: classes.stepColor
                                                }}>
                                                {label}
                                            </StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        )}
                        {!scenario.isNew && (
                            <Stepper
                                nonLinear
                                className={classes.stepRoot}
                                alternativeLabel
                                activeStep={scenario.activeStep || 0}>
                                {steps.map((label, index) => {
                                    return (
                                        <Step key={label} className={classes.pointer}>
                                            <StepButton onClick={() => this.handleStep(index, true)}>
                                                <StepLabel
                                                    classes={{
                                                        labelContainer: classes.lessMargin,
                                                        iconContainer: classes.stepColor
                                                    }}>
                                                    {label}
                                                </StepLabel>
                                            </StepButton>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        )}
                        <div>
                            {(scenario.activeStep === 0 || !scenario.activeStep) && (
                                <Details
                                    onRef={el => (this.activeForm = el)}
                                    scenario={scenario}
                                    classes={classes}
                                    ormScenarioUpdate={ormScenarioUpdate}
                                    ormScenarioUpdateLocalOnly={ormScenarioUpdateLocalOnly}
                                    ormScenarioCreate={ormScenarioCreate}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                />
                            )}
                            {scenario.activeStep === 1 && (
                                <PracticeProperties
                                    onRef={el => (this.activeForm = el)}
                                    scenario={scenario}
                                    field_id={scenario.field}
                                    scenario_id={scenario.id}
                                    classes={classes}
                                    ormScenarioUpdate={ormScenarioUpdate}
                                    ormScenarioUpdateLocalOnly={ormScenarioUpdateLocalOnly}
                                    ormScenarioCreate={ormScenarioCreate}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    updateParent={this.updateParent}
                                    navSelectPage={navSelectPage}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

ScenarioProperties = connect(
    (state, ownProps) => ({
        scenario:
            ownProps.match && ownProps.match.params && ownProps.match.params.id !== "new"
                ? getScenario(state, ownProps)
                : newScenario(state, ownProps)
    }),
    {
        ...Scenario.actions,
        ...navActions
    }
)(ScenarioProperties);

export default withStyles(styles)(ScenarioProperties);
