import React, { Component } from "react";

import { Dialog, DialogContent } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import withStyles from "@mui/styles/withStyles";
import Typography from "../common/TypographyWrapper";
import Toolbar from "@mui/material/Toolbar";
import Close from "@mui/icons-material/CloseOutlined";
import IconButton from "@mui/material/IconButton";

const styles = {
    linkColor: {
        color: "#808080"
    },
    flex: {
        flex: 1
    },
    termDialog: {
        width: 1000,
        maxWidth: "80%"
    },
    marginLeft: {
        marginLeft: 24
    }
};

class Privacy extends Component {
    render() {
        const { classes, dialogOpen, closeEvent } = this.props;
        return (
            <Dialog open={dialogOpen} classes={{ paper: classes.termDialog }}>
                <Toolbar>
                    <Typography variant="headline" className={classes.flex}>
                        Data and Privacy Policy
                    </Typography>
                    <IconButton onClick={closeEvent} size="large">
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="title" gutterBottom>
                            Last Updated: September 28, 2018
                        </Typography>
                        <Typography gutterBottom>
                            This privacy policy (“Policy”) explains what personal information Field to Market may
                            collect through the Fieldprint Platform (“Calculator”) (calculator.fieldtomarket.org) and
                            the website (www.fieldtomarket.org), how that information may be used, to whom it may be
                            disclosed, and how it is safeguarded. Unless indicated otherwise, this Policy applies only
                            to information collected through the Calculator, website and related web interfaces where
                            this Policy is displayed or linked by us (the “Site”). This policy applies regardless of the
                            device used to access the Site. We may refer to ourselves as “we,” “us,” or “our” throughout
                            this Policy.
                        </Typography>
                        <Typography gutterBottom>
                            This Policy is subject to change at any time. Updates to this Policy are posted on the Site
                            when they become available. We encourage you to review this Policy regularly to ensure you
                            agree with any changes that are made. Changes become effective when we post or distribute
                            the revised Policy. Your use of the Site constitutes your consent to the terms of this
                            Policy. If you cannot or do not consent to the terms of this Policy, we ask that you not use
                            this Site.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            Information We Collect and Use
                        </Typography>
                        <Typography gutterBottom>
                            Information collected directly from you includes:
                            <ul>
                                <li>
                                    Personal Information: We may collect personal information that you provide to us,
                                    including identification information (such as your name, address, email address,
                                    telephone number) and account information (such as your user name and password).
                                </li>
                                <li>
                                    Farm Data: We may collect farm data you provide to us, including land, field,
                                    geospatial (location), agronomic, application, farm operation, and other forms of
                                    farm data.
                                </li>
                            </ul>
                        </Typography>
                        <Typography gutterBottom>
                            This information is collected when you register for an account and when you enter input data
                            for Fieldprint Analysis.
                        </Typography>
                        <Typography gutterBottom>
                            Information we collect automatically includes:
                            <ul>
                                <li>
                                    Navigational Information: When you visit the Site, we automatically collect
                                    information related to how you got to the Site, such as browser type and version,
                                    service-provider identification, IP address, the site from which you came, and the
                                    site to which you navigate after leaving our Site. Some of this information is
                                    anonymous, as it does not permit us to identify you personally. However, we may
                                    associate this information with your personal information if you provide it.
                                </li>
                                <li>
                                    Cookies and Clear gif files and Google Analytics: This Site may also use “cookies”
                                    and other technologies to recognize users, customize their experience, or target
                                    marketing. A cookie is a small text file that a website’s server places on a user’s
                                    computer, mobile phone, or other device. The cookie transmits information back to
                                    the website’s server about the browsing activities of the user. This includes
                                    information such as pages and content viewed, the time and duration of visits, and
                                    what links a user may have clicked on the Site. Cookies can also be used to
                                    recognize users and maintain data related to a particular individual, including
                                    passwords. In some contexts, such as where a number of separate websites participate
                                    in a network, cookies can be used to track a user across different sites. Cookies
                                    are used as a standard practice by many sites.
                                </li>
                            </ul>
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            How We Use Your Information
                        </Typography>
                        <Typography gutterBottom>
                            We may use information we collect from you for the following purposes:
                            <ul>
                                <li>Generate Fieldprint Results for your own personal use;</li>
                                <li>
                                    Calculate aggregate Fieldprint Results for Fieldprint Projects to which you have
                                    opted-in;
                                </li>
                                <li>Personalize your Site experience with content or settings tailored to you; </li>
                                <li>
                                    Provide customer service, whether in response to a request or following up on an
                                    inquiry;
                                </li>
                                <li>
                                    Improve our Site, the manner in which our services are offered on our Site, or the
                                    interactions visitors have with our Site; and
                                </li>
                                <li>Send email updates, news and announcements about the Site.</li>
                            </ul>
                        </Typography>
                        <Typography gutterBottom>
                            We also use cookies and Google Analytics to improve your experience with the Site. Google
                            Analytics is a web analysis service provided by Google that utilizes the data collected from
                            our Site, through cookies and similar technologies, to track and examine the use of the
                            Site, prepare reports on these activities, and share them with us. We use these cookies and
                            the Google Analytics information to improve the delivery and performance of our Site by:
                            <ul>
                                <li>Better understanding how you use our Site;</li>
                                <li>Detecting and defending against fraud and other security risks; and</li>
                                <li>Better presenting products and/or services of interest to you.</li>
                            </ul>
                        </Typography>
                        <Typography gutterBottom>
                            To learn more about how Google Analytics collects and processes data, please visit “How
                            Google uses data when you use our partners’ sites or apps”, located at&nbsp;
                            <a
                                className={classes.linkColor}
                                href="www.google.com/policies/privacy/partners/"
                                target="_blank"
                                rel="noopener noreferrer">
                                www.google.com/policies/privacy/partners/
                            </a>
                            . To learn about how to opt-out of Google Analytics, please visit&nbsp;
                            <a
                                className={classes.linkColor}
                                href="http://tools.Google.com/dlpage/gaoptout/"
                                target="_blank"
                                rel="noopener noreferrer">
                                http://tools.Google.com/dlpage/gaoptout/
                            </a>
                            .
                        </Typography>
                        <Typography gutterBottom>
                            If you would prefer not to accept cookies from our Site or any other website, you can modify
                            the settings in your browser. Refer to your browser’s online help feature or user manual for
                            instructions on how to do this. If you refuse to accept cookies, it is possible that some
                            portions of the Site may not function as intended.
                        </Typography>
                        <Typography gutterBottom>Use of Anonymized and Aggregated Information</Typography>
                        <Typography gutterBottom>
                            We may use your data to develop anonymized and aggregated reports (reports that cannot be
                            tracked back to any specific user or operation) for the following purposes:
                            <ul>
                                <li>Calculate aggregate Fieldprint Results for Fieldprint Projects; </li>
                                <li>Generate Field to Market program reports;</li>
                                <li>Improve and develop sustainability assessment metrics and tools;</li>
                                <li>Promote the understanding and adoption of sustainability; or</li>
                                <li>
                                    Share with third parties to further the promotion and development of sustainability.
                                </li>
                            </ul>
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            Your Information Ownership
                        </Typography>
                        <Typography gutterBottom>
                            Other than the uses outlined in this policy, personal data and farm data you provide us
                            through the Site is only for your use. You retain ownership of your personal data and farm
                            data, even after it is collected by us. Information you provide us that is capable of
                            identifying you or your farm data will never be shared by us with any third parties without
                            your prior knowledge and consent.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            Information We Share with Third Parties
                        </Typography>
                        <Typography gutterBottom>
                            We may provide your information to third parties who provide services to us or on our
                            behalf, such as:
                            <ul>
                                <li>
                                    Model and Data Web Services: Through a Memorandum of Understanding between Field to
                                    Market and USDA’s Natural Resource Conservation Service (NRCS), the Site derives
                                    secondary input data from NRCS Model and Data Web Services hosted with the Colorado
                                    State University, Object Modeling System Lab (OMSLab). Farm data including field
                                    boundary, soil data, and crop rotation system are sent to CSU OMSlab to retrieve
                                    secondary input data required for Fieldprint Analysis. Information sent to CSU
                                    OMSLab is stored for a 24-hour period for logging and troubleshooting purposes only.
                                    These Model and Data Web Services include:
                                    <ul>
                                        <li>Water Erosion Prediction Project (WEPP)</li>
                                        <li>Revised Universal Soil Loss Equation, Version 2 (RUSLE2)</li>
                                        <li>Wind Erosion Prediction System (WEPS)</li>
                                        <li>
                                            Reference databases including Land Management and Operation Database (LMOD)
                                            and Soil Survey Geographic Database (SSURGO)
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Vendors: We use vendors to help handle parts of our business because of their
                                    expertise, resources, or scale. They help us do things like develop Site features
                                    and functionality; provide technical support for the Site application, database and
                                    infrastructure; and provide user support.{" "}
                                </li>
                                <li>
                                    Third-Party Support: We may use third parties to provide a variety of services to
                                    us, including, hosting the Site, providing technical support, processing and storing
                                    your information, and sending or coordinating the sending of marketing
                                    communications on our behalf.
                                </li>
                                <li>
                                    Law Enforcement and Regulators: We may disclose specific information about our Site
                                    visitors with outside companies, organizations, or individuals if we have a
                                    good-faith belief that access, use, preservation, or disclosures of the information
                                    is reasonably necessary to:
                                    <ul>
                                        <li>
                                            meet any applicable law, regulation, legal process or enforceable
                                            governmental request.
                                        </li>
                                        <li>
                                            detect, prevent, or otherwise address fraud, security or technical issues.
                                        </li>
                                        <li>
                                            protect against harm to the rights, property, or safety of our business, our
                                            users, or the public as require or permitted by law.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </Typography>
                        <Typography gutterBottom>
                            If we are involved in a merger, acquisition, or asset sale, we will continue to ensure the
                            confidentiality of any personal information we possess and give affected users notice before
                            personal information is transferred or becomes subject to a different privacy policy.
                        </Typography>
                        <Typography gutterBottom>
                            As noted above, we may share anonymized and aggregated data publicly with our partners for
                            use in case studies or similar programs. However, we will never share identifying
                            information without your prior knowledge and consent.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            Deleting Your Information
                        </Typography>
                        <Typography gutterBottom>
                            To terminate your account and have personal and farm data deleted from the Site, a request
                            must be sent to&nbsp;
                            <a className={classes.linkColor} href="mailto:support@fieldtomarket.org">
                                support@fieldtomarket.org
                            </a>
                            . Once the request has been confirmed and validated, information will be deleted from the
                            site after 90 days, subject to any regulatory or technological limitations. You can request
                            a copy of your data in the format it was provided or another commonly used format. Note that
                            anonymized and aggregated information derived from your personal and farm data will not be
                            deleted from the Site or removed from published or previously generated reports.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            Sharing Your Information with Other Site Users
                        </Typography>
                        <Typography gutterBottom>
                            The site allows you to authorize other Site users to access your account and access your
                            information. This is only possible if you explicitly authorize this access using certain
                            Site features. You can revoke access at any time. When you authorize users to access your
                            account and act on your behalf to provide information on the Site, the information is
                            assumed to be yours. You are responsible to ensure that any authorized users appropriately
                            use and protect your information. Authorized users cannot modify your user profile or
                            delegate access settings.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            California Privacy Rights: Do Not Track & Behavioral Advertising
                        </Typography>
                        <Typography gutterBottom>
                            This Site does not currently respond to “Do Not Track” signals, and does not authorize the
                            collection of personally identifiable information by third parties or sharing of personally
                            identifiable information with third parties except as provided in this Policy.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            Links to Other Websites
                        </Typography>
                        <Typography gutterBottom>
                            While you are using the Site, you may be linked or directed to other third-party sites
                            outside of our Site that are beyond our control. Each of these third-party sites may have a
                            privacy policy different from ours. Please review the privacy policies of these sites
                            carefully. We are not responsible for any actions or policies of such third parties.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            Security
                        </Typography>
                        <Typography gutterBottom>
                            In order to prevent the loss or misuse of the data, we make use of industry-standard
                            administrative, physical, and technical security measures and safeguards that are regularly
                            checked and adapted to technical progress. However, please note that, due to the structure
                            of the Internet, it is possible that the rules of data privacy and the aforementioned
                            security measures may not be observed by other individuals or institutions over which we
                            have no responsibility or control. This means that we cannot guarantee, ensure, or warrant
                            the security of any information you transmit to us. There is no guarantee that information
                            may not be accessed, disclosed, altered, or destroyed by breach of any of our
                            administrative, physical, and physical safeguards. It is your responsibility to protect the
                            security of your account and login information. Please note that emails and other
                            communications sent to us through our Site are not encrypted and we strongly advise you not
                            to communicate any confidential information through these means.
                        </Typography>
                        <Typography gutterBottom>
                            In the event of a breach of the confidentiality or security of your personal information, we
                            will notify you as necessary, and to the extent possible, so you can take appropriate
                            protective steps. Unless you indicate otherwise, we may notify you using the email address
                            you provided to us when you registered with our Site.
                        </Typography>
                        <Typography gutterBottom>
                            You help to maintain the security of your personally identifiable information stored in our
                            systems by keeping your username and password confidential. You should protect your password
                            and not share it with any other individual. Be sure to sign off or log-out when finished if
                            you are using a shared computer.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            Privacy of Children
                        </Typography>
                        <Typography gutterBottom>
                            The Site is not intended for individuals under the age of 13. We do not intentionally
                            collect personally identifiable information from users of this Site who are under the age of
                            13. If we become aware that a user of our Site is under the age of 13 and has provided
                            personally identifiable information to us via our Site, we will remove that child’s personal
                            information from our files.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            Reviewing or Updating Your Information or Email Preferences
                        </Typography>
                        <Typography gutterBottom>Reviewing or Updating Your Information</Typography>
                        <Typography gutterBottom>
                            We believe that you have a right to know that the information we collect from you is
                            accurate and up to date. Maintaining the accuracy of that information is very important to
                            us. If your account information is incomplete, inaccurate, or not up to date, or if you
                            would like to review and/or request changes to any of your information, you may sign in and
                            review or make changes to your information under your account settings, or, you may email us
                            at&nbsp;
                            <a className={classes.linkColor} href="mailto:support@fieldtomarket.org">
                                support@fieldtomarket.org
                            </a>
                            &nbsp;and we will provide you with the information we have about you to review or make
                            changes. If you have further questions or concerns about your information, please
                            contact&nbsp;
                            <a className={classes.linkColor} href="mailto:support@fieldtomarket.org">
                                support@fieldtomarket.org
                            </a>
                            &nbsp;for further assistance.
                        </Typography>
                        <Typography gutterBottom>Email Preferences</Typography>
                        <Typography gutterBottom>
                            If you set up an account or otherwise sign up to receive notifications, you may receive
                            emails from us. You may unsubscribe from these emails by following the removal instructions
                            located at the bottom of each email. Opting out of any of these emails will not stop
                            operational email messages such as password reset or account related information from Field
                            to Market.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            Visitors From Outside the U.S.
                        </Typography>
                        <Typography gutterBottom>
                            This is a U.S.-based Site. This Policy governs the information collected by Field to Market
                            including information collected at this Site irrespective of where you are located when you
                            access it, browse it, interact with it, or submit information to it. This means your
                            personal information may be stored outside of the province, state, and/or country in which
                            you reside (including in the U.S.), and processed by us, an affiliate or a third-party
                            service provider as described in this Policy. Moreover, governmental and regulatory bodies
                            that have jurisdiction (e.g., courts, regulators, and law enforcement agencies) may be
                            entitled to access your personal information.
                        </Typography>
                        <Typography variant="headline" gutterBottom>
                            Contact Us
                        </Typography>
                        <Typography gutterBottom>
                            If you have questions or concerns with respect to this Policy, please contact us at&nbsp;
                            <a className={classes.linkColor} href="mailto:support@fieldtomarket.org">
                                support@fieldtomarket.org
                            </a>
                            &nbsp; or at Field to Market, The Alliance for Sustainable Agriculture, 777 N. Capitol
                            Street, NE, Suite 803, Washington, DC 20002.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Privacy);
