import { setValue, getValue } from "../../../api/utils";
import { RICE } from "../../../api/constants";
const LIME_UNIT_MSG = "Enter amount in units of ton/acre.  If measured in lbs/acre, divide by 2,000.",
    N_CARRY_OVER_MSG = "Typically the carry over value does not exceed 275 lbs/ac.";

export const isCompleted = (values, cY, hardRequire, fertilizers, manureCt, submitClicked) => {
    var numberRequired = 0; // Keep track of the actual number of required fields

    const isRequired = path => {
        numberRequired++;
        let val = getValue(values, path);
        setValue(valObj, path, val || val === false ? null : "Required");
    };
    const validateYear = (path, path2) => {
        numberRequired++;
        let val = getValue(values, path);
        if (!val) {
            setValue(valObj, path, "Required");
            return;
        }
        if (val.length !== 4) {
            setValue(valObj, path, "Invalid year");
            return;
        }
        const number = parseFloat(val);
        if (number < 1900 || number > 2100) {
            setValue(valObj, path, "Invalid year");
            return;
        }
        if (number % 1 !== 0) {
            setValue(valObj, path, "Invalid year");
            return;
        }
        let val2 = getValue(values, path2);
        const number2 = parseFloat(val2);
        const year = parseFloat(cY.year);
        if (number + number2 < year) {
            setValue(
                valObj,
                path,
                "You are entering data for crop year " +
                    year +
                    ", but the year lime was applied plus number of years applicable is less than this year."
            );
            return;
        }
        if (number > year) {
            setValue(
                valObj,
                path,
                "You are entering data for crop year " + year + ". The lime applied year cannot be in the future."
            );
            return;
        }
        setValue(valObj, path, null);
    };
    const validateYears = path => {
        numberRequired++;
        let val = getValue(values, path);
        if (!val) {
            setValue(valObj, path, "Required");
            return;
        }
        const number = parseFloat(val);
        if (number < 0) {
            setValue(valObj, path, "Invalid number of years");
            return;
        }
        if (number % 1 !== 0) {
            setValue(valObj, path, "Invalid number of years");
            return;
        }
        setValue(valObj, path, null);
    };
    const validateNumber = (path, max, maxMessage) => {
        numberRequired++;
        let val = getValue(values, path);
        if (!val) {
            setValue(valObj, path, "Required");
            return;
        }
        const number = parseFloat(val);
        if (number < 0) {
            setValue(valObj, path, "Invalid");
            return;
        }
        if (max && number > max) {
            setValue(valObj, path, maxMessage || "Invalid");
            return;
        }
        setValue(valObj, path, null);
    };
    const hasPotassiumOnly = fert => {
        let pOnly = false,
            hasNitrogen = false,
            hasPhosphorus = false,
            hasPotassium = false,
            isValidCustomblend = false,
            isValidCustomrate = false,
            isValidFert = false,
            fHasPotassium = false,
            fHasNitrogen = false,
            fHasPhosphorus = false;

        const PotFertIds = ["13", "14"];
        const NitrogenFertIds = [
            "4",
            "8L",
            "3",
            "5",
            "2",
            "29",
            "28",
            "27",
            "30",
            "31",
            "32",
            "33",
            "34",
            "7",
            "8",
            "11",
            "1",
            "16",
            "15",
            "6",
            "24",
            "29W",
            "23",
            "17",
            "20",
            "28W",
            "21",
            "22",
            "18",
            "19",
            "27W",
            "30W",
            "31W",
            "32W",
            "33W",
            "34W"
        ];
        const PhosphorusFertIds = [
            "29",
            "28",
            "27",
            "11",
            "16",
            "12",
            "9",
            "10",
            "25",
            "26",
            "24",
            "29W",
            "23",
            "17",
            "20",
            "28W",
            "21",
            "22",
            "18",
            "19",
            "27W"
        ];
        const PotassiumFertIds = ["13", "29", "15", "14", "25", "26", "24", "29W", "23", "17", "20", "21", "22", "18"];

        fert.forEach((a, i) => {
            if (a.energyuse) {
                const eu = a.energyuse;

                if (eu.fertilizer_id === "35") {
                    if (eu.customblend) {
                        const customblend = eu.customblend;
                        if (parseInt(customblend.nitrogen) > 0) {
                            hasNitrogen = true;
                            fHasNitrogen = true;
                        }
                        if (parseInt(customblend.phosphorus) > 0) {
                            hasPhosphorus = true;
                            fHasPhosphorus = true;
                        }
                        if (parseInt(customblend.potassium) > 0) {
                            hasPotassium = true;
                            fHasPotassium = true;
                        }

                        if (!hasNitrogen && !hasPhosphorus && parseInt(customblend.potassium) === 100) {
                            isValidCustomblend = true;
                        } else {
                            isValidCustomblend = false;
                        }
                    }
                } else if (eu.fertilizer_id === "36") {
                    if (eu.customrate) {
                        const customrate = eu.customrate;
                        if (parseInt(customrate.nitrogen) > 0) {
                            hasNitrogen = true;
                            fHasNitrogen = true;
                        }
                        if (parseInt(customrate.phosphorus) > 0) {
                            hasPhosphorus = true;
                            fHasPhosphorus = true;
                        }
                        if (parseInt(customrate.potassium) > 0) {
                            hasPotassium = true;
                            fHasPotassium = true;
                        }
                        if (!hasNitrogen && !hasPhosphorus && hasPotassium) {
                            isValidCustomrate = true;
                        } else {
                            isValidCustomrate = false;
                        }
                    }
                } else {
                    if (NitrogenFertIds.indexOf(eu.fertilizer_id) > -1) {
                        fHasNitrogen = true;
                    }
                    if (PhosphorusFertIds.indexOf(eu.fertilizer_id) > -1) {
                        fHasPhosphorus = true;
                    }
                    if (PotassiumFertIds.indexOf(eu.fertilizer_id) > -1) {
                        fHasPotassium = true;
                    }
                }

                if (PotFertIds.includes(eu.fertilizer_id)) {
                    isValidFert = true;
                } else {
                    isValidFert = false;
                }

                if (isValidCustomrate || isValidCustomblend || isValidFert) {
                    pOnly = true;
                }
            }
        });

        if (fertilizers === null) {
            pOnly = null;
        }

        return { pOnly, fHasNitrogen, fHasPotassium, fHasPhosphorus };
    };

    var valObj = {};

    isRequired("is_irrigated");
    isRequired("soils.has_wind_barriers");
    isRequired("waterquality.tile_drainage");

    const nutrient_applied = getValue(values, "waterquality.nutrient_applied"),
        nitrogen_applied = getValue(values, "waterquality.nitrogen_applied"),
        phosphorus_applied = getValue(values, "waterquality.phosphorus_applied"),
        potassiumOnly = hasPotassiumOnly(fertilizers);

    if (parseInt(manureCt) > 0) {
        potassiumOnly.fHasNitrogen = true;
        potassiumOnly.fHasPhosphorus = true;
    }

    if (nutrient_applied === "true" || nutrient_applied === true) {
        isRequired("biodiversity.nutrient_management_plan");
        if (
            (nitrogen_applied === false || nitrogen_applied === "false") &&
            (phosphorus_applied === false || phosphorus_applied === "false")
        ) {
            setValue(valObj, "waterquality.nitrogen_applied", null);
            setValue(
                valObj,
                "waterquality.phosphorus_applied",
                "You indicated that you applied fertilizer (either organic or inorganic source) this crop year.  Please indicate whether the fertilizer contained Nitrogen, Phosphorus, or both."
            );
            if (potassiumOnly.pOnly !== null) {
                if (!potassiumOnly.pOnly) {
                    setValue(valObj, "waterquality.nitrogen_applied", null);
                    setValue(
                        valObj,
                        "waterquality.phosphorus_applied",
                        "You indicated that you applied fertilizer (either organic or inorganic source) this crop year.  Please indicate whether the fertilizer contained Nitrogen, Phosphorus, or both."
                    );
                } else {
                    setValue(valObj, "waterquality.nitrogen_applied", null);
                    setValue(valObj, "waterquality.phosphorus_applied", null);
                }
            }
        } else {
            var hasError = false;

            if (nitrogen_applied === false || nitrogen_applied === "false") {
                if (potassiumOnly.fHasNitrogen !== null && potassiumOnly.fHasNitrogen) {
                    hasError = true;
                    setValue(
                        valObj,
                        "waterquality.nitrogen_applied",
                        "Nitrogen was included in a fertilizer application. You indicated that you did not apply nitrogen fertilizer (either organic or inorganic source) this crop year."
                    );
                }
            }

            if (nitrogen_applied === true || nitrogen_applied === "true") {
                if (potassiumOnly.fHasNitrogen !== null && !potassiumOnly.fHasNitrogen) {
                    hasError = true;
                    setValue(
                        valObj,
                        "waterquality.nitrogen_applied",
                        "Nitrogen was not included in a fertilizer application. You indicated that you applied nitrogen fertilizer (either organic or inorganic source) this crop year."
                    );
                }
            }

            if (phosphorus_applied === false || phosphorus_applied === "false") {
                if (potassiumOnly.fHasPhosphorus !== null && potassiumOnly.fHasPhosphorus) {
                    hasError = true;
                    setValue(
                        valObj,
                        "waterquality.phosphorus_applied",
                        "Phosphorus was included in a fertilizer application. You indicated that you did not apply phosphorus fertilizer (either organic or inorganic source) this crop year."
                    );
                }
            }

            if (phosphorus_applied === true || phosphorus_applied === "true") {
                if (potassiumOnly.fHasPhosphorus !== null && !potassiumOnly.fHasPhosphorus) {
                    hasError = true;
                    setValue(
                        valObj,
                        "waterquality.phosphorus_applied",
                        "Phosphorus was not included in a fertilizer application. You indicated that you applied phosphorus fertilizer (either organic or inorganic source) this crop year."
                    );
                }
            }

            if (!hasError) {
                isRequired("waterquality.nitrogen_applied");
                isRequired("waterquality.phosphorus_applied");
            }
        }
    }

    if (nutrient_applied === false && fertilizers !== undefined && fertilizers.length >= 1) {
        setValue(
            valObj,
            "waterquality.nutrient_applied",
            "You indicated that you did not appy fertilizer this crop year but you added one or more fertilizer application trips."
        );
    }

    isRequired("energyuse.lime_applied");
    isRequired("waterquality.pest_management_id");
    isRequired("biodiversity.land_cover_change");

    if (cY.crop === RICE) {
        isRequired("greenhouse.water_regime");
        isRequired("greenhouse.has_sulfur");
    }

    const lime_applied = getValue(values, "energyuse.lime_applied");
    if (lime_applied === "true" || lime_applied === true) {
        validateYear("energyuse.lime_year_applied", "energyuse.lime_years");
        validateYears("energyuse.lime_years");
        validateNumber("energyuse.lime_amount", 10, LIME_UNIT_MSG);
    }

    const applysulfate = getValue(values, "greenhouse.has_sulfur");
    if (cY.crop === RICE && (applysulfate === "true" || applysulfate === true)) {
        validateNumber("greenhouse.sulfur_rate");
    }

    if (nitrogen_applied === "true" || nitrogen_applied === true) {
        isRequired("waterquality.n_carry_over");
        const nCarryOver = getValue(values, "waterquality.n_carry_over");
        if (nCarryOver) {
            validateNumber("waterquality.n_carry_over", 325, N_CARRY_OVER_MSG);
        }
    }

    if (phosphorus_applied === "true" || phosphorus_applied === true) {
        isRequired("waterquality.p_multi_year_app");
        isRequired("waterquality.p_soil_test_level");
    }

    const landconversion = getValue(values, "biodiversity.land_cover_change");
    if (landconversion === "true" || landconversion === true) {
        isRequired("biodiversity.previous_land_cover");
        validateNumber("biodiversity.field_area_converted");
    }

    if (hardRequire) {
        // hardRequire = actual validation
        // FIXME: v2.X of react-forms has a submitting attribute on the formapi, but doesn't appear to be functional
        // V3.X seems to be a lot of work to upgrade
        // We are simulating a custom state (submitClicked) to know if its actually submiting
        // If it is submitting ignore the validator, submit, and move on
        if (submitClicked) {
            Object.keys(valObj).forEach(k => (valObj[k] = null)); // Set all values to null to clear validation
        }
        return valObj;
    }

    // If we are doing the final save of the form track how many fields are missing
    var missing = 0;
    Object.keys(valObj).forEach(function (key) {
        if (valObj[key] !== null && typeof valObj[key] === "object") {
            // Also check child objects
            Object.keys(valObj[key]).forEach(function (childKey) {
                if (valObj[key][childKey] !== null) missing++;
            });
        } else if (valObj[key] !== null) missing++;
    });

    return [missing, numberRequired];
};
