import React, { Component } from "react";
import { Form } from "react-form";
import withRouter from "../common/withRouter";
import { connect } from "react-redux";

import Button from "../common/ButtonWrapper";
import { Dialog, DialogContent, DialogContentText } from "@mui/material";
import Grid from "../common/GridWrapper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "../common/TypographyWrapper";

import withStyles from "@mui/styles/withStyles";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import { FarmCropYear } from "./models";
import Select from "../common/Select";
import HelpLabel from "../common/HelpLabel";
import TextField from "../common/TextField";
import { createSelector } from "../common/orm";
import { MAKE_OPTIONS } from "../../api/constants";

const allCrops = createSelector(schema => {
    return schema.Crops.all().orderBy("name").toModelArray();
});
const getFarmCropYear = createSelector(
    (state, ownProps) => ownProps.farm_id,
    (session, farm_id) => {
        return session.FarmCropYear.filter({ farm: farm_id }).toModelArray();
    }
);

const styles = {
    dialogMin: {
        minWidth: "400px"
    },
    flex: {
        flex: 1
    },
    button: {
        float: "right"
    },
    gutterTop: {
        marginTop: ".6em"
    },
    error: {
        marginTop: ".6em",
        color: "red"
    },
    minWidth: {
        minWidth: 600
    }
};

class AddManagedAcre extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            crop: null,
            year: null,
            showFarmCropYear: false,
            showMultiple: false
        };
    }

    addCropYear(values) {
        const { returnDialog, farm_id, ormFarmCropYearCreate } = this.props;

        ormFarmCropYearCreate({
            farm: farm_id,
            year: values.year,
            crop: values.primary,
            irrigated: values.irrigated,
            nonirrigated: values.nonirrigated
        });

        returnDialog();
    }

    errorValidator = values => {
        const validateYear = year => {
            if (!year) {
                return "Required";
            }
            if (year.length !== 4) {
                return "Invalid year";
            }
            const number = parseFloat(year);
            if (number < 1900 || number > 2100) {
                return "Invalid year";
            }
            if (number % 1 !== 0) {
                return "Invalid year";
            }
            return null;
        };
        const validateNumber = (num, sib) => {
            if (!num) {
                return "Required";
            }
            const number = parseFloat(num);
            if (number < 0 || number > 80000) {
                return "Must be between 0-80000";
            }
            return null;
        };
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            year: validateYear(values.year),
            primary: isRequired(values.primary)
        };

        valObj["irrigated"] = validateNumber(values.irrigated, values.nonirrigated);
        valObj["nonirrigated"] = validateNumber(values.nonirrigated, values.irrigated);

        return valObj;
    };

    checkFarmCropYear = value => {
        const { cropLookup, farmCropYear } = this.props;

        const addComponent = this;
        setTimeout(function () {
            // The formapi values are not present right away
            const year = addComponent.formApi.getValue("year");
            const primary = addComponent.formApi.getValue("primary");
            if (year && primary) {
                const mALength = farmCropYear.filter(
                    mA => mA.year === year && ((mA.crop && mA.crop.id === primary) || mA.crop_id === primary)
                );
                if (mALength.length > 0) {
                    addComponent.setState({
                        year: year,
                        crop: cropLookup[primary].name,
                        showMultiple: true,
                        showFarmCropYear: false
                    });
                } else {
                    addComponent.setState({
                        year: year,
                        crop: cropLookup[primary].name,
                        showFarmCropYear: true,
                        showMultiple: false
                    });
                }
            } else {
                addComponent.setState({ showFarmCropYear: false, showMultiple: false });
            }
        }, 100);
    };

    render() {
        const { classes, open, allCrops, returnDialog, farm_name } = this.props;

        const { crop, year, showFarmCropYear, showMultiple } = this.state;

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="title" className={classes.flex}>
                        Add New Managed Acres
                    </Typography>
                    <IconButton
                        onClick={() => {
                            this.setState({ showFarmCropYear: false, showMultiple: false });
                            returnDialog();
                        }}
                        size="large">
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent className={classes.dialogMin}>
                    <DialogContentText>
                        <Form
                            getApi={el => (this.formApi = el)}
                            dontValidateOnMount="true"
                            validateOnSubmit="true"
                            validateError={this.errorValidator}
                            defaultValues={{
                                year: new Date().getFullYear().toString()
                            }}
                            onSubmit={values => this.addCropYear(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <TextField
                                                field="year"
                                                label="Year"
                                                fullWidth
                                                eventHandle={this.checkFarmCropYear}
                                                type="number"
                                                margin="normal"
                                            />
                                            <Select
                                                field="primary"
                                                label="Enter Primary Crop Grown in That Year"
                                                eventHandle={this.checkFarmCropYear}
                                                options={MAKE_OPTIONS(allCrops)}
                                                fullWidth
                                                margin="normal"
                                            />
                                            {showFarmCropYear && (
                                                <div>
                                                    <Typography
                                                        variant="title"
                                                        gutterBottom
                                                        className={classes.gutterTop}>
                                                        Enter Managed Acres
                                                        <HelpLabel
                                                            question="Managed Acres"
                                                            style={{ marginLeft: 4, display: "inline" }}
                                                            helpText={
                                                                <div>
                                                                    <Typography gutterBottom>
                                                                        Managed acres represent the total number of
                                                                        acres, for both irrigated and non-irrigated
                                                                        acres, you grow for the selected crop on this
                                                                        farm in the selected year. You can edit this
                                                                        information at any time on the Farm page.
                                                                    </Typography>
                                                                    <Typography gutterBottom>
                                                                        For example, you enter two corn fields for a
                                                                        total of 100 acres in the Platform for 2017,
                                                                        that represent a total of 850 acres of corn
                                                                        across your entire farm. If these fields are
                                                                        associated with a Project, the total managed
                                                                        acres (850 acres) may be considered enrolled if
                                                                        a minimum of 10% of the total managed acres are
                                                                        entered in Fieldprint Platform.
                                                                    </Typography>
                                                                </div>
                                                            }
                                                        />
                                                    </Typography>
                                                    <TextField
                                                        field="nonirrigated"
                                                        label={
                                                            <div>
                                                                Enter the total <b>non-irrigated</b> acres of {crop}{" "}
                                                                grown in {year} on {farm_name}.
                                                            </div>
                                                        }
                                                        fullWidth
                                                        type="number"
                                                        units="acres"
                                                        margin="normal"
                                                    />
                                                    <TextField
                                                        field="irrigated"
                                                        label={
                                                            <div>
                                                                Enter the total <b>irrigated</b> acres of {crop} grown
                                                                in {year} on {farm_name}.
                                                            </div>
                                                        }
                                                        fullWidth
                                                        type="number"
                                                        units="acres"
                                                        margin="normal"
                                                    />
                                                    <Button
                                                        type="submit"
                                                        variant="raised"
                                                        color="primary"
                                                        className={classes.button}>
                                                        Create
                                                    </Button>
                                                </div>
                                            )}
                                            {showMultiple && (
                                                <Typography gutterBottom className={classes.error}>
                                                    Error: Managed acre record for this year/crop already exists.
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

AddManagedAcre = connect(
    (state, ownProps) => ({
        allCrops: allCrops(state),
        farmCropYear: getFarmCropYear(state, ownProps),
        cropLookup: state.orm.Crops.itemsById
    }),
    {
        ...FarmCropYear.actions
    }
)(AddManagedAcre);

export default withStyles(styles)(withRouter(AddManagedAcre));
