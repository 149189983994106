import React, { Component } from "react";
import withRouter from "../common/withRouter";
import { connect } from "react-redux";
import { Dialog, DialogContent, DialogContentText } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Button from "../common/ButtonWrapper";
import Typography from "../common/TypographyWrapper";
import Toolbar from "@mui/material/Toolbar";
import { ResearchOpt } from "./Research";

import * as navActions from "../common/actions";
import * as authActions from "../auth/actions";

const styles = {
    flex: {
        flex: 1
    },
    termDialog: {
        width: 1000,
        maxWidth: "80%"
    },
    marginLeft: {
        marginLeft: 24
    },
    linkColor: {
        color: "#808080",
        cursor: "pointer",
        textDecoration: "underline"
    },
    toolbar: {
        backgroundColor: "#00a2e5",
        "& h1": {
            color: "#fff"
        }
    },
    moreButton: {
        backgroundColor: "#00a2e5",
        color: "#fff",
        border: "1.5px solid #00a2e5",
        boxShadow: "none",
        float: "left",
        marginRight: 10
    },
    laterButton: {
        backgroundColor: "#fff",
        color: "#00a2e5",
        border: "1.5px solid #00a2e5",
        boxShadow: "none",
        float: "left",
        "&:hover": {
            backgroundColor: "#00a2e5",
            color: "#fff",
            border: "1.5px solid #fff"
        }
    },
    greyButton: {
        backgroundColor: "#fff",
        color: "#606060",
        border: "1.5px solid #606060",
        boxShadow: "none",
        float: "right",
        "&:hover": {
            backgroundColor: "#606060",
            color: "#fff",
            border: "1.5px solid #fff"
        }
    },
    marginTop: {
        marginTop: 5
    },
    title: {
        color: "#000"
    }
};

class ResearchDialog extends Component {
    state = {
        privacyOpen: false
    };

    continueResearch = () => {
        this.props.navSelectPage("research");
        this.props.history.push("/research");
    };

    maybeLater = () => {
        this.props.authResearch("shown", {});
    };

    hideDialog = () => {
        this.props
            .authResearch("hide", {
                hide: true
            })
            .then(() => {
                if (this.props.update.success) {
                    this.props.history.push("/dashboard");
                }
            });
    };

    compare = user => {
        if (user.research_hide && !user.research_last_shown) {
            return true;
        }

        const shown = new Date(user.research_last_shown);
        const login = new Date(user.last_login);
        if (user.research_last_shown && login < shown) {
            return true;
        }
        return false;
    };

    render() {
        const { authState, classes } = this.props;
        const isRealUser = authState && authState.user && !authState.user.guest,
            research_opt = isRealUser && authState.user.research_opt;

        var open = true;
        if (!isRealUser) {
            open = false;
        } else if (authState.user.research_hide) {
            open = false;
        } else if (this.compare(authState.user)) {
            open = false;
        }

        return (
            <Dialog open={open} classes={{ paper: classes.termDialog }}>
                <Toolbar className={classes.toolbar}>
                    <Typography variant="headline" className={classes.flex}>
                        Field to Market Research Database
                    </Typography>
                </Toolbar>
                <DialogContent>
                    <DialogContentText className={classes.marginTop}>
                        <Typography className={classes.title} variant="title" gutterBottom>
                            Building Knowledge and Transparency
                        </Typography>
                        <Typography gutterBottom>
                            Field to Market recently introduced a scientific research database funded by The Foundation
                            for Food and Agriculture Research with the purpose of incentivizing and encouraging research
                            that will advance sustainability guidance, metrics and programs for all farmers.
                        </Typography>
                        <Typography gutterBottom>
                            We believe there is a reputational benefit of sharing data with the research community to
                            advance knowledge. Transparency leads to public credibility for all farmers. If you opt in,
                            your data will help build knowledge that will lead to better, more targeted information
                            about how practice changes may impact crop yield and environmental outcomes, and better
                            regional assessments of how farming practices can address environmental concerns.
                        </Typography>
                        <Typography gutterBottom>
                            The research database is 100% voluntary. Your data is not included in the research database
                            unless you explicitly opt-in. We encourage you to learn more about the research database,
                            including the benefit to you, how the data is used, and how your data is protected.
                        </Typography>
                        <Typography gutterBottom>
                            You can always access the Research Database options menu from your profile menu.
                        </Typography>
                        <ResearchOpt />
                    </DialogContentText>
                </DialogContent>
                <DialogContent>
                    <Button onClick={this.continueResearch} className={classes.moreButton}>
                        Learn More
                    </Button>
                    {!research_opt && (
                        <Button onClick={this.maybeLater} className={classes.laterButton}>
                            Remind Me Later
                        </Button>
                    )}
                    <Button onClick={this.hideDialog} className={classes.greyButton}>
                        {research_opt ? "Close Window" : "Not Interested"}
                    </Button>
                </DialogContent>
            </Dialog>
        );
    }
}

ResearchDialog = connect(
    state => ({
        navState: state.nav,
        authState: state.auth,
        update: (state.auth && state.auth.update) || {}
    }),
    {
        ...navActions,
        ...authActions
    }
)(ResearchDialog);

export default withStyles(styles)(withRouter(ResearchDialog));
