import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Link } from "react-router-dom";

import Button from "../../common/ButtonWrapper";
import Divider from "@mui/material/Divider";
import Grid from "../../common/GridWrapper";
import Paper from "@mui/material/Paper";
import Typography from "../../common/TypographyWrapper";

import WaterUseIcon from "../../field/analysis/img/irrigation_water.png";
import EnergyUseIcon from "../../field/analysis/img/energy_use.png";
import LandUseIcon from "../../field/analysis/img/land_use.png";
import CostSavingIcon from "../../field/analysis/img/wallet.png";

import withStyles from "@mui/styles/withStyles";

import AppContainer from "../../common/AppContainer";
import CornerArrow from "../../common/icons/CornerArrow";
import FieldIcon from "../../common/icons/FieldIconWhite";
import MagnifyIcon from "../../common/icons/Magnify";
import * as navActions from "../../common/actions";
import farmer1 from "./3.jpg";
import Popup from "./Popup";
import spidergram1 from "./3-1.jpg";
import spidergram2 from "./3-2.jpg";
import spidergram1thumb from "./3-1-thumb.jpg";
import spidergram2thumb from "./3-2-thumb.jpg";

const styles = theme => ({
    marginTop: {
        marginTop: 16
    },
    icon: {
        verticalAlign: "middle",
        height: 60,
        width: 60,
        margin: "-8px -4px"
    },
    linkColor: {
        color: "#808080"
    },
    crumbColor: {
        color: theme.palette.primary.main
    },
    noPadding: {
        paddingTop: 0,
        paddingBottom: 0
    },
    insertText: {
        padding: "8px 16px",
        fontSize: 14,
        fontWeight: 400,
        fontStyle: "italic"
    },
    insertTextPop: {
        padding: 16,
        fontSize: 16.2,
        fontWeight: 400,
        fontStyle: "italic"
    },
    iconSize: {
        width: "1em",
        height: "1em",
        fontSize: "24px"
    },
    buttonPadding: {
        padding: "0 0 12px 0px",
        "& span": {
            display: "inline-block",
            textAlign: "center"
        }
    },
    svgField: {
        marginRight: 8,
        marginBottom: -8
    },
    buttonTypo: {
        display: "inline-block",
        marginTop: 12,
        fontSize: 15,
        color: "#fff"
    },
    svgCorner: {
        float: "right"
    },
    quoteText: {
        color: theme.palette.primary.main,
        margin: 16,
        fontSize: 20,
        fontStyle: "italic"
    },
    quoteFarmer: {
        textAlign: "right",
        marginBottom: 24
    },
    caption: {
        marginTop: 24,
        marginLeft: 8,
        lineHeight: "1.6em",
        fontSize: 11,
        color: "rgba(0, 0, 0, 0.54)"
    },
    magnify: {
        position: "absolute",
        bottom: 3,
        right: 0,
        width: 40,
        height: 40,
        cursor: "pointer"
    },
    thumbnails: {
        width: 70,
        border: "1px solid #bbb",
        marginRight: 16
    },
    thumbnailSelected: {
        border: "3px solid " + theme.palette.primary.light + " !important"
    },
    buttonDialogPadding: {
        marginTop: 16,
        marginBottom: 16
    },
    dynamicText: {
        lineHeight: "1.8em",
        fontSize: 15,
        "@media (min-width: 1280px) and (max-width: 1360px)": {
            lineHeight: "1.3em",
            fontSize: 13
        },
        "@media (min-width: 1360px) and (max-width: 1450px)": {
            lineHeight: "1.4em",
            fontSize: 13.5
        },
        "@media (min-width: 1450px) and (max-width: 1650px)": {
            lineHeight: "1.6em",
            fontSize: 14
        }
    },
    walletIcon: {
        padding: 8,
        width: 45,
        height: 45
    }
});

class FarmerA extends Component {
    state = {
        isOpen: false,
        currentGraph: 0
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { isOpen, currentGraph } = this.state;
        const { classes, navSelectPage } = this.props;

        return (
            <AppContainer
                authenticated
                color="welcome"
                title="Shine a Light on Your Sustainability Story"
                pageTitle="Meet Glenn Schur"
                crumbs={
                    <div>
                        <Link className={classes.linkColor} to={"/welcome"}>
                            Hear From Farmers Like You
                        </Link>
                        &nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;{" "}
                        <span className={classes.crumbColor}>Meet Glenn Schur</span>
                    </div>
                }>
                <Grid container spacing={16}>
                    <Grid item xs={12} lg={6} className={classes.marginTop}>
                        <Typography variant="title" gutterBottom>
                            <i>Meet Glenn Schur</i>
                        </Typography>
                        <Typography gutterBottom>
                            Glenn utilized the Fieldprint® Platform during the Texas Alliance for Water Conservation
                            Cotton Project, using data gleaned from the platform to improve his irrigation practices and
                            make every drop of water count. Glenn can track which management practices are most
                            effective using data from the Fieldprint Platform, allowing him to realize improvements such
                            as:
                        </Typography>
                        <Grid container spacing={16}>
                            <Grid item xs={6}>
                                <table>
                                    <tr>
                                        <td>
                                            <img src={WaterUseIcon} className={classes.icon} alt="Reduced water use" />
                                        </td>
                                        <td>
                                            <Typography>Reduced water use</Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img
                                                src={EnergyUseIcon}
                                                className={classes.icon}
                                                alt="Reduced energy use"
                                            />
                                        </td>
                                        <td>
                                            <Typography>Reduced energy use</Typography>
                                        </td>
                                    </tr>
                                </table>
                            </Grid>
                            <Grid item xs={6}>
                                <table>
                                    <tr>
                                        <td>
                                            <img src={LandUseIcon} className={classes.icon} alt="Improved yields" />
                                        </td>
                                        <td>
                                            <Typography>Improved yields</Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img
                                                src={CostSavingIcon}
                                                className={classNames(classes.walletIcon, classes.icon)}
                                                alt="Cost savings"
                                            />
                                        </td>
                                        <td>
                                            <Typography>Cost savings</Typography>
                                        </td>
                                    </tr>
                                </table>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    component={Link}
                                    to="/cropyear/DEMO3-0001-2016"
                                    onClick={() => navSelectPage("field_DEMO3-0001")}
                                    variant="raised"
                                    color="primary"
                                    fullWidth
                                    className={classes.buttonPadding}>
                                    <CornerArrow className={classNames(classes.svgCorner, classes.iconSize)} />
                                    <FieldIcon className={classNames(classes.svgField, classes.iconSize)} />
                                    <Typography className={classes.buttonTypo}>Explore My Sorghum Field</Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    component={Link}
                                    to="/cropyear/DEMO3-0001-2013"
                                    onClick={() => navSelectPage("field_DEMO3-0001")}
                                    variant="raised"
                                    color="primary"
                                    fullWidth
                                    className={classes.buttonPadding}>
                                    <CornerArrow className={classNames(classes.svgCorner, classes.iconSize)} />
                                    <FieldIcon className={classNames(classes.svgField, classes.iconSize)} />
                                    <Typography className={classes.buttonTypo}>Explore My Cotton Field</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} className={classes.marginTop}>
                        <Paper style={{ backgroundColor: "#eee" }}>
                            <Grid container spacing={0} className={classes.marginTop} alignItems="center">
                                <Grid item xs={6} className={classes.noPadding} style={{ marginBottom: -5 }}>
                                    <img src={farmer1} alt="Glenn Schur" style={{ width: "100%" }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classNames(classes.dynamicText, classes.insertText)}>
                                        Glenn Schur grows cotton, corn, wheat, sorghum, millet and alfalfa together with
                                        his son on their 1,800-acre farm in Plainview, Texas. A strong advocate for
                                        water conservation, he works to improve water usage efficiency while maintaining
                                        farm profitability. Glenn uses the Fieldprint Platform to evaluate the efficacy
                                        of his water management techniques to conserve freshwater.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={6} className={classes.marginTop}>
                        {currentGraph === 0 && (
                            <Paper style={{ backgroundColor: "#eee" }}>
                                <Grid container spacing={0}>
                                    <Grid
                                        item
                                        xs={6}
                                        className={classes.noPadding}
                                        style={{ marginBottom: -5, position: "relative" }}>
                                        <img
                                            onClick={() => this.setState({ isOpen: true })}
                                            src={spidergram1}
                                            alt="Glenn's Sorghum Field"
                                            style={{ cursor: "pointer", width: "100%" }}
                                        />
                                        <MagnifyIcon
                                            onClick={() => this.setState({ isOpen: true })}
                                            className={classes.magnify}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="title" style={{ padding: 16, fontSize: 23 }}>
                                            Fieldprint® Analysis of Glenn’s Sorghum Field
                                        </Typography>
                                        <Typography className={classes.insertText}>
                                            Click on the spider diagram to explore Glenn’s Fieldprint Analysis.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        )}
                        {currentGraph === 1 && (
                            <Paper style={{ backgroundColor: "#eee" }}>
                                <Grid container spacing={0}>
                                    <Grid
                                        item
                                        xs={6}
                                        className={classes.noPadding}
                                        style={{ marginBottom: -5, position: "relative" }}>
                                        <img
                                            onClick={() => this.setState({ isOpen: true })}
                                            src={spidergram2}
                                            alt="Glenn's Coton Field"
                                            style={{ cursor: "pointer", width: "100%" }}
                                        />
                                        <MagnifyIcon
                                            onClick={() => this.setState({ isOpen: true })}
                                            className={classes.magnify}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="title" style={{ padding: 16, fontSize: 23 }}>
                                            Fieldprint® Analysis of Glenn’s Cotton Field
                                        </Typography>
                                        <Typography className={classes.insertText}>
                                            Click on the spider diagram to explore Glenn’s Fieldprint Analysis.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        )}
                        <div className={classes.marginTop}>
                            <img
                                src={spidergram1thumb}
                                alt="spidergram 1"
                                onClick={() => this.setState({ currentGraph: 0 })}
                                className={classNames(
                                    classes.thumbnails,
                                    currentGraph === 0 && classes.thumbnailSelected
                                )}
                            />
                            <img
                                src={spidergram2thumb}
                                alt="spidergram 2"
                                onClick={() => this.setState({ currentGraph: 1 })}
                                className={classNames(
                                    classes.thumbnails,
                                    currentGraph === 1 && classes.thumbnailSelected
                                )}
                            />
                        </div>
                        {currentGraph === 0 && (
                            <Popup
                                open={isOpen}
                                closeEvent={() => this.setState({ isOpen: false })}
                                image={spidergram1}
                                imagealt="Glenn's Sorghum Field"
                                title="Fieldprint® Analysis of Glenn’s Sorghum Field"
                                text={
                                    <div>
                                        <Typography className={classes.insertTextPop}>
                                            The Fieldprint Analysis of Glenn’s sorghum field gives him a full picture
                                            into his field's sustainability performance. The closer the blue shaded area
                                            is to the center of the spider diagram, the greater the sustainability
                                            performance or resource use efficiency.
                                        </Typography>
                                        <Typography className={classes.insertTextPop}>
                                            Farmers like Glenn utilize the Fieldprint Platform to both document the
                                            environmental benefits of their conservation and stewardship as well as
                                            identify opportunities for continuous improvement in the next growing
                                            season. Generally, if you there are blue areas that extend beyond the state
                                            or national benchmarks in a Fieldprint Analysis, this highlights an
                                            opportunity for improvement and an important area to consider in the next
                                            growing season. Over time, comparing the results of multiple analyses can
                                            help you evaluate sustainability progress and consider trade-offs between
                                            different management approaches for your field.
                                        </Typography>
                                        <Grid container justifyContent="center">
                                            <Grid item xs={10} lg={6}>
                                                <Button
                                                    component={Link}
                                                    to="/cropyear/DEMO3-0001-2016"
                                                    onClick={() => navSelectPage("field_DEMO3-0001")}
                                                    variant="raised"
                                                    color="primary"
                                                    fullWidth
                                                    className={classNames(
                                                        classes.buttonDialogPadding,
                                                        classes.buttonPadding
                                                    )}>
                                                    <CornerArrow
                                                        className={classNames(classes.svgCorner, classes.iconSize)}
                                                    />
                                                    <FieldIcon
                                                        className={classNames(classes.svgField, classes.iconSize)}
                                                    />
                                                    <Typography className={classes.buttonTypo}>
                                                        Explore My Sorghum Field
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                }
                            />
                        )}
                        {currentGraph === 1 && (
                            <Popup
                                open={isOpen}
                                closeEvent={() => this.setState({ isOpen: false })}
                                image={spidergram2}
                                imagealt="Glenn's Cotton Field"
                                title="Fieldprint® Analysis of Glenn’s Cotton Field"
                                text={
                                    <div>
                                        <Typography className={classes.insertTextPop}>
                                            The Fieldprint Analysis of Glenn’s cotton field gives him a full picture
                                            into his field's sustainability performance. The closer the blue shaded area
                                            is to the center of the spider diagram, the greater the sustainability
                                            performance or resource use efficiency.
                                        </Typography>
                                        <Typography className={classes.insertTextPop}>
                                            Farmers like Glenn utilize the Fieldprint Platform to both document the
                                            environmental benefits of their conservation and stewardship as well as
                                            identify opportunities for continuous improvement in the next growing
                                            season. Generally, if you there are blue areas that extend beyond the state
                                            or national benchmarks in a Fieldprint Analysis, this highlights an
                                            opportunity for improvement and an important area to consider in the next
                                            growing season. Over time, comparing the results of multiple analyses can
                                            help you evaluate sustainability progress and consider trade-offs between
                                            different management approaches for your field.
                                        </Typography>
                                        <Grid container justifyContent="center">
                                            <Grid item xs={10} lg={6}>
                                                <Button
                                                    component={Link}
                                                    to="/cropyear/DEMO3-0001-2013"
                                                    onClick={() => navSelectPage("field_DEMO3-0001")}
                                                    variant="raised"
                                                    color="primary"
                                                    fullWidth
                                                    className={classNames(
                                                        classes.buttonDialogPadding,
                                                        classes.buttonPadding
                                                    )}>
                                                    <CornerArrow
                                                        className={classNames(classes.svgCorner, classes.iconSize)}
                                                    />
                                                    <FieldIcon
                                                        className={classNames(classes.svgField, classes.iconSize)}
                                                    />
                                                    <Typography className={classes.buttonTypo}>
                                                        Explore My Cotton Field
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                }
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} lg={6} className={classNames(classes.marginTop)}>
                        <Divider />
                        <Typography gutterBottom className={classes.quoteText}>
                            “The Fieldprint Platform has helped show me which management practices are the most
                            successful and should be implemented throughout my operation. Technology is expensive, and I
                            want to only use that which will be the most beneficial to me economically.”
                        </Typography>
                        <div className={classes.quoteFarmer}>
                            <Typography>
                                <b>Glenn Schur</b>
                            </Typography>
                            <Typography>4th Generation Farmer</Typography>
                            <Typography>Plainview, Texas</Typography>
                        </div>
                        <Divider />
                        <Typography variant="caption" className={classes.caption}>
                            Please note that the sample fields offered are based on similar climatic conditions, soil
                            type and management practices utilized on this farmer’s operation. This data is provided for
                            illustrative purposes to demonstrate how the Fieldprint Platform can be utilized to measure
                            and assess the sustainability performance of a given field.
                        </Typography>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

FarmerA = connect(state => null, {
    ...navActions
})(FarmerA);

export default withStyles(styles)(FarmerA);
