import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Table, TableBody, TablePagination, TableRow, TableFooter } from "@mui/material";
// import Button from "material-ui/Button";
// import Tooltip from "material-ui/Tooltip";

// import DeleteIcon from "material-ui-icons/Delete";
// import EditIcon from "material-ui-icons/Edit";
// import ContentCopyIcon from "material-ui-icons/ContentCopy";
// import FileDownloadIcon from "material-ui-icons/FileDownload";
// import RemoveRedEyeIcon from "material-ui-icons/RemoveRedEye";

import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import TablePaginationActionsWrapped from "../../common/Paginator";

const styles = {
    table: {
        minWidth: 500
    },
    deleteWidth: {
        minWidth: "44px",
        width: "44px"
    },
    analyticsTooltip: {
        // Tooltips don't work on disabled buttons without div, style div as button
        display: "inline-block",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    },
    center: {
        textAlign: "center"
    }
};

class CropTable extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "asc",
            orderBy: "",
            page: 0,
            rowsPerPage: 10,
            useEmptyRows: false
        };
    }

    componentDidMount() {
        this.setState({
            orderBy: this.props.orderBy,
            rowsPerPage: this.props.rowsPerPage ? this.props.rowsPerPage : 10
            // rowsPerPage: this.props.pagination ? 10 : this.props.data.length + 1
        });
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        this.setState({ order, orderBy });
    };

    incomingSort = () => {
        const { orderBy, order } = this.state;

        if (!orderBy) {
            return this.props.data;
        } else {
            function sniff(data) {
                function isNumber(n) {
                    return !isNaN(parseFloat(n)) && isFinite(n);
                }

                let isString = true;
                data.forEach(datum => {
                    if (isNumber(datum[orderBy])) {
                        isString = false;
                    }
                });

                return isString;
            }

            function cleanValue(val) {
                return val ? val.toLowerCase() : "";
            }

            const isString = sniff(this.props.data);

            let data = [];

            if (isString) {
                data =
                    order === "desc"
                        ? this.props.data.sort((a, b) => (cleanValue(b[orderBy]) < cleanValue(a[orderBy]) ? -1 : 1))
                        : this.props.data.sort((a, b) => (cleanValue(a[orderBy]) < cleanValue(b[orderBy]) ? -1 : 1));
            } else {
                data =
                    order === "desc"
                        ? this.props.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
                        : this.props.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
            }

            return data;
        }
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    formatDate(dateString) {
        if (!dateString) {
            return null;
        }
        const d = new Date(dateString);
        return d.toLocaleDateString();
    }

    getTip(toolTips, column, rowData) {
        console.log("getTip");

        if (toolTips) {
            let tips = toolTips.filter(function (el) {
                return el.column === column.id;
            });

            if (tips.length) {
                return rowData[tips[0].tip];
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    render() {
        const { classes } = this.props;
        const { columnData, data, noRecordsMessage, toolTips, noPagination, useEmptyRows } = this.props;
        const { order, orderBy, page } = this.state;

        const sortedData = this.incomingSort(data);

        const rowsPerPage = noPagination === true ? data.length + 2 : this.state.rowsPerPage;

        const emptyRows =
            noPagination === true || useEmptyRows === false
                ? 0
                : rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

        return (
            <Table className={classes.table}>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    columnData={columnData}
                />
                <TableBody>
                    {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={n.id}>
                                {columnData.map(column => {
                                    if (!column.isAction) {
                                        return (
                                            <CustomTableCell
                                                style={{ width: "auto" }}
                                                // key={column.id + "_" + n.id}
                                                numeric={column.numeric}
                                                padding={column.disablePadding === true ? "none" : "default"}
                                                className={column.center === true ? classes.center : ""}
                                                title={this.getTip(toolTips, column, n)}>
                                                {column.isDate ? this.formatDate(n[column.id]) : n[column.id]}
                                            </CustomTableCell>
                                        );
                                    } else {
                                        return null;
                                    }
                                }, this)}
                            </TableRow>
                        );
                    })}
                    {(this.props.useEmptyRows === undefined || this.props.useEmptyRows === true) &&
                        emptyRows > 0 &&
                        data.length > 10 && (
                            <TableRow style={{ height: 48 * emptyRows }}>
                                <CustomTableCell colSpan={100} />
                            </TableRow>
                        )}
                    {data.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={100} className={classes.textCenter}>
                                {noRecordsMessage}
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                {(noPagination === undefined || noPagination === false) && data.length > 10 && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                Actions={TablePaginationActionsWrapped}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        );
    }
}

export default withStyles(styles)(CropTable);
