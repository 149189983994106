import React from "react";
import { getValue } from "../../../api/utils";
import { Sector } from "recharts";
import Typography from "../../common/TypographyWrapper";

import EqualizerIcon from "@mui/icons-material/Equalizer";

import _ from "lodash";

const IRRIGATED_METRICS = [
    "landUse",
    "soilConservation",
    "soilCarbon",
    "irrigationWaterUse",
    "energyUse",
    "greenhouseGas",
    "waterQuality",
    "biodiversity"
];

const NON_IRRIGATED_METRICS = [
    "landUse",
    "soilConservation",
    "soilCarbon",
    "energyUse",
    "greenhouseGas",
    "waterQuality",
    "biodiversity"
];

const SIMPLE_METRICS = ["soilConservationInsights"];

const PRINT_LAST_METRICS = ["energyUse", "greenhouseGas"];

export const ENERGY_USE_SUBMETRICS = {
    management: [],
    application: ["seedTreatment", "fertilizers", "protectants", "fieldOps", "lime"],
    manureLoading: [],
    seed: [],
    irrigation: [],
    postHarvest: [],
    transportation: []
};

export function getMetrics(cropYear, printable) {
    const isIrrigated = getValue(cropYear, "metrics.Calculator.result.cropyears[0].isIrrigated"),
        metrics = isIrrigated ? IRRIGATED_METRICS : NON_IRRIGATED_METRICS;
    if (printable) {
        return metrics.filter(metric => !PRINT_LAST_METRICS.includes(metric)).concat(PRINT_LAST_METRICS);
    } else {
        return metrics;
    }
}

export function getMetricsBasic() {
    return SIMPLE_METRICS;
}

export const BENCHMARKS_PROJECT = ["project", "state", "national"];

export const BENCHMARKS = ["state", "national"];

const COLORS = {
    active_year: "#00A4E5",
    project: "#8A2BE2",
    state: "#7AB843",
    national: "#FF7D32"
};

export function getColor(code) {
    return COLORS[code] || "#FF00FF";
}

// Pie Chart configuration
// From http://www.mulinblog.com/a-color-palette-optimized-for-data-visualization/
export const PIE_COLORS = [
    "#5D5D5D",
    "#FAA43A",
    "#60BD68",
    "#F17CB0",
    "#B2912F",
    "#B276B2",
    "#DECF3F",
    "#F15854",
    "#4D4D4D"
];

const PRECISION = {
    landUse: 4,
    soilConservation: 1,
    soilCarbon: 2,
    irrigationWaterUse: 3,
    energyUse: 0,
    greenhouseGas: 1,
    waterQuality: 2,
    biodiversity: 0
};

export function getPrecision(code) {
    return code in PRECISION ? PRECISION[code] : 2;
}

export function round(value, code) {
    if (value || value === 0) {
        return value.toLocaleString("en-US", {
            useGrouping: true,
            minimumFractionDigits: 0,
            maximumFractionDigits: getPrecision(code)
        });
    } else {
        return value;
    }
}

export function chartValue(value, code) {
    if (value || value === 0) {
        return +value.toFixed(getPrecision(code));
    } else {
        return value;
    }
}

const BREAK_BEFORE = {
        energyUse: true,
        greenhouseGas: true,
        waterQuality: true
    },
    BREAK_AFTER = {
        energyUse: true,
        greenhouseGas: true
    };

export function getBreakBefore(code) {
    return BREAK_BEFORE[code] || false;
}
export function getBreakAfter(code) {
    return BREAK_AFTER[code] || false;
}

export const PIE_CHART_LABEL = (props, unit) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    /*            
            <path id="path1" d="M10,30 H190 M10,60 H190 M10,90 H190 M10,120 H190"></path>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                <textPath "xlink:href"="#path1">{payload.name}</textPath>
            </text>
            
            */
    return (
        <g>
            {payload.name.length < 18 && (
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
            )}
            {payload.name.length >= 18 && (
                <foreignObject x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    <p style={{ width: 100, marginLeft: -50, marginTop: -30, color: fill }}>{payload.name}</p>
                </foreignObject>
            )}
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
                {`${value}`}
                <br />
            </text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {unit}
            </text>
        </g>
    );
};

const LABELS = {
    // Categories
    active_year: "Your Score",
    project: "Project Benchmarks",
    state: "State Benchmarks",
    national: "National Benchmarks",

    // Top level metrics
    landUse: "Land Use",
    soilConservation: "Soil Conservation",
    soilCarbon: "Soil Carbon",
    energyUse: "Energy Use",
    greenhouseGas: "Greenhouse Gas",
    waterQuality: "Water Quality",
    biodiversity: "Biodiversity",
    irrigationWaterUse: "Irrigation Water Use",

    // Energy Use / GHG subcomponents
    management: "Management",
    application: "Application",
    "application.seedTreatment": "Seed Treatment",
    "application.fertilizers": "Fertilizer",
    "application.protectants": "Protectant",
    "application.fieldOps": "Field Ops",
    "application.lime": "Lime",
    manureLoading: "Manure Loading",
    seed: "Seed",
    irrigation: "Irrigation",
    postHarvest: "Post-Harvest",
    transportation: "Transportation",
    // Doesn't render as a label in the pie chart if it isn't a string
    /*nitrousOxide: (
        <>
            Soil N<sub>2</sub>O emissions
        </>
    ),*/
    nitrousOxide: "Soil N2O emissions",
    rice: "Methane emissions",
    residueBurning: "Residue burning emissions"
};

export function getLabel(code) {
    return LABELS[code] || _.startCase(code);
}

const UNIT_FORMAT = {
        lbs_co2e: "lbs-CO₂e",
        acre_inch: "ac-in",
        short_ton: "ton"
    },
    UNIT_ABBR = {
        acre: "ac",
        bushel: "bu"
    };

function Sub({ children }) {
    return <sub style={{ fontSize: "80%", display: "inline-block", height: 0 }}>{children}</sub>;
}

export function getUnitsValue(obj, path, abbreviate) {
    let units = getValue(obj, path);
    if (units) {
        Object.entries(UNIT_FORMAT).forEach(([key, val]) => {
            units = units.replace(key, val);
        });
        if (abbreviate) {
            Object.entries(UNIT_ABBR).forEach(([key, val]) => {
                units = units.replace(key, val);
            });
            units = units.replace(/ /g, "");
        }
        if (units.match(/₂/)) {
            const [before, after] = units.split("₂");
            units = (
                <>
                    {before}
                    <Sub>2</Sub>
                    {after}
                </>
            );
        }
    }
    return units;
}

export function getPercentValue(obj, path) {
    let value = getValue(obj, path);
    if (typeof value === "number") {
        value = (value * 100).toFixed(2);
        value = +value;
        return `${value} %`;
    } else {
        return value;
    }
}

const DESCRIPTIONS = {
    fieldprintWait: "We are calculating your Fieldprint Results",

    fieldprintResults: (
        <>
            <Typography gutterBottom style={{ marginTop: "0.6em" }}>
                Please be patient while your Fieldprint Results are calculated for display. This can take up to several
                minutes, dependent on your specific inputs and your crop rotation system. If you encounter a connection
                or timeout error, please select Try Again.
            </Typography>
            <Typography gutterBottom>
                Alternatively, you can leave this page to perform other tasks while results are being calculated in the
                background. Return to this page by clicking the chart icon (
                <EqualizerIcon style={{ height: 18, width: 24, marginBottom: -3 }} color="primary" />) for the crop year
                from the field library page.
            </Typography>{" "}
            <Typography>
                If you experience significant delays or encounter an error message, please contact us at{" "}
                <a style={{ color: "#ff7d32" }} href="mailto:support@fieldtomarket.org">
                    support@fieldtomarket.org
                </a>
            </Typography>
        </>
    ),

    disclaimer:
        "Disclaimer: The report is for educational purposes only. Field to Market does not make any representations, warranties or guarantees, express or implied, regarding the accuracy, correctness, or completeness of the content or the services and products associated with the Fieldprint Calculator, nor the applicability of its products for other purposes. It is your sole responsibility to independently evaluate the accuracy, correctness or completeness of the content and the services and products associated with Field to Market.",

    landUse:
        "The Land Use metric is an efficiency metric that uses a simple equation to account for the planted area used to produce a crop.  Land Use is calculated as the simple inverse of user-supplied crop yield. Outcomes are in units of planted land area per unit of production. A lower number indicates greater efficiency.",
    landUsePrint:
        "The Land Use metric is an efficiency metric that uses a simple equation to account for the planted area used to produce a crop.  Land use efficiency is a measure of the amount of land (acres) used to produce a unit of crop (bushels, pounds, etc.)  Lower scores are desirable and indicate greater land use efficiency.",

    soilConservation:
        "The Soil Conservation metric is a measure of soil lost to erosion from water and wind, and is calculated using USDA NRCS models and reported to the user as tons of soil lost per acre. It is an efficiency metric that uses a complex biophysical model to simulate crop growth, water flow across the field, and sediment runoff.",
    soilConservationChart:
        "Chart showing water erosion and wind erosion from USDA NRCS erosion models (WEPP, WEPS), in T / ac / yr.",
    soilConservationPrint:
        "The Soil Conservation Metric is a measure of soil lost to erosion from water and wind, calculated using USDA NRCS models and reported to the user as tons of soil lost per acre. Lower numbers are desirable and indicate less soil lost from erosion per acre. A Soil Erosion Fieldprint Score of 0 would indicate that no soil was lost in that year.",

    soilConservationInsights:
        "The panel is intended to provide insights into physical characteristics, climate and soil loss on your field.",

    soilCarbon:
        "Soil carbon is important in supporting water infiltration, water and nutrient holding, crop productivity and carbon storage. The Fieldprint Platform utilizes the Soil Conditioning Index (SCI) a qualitative, directional measure of how soil carbon is impacted by the organic matter and crop residue on your field, the soil lost to wind and water erosion, and soil impacting characteristics of your field operations.",
    soilCarbonChart:
        "The SCI returns a value between -1 and 1 for each field. A positive value indicates increasing soil carbon, a neutral value (between -0.05 and 0.05) indicates maintaining soil carbon, and a negative value indicates losses of soil carbon. The magnitude of the index reflects confidence in the directionality and does not indicate a higher or lower quantity of carbon in the soil.",
    soilCarbonPrint:
        "Soil Carbon Metric utilizes the Soil Conditioning Index (SCI) a qualitative, directional measure of how soil carbon is impacted by the organic matter and crop residue on your field, the soil lost to wind and water erosion, and soil impacting characteristics of your field operations. The SCI returns a value between -1 and 1 for each field. A positive value indicates increasing soil carbon, a neutral value (between -0.05 and 0.05) indicates maintaining soil carbon, and a negative value indicates depletion of soil carbon. The magnitude of the index reflects confidence in the directionality and does not indicate a higher or lower quantity of carbon in the soil.",

    irrigationWaterUse:
        "The Irrigation Water Use metric is an efficiency metric that uses a simple equation to account for the amount of water used to achieve an incremental increase in crop yield.",

    irrigationWaterUsePrint:
        "The Irrigation Water Use Metric is an efficiency metric that uses a simple equation to account for the amount of water used to achieve an incremental increase in crop yield. Irrigation Water Use (IWU) is expressed in the Fieldprint Platform as the amount of water, measured in acre-inches (ac-in) required to produce a unit of crop (pound, bushel, etc.). Lower numbers are desirable, indicating less water required to produce a unit of crop.",

    energyUse:
        "The Energy Use Metric calculates all energy used in the production of the crop in one year from pre-planting activities through to the first point of sale. It is an efficiency metric, calculated using a series of algorithms and designed to provide feedback on the energy used per unit of crop production.",
    energyUseParts:
        "Table showing values for each individual component of your Energy Use score, in both BTU / acre and BTU / bushel.",
    energyUseChart: "Chart showing values for each individual component of your Energy Use score, in BTU / bushel.",
    energyUsePrint:
        "The Energy Use Metric calculates all energy used in the production of the crop in one year from pre-planting activities through to the first point of sale. The Energy Use metric includes direct energy used for operating equipment, pumping irrigation water, grain drying and transport as well as embedded energy, which is required to produce crop inputs like seeds, fertilizers and crop protectants. Lower numbers are desirable and indicate less energy used to produce a unit of crop.",
    energyUsePartsPrint:
        "The following table shows values for each individual component of your Energy Use score, per unit of crop yield.",

    greenhouseGas:
        "The Greenhouse Gas (GHG) Emissions metric calculates the total emissions from four main sources – energy use, nitrous oxide emissions from soils, methane emissions (rice only) and emissions from residue burning. It is an efficiency metric calculated using a series of complex algorithms to determine the total GHG emissions per unit of crop production.",
    greenhouseGasParts: (
        <>
            <Typography gutterBottom>
                Breakdown of Greenhouse Gas Emission components. Values are shown on both a per acre and per bushel
                basis.
            </Typography>
            <Typography>
                Nitrous oxide emissions from a field are taken from results of a detailed crop model based on crop type,
                region of the country and soil texture to determine how much N<sub>2</sub>O results from additions of
                nitrogen (N) from fertilizer and manure.
            </Typography>
        </>
    ),
    greenhouseGasChart: (
        <>
            Chart showing values for each individual component of your Greenhouse Gas Emission score, in lbs CO
            <sub>2</sub>e / lb.
        </>
    ),
    greenhouseGasPrint: (
        <>
            The Greenhouse Gas (GHG) Emissions Metric calculates the total emissions from four main sources – energy
            use, nitrous oxide emissions from soils, methane emissions (rice only) and emissions from residue burning.
            Greenhouse gas emissions are reported as pounds of carbon dioxide equivalent (CO<Sub>2</Sub>e) per crop unit
            produced (e.g. bushels or pounds). “CO<Sub>2</Sub>e” simply means the N<Sub>2</Sub>O and CH<Sub>4</Sub>{" "}
            emissions are converted to the equivalent amount of CO<Sub>2</Sub>, to provide a common unit of all
            emissions in one measure, which is comparable over time and influenced by all the actions a farmer takes.
            Low scores are desirable and indicate less greenhouse gas emitted per unit of crop yield.
        </>
    ),
    greenhouseGasPartsPrint:
        "The following table shows values for each individual component of your Breakdown of Greenhouse Gas Emissions Score.",

    waterQuality:
        "The Water Quality Metric uses the Stewardship Tool for Environmental Performance (STEP), developed by NRCS, to assess how likely a field is to lose nutrients to waterways. Based on soil properties and local climate characteristics, STEP assigns a Field Sensitivity Score to each field that represents the potential for nutrient losses, either by runoff beyond the edge of the field (surface loss) or leaching below the rootzone (subsurface loss), for each of four Loss Pathways: Surface P (Phosphorus), Subsurface P, Surface N (Nitrogen), and Subsurface N. STEP then assigns mitigation points for management practices that impact nutrient loss (a Risk Mitigation Score (RMS)).",
    waterQualityParts:
        "The final metric score for each nutrient loss pathway is a ratio of how effective management practices are at mitigating nutrient loss (Risk Mitigation score (RMS)) to how sensitive the field is to nutrient loss based (Field Sensitivity Score (FSS)). If the ratio is 1 or higher, the basic level of risk mitigation for excessive nutrient loss has been met. If the ratio is below 1, excessive nutrient loss is likely, and producers should discuss potential mitigation practices with their advisors.",
    waterQualityPrint:
        "The Water Quality Metric uses the Stewardship Tool for Environmental Performance (STEP), developed by NRCS, to assess how likely a field is to lose nutrients to waterways. Based on soil properties and local climate characteristics, STEP assigns a Field Sensitivity Score to each field that represents the potential for nutrient losses, either by runoff beyond the edge of the field (surface loss) or leaching below the rootzone (subsurface loss), for each of four Loss Pathways: Surface P (Phosphorus), Subsurface P, Surface N (Nitrogen), and Subsurface N. STEP then assigns mitigation points for management practices that impact nutrient loss (a Risk Mitigation Score (RMS)).",
    waterQualityPartsPrint:
        "The final metric score for each nutrient loss pathway is a ratio of how effective management practices are at mitigating nutrient loss (Risk Mitigation score (RMS)) to how sensitive the field is to nutrient loss based (Field Sensitivity Score (FSS)). If the ratio is 1 or higher, the basic level of risk mitigation for excessive nutrient loss has been met. If the ratio is below 1, excessive nutrient loss is likely, and producers should discuss potential mitigation practices with their advisors.",

    biodiversity:
        "The Biodiversity metric is a measure of the potential capacity of the field to support diverse ecosystems. It is an index metric calculated based on both inherent land properties and land management.",
    biodiversityPrint:
        "The Biodiversity Metric is a measure of the potential capacity of the field to support diverse ecosystems. It is an index metric calculated based on both inherent land properties and land management. Biodiversity is assessed using the Habitat Potential Index (HPI). HPI scores range from 0-100 and measure the level of opportunity to improve or maximize habitat potential. Higher scores are desirable and indicate a greater potential to support wildlife habitat.  Scores less than 50% represent significant opportunities for improving habitat potential, whereas values of 50-80% indicate moderate realized potential and scores greater than 80% demonstrate farms that have maximized opportunities for biodiversity to flourish."
};

export function getDescription(code) {
    return DESCRIPTIONS[code];
}

const SCORE_DESCRIPTIONS = {
    landUse:
        "Land use efficiency is a measure of the amount of land (acres) used to produce a unit of crop (bushels, pounds, etc.)  Examples: In corn, land use is measured in acres/bushel; in cotton as acres/pound of lint. This is an inverse of yield measures, which are expressed as bushels per acre or pounds of lint per acre. Lower scores are desirable and indicate greater land use efficiency.",
    soilConservation:
        "The Soil Conservation metric is expressed as soil erosion and is measured as tons of soil lost (T) per unit of land area (acre) per year.  Lower numbers are desirable and indicate less soil lost from erosion per acre. A Soil Erosion Fieldprint Score of 0 would indicate that no soil was lost in that year.",
    soilCarbon:
        "The Soil Carbon metric in the Fieldprint Platform® is measured using the NRCS Soil Conditioning Index. The SCI returns a value between -1 and 1 for each field. A positive value indicates increasing soil carbon, a neutral value (between -0.05 and 0.05) indicates maintaining soil carbon, and a negative value indicates depletion of soil carbon. The magnitude of the index reflects confidence in the directionality and does not indicate a higher or lower quantity of carbon in the soil. Scores ranges from +1 to -1 and are unitless, relative, and crop-specific.",
    irrigationWaterUse:
        "Irrigation Water Use (IWU) is expressed in the Fieldprint Platform as the amount of water, measured in acre-inches (ac-in) required to produce a unit of crop (pound, bushel, etc.).  IWU is calculated using the difference irrigation contributes to yield improvement.  Lower numbers are desirable, indicating less water required to produce a unit of crop.",
    energyUse: (
        <>
            <Typography gutterBottom>
                The Energy Use metric includes direct energy used for operating equipment, pumping irrigation water,
                grain drying and transport as well as embedded energy, which is required to produce crop inputs like
                seeds, fertilizers and crop protectants.
            </Typography>
            <Typography gutterBottom>
                Energy use is expressed as British thermal units (BTU) per unit of crop production (i.e., bushel, pound
                or hundred weight). It takes one BTU to raise the temperature of one pound of water by 1&deg;F. One
                gallon of diesel produces 137,452 BTU.
            </Typography>
            <Typography gutterBottom>
                Lower numbers are desirable and indicate less energy used to produce a unit of crop.
            </Typography>
        </>
    ),
    greenhouseGas: (
        <>
            <Typography gutterBottom>
                Greenhouse gas emissions are reported in the Fieldprint® Platform as pounds of carbon dioxide
                equivalent (CO<sub>2</sub>e) per crop unit produced (e.g. bushels or pounds). “CO<sub>2</sub>e” simply
                means the N<sub>2</sub>O and CH<sub>4</sub> emissions are converted to the equivalent amount of CO
                <sub>2</sub>, to provide a common unit of all emissions in one measure, which is comparable over time
                and influenced by all the actions a farmer takes.
            </Typography>
            <Typography gutterBottom>
                The Fieldprint® Platform uses standard U.S. government assumptions regarding fuel use, such as the 22.3
                pounds of CO<sub>2</sub>e that are emitted per gallon of diesel combusted. Emissions also result from
                electricity and fuel usage as well as from burning crop residues.
            </Typography>
            <Typography>
                <b>Low scores are desirable</b> and indicate less greenhouse gas emitted per unit of crop produced.
            </Typography>
        </>
    ),
    waterQuality: "The combined STEP metric indicates how many pathways have been mitigated.",
    biodiversity: indicator => {
        const value = getValue(indicator, "fieldprintResult.value");
        var category = "";
        if (value < 0.5) {
            category = "Low";
        } else if (value <= 0.8) {
            category = "Moderate";
        } else if (value > 0.8) {
            category = "High";
        } else {
            category = "Unknown";
        }
        return (
            <>
                <Typography gutterBottom>
                    Biodiversity is assessed using the Habitat Potential Index (HPI). HPI scores the potential for a
                    given farm to provide wildlife habitat on land or in the water. HPI scores range from 0-100 and
                    measure the level of opportunity to improve or maximize habitat potential. Higher scores are
                    desirable and indicate a greater potential to support wildlife habitat.
                </Typography>
                <Typography gutterBottom>
                    Scores less than 50% represent significant opportunities for improving habitat potential, whereas
                    values of 50-80% indicate moderate realized potential and scores greater than 80% demonstrate farms
                    that have maximized opportunities for biodiversity to flourish.
                </Typography>
                {category === "Low" && (
                    <Typography gutterBottom>
                        Your HPI score is
                        <strong> Low(&lt;50%)</strong>; you have many opportunities to maximize the habitat support on
                        this field.
                    </Typography>
                )}
                {category === "Moderate" && (
                    <Typography gutterBottom>
                        Your HPI score is
                        <strong> Moderate (50-80%)</strong>; you have opportunities to improve the habitat support on
                        this field.
                    </Typography>
                )}
                {category === "High" && (
                    <Typography gutterBottom>
                        Your HPI score is
                        <strong> High(&gt;80%)</strong>; you have already adopted many practices that support habitat,
                        but there is opportunity for continued improvement.
                    </Typography>
                )}
                <Typography>
                    For all scores, consult the&nbsp;
                    <a
                        href="https://fieldtomarket.org/media/2018/06/FTM_Harnessing-Sustainability-Insights_WEB.pdf"
                        target="_blank"
                        rel="noopener noreferrer">
                        Fact Sheet
                    </a>
                    &nbsp;and consult with your advisor about specific opportunities to improve your score on this
                    field.
                </Typography>
            </>
        );
    }
};

export function getScoreDescription(code, score) {
    const desc = SCORE_DESCRIPTIONS[code];
    if (typeof desc === "function") {
        return desc(score);
    } else {
        return desc;
    }
}

const TABLE_DESCRIPTIONS = {
    waterQuality:
        "Water Quality score in comparison to available benchmarks. Benchmarks should not be interpreted as a specific level of sustainability, or a performance target. State and National benchmarks that are not shown in the table or on the spidergram are not available for the applicable metric.",
    biodiversity:
        "Biodiversity score in comparison to available benchmarks. Benchmarks should not be interpreted as a specific level of sustainability, or a performance target. State and National benchmarks that are not shown in the table or on the spidergram are not available for the applicable metric.",
    benchmark:
        " score in comparison to available benchmarks. Benchmarks are an average of USDA statistical data for the period 2008-2012, to provide context for your scores. Benchmarks should not be interpreted as a specific level of sustainability, or a performance target. State and National benchmarks that are not shown in the table or on the spidergram are not available for the applicable metric."
};

export function getTableDescription(code) {
    let desc = TABLE_DESCRIPTIONS[code];
    if (!desc) {
        desc = getLabel(code) + TABLE_DESCRIPTIONS.benchmark;
    }
    return desc;
}
