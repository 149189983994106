import React, { Component } from "react";
import CompatTypography from "../../common/TypographyWrapper";
import MuiTypography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";

export const webStyles = {
    dataTable: {
        border: "1px solid #ccc",
        borderCollapse: "collapse",
        width: "100%"
    },
    dataTableNonCallout: {
        border: "1px solid #ccc",
        borderCollapse: "collapse",
        width: "100%",
        marginLeft: "12px"
    },
    header: {
        border: "1px solid #ccc",
        backgroundColor: "#FAA43A",
        padding: 8,
        textAlign: "left",
        color: "#fff"
    },
    headerText: {
        color: "#fff"
    },
    noBorder: {},
    total: {
        border: "1px solid #ccc",
        backgroundColor: "#ddd",
        padding: 4,
        textAlign: "right"
    },
    subtotal: {
        border: "1px solid #ccc",
        backgroundColor: "#eee",
        padding: 4,
        textAlign: "right"
    },
    cell: {
        border: "1px solid #ccc",
        padding: 4,
        textAlign: "right"
    },
    toggleButton: {
        width: 32,
        height: 32
    },
    collapsible: {
        border: "1px solid #ccc",
        padding: 0,
        textAlign: "right"
    },
    collapsibleInner: {},
    collapsibleRow: {
        padding: 4,
        borderTop: "1px solid #ccc"
    },
    collapsibleFirst: {
        borderTop: "none"
    },
    collapsed: {
        borderBottom: "1px solid transparent"
    },
    first: {
        textAlign: "left"
    },
    indent: {
        paddingLeft: 4 + 21
    },
    indentCollapse: {
        paddingLeft: 4 + 32
    },
    indentCollapseIndent: {
        paddingLeft: 4 + 32 + 21
    },
    pending: {
        color: "#C0C0C0"
    },
    subcomponent: {
        color: "#999"
    }
};

export const printStyles = {
    dataTable: {
        backgroundColor: "#f7f7f7",
        borderCollapse: "collapse",
        width: "100%"
    },
    header: {
        borderBottom: "3px solid black",
        backgroundColor: "#ddd",
        textAlign: "center"
    },
    headerText: {
        fontSize: 13,
        fontWeight: "bold"
    },
    noBorder: {
        borderBottom: "none"
    },
    total: {
        borderTop: "3px solid black",
        backgroundColor: "#ddd",
        textAlign: "right"
    },
    subtotal: {
        borderTop: "3px solid #666",
        backgroundColor: "#eee",
        textAlign: "right"
    },
    cell: {
        borderBottom: "1px dashed #ccc",
        textAlign: "right"
    },
    cellText: {
        fontSize: 13
    },
    first: {
        textAlign: "left"
    },
    indent: {
        paddingLeft: 16
    },
    slightBold: {
        fontWeight: 600
    },
    pending: {
        color: "#999"
    },
    subcomponent: {
        color: "#666"
    }
};

export function withTableStyles(otherStyles) {
    const mergeStyles = { ...otherStyles };
    Object.entries(webStyles).forEach(([key, style]) => {
        mergeStyles["web_" + key] = style;
    });
    Object.entries(printStyles).forEach(([key, style]) => {
        mergeStyles["print_" + key] = style;
    });
    return function (Inner) {
        return withStyles(mergeStyles)(function Outer({ classes: allClasses, printable, ...rest }) {
            const classes = {};
            Object.entries(allClasses).forEach(([key, className]) => {
                if (key.startsWith("print_")) {
                    if (printable) {
                        classes[key.slice("print_".length)] = className;
                    }
                } else if (key.startsWith("web_")) {
                    if (!printable) {
                        classes[key.slice("web_".length)] = className;
                    }
                } else {
                    classes[key] = className;
                }
            });
            return <Inner classes={classes} printable={printable} {...rest} />;
        });
    };
}

class _TD extends Component {
    render() {
        const {
                children,
                first,
                bold,
                total,
                subtotal,
                indent,
                indentCollapse,
                pending,
                subcomponent,
                colspan,
                classes,
                printable,
                toggle,
                open,
                setOpen,
                collapsible
            } = this.props,
            toggleOpen = () => setOpen(!open),
            className = classNames([
                first && classes.first,
                collapsible && !open && classes.collapsed,
                total && classes.total,
                subtotal && classes.subtotal,
                collapsible && classes.collapsible,
                !total && !subtotal && !collapsible && classes.cell,
                indent && indentCollapse && classes.indentCollapseIndent,
                indent && classes.indent,
                indentCollapse && classes.indentCollapse
            ]);
        const slightBold = bold || total || subtotal;
        const Typography = printable ? MuiTypography : CompatTypography;
        return (
            <td className={className} colSpan={colspan}>
                {collapsible && (
                    <Collapse in={open}>
                        <Typography
                            variant="body1"
                            className={classNames(subcomponent && classes.subcomponent, classes.collapsibleInner)}>
                            {React.Children.map(children, (child, i) => (
                                <div
                                    className={classNames([
                                        classes.collapsibleRow,
                                        classes.indentCollapseIndent,
                                        i === 0 && classes.collapsibleFirst
                                    ])}>
                                    {child}
                                </div>
                            ))}
                        </Typography>
                    </Collapse>
                )}
                {!collapsible && (
                    <Typography
                        variant={printable ? "body2" : "body1"}
                        className={classNames(
                            classes.cellText,
                            pending && classes.pending,
                            subcomponent && classes.subcomponent,
                            slightBold && classes.slightBold
                        )}>
                        {toggle && (
                            <IconButton onClick={toggleOpen} className={classes.toggleButton} size="large">
                                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        )}
                        {children}
                    </Typography>
                )}
            </td>
        );
    }
}

export const TD = withTableStyles()(_TD);

class _TH extends Component {
    render() {
        const { children, colspan, noborder, classes, printable } = this.props;
        const Typography = printable ? MuiTypography : CompatTypography;
        if (printable) {
            return (
                <th className={classNames(classes.header, noborder && classes.noBorder)} colspan={colspan}>
                    <Typography className={classes.headerText} variant="body2">
                        {children}
                    </Typography>
                </th>
            );
        } else {
            return (
                <th className={classes.header} colspan={colspan}>
                    <Typography className={classes.headerText} variant="title">
                        {children}
                    </Typography>
                </th>
            );
        }
    }
}

export const TH = withTableStyles()(_TH);

export function Header({ children, style }) {
    const Typography = MuiTypography;
    return (
        <Typography variant="h6" style={{ fontSize: 15, fontWeight: "600", ...style }}>
            {children}
        </Typography>
    );
}

export function Paragraph({ children }) {
    const Typography = MuiTypography;
    return (
        <Typography variant="body2" style={{ fontSize: 13, textAlign: "justify" }}>
            {children}
        </Typography>
    );
}

export function Caption({ children }) {
    const Typography = MuiTypography;
    return (
        <Typography
            variant="caption"
            style={{ fontSize: 12, textAlign: "justify", color: "rgba(0, 0, 0, 0.54)", lineHeight: 1.375 }}
            component="div">
            {children}
        </Typography>
    );
}
