import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Button from "../common/ButtonWrapper";
import Grid from "../common/GridWrapper";
import Typography from "../common/TypographyWrapper";
import ProjectFacts from "./components/ProjectFacts";
import AppContainer from "../common/AppContainer";
import DataWizardDialog from "../field/DataWizardDialog";
import { Project } from "./models";
import { useSelector } from "react-redux";

const getProject = Project.selectByUrlId();

const sx = {
    linkColor: { color: "#808080" },
    crumbColor: { color: "primary.main" }
};

export default function ProjectAdminManage(props) {
    const match = { params: useParams() },
        project = useSelector(state => getProject(state, { match })),
        [open, setOpen] = useState(false);
    return (
        <AppContainer
            authenticated
            color="project"
            title={project.name}
            pageTitle={`Reports for ${project.name}`}
            crumbs={
                <div>
                    <Link style={sx.linkColor} to={"/projectadmin"}>
                        Project Admin Dashboard
                    </Link>
                    &nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;{" "}
                    <Link style={sx.linkColor} to={"/projectadmin/" + project.id}>
                        {project.name}
                    </Link>
                    &nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;{" "}
                    <Box component="span" sx={sx.crumbColor}>
                        Manage
                    </Box>
                </div>
            }>
            <DataWizardDialog
                path={`projectusersources/new?project_id=${project.id}`}
                title="Import Project Users"
                open={open}
                handleClose={() => setOpen(false)}
            />

            <Grid container spacing={24}>
                <ProjectFacts project={project} />
                <Grid item xs={12}>
                    <Typography variant="display3" gutterBottom>
                        Manage Project
                    </Typography>
                    <Button
                        variant="raised"
                        type="submit"
                        color="primary"
                        style={{ marginRight: 32 }}
                        onClick={() => setOpen(true)}>
                        New Grower Bulk Import Tool
                    </Button>
                </Grid>
            </Grid>
        </AppContainer>
    );
}
