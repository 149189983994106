import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Link } from "react-router-dom";

import Button from "../../common/ButtonWrapper";
import Divider from "@mui/material/Divider";
import Grid from "../../common/GridWrapper";
import Paper from "@mui/material/Paper";
import Typography from "../../common/TypographyWrapper";

import SoilConservationIcon from "../../field/analysis/img/soil_conservation.png";
import EnergyUseIcon from "../../field/analysis/img/energy_use.png";
import SoilCarbonIcon from "../../field/analysis/img/soil_carbon.png";
import GreenhouseGasIcon from "../../field/analysis/img/greenhouse_gas.png";

import withStyles from "@mui/styles/withStyles";

import AppContainer from "../../common/AppContainer";
import CornerArrow from "../../common/icons/CornerArrow";
import FieldIcon from "../../common/icons/FieldIconWhite";
import MagnifyIcon from "../../common/icons/Magnify";
import * as navActions from "../../common/actions";
import farmer1 from "./2.png";
import spidergram1 from "./2-1.jpg";
import Popup from "./Popup";

const styles = theme => ({
    marginTop: {
        marginTop: 16
    },
    icon: {
        verticalAlign: "middle",
        height: 60,
        width: 60,
        margin: "-8px -4px"
    },
    linkColor: {
        color: "#808080"
    },
    crumbColor: {
        color: theme.palette.primary.main
    },
    noPadding: {
        paddingTop: 0,
        paddingBottom: 0
    },
    insertText: {
        padding: "8px 16px",
        fontSize: 14,
        fontWeight: 400,
        fontStyle: "italic"
    },
    insertTextPop: {
        padding: 16,
        fontSize: 16.2,
        fontWeight: 400,
        fontStyle: "italic"
    },
    iconSize: {
        width: "1em",
        height: "1em",
        fontSize: "24px"
    },
    buttonPadding: {
        padding: "0 0 12px 0px",
        "& span": {
            display: "inline-block",
            textAlign: "center"
        }
    },
    svgField: {
        marginRight: 8,
        marginBottom: -8
    },
    buttonTypo: {
        display: "inline-block",
        marginTop: 12,
        fontSize: 15,
        color: "#fff"
    },
    svgCorner: {
        float: "right"
    },
    quoteText: {
        color: theme.palette.primary.main,
        margin: 16,
        fontSize: 20,
        fontStyle: "italic"
    },
    quoteFarmer: {
        textAlign: "right",
        marginBottom: 24
    },
    caption: {
        marginTop: 24,
        marginLeft: 8,
        lineHeight: "1.6em"
    },
    magnify: {
        position: "absolute",
        bottom: 3,
        right: 0,
        width: 40,
        height: 40,
        cursor: "pointer"
    },
    thumbnails: {
        width: 70,
        border: "1px solid #bbb",
        marginRight: 16
    },
    thumbnailSelected: {
        border: "3px solid " + theme.palette.primary.light + " !important"
    },
    buttonDialogPadding: {
        marginTop: 16,
        marginBottom: 16
    },
    specialBreak: {
        "@media (min-width: 1280px) and (max-width: 1450px)": {
            marginTop: -30
        },
        "@media (min-width: 1450px) and (max-width: 1550px)": {
            marginTop: -30
        }
    },
    linkColor2: {
        textDecoration: "none",
        color: "#666666"
    },
    dynamicText: {
        lineHeight: "1.8em",
        fontSize: 15,
        "@media (min-width: 1280px) and (max-width: 1360px)": {
            lineHeight: "1.3em",
            fontSize: 13
        },
        "@media (min-width: 1360px) and (max-width: 1450px)": {
            lineHeight: "1.4em",
            fontSize: 13.5
        },
        "@media (min-width: 1450px) and (max-width: 1650px)": {
            lineHeight: "1.6em",
            fontSize: 14
        }
    }
});

class FarmerB extends Component {
    state = {
        isOpen: false
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { isOpen } = this.state;
        const { classes, navSelectPage } = this.props;

        return (
            <AppContainer
                authenticated
                color="welcome"
                title="Shine a Light on Your Sustainability Story"
                pageTitle="Meet Justin Knopf"
                crumbs={
                    <div>
                        <Link className={classes.linkColor} to={"/welcome"}>
                            Hear From Farmers Like You
                        </Link>
                        &nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;{" "}
                        <span className={classes.crumbColor}>Meet Justin Knopf</span>
                    </div>
                }>
                <Grid container spacing={16}>
                    <Grid item xs={12} lg={6} className={classes.marginTop}>
                        <Typography variant="title" gutterBottom>
                            <i>Meet Justin Knopf</i>
                        </Typography>
                        <Typography gutterBottom>
                            After using the Fieldprint® Platform and finding value in the information collected, Justin
                            participated in projects such as the&nbsp;
                            <a
                                className={classes.linkColor2}
                                rel="noopener noreferrer"
                                href="https://fieldtomarket.org/case-studies-series/2018collaboration/"
                                target="_blank">
                                <b>Southern Plains Hard Wheat</b>
                            </a>
                            &nbsp;Project to establish an environmental performance baseline and document improvements
                            across the wheat rotation throughout Kansas. Using this data, Justin can trace improvements,
                            including:
                        </Typography>
                        <Grid container spacing={16}>
                            <Grid item xs={6}>
                                <table>
                                    <tr>
                                        <td>
                                            <img
                                                src={SoilConservationIcon}
                                                className={classes.icon}
                                                alt="Reduced soil erosion"
                                            />
                                        </td>
                                        <td>
                                            <Typography>Reduced soil erosion</Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img
                                                src={GreenhouseGasIcon}
                                                className={classes.icon}
                                                alt="Improved carbon capture"
                                            />
                                        </td>
                                        <td>
                                            <Typography>Improved carbon capture</Typography>
                                        </td>
                                    </tr>
                                </table>
                            </Grid>
                            <Grid item xs={6}>
                                <table>
                                    <tr>
                                        <td>
                                            <img
                                                src={SoilCarbonIcon}
                                                className={classes.icon}
                                                alt="Increased resiliency over time"
                                            />
                                        </td>
                                        <td>
                                            <Typography>Increased resiliency over time</Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img
                                                src={EnergyUseIcon}
                                                className={classes.icon}
                                                alt="Reduced energy use"
                                            />
                                        </td>
                                        <td>
                                            <Typography>Reduced energy use</Typography>
                                        </td>
                                    </tr>
                                </table>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    component={Link}
                                    to="/cropyear/DEMO5-0001-2019"
                                    onClick={() => navSelectPage("field_DEMO5-0001")}
                                    variant="raised"
                                    color="primary"
                                    fullWidth
                                    className={classes.buttonPadding}>
                                    <CornerArrow className={classNames(classes.svgCorner, classes.iconSize)} />
                                    <FieldIcon className={classNames(classes.svgField, classes.iconSize)} />
                                    <Typography className={classes.buttonTypo}>Explore My Wheat Field</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} className={classes.marginTop}>
                        <Paper style={{ backgroundColor: "#eee" }}>
                            <Grid container spacing={0} className={classes.marginTop} alignItems="center">
                                <Grid item xs={6} className={classes.noPadding} style={{ marginBottom: -5 }}>
                                    <img src={farmer1} alt="Justin Knopf" style={{ width: "100%" }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classNames(classes.dynamicText, classes.insertText)}>
                                        Justin Knopf is dedicated to telling the sustainability story of his land. In
                                        total, Justin manages 4,200 acres of alfalfa, corn, grain sorghum, soybeans and
                                        wheat. Justin uses the Fieldprint Platform to fine tune his management choices
                                        and monitor key metrics, like soil carbon, to ensure that the land his family
                                        farms is in good condition to produce crops for future generations.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={6} className={classes.marginTop}>
                        <Paper style={{ backgroundColor: "#eee" }}>
                            <Grid container spacing={0}>
                                <Grid
                                    item
                                    xs={6}
                                    className={classes.noPadding}
                                    style={{ marginBottom: -5, position: "relative" }}>
                                    <img
                                        onClick={() => this.setState({ isOpen: true })}
                                        src={spidergram1}
                                        alt="Justin's Wheat Field"
                                        style={{ cursor: "pointer", width: "100%" }}
                                    />
                                    <MagnifyIcon
                                        onClick={() => this.setState({ isOpen: true })}
                                        className={classes.magnify}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="title" style={{ padding: 16, fontSize: 23 }}>
                                        Fieldprint® Analysis of Justin’s Wheat Field
                                    </Typography>
                                    <Typography className={classes.insertText}>
                                        Click on the spider diagram to explore Justin’s Fieldprint Analysis.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Popup
                            open={isOpen}
                            closeEvent={() => this.setState({ isOpen: false })}
                            image={spidergram1}
                            imagealt="Justin's Wheat Field"
                            title="Fieldprint® Analysis of Justin’s Wheat Field"
                            text={
                                <div>
                                    <Typography className={classes.insertTextPop}>
                                        The Fieldprint Analysis of Justin’s wheat field gives him a full picture into
                                        his field's sustainability performance. The closer the blue shaded area is to
                                        the center of the spider diagram, the greater the sustainability performance or
                                        resource use efficiency.
                                    </Typography>
                                    <Typography className={classes.insertTextPop}>
                                        Farmers like Justin utilize the Fieldprint Platform to both document the
                                        environmental benefits of their conservation and stewardship as well as identify
                                        opportunities for continuous improvement in the next growing season. Generally,
                                        if you there are blue areas that extend beyond the state or national benchmarks
                                        in a Fieldprint Analysis, this highlights an opportunity for improvement and an
                                        important area to consider in the next growing season. Over time, comparing the
                                        results of multiple analyses can help you evaluate sustainability progress and
                                        consider trade-offs between different management approaches for your field.
                                    </Typography>
                                    <Grid container justifyContent="center">
                                        <Grid item xs={10} lg={6}>
                                            <Button
                                                component={Link}
                                                to="/cropyear/DEMO5-0001-2019"
                                                onClick={() => navSelectPage("field_DEMO5-0001")}
                                                variant="raised"
                                                color="primary"
                                                fullWidth
                                                className={classNames(
                                                    classes.buttonDialogPadding,
                                                    classes.buttonPadding
                                                )}>
                                                <CornerArrow
                                                    className={classNames(classes.svgCorner, classes.iconSize)}
                                                />
                                                <FieldIcon className={classNames(classes.svgField, classes.iconSize)} />
                                                <Typography className={classes.buttonTypo}>
                                                    Explore My Wheat Field
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} lg={6} className={classNames(classes.specialBreak, classes.marginTop)}>
                        <Divider />
                        <Typography gutterBottom className={classes.quoteText}>
                            “The Fieldprint Platform takes a snapshot across time of improvements across the land. Start
                            with a single field and study the metrics produced to find where there might be valuable to
                            your operation and decision-making going forward.”
                        </Typography>
                        <div className={classes.quoteFarmer}>
                            <Typography>
                                <b>Justin Knopf</b>
                            </Typography>
                            <Typography>5h Generation Farmer</Typography>
                            <Typography>Gypsum, Kansas</Typography>
                        </div>
                        <Divider />
                        <Typography variant="caption" className={classes.caption}>
                            Please note that the sample field offered is based on similar climatic conditions, soil type
                            and management practices utilized on this farmer’s operation. This data is provided for
                            illustrative purposes to demonstrate how the Fieldprint Platform can be utilized to measure
                            and assess the sustainability performance of a given field.
                        </Typography>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

FarmerB = connect(state => null, {
    ...navActions
})(FarmerB);

export default withStyles(styles)(FarmerB);
