import React from "react";
import classNames from "classnames";
import TextField from "@mui/material/TextField";
import withStyles from "@mui/styles/withStyles";
import Button from "../../common/ButtonWrapper";
import Grid from "../../common/GridWrapper";
import Typography from "../../common/TypographyWrapper";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Form } from "react-form";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const styles = theme => ({
    container: {
        flexGrow: 1,
        position: "relative"
    },
    optionContainer: {
        display: "flex"
        // paddingBottom: "16px"
    },
    suggestion: {
        display: "block"
    },
    option: {
        paddingTop: "0px",
        paddingBottom: "0px"
    },
    optionLabel: {
        marginRight: "10px",
        marginTop: "8px"
    },
    optionLabelError: {
        marginRight: "10px",
        marginTop: "8px",
        color: "red"
    },
    root: {
        backgroundColor: theme.palette.primary.main
    },
    rootText: {
        color: "#fff"
    },
    cancelBorder: {
        border: "1px solid"
    },
    buttonMargin: {
        marginRight: 24,
        marginBottom: 16
    },
    gutterTop: {
        marginTop: ".6em"
    },
    noPadding: {
        paddingBottom: 0 + " !important"
    },
    minHeight: {
        // minHeight: "80vh",
        minWidth: 600
    },
    // dialogHeight: {
    //     height: 600
    // },
    expansionResults: {
        minHeight: 250,
        maxHeight: 250,
        width: "100%",
        overflowY: "auto"
    }
});

class stateOptionsDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            stateOptions: [],
            selectedStates: []
        };
    }

    filterStates = event => {
        let stateOptions = this.state.stateOptions;
        let compareValues = event.target.value.toLowerCase().trim().split(" ");

        if (compareValues.length === 0) {
            stateOptions.forEach(stateOption => {
                stateOption["show"] = true;
            });
            this.setState({ stateOptions: stateOptions });
        } else {
            stateOptions.forEach(stateOption => {
                let found = true;
                compareValues.forEach(compareValue => {
                    if (stateOption.label.toLowerCase().includes(compareValue) === false) {
                        found = false;
                    }
                });
                stateOption["show"] = found;
            });
            this.setState({ stateOptions: stateOptions });
        }
    };

    handleStatesSelectChange = event => {
        let selectedStateIds = this.state.selectedStates;
        let index = selectedStateIds.indexOf(event.target.value);

        if (event.target.checked === true) {
            if (index === -1) {
                selectedStateIds.push(event.target.value);
            }
        } else {
            selectedStateIds = selectedStateIds.filter(stateId => {
                return stateId !== event.target.value;
            });
        }

        this.setState({ selectedStates: selectedStateIds });
    };

    componentDidMount() {
        this.setState({
            stateOptions: this.props.stateOptions,
            selectedStates: this.props.alreadySelectedStates
        });
    }

    render() {
        const { classes } = this.props;

        const statesToShow = this.props.stateOptions.filter(state => {
            return state["show"];
        });

        return (
            <Dialog open={this.props.open} onClose={this.handleClose} classes={{ paper: classes.minHeight }}>
                <DialogTitle className={classes.root}>
                    <Typography variant="title" className={classes.rootText}>
                        Select States
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogHeight}>
                    <Form dontValidateOnMount="true" validateOnSubmit="true">
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Typography className={classes.gutterTop}>
                                    Select states to narrow the template search results.
                                </Typography>

                                <TextField
                                    id="state-search"
                                    label="Find States"
                                    type="search"
                                    className={classes.textField}
                                    margin="normal"
                                    variant="filled"
                                    onChange={this.filterStates}
                                />
                                <div style={{ "max-height": "250px", "min-height": "250px", "overflow-y": "auto" }}>
                                    {statesToShow.map(option =>
                                        option["show"] === true ? (
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disableRipple={true}
                                                            checked={
                                                                this.state.selectedStates.filter(state => {
                                                                    return state === option.value;
                                                                }).length > 0
                                                            }
                                                            onChange={this.handleStatesSelectChange}
                                                            value={option.value}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={option.label}
                                                />
                                            </Grid>
                                        ) : (
                                            <div />
                                        )
                                    )}
                                    {statesToShow.length === 0 && (
                                        <Grid item xs={12}>
                                            No States found.
                                        </Grid>
                                    )}
                                </div>
                            </form>
                        )}
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => this.props.onCancelClick()}
                        color="primary"
                        className={classNames(classes.cancelBorder, classes.buttonMargin)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => this.props.onSaveClick(this.state.selectedStates)}
                        className={classes.buttonMargin}
                        variant="raised"
                        color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(stateOptionsDialog);
