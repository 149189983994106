import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../common/withRouter";
import { Form } from "react-form";
import classNames from "classnames";

import Button from "../common/ButtonWrapper";
import Grid from "../common/GridWrapper";
import Typography from "../common/TypographyWrapper";
import { Dialog, DialogTitle, DialogContent, DialogContentText } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import withStyles from "@mui/styles/withStyles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EqualizerIcon from "@mui/icons-material/Equalizer";

// import Accordion from "@mui/material/Accordion";
const Accordion = () => null;
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import Close from "@mui/icons-material/Close";
import Warning from "@mui/icons-material/Warning";

import CropYearTable from "./CropYearTable";
import Facts from "./Facts";
import { Field as FieldModel } from "./models";
import { CropYear, FieldActivity } from "./fieldactivities/models";
import AppContainer from "../common/AppContainer";
import Select from "../common/Select";
import PageHeader from "../common/PageHeader";
import WarningDialog from "../common/WarningDialog";
import { createSelector } from "../common/orm";
import * as navActions from "../common/actions";
import { MAKE_OPTIONS } from "../../api/constants";
import Move_Help from "../../Move_Help.png";

const SUPPORT = "https://support.fieldtomarket.org/",
    GUIDE = "https://support.fieldtomarket.org/version-4-0-release/";

const allFarms = createSelector(schema => {
    return schema.Farm.all().orderBy("name").toModelArray();
});
const getField = FieldModel.selectByUrlId(field => ({
    farm_name: field.farm.name,
    farm_id: field.farm.id,
    ...field.ref
}));
const getCropYears = createSelector(
    (state, ownProps) => ownProps.match.params["id"],
    (session, fieldId) => {
        return session.CropYear.filter({ field: fieldId })
            .orderBy("year", "desc")
            .toModelArray()
            .map(cropYear => ({
                crop_name: cropYear.crop.name,
                activitiesLoad: cropYear.activities.all().toModelArray(),
                _activities: cropYear.activities
                    .all()
                    .toModelArray()
                    .map(activity => {
                        const activityFert = activity.fertilizers.count();
                        return {
                            _fertilizers: activityFert > 0 && activity.fertilizers.all().toRefArray(),
                            ...activity.ref
                        };
                    }),
                ...cropYear.ref,
                fieldObject: cropYear.field._fields
            }));
    }
);

const styles = theme => ({
    marginTop: {
        marginTop: -36,
        marginLeft: -18,
        float: "left"
    },
    iconText: {
        height: 18,
        width: 24,
        marginBottom: -3
    },
    delBtn: {
        marginRight: -30
    },
    moveBtn: {
        marginRight: 16
    },
    cancelBtn: {
        border: "1px solid",
        marginRight: 24
    },
    rightFloat: {
        float: "right !important"
    },
    alertNotice: {
        textAlign: "center",
        backgroundColor: "#FFFFE0",
        padding: 8,
        fontSize: "1rem",
        lineHeight: "1.5em"
    },
    linkColor: {
        color: theme.palette.primary.main
    },
    titleContainer: {
        backgroundColor: "#ff7d32",
        textAlign: "center"
    },
    closeIcon: {
        float: "right",
        color: "#fff",
        marginTop: -16,
        marginRight: -16
    },
    dialogMin: {
        minWidth: "400px"
    },
    questionTitle: {
        color: "#fff",
        fontWeight: 500,
        float: "left"
    },
    button: {
        float: "right",
        marginTop: 24
    },
    marginTopTwo: {
        marginTop: 24
    },
    expansionPanel: {
        boxShadow: "none"
    },
    expansionSummary: {
        backgroundColor: "#F7F6F6",
        border: "1px solid lightgrey"
    }
});

class Field extends Component {
    state = {
        deleteDialogOpen: false,
        deleteDialogText: null,
        deleteDialogConfirmAction: null,
        moveDialogOpen: false,
        moveDialogConfirmAction: null
    };

    deleteField = (field, name) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: field,
            deleteDialogText: "Are you sure you wish to permanently delete " + name + "?"
        });
    };

    moveField = field => {
        this.setState({
            moveDialogOpen: true,
            moveDialogConfirmAction: field
        });
    };

    doDeleteField = field => {
        this.props.ormFieldDelete(field);
        this.setState({ deleteDialogOpen: false });
        this.props.navSelectPage("dashboard");
        this.props.history.push("/dashboard");
    };

    doMoveField = values => {
        const { field, ormFieldUpdate } = this.props;
        ormFieldUpdate({
            id: field.id,
            farm: values.farm
        });
        this.setState({ moveDialogOpen: false });
    };

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        return {
            farm: isRequired(values.farm)
        };
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { classes, field, cropYears, ormCropYearCreate, ormCropYearDelete, ormFieldActivityCreate, allFarms } =
            this.props;
        const { deleteDialogOpen, deleteDialogText, deleteDialogConfirmAction, moveDialogOpen } = this.state;

        return (
            <AppContainer
                authenticated
                header={
                    <>
                        <PageHeader
                            color="field"
                            title={(field.name || field.id) + " on " + (field.farm_name || "New Farm")}
                        />
                        <Facts field={field} />
                    </>
                }>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Button component={Link} to={"/field/" + field.id + "/location"} className={classes.marginTop}>
                            <EditIcon /> &nbsp;&nbsp;&nbsp;Modify Field Location
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button
                            onClick={() => this.deleteField(field.id, field.name || field.id)}
                            className={classNames(classes.delBtn, classes.marginTop, classes.rightFloat)}>
                            <DeleteIcon />
                            &nbsp;&nbsp;&nbsp;Delete Field
                        </Button>
                        <Button
                            onClick={() => this.moveField(field.id)}
                            className={classNames(classes.marginTop, classes.rightFloat, classes.moveBtn)}>
                            <OpenWithIcon />
                            &nbsp;&nbsp;&nbsp;Move Field
                        </Button>
                        <Accordion className={classes.expansionPanel}>
                            <AccordionSummary className={classes.expansionSummary} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="title">Crop History</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={8}>
                                    {!field.crop_history && (
                                        <Grid item xs={12}>
                                            Not Available. Please modify the field location to look up the crop history.
                                        </Grid>
                                    )}
                                    {field.crop_history && (
                                        <Grid item xs={12}>
                                            <table style={{ width: "100%", borderSpacing: 8 }}>
                                                <thead>
                                                    <td style={{ fontWeight: "bold" }}>Year</td>
                                                    <td style={{ fontWeight: "bold" }}>Majority Crop Type</td>
                                                    <td style={{ fontWeight: "bold" }}>Majority Crop Acres</td>
                                                    <td style={{ fontWeight: "bold" }}>Majority Crop %</td>
                                                    <td style={{ fontWeight: "bold" }}>Secondary Crop Type</td>
                                                    <td style={{ fontWeight: "bold" }}>Secondary Crop Acres</td>
                                                    <td style={{ fontWeight: "bold" }}>Additional Crop Types</td>
                                                </thead>
                                                <tbody>
                                                    {field.crop_history.map(d => {
                                                        var majority_crop = "-";
                                                        var majority_acres = "-";
                                                        var majority_percent = "-";
                                                        var secondary_crop = "-";
                                                        var secondary_acres = "-";
                                                        var other_crops = "";
                                                        var total_acres = 0;
                                                        d.crops.forEach((dc, i) => {
                                                            if (i !== 0 && i !== 1) {
                                                                other_crops += dc.crop + " (" + dc.acres + ") | ";
                                                            }
                                                            total_acres += dc.acres;
                                                        });
                                                        if (d.crops[0]) {
                                                            majority_crop = d.crops[0].crop;
                                                            majority_acres = d.crops[0].acres;
                                                            majority_percent =
                                                                Math.round((majority_acres / total_acres) * 1000) / 10 +
                                                                "%";
                                                        }
                                                        if (d.crops[1]) {
                                                            secondary_crop = d.crops[1].crop;
                                                            secondary_acres = d.crops[1].acres;
                                                        }
                                                        other_crops = other_crops.slice(0, -3);
                                                        if (other_crops === "") other_crops = "-";
                                                        return (
                                                            <tr>
                                                                <td>{d.year}</td>
                                                                <td>{majority_crop}</td>
                                                                <td>{majority_acres}</td>
                                                                <td>{majority_percent}</td>
                                                                <td>{secondary_crop}</td>
                                                                <td>{secondary_acres}</td>
                                                                <td>{other_crops}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </Grid>
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Typography variant="title" gutterBottom>
                            Crop Years
                        </Typography>
                        <Typography gutterBottom>
                            Your Fieldprint Analysis is calculated at the Crop Year level (e.g. 2017 Corn, 2016
                            Soybeans). Use this page to add Crop Years and then click the edit icon (
                            <EditIcon color="primary" className={classes.iconText} />) to enter your Fieldprint Data.
                            When Fieldprint data entry is complete, you can click the analysis icon (
                            <EqualizerIcon color="primary" className={classes.iconText} />) to calculate and view your
                            Fieldprint Results. Click the delete icon (
                            <DeleteIcon color="primary" className={classes.iconText} />) to delete a Crop year. Note
                            that deleting a crop year permanently deletes all data associated with the crop year and
                            removes any associations to Projects. Where double cropping is in use, data entered for a
                            Crop Year should be based on the primary cropping system. Production from the secondary crop
                            is not included in the Fieldprint Analysis at this time.
                        </Typography>
                        <Typography variant="subheading" className={classes.alertNotice}>
                            Please visit the new Fieldprint Platform{" "}
                            <a className={classes.linkColor} href={SUPPORT} target="_blank" rel="noopener noreferrer">
                                Support Portal
                            </a>{" "}
                            to learn more about the Version 4 Release. Read the{" "}
                            <a className={classes.linkColor} href={GUIDE} target="_blank" rel="noopener noreferrer">
                                Version 4 Release Overview
                            </a>{" "}
                            document to learn about the Version 4 enhancements including the new Water Quality metric.{" "}
                            <strong>
                                Version 4.0 introduces changes in overall data inputs at the crop year level.
                            </strong>{" "}
                            The{" "}
                            <a className={classes.linkColor} href={GUIDE} target="_blank" rel="noopener noreferrer">
                                Version 4 Release Overview
                            </a>{" "}
                            describes the potential impact to your data and includes a detailed breakdown of input
                            changes.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CropYearTable
                            fieldId={field.id}
                            fieldSize={field.size}
                            farm_id={field.farm_id}
                            farm_name={field.farm_name}
                            ormCropYearCreate={ormCropYearCreate}
                            ormFieldActivityCreate={ormFieldActivityCreate}
                            cropYears={cropYears}
                            ormCropYearDelete={ormCropYearDelete}
                        />
                    </Grid>
                </Grid>
                <WarningDialog
                    confirmAction={() => this.doDeleteField(deleteDialogConfirmAction)}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title="Delete Field"
                    text={deleteDialogText}
                />
                <Dialog open={moveDialogOpen} classes={{ paper: classes.minWidth }}>
                    <DialogTitle className={classes.titleContainer}>
                        <Typography variant="title" className={classes.questionTitle}>
                            {"Move " + field.name + " on " + field.farm_name}
                        </Typography>
                        <IconButton
                            onClick={() => this.setState({ moveDialogOpen: false })}
                            className={classes.closeIcon}
                            size="large">
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.marginTopTwo}>
                            <Typography gutterBottom>
                                Select the farm underwhich you want to move this field. Note that any previously
                                generated reports will need to be run again to reflect this change. Be sure to confirm
                                the managed acres for the farm after any changes to field organization.
                            </Typography>
                            <Form
                                dontValidateOnMount="true"
                                validateOnSubmit="true"
                                validateError={this.errorValidator}
                                onSubmit={values => this.doMoveField(values)}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={16}>
                                            <Grid item xs={12}>
                                                <Select
                                                    field="farm"
                                                    label="Move To"
                                                    options={MAKE_OPTIONS(allFarms)}
                                                    eventHandle={this.checkFarmCropYear}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <Typography variant="title" gutterBottom>
                                                    <Warning color="primary" style={{ marginBottom: -5 }} />
                                                    &nbsp;&nbsp;&nbsp;Updating Managed Acres
                                                </Typography>
                                                <Typography gutterBottom>
                                                    After moving this field it is important that you confirm the managed
                                                    acres entered for the farm from which you moved the field AND the
                                                    farm to which you moved the field. Managed acres represent the total
                                                    number of acres, for both irrigated and non-irrigated acres, you
                                                    grow on a farm per year per crop (e.g. total of X acres of corn
                                                    grown on Farm A in 2018).
                                                </Typography>
                                                <Typography gutterBottom style={{ textAlign: "center" }}>
                                                    <img
                                                        onClick={() => window.open(Move_Help)}
                                                        src={Move_Help}
                                                        alt="Managed Acres"
                                                        style={{ width: 200 }}
                                                    />
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="caption"
                                                    style={{ textAlign: "center" }}>
                                                    Click to Enlarge
                                                </Typography>
                                                <Typography gutterBottom>
                                                    To check your managed acres data, for both farms, click on the farm
                                                    name to see managed acres per year per crop. Edit the managed acres
                                                    as required. Accurate managed acres data is important for accurate
                                                    Project reporting.
                                                </Typography>
                                                <Button
                                                    type="submit"
                                                    variant="raised"
                                                    color="primary"
                                                    className={classes.button}>
                                                    Confirm
                                                </Button>
                                                <Button
                                                    onClick={() => this.setState({ moveDialogOpen: false })}
                                                    color="primary"
                                                    className={classNames(classes.button, classes.cancelBtn)}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </AppContainer>
        );
    }
}

Field = connect(
    (state, ownProps) => ({
        field: getField(state, ownProps),
        allFarms: allFarms(state),
        cropYears: getCropYears(state, ownProps)
    }),
    {
        ...CropYear.actions,
        ...FieldActivity.actions,
        ...FieldModel.actions,
        ...navActions
    }
)(Field);

export default withStyles(styles)(withRouter(Field));
