import React, { Component } from "react";
import withRouter from "../common/withRouter";
import AppContainer from "../common/AppContainer";
import NotReady from "../common/NotReady";
import Prompt from "../common/Prompt";
import Typography from "../common/TypographyWrapper";
import Grid from "../common/GridWrapper";
import TextField from "../common/TextField";
import Snackbar from "../common/Snackbar";
import Button from "../common/ButtonWrapper";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Form } from "react-form";

import { RotationSystem } from "./models";
import { RotationEvent } from "./models";
import { connect } from "react-redux";

import withStyles from "@mui/styles/withStyles";
import RotationEventDialog from "./RotationEventDialog";
import * as utils from "./CropManagementUtils";
import TemplateLibraryTable from "./tables/TemplateLibraryTable";
import WarningDialog from "../common/WarningDialog";
import { setValue } from "../../api/utils";
import { CropYear } from "../field/fieldactivities/models";

const styles = theme => ({
    addButtonIcon: {
        marginLeft: theme.spacing(1)
    },
    tableMargin: {
        marginTop: "-24px"
    },
    errorList: {
        backgroundColor: "#efe8e5",
        color: "red"
    }
});

const getRotationSystem = RotationSystem.selectByUrlId(system => ({
    events: system.events.toRefArray(),
    cropyears: system.cropyears.toRefArray(),
    ...system.ref
}));

class RotationEditor extends Component {
    constructor(props) {
        super(props);
        this.getBuilderData = this.getBuilderData.bind(this);
        this.handleYesDeleteEvent = this.handleYesDeleteEvent.bind(this);
        this.handleNoDeleteEvent = this.handleNoDeleteEvent.bind(this);
        this.handleRotationEventCancelClick = this.handleRotationEventCancelClick.bind(this);
        this.handleRotationEventSaveClick = this.handleRotationEventSaveClick.bind(this);
        this.handleEditEventClick = this.handleEditEventClick.bind(this);
        this.handleDeleteEventClick = this.handleDeleteEventClick.bind(this);

        this.state = {
            name: "",
            groupedOperations: [],
            operations: [],
            crops: [],
            residues: [],

            showAlert: false,
            removeId: "",
            eventToEdit: "",
            editing: false,
            showSaveAlert: false,
            saving: false
        };
    }

    handleDeleteEventClick(eventId) {
        this.setState({ showAlert: true, deleteId: eventId });
    }

    handleEditEventClick(eventId) {
        let events = this.props.rotationSystem.events.filter(o => o.id === eventId);
        if (events.length) {
            let event = events[0];
            event = JSON.parse(JSON.stringify(event));
            this.setState({
                eventToEdit: event,
                editing: true
            });
        }
    }

    handleAddEventClick() {
        console.log("-->  handleAddEventClick");
        let blankEvent = {};
        this.setState({
            eventToEdit: blankEvent,
            editing: true
        });
    }

    handleRotationEventCancelClick() {
        console.log("-->  handleRotationEventCancelClick");
        this.setState({
            eventToEdit: "",
            editing: false
        });
    }

    // Clear the metrics value of any associated cropyear
    clearCropYear(cropYears) {
        const { ormCropYearUpdate } = this.props;
        if (cropYears.length > 0) {
            cropYears.forEach(function (cY) {
                const id = cY.id;
                let values = {};

                setValue(values, "metrics", null);
                ormCropYearUpdate({
                    id: id,
                    ...values
                });
            });
        }
    }

    handleRotationEventSaveClick(theEvent) {
        const events = this.props.rotationSystem.events.map(evt => (evt.id === theEvent.id ? theEvent : evt));
        if (theEvent.id) {
            this.props.ormRotationEventUpdate(theEvent);
        } else {
            theEvent["system"] = this.props.rotationSystem.id;
            this.props.ormRotationEventCreate(theEvent);
            events.push(theEvent);
        }

        this.clearCropYear(this.props.rotationSystem.cropyears);

        utils.updateRotationValid(
            this.props.ormRotationSystemUpdate,
            events,
            this.props.rotationSystem.id,
            this.state.operations,
            this.state.crops
        );

        this.setState({
            eventToEdit: "",
            editing: false
        });
    }

    handleYesDeleteEvent() {
        this.props.ormRotationEventDelete(this.state.deleteId);
        this.clearCropYear(this.props.rotationSystem.cropyears);
        let events = this.props.rotationSystem.events.filter(event => event.id !== this.state.deleteId);
        utils.updateRotationValid(
            this.props.ormRotationSystemUpdate,
            events,
            this.props.rotationSystem.id,
            this.state.operations,
            this.state.crops
        );
        this.setState({ showAlert: false, deleteId: "" });
    }

    handleNoDeleteEvent() {
        this.setState({ showAlert: false, removeId: "" });
    }

    componentDidMount() {
        this.getBuilderData();
        this.setState({
            name: this.props.rotationSystem.name
        });
        window.scrollTo(0, 0);
    }

    getBuilderData() {
        console.log("--> getBuilderData");
        let that = this;
        Promise.all([utils.OperationsRequest, utils.CropsRequest, utils.ResiduesRequest]).then(function (values) {
            let operations = values[0]["result"][0]["value"]["operations"];
            let crops = values[1];
            let residues = values[2]["result"][0]["value"]["residues"];
            let groupedOperations = utils.GroupOperationData(operations);

            //  filter out water as a residue
            residues = residues.filter(resiude => {
                return resiude["name"] !== "water";
            });

            that.setState({
                groupedOperations: groupedOperations,
                operations: operations,
                crops: crops,
                residues: residues,
                loading: false
            });
        });
    }

    updateName(name) {
        if (name.trim()) {
            this.setState({ name: name });
        } else {
            this.setState({ name: "" });
        }
    }

    saveRotationSystem() {
        //  if there are no events, prompt the user regarding no events and if they want to delete the rotation system

        this.setState({ saving: true });

        if (this.props.rotationSystem.events.length > 0) {
            this.updateRotationName();

            var link = "";
            if (this.props.match.params.cropyearId) {
                link = "/cropyear/" + this.props.match.params.cropyearId;
            } else if (this.props.match.params.projectId) {
                link = "/projectadmin/" + this.props.match.params.projectId;
            } else {
                link = "/rotation/library";
            }
            this.props.history.push(link);
        } else {
            this.setState({ showSaveAlert: true });
        }
    }

    handleSaveEmptyRotationSystemClick = () => {
        this.updateRotationName();

        this.setState({ showSaveAlert: false });

        var link = "";
        if (this.props.match.params.cropyearId) {
            link = "/cropyear/" + this.props.match.params.cropyearId;
        } else if (this.props.match.params.projectId) {
            link = "/projectadmin/" + this.props.match.params.projectId;
        } else {
            link = "/rotation/library";
        }
        this.setState({ saving: false });
        this.props.history.push(link);
    };

    handleDontSaveEmptyRotationSystemClick = () => {
        this.setState({ showSaveAlert: false });

        this.props.ormRotationSystemDelete(this.props.rotationSystem.id);

        var link = "";
        if (this.props.match.params.cropyearId) {
            link = "/cropyear/" + this.props.match.params.cropyearId;
        } else if (this.props.match.params.projectId) {
            link = "/projectadmin/" + this.props.match.params.projectId;
        } else {
            link = "/rotation/library";
        }
        this.setState({ saving: false });
        this.props.history.push(link);
    };

    updateRotationName() {
        this.props.ormRotationSystemUpdate({
            id: this.props.rotationSystem.id,
            name: this.state.name
        });
        utils.updateRotationValid(
            this.props.ormRotationSystemUpdate,
            this.props.rotationSystem.events,
            this.props.rotationSystem.id,
            this.state.operations,
            this.state.crops
        );
    }

    getRotationErrors(events) {
        const { operations, crops } = this.state;
        if (!crops) {
            // Generic message while crops load
            return ["Invalid Rotation"];
        }
        if (events && events.length > 0) {
            var remapEvents = utils.remapRotationEvents(events, operations);
            let validation = utils.validateRotationSystemEvents(remapEvents, crops);
            return validation;
        } else {
            let validation = utils.validateRotationSystemEvents(events, crops);
            return validation;
        }
    }

    render() {
        const { rotationSystem, classes, syncState } = this.props;
        const {
            groupedOperations,
            operations,
            crops,
            residues,
            name,
            showAlert,
            showSaveAlert,
            eventToEdit,
            editing,
            saving
        } = this.state;

        const { pending = {} } = syncState || {};

        if (!rotationSystem || pending.RotationEvent === "PULLING") {
            return (
                <AppContainer authenticated>
                    <NotReady message="Loading rotation system..." />
                </AppContainer>
            );
        }
        if (!operations || !operations.length) {
            return (
                <AppContainer authenticated>
                    <NotReady message="Loading CR-LMOD operation list..." />
                </AppContainer>
            );
        }

        const rotationEvents = utils.remapRotationEvents(rotationSystem.events, operations);

        const separator = "@#$%^&";
        const message =
            "Your rotation system has no events. Do you want to keep the empty rotation system or delete it?";
        const noEvents = rotationEvents.length > 0 ? false : true;
        const noEventsMessage = rotationSystem.id
            ? [rotationSystem.id.toString(), name === "" ? "Custom" : name, message].join(separator)
            : "";

        const columnData = [
            {
                id: "operationName",
                numeric: false,
                disablePadding: false,
                label: "Operation Name",
                allowSort: false
            },
            {
                id: "year",
                numeric: false,
                disablePadding: false,
                label: "Year",
                allowSort: false
            },
            {
                id: "day",
                numeric: false,
                disablePadding: false,
                label: "Day",
                allowSort: false
            },
            {
                id: "crop",
                numeric: false,
                disablePadding: false,
                label: "Crop",
                allowSort: false
            },
            {
                id: "residue",
                numeric: false,
                disablePadding: false,
                label: "Residue",
                allowSort: false
            },
            {
                id: "residueAmount",
                numeric: false,
                disablePadding: false,
                label: "Residue Amount",
                allowSort: false
            }
        ];

        const operationsStatus = rotationSystem.is_valid ? [] : this.getRotationErrors(rotationSystem.events);

        return (
            <AppContainer authenticated color="crop" title="Crop Rotation Library">
                {saving === false && <Prompt when={noEvents} message={noEventsMessage} />}

                <Grid container spacing={24}>
                    <WarningDialog
                        confirmText={"Yes"}
                        confirmAction={this.handleYesDeleteEvent}
                        cancelText={"No"}
                        cancelAction={this.handleNoDeleteEvent}
                        open={showAlert}
                        title="Delete operation"
                        text={"Are you sure you want to delete this operation?"}
                    />

                    <WarningDialog
                        confirmText={"Yes"}
                        confirmAction={this.handleSaveEmptyRotationSystemClick}
                        cancelText={"No"}
                        cancelAction={this.handleDontSaveEmptyRotationSystemClick}
                        open={showSaveAlert}
                        title="Empty Rotation System"
                        text={
                            "There are no events in this rotation system.  Do you want to keep this empty rotation system?"
                        }
                    />

                    {eventToEdit && (
                        <RotationEventDialog
                            event={eventToEdit}
                            groupedOperations={groupedOperations}
                            crops={crops}
                            residues={residues}
                            open={eventToEdit}
                            editing={editing}
                            onCancelClick={this.handleRotationEventCancelClick}
                            onSaveClick={this.handleRotationEventSaveClick}
                        />
                    )}

                    <Grid item xs={12}>
                        <Typography>
                            Edit operations to create a customized Crop Rotation System to represent your planned crop
                            rotation. Operations include application, harvest, residue, seeding, and tillage. Please
                            remember that the dates are representative with the goal to assign an average day for the
                            operations. We recommend entering operations by date to model the overall rotation making
                            sure to avoid unwanted fallow seasons.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Form dontValidateOnMount="true" validateOnSubmit="true">
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <TextField
                                        field="rotationName"
                                        label="Crop Rotation Name"
                                        placeholder="Enter crop rotation name"
                                        value={name}
                                        required
                                        eventHandle={value => this.updateName(value)}
                                        error={
                                            !this.props.rotationSystem.name ||
                                            this.props.rotationSystem.name === "NRCS Copy" ||
                                            this.props.rotationSystem.name === "Custom"
                                        }
                                        helperText={
                                            !this.props.rotationSystem.name ||
                                            this.props.rotationSystem.name === "NRCS Copy" ||
                                            this.props.rotationSystem.name === "Custom"
                                                ? "Please rename this rotation"
                                                : ""
                                        }
                                        margin="normal"
                                    />
                                </form>
                            )}
                        </Form>

                        {rotationSystem.template_name && rotationSystem.template_name.toLowerCase() !== "custom" && (
                            <Typography gutterBottom>
                                This Crop Rotation System was originally based on: {rotationSystem.template} -{" "}
                                {rotationSystem.template_name}
                            </Typography>
                        )}
                    </Grid>

                    {operationsStatus.length > 0 && (
                        <Grid item xs={12} className={classes.errorList}>
                            <Typography>
                                <strong>Validation Errors</strong>
                            </Typography>
                            <ul>
                                {operationsStatus.map(status => (
                                    <li>{status}</li>
                                ))}
                            </ul>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <span>
                                    <Button
                                        style={{ width: "100%" }}
                                        size={"small"}
                                        color="primary"
                                        variant="raised"
                                        onClick={() => this.handleAddEventClick()}>
                                        Add Operation
                                        <AddCircleOutlineIcon className={classes.addButtonIcon} />
                                    </Button>
                                </span>
                            </Grid>

                            <Grid item xs={12} className={classes.tableMargin}>
                                <TemplateLibraryTable
                                    // orderBy={"date"}
                                    noPagination={true}
                                    data={rotationEvents}
                                    columnData={columnData}
                                    noRecordsMessage={"No rotation events."}
                                    onEdit={this.handleEditEventClick}
                                    onDelete={this.handleDeleteEventClick}
                                    toolTips={[
                                        {
                                            column: "operationName",
                                            tip: "operationTip"
                                        }
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            disabled={!name}
                            onClick={() => this.saveRotationSystem()}
                            color="primary"
                            variant="raised">
                            {this.props.match.params.cropyearId ? "Save and Return to Editing Crop Year " : ""}
                            {this.props.match.params.projectId ? "Save and Return to Project Page" : ""}
                            {this.props.match.params.cropyearId === undefined &&
                            this.props.match.params.projectId === undefined
                                ? "Save and Return to Crop Rotation Library"
                                : ""}
                            {/* Save and Return to Crop Rotation Library */}
                        </Button>
                        {rotationSystem.is_valid && <Snackbar auto success="No validation errors detected." />}
                        {!rotationSystem.is_valid && <Snackbar auto error="Validation errors detected." />}
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

RotationEditor = connect(
    (state, ownProps) => ({
        rotationSystem: getRotationSystem(state, ownProps),
        syncState: state.sync
    }),
    // null, // Actions are handled by subcomponents
    {
        ...RotationSystem.actions,
        ...RotationEvent.actions,
        ...CropYear.actions
    }
)(RotationEditor);

export default withStyles(styles)(withRouter(RotationEditor));
