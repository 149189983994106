import React, { Component } from "react";
import { Form } from "react-form";
import { ResponsiveContainer } from "recharts";
import saveAs from "file-saver";

import withStyles from "@mui/styles/withStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorOutline from "@mui/icons-material/ErrorOutline";

import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import Grid from "../../common/GridWrapper";
import Typography from "../../common/TypographyWrapper";

import LinearProgress from "@mui/material/LinearProgress";

import Button from "../../common/ButtonWrapper";
import ActionButton from "../../common/ActionButton";
import { Link } from "react-router-dom";
import withRouter from "../../common/withRouter";

import PageHeader from "../../common/PageHeader";
import Switch from "../../common/Switch";
import Select from "../../common/Select";

import AppContainer from "../../common/AppContainer";
import Facts from "../Facts";

import { CropYear, CROP_YEAR_COMPLETED, FieldActivity } from "../fieldactivities/models";
import { Metric } from "../../../api/models";
import { connect } from "react-redux";
import { getValue } from "../../../api/utils";
import { apiFetch, dbFetch } from "../../../api/fetch";

import { BENCHMARKS, BENCHMARKS_PROJECT, getMetrics, getLabel, getDescription, getMetricsBasic } from "./StaticData";
import { getIcon } from "./StaticIcons";

import SpiderGram from "./SpiderGram";
import MetricDetail from "./MetricDetail";
import MetricBasic from "./MetricBasic";
import { Fertilizer } from "../fieldactivities/activities/models";
import { Field } from "../models";

// CSS in JS styles
const styles = theme => ({
    benchmarkToggle: {
        marginTop: "12px"
    },
    indicatorIcon: {
        width: "50px",
        height: "50px"
    },
    indicatorTitle: {
        marginLeft: "8px",
        marginTop: "12px"
    },
    borderButton: {
        border: `1px solid ${theme.palette.primary.main}`,
        marginTop: 16
    },
    linkColor: {
        color: "#808080"
    },
    serverError: {
        display: "flex",
        backgroundColor: "#efe8e5",
        padding: "1em",
        marginBottom: "1em"
    }
});

// Grab an instance of the cropYear through the ID in the URL
const getCropYear = CropYear.selectByUrlId(cropYear => ({
    crop_name: cropYear.crop.name,
    farm_name: cropYear.field.farm.name,
    field_name: cropYear.field._fields.name,
    field_id: cropYear.field._fields.id,
    fieldObject: cropYear.field._fields,
    activities: cropYear.activities.toModelArray().map(activity => ({
        fertilizers: activity.fertilizers.toRefArray(),
        ...activity.ref
    })),
    ...cropYear.ref,
    rotation: {
        events: cropYear.rotation.events.toRefArray(),
        ...cropYear.rotation.ref
    },
    cropYears: cropYear.field.cropyears
        .orderBy("year", "desc")
        .toModelArray()
        .filter(cy => CROP_YEAR_COMPLETED(cy))
        .map(cy => ({
            year: cy.year,
            crop_name: cy.crop.name,
            id: cy.id,
            ...cy.ref
        }))
}));

class ActiveYearSelect extends Component {
    makeYearOptions() {
        const { cropYears } = this.props;
        if (!cropYears || !cropYears.length) {
            return [{ value: "", label: "Loading Crop Years..." }];
        }
        return cropYears.map((cropYear, i) => ({
            label: cropYear.year + " — " + cropYear.crop_name,
            value: cropYear.id
        }));
    }

    handleChange = value => {
        const { history, hideBenchmarks } = this.props;
        hideBenchmarks();
        history.push(`/cropyear/${value}/analysis`);
    };

    render() {
        const { cropYear, classes } = this.props,
            options = this.makeYearOptions();

        return (
            <Select
                eventHandle={value => this.handleChange(value)}
                field="dyear"
                name="select-active-year"
                label="Active Year"
                className={classes.noMargin}
                options={options}
                margin="Normal"
                fullWidth
                value={(cropYear && cropYear.id) || ""}
            />
        );
    }
}

ActiveYearSelect = withRouter(ActiveYearSelect);

class BenchmarkSwitch extends Component {
    constructor(props) {
        super(props);
        this.state = { checked: false };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        //this.props.onRef(undefined);
    }

    turnOff = () => {
        this.setState({ checked: false });
    };

    handleChange(value) {
        this.setState({ checked: value });
        this.props.toggleBenchmark(this.bm_key, value);
    }

    render() {
        this.bm_key = this.props.benchmark_key;
        return (
            <Switch
                eventHandle={value => this.handleChange(value)}
                field={"benchmarkToggle_" + this.bm_key}
                name={"benchmarkToggle_" + this.bm_key}
                id={"benchmarkToggle_" + this.bm_key}
                label={this.props.name}
                labelNo="Off"
                labelYes="On"
                checked={this.state.checked}
            />
        );
    }
}

class MetricPanel extends Component {
    render() {
        const { metric, cropYear, classes } = this.props,
            label = getLabel(metric),
            icon = getIcon(metric),
            basicsArr = getMetricsBasic();

        if (basicsArr.includes(metric)) {
            return (
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <img src={icon} className={classes.indicatorIcon} alt={label} />
                            <Typography variant="title" className={classes.indicatorTitle}>
                                {label}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <MetricBasic metric={metric} cropYear={cropYear} />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            );
        } else {
            return (
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <img src={icon} className={classes.indicatorIcon} alt={label} />
                            <Typography variant="title" className={classes.indicatorTitle}>
                                {label}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <MetricDetail metric={metric} cropYear={cropYear} />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            );
        }
    }
}

MetricPanel = withStyles(styles)(MetricPanel);

class Analysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_project: false,
            show_state: false,
            show_national: false,
            last_id: ""
        };
    }
    bSwitch = {};

    setActiveYear(prevProps) {
        const {
                cropYear,
                syncState,
                ormCropYearLoadDetail,
                authState,
                ormFieldActivityLoadDetail,
                ormFertilizerLoadDetail,
                ormFieldLoadDetail
            } = this.props,
            { cropYear: lastYear, syncState: lastSyncState } = prevProps;
        if (!cropYear) {
            return;
        }
        // First sync the crop year
        if (
            (!lastYear || lastYear.id !== cropYear.id || !lastSyncState.ready) &&
            authState.user &&
            !authState.user.guest
        ) {
            if (syncState.ready && !getValue(cropYear.fieldObject, "synced")) {
                ormFieldLoadDetail(cropYear.fieldObject.id);
            }
            if (syncState.ready && !getValue(cropYear, "synced")) {
                ormCropYearLoadDetail(cropYear.id);

                cropYear.activities.forEach(function (activity) {
                    ormFieldActivityLoadDetail(activity.id);

                    activity.fertilizers.forEach(function (fertilizer) {
                        ormFertilizerLoadDetail(fertilizer.id);
                    });
                });
                return;
            }
        }
        if (
            !lastYear ||
            lastYear.id !== cropYear.id ||
            !lastSyncState.ready ||
            (!getValue(lastYear, "synced") && authState.user && !authState.user.guest)
        ) {
            // Then run the metrics if applicable after crop year has been synced
            if (
                syncState.ready &&
                !getValue(cropYear, "metrics.Calculator.success") &&
                (getValue(cropYear, "synced") || !authState.user || authState.user.guest)
            ) {
                // FIXME: Make syncreducer support handing multiple instances of one model
                // For now make sure all activities have finished before proceeding
                const aComponent = this;
                /*const aInterval = setInterval(checkPending, 500);
                function checkPending() {
                    var pending = false;
                    cropYear.activities.forEach(function(activity) {
                        if (!getValue(activity, "synced")) pending = true;

                        activity.fertilizers.toModelArray().forEach(function(fertilizer) {
                            if (!getValue(fertilizer, "synced")) pending = true;
                        });
                    });
                    if (!pending) {
                        aComponent.runMetrics();
                        clearInterval(aInterval);
                    }
                }*/
                // The above code won't refresh with the last loaded activity
                // Set a generic timeout and assume everything is loaded...
                setTimeout(function () {
                    aComponent.runMetrics();
                }, 5000);
            }
        }
    }

    toggleBenchmark = (bm_key, value) => {
        this.bm_key = value;
        if (bm_key === "project") {
            this.setState({ show_project: value });
        }
        if (bm_key === "state") {
            this.setState({ show_state: value });
        }
        if (bm_key === "national") {
            this.setState({ show_national: value });
        }
    };

    hideBenchmarks = () => {
        if (this.bSwitch) {
            for (var k in this.bSwitch) {
                if (this.bSwitch[k]) {
                    this.bSwitch[k].turnOff();
                }
            }
        }
        this.setState({ show_project: false, show_state: false, show_national: false });
    };

    runMetrics(retry) {
        const { cropYear, runMetric } = this.props;
        runMetric("Calculator", cropYear.id, retry);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setActiveYear({});
    }

    componentDidUpdate(prevProps) {
        this.setActiveYear(prevProps);
    }

    async download() {
        this.setState({ downloading: true });

        const { authState, cropYear } = this.props;
        let response;
        if (authState.user && authState.user.guest) {
            response = await apiFetch("/api/v4/calc/CalculatorReport", {
                method: "POST",
                body: JSON.stringify(cropYear),
                headers: { Accept: "application/pdf" }
            });
        } else {
            response = await dbFetch(`/reports/${cropYear.id}/pdf`);
        }

        saveAs(await response.blob(), cropYear.id + ".pdf");

        this.setState({ downloading: false });
    }

    render() {
        const { classes, cropYear, syncState, authState } = this.props,
            { cropYears } = cropYear;
        const { show_project, show_state, show_national, downloading } = this.state;
        const success = getValue(cropYear, "metrics.Calculator.success"),
            error = getValue(cropYear, "metrics.Calculator.error"),
            pending = getValue(cropYear, "metrics.Calculator.pending"),
            percentComplete = getValue(cropYear, "metrics.Calculator.percentComplete"),
            completeStatus = getValue(cropYear, "metrics.Calculator.completeStatus"),
            syncing = !syncState.ready,
            unknown = !success && !error && !pending && !syncing,
            metrics = getMetrics(cropYear);
        var synced;
        if (authState.user && authState.user.guest) synced = true;
        else synced = getValue(cropYear, "synced");

        // Check for missing field soil input
        const soil_texture_id = getValue(cropYear.fieldObject, "greenhouse.soil_texture_id"),
            slope = getValue(cropYear.fieldObject, "soils.slope"),
            slope_length = getValue(cropYear.fieldObject, "soils.slope_length"),
            omcontent = getValue(cropYear.fieldObject, "waterquality.omcontent");
        var fieldWarning = false;
        if (!soil_texture_id || !slope || !slope_length || !omcontent) fieldWarning = true;

        var benchmarkList;
        if (getValue(cropYear, "project.project_benchmarks")) benchmarkList = BENCHMARKS_PROJECT;
        else benchmarkList = BENCHMARKS;

        const title = (cropYear.field_name || cropYear.field_id) + " on " + (cropYear.farm_name || "New Farm");

        return (
            <AppContainer
                authenticated
                synced={!synced}
                header={
                    <>
                        <PageHeader color="field" title={title} pageTitle={`Analysis for ${title}`} />
                        <Facts field={cropYear.fieldObject} />
                    </>
                }>
                <Grid container spacing={24}>
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="headline" gutterBottom>
                                Fieldprint Spidergram
                            </Typography>
                            <Typography gutterBottom>
                                Fieldprint results are shown on the spidergram as relative indices on a scale of 1-100
                                that represent your metric scores. The indices are calculated so that smaller values
                                indicate less resource use or environmental impact from your field. This illustration
                                can be used to identify where the greatest opportunities for improvement are for your
                                field, and over time can be used to evaluate progress and trade-offs between different
                                sustainability metrics for your field.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12}>
                        <Grid item xs={8}>
                            {fieldWarning && (
                                <Grid container justifyContent="center" spacing={16} xs={12}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="headline"
                                            style={{
                                                marginTop: 50,
                                                textAlign: "center"
                                            }}>
                                            <ErrorOutline />
                                            <br />
                                            Warning: You are missing required field soil data inputs. On the field
                                            dashboard go to Modify Field Location and click on step 3 "Field Soil Data"
                                            and make sure the slope, slope length, surface soil texture, and organic
                                            matter content are completed.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {!success && (
                                <Grid container justifyContent="center" spacing={16} xs={12}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="headline"
                                            gutterBottom
                                            style={{
                                                marginTop: 50,
                                                textAlign: "center"
                                            }}>
                                            {pending && getDescription("fieldprintWait")}
                                            {error && (
                                                <>
                                                    <ErrorOutline color="primary" style={{ fontSize: 48 }} />
                                                    <p>
                                                        The system encountered an error while computing your Fieldprint
                                                        Results. An automated email with error details has been sent to
                                                        our technical team.
                                                    </p>
                                                    <p>
                                                        If available, an additional system message may be displayed
                                                        below. There may be instances where errors occur due to network
                                                        connectivity issues. You might try running your results again
                                                        now or come back to this page later.
                                                    </p>
                                                    <p>
                                                        If needed, contact us at{" "}
                                                        <a
                                                            className={classes.linkColor}
                                                            href="mailto:support@fieldtomarket.org?subject=FPP Error">
                                                            support@fieldtomarket.org
                                                        </a>
                                                        .
                                                    </p>
                                                </>
                                            )}
                                            {syncing && "Syncing account data..."}
                                            {unknown && "Initializing metrics API connection..."}
                                        </Typography>
                                        {pending && (
                                            <div>
                                                <LinearProgress variant="determinate" value={percentComplete} />
                                                <Typography style={{ textAlign: "center" }}>
                                                    <span>Status</span>
                                                    <br />
                                                    <span>{percentComplete}% Complete</span>
                                                    <br />
                                                    {completeStatus}
                                                </Typography>
                                            </div>
                                        )}
                                        {pending && getDescription("fieldprintResults")}
                                        {error && (
                                            <>
                                                <div className={classes.serverError}>
                                                    <ErrorOutline
                                                        color="primary"
                                                        style={{ fontSize: 32, marginRight: 16 }}
                                                    />
                                                    <Typography variant="title" style={{ flex: 1 }}>
                                                        {error}
                                                    </Typography>
                                                </div>
                                                <Typography style={{ textAlign: "center" }}>
                                                    <Button
                                                        color="primary"
                                                        variant="raised"
                                                        onClick={() => this.runMetrics(true)}>
                                                        Try Again
                                                    </Button>
                                                </Typography>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            )}

                            {success && (
                                <div>
                                    <ResponsiveContainer width="100%" height={500}>
                                        <SpiderGram
                                            cropYear={cropYear}
                                            showProject={show_project}
                                            showState={show_state}
                                            showNational={show_national}
                                        />
                                    </ResponsiveContainer>
                                    <Typography gutterBottom>
                                        Benchmarks represent an average based on USDA statistical data for the period
                                        2008-2012 and provide context for how your scores relate to this known point.
                                        Benchmarks should not be interpreted as a specific level of sustainability, or a
                                        performance target. State and National benchmarks that are not shown in the
                                        table or on the spidergram are not available for the applicable metric.
                                        {getValue(cropYear, "project.project_benchmarks") &&
                                            " Project benchmarks represent the average performance across fields enrolled in the " +
                                                getValue(cropYear, "project.project_name") +
                                                " Project."}
                                    </Typography>
                                </div>
                            )}
                        </Grid>
                        <Grid item justifyContent="right" xs={4}>
                            <Form key="crop_year_select">
                                {formApi => (
                                    <form>
                                        <Typography variant="headline">Select Active Year for Data</Typography>
                                        <ActiveYearSelect
                                            hideBenchmarks={this.hideBenchmarks}
                                            classes={classes}
                                            cropYears={cropYears}
                                            cropYear={cropYear}
                                        />
                                    </form>
                                )}
                            </Form>
                            <Form key="benchmark_switch">
                                {formApi => (
                                    <form>
                                        <Typography variant="headline">Display Benchmarks</Typography>
                                        {benchmarkList.map(benchmark => (
                                            <Grid container xs={12}>
                                                <Grid item xs={2}>
                                                    <img
                                                        src={getIcon(benchmark)}
                                                        className={classes.benchmarkToggle}
                                                        alt={this.props.name}
                                                    />
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <BenchmarkSwitch
                                                        benchmark_key={benchmark}
                                                        name={getLabel(benchmark)}
                                                        toggleBenchmark={this.toggleBenchmark}
                                                        onRef={el => (this.bSwitch[benchmark] = el)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ))}
                                        <Grid container xs={12}>
                                            <Grid item xs={12}>
                                                <Typography gutterBottom>
                                                    Use the buttons indicated to plot the state and national benchmarks
                                                    relevant for this Fieldprint.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ActionButton
                                                    onClick={evt => {
                                                        evt.preventDefault();
                                                        this.download();
                                                    }}
                                                    className={classes.borderButton}
                                                    href={
                                                        authState.user &&
                                                        !authState.user.guest &&
                                                        `/reports/${cropYear.id}/pdf`
                                                    }
                                                    target="_blank"
                                                    loading={downloading}
                                                    disabled={!success}
                                                    label="Fieldprint Report (PDF)"
                                                    variant="text"
                                                />
                                            </Grid>
                                            {window.location.hostname === "localhost" && (
                                                <Grid item xs={12}>
                                                    <Button
                                                        component={Link}
                                                        to={"/cropyear/" + cropYear.id + "/report"}
                                                        fullWidth
                                                        color="primary"
                                                        className={classes.borderButton}>
                                                        Fieldprint Report (HTML Debug)
                                                    </Button>
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <Button
                                                    component={Link}
                                                    to={"/cropyear/" + cropYear.id}
                                                    fullWidth
                                                    color="primary"
                                                    className={classes.borderButton}>
                                                    Return to Data Input
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </Grid>
                    </Grid>

                    {success && (
                        <Grid container justifyContent="center" spacing={16} xs={12}>
                            <Grid item xs={12}>
                                <Typography variant="headline">Fieldprint Data per Indicator</Typography>
                            </Grid>

                            {metrics.map(metric => (
                                <MetricPanel metric={metric} cropYear={cropYear} />
                            ))}
                        </Grid>
                    )}
                </Grid>
            </AppContainer>
        );
    }
}

Analysis = connect(
    (state, ownProps) => ({
        cropYear: getCropYear(state, ownProps),
        syncState: state.sync,
        authState: state.auth
    }),
    {
        ...Metric.actions,
        ...CropYear.actions,
        ...FieldActivity.actions,
        ...Fertilizer.actions,
        ...Field.actions
    }
)(Analysis);

export default withStyles(styles)(withRouter(Analysis));
