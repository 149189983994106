import React from "react";
import ReactDOM from "react-dom";
import Autosuggest from "react-autosuggest";
// import "./autosuggest.css";

class RotationAutosuggest extends React.Component {
    constructor() {
        super();

        this.state = {
            value: "",
            suggestions: []
        };
    }

    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase().replace(/\s\s+/g, " ");
        const inputValues = inputValue.split(" ");

        if (!this.props.multiSection) {
            return inputValues.length === 0
                ? this.props.suggestions
                : this.props.suggestions.filter(suggestion => {
                      let found = true;
                      inputValues.forEach(value => {
                          if (suggestion.name.toLowerCase().indexOf(value) === -1) {
                              found = false;
                          }
                      });
                      return found;
                  });
        } else {
            return inputValues.length === 0
                ? this.props.suggestions
                : this.props.suggestions
                      .map(group => {
                          return {
                              group: group.name,
                              options: group.options.filter(option => {
                                  let found = true;
                                  inputValues.forEach(value => {
                                      if (option.name.toLowerCase().indexOf(value) === -1) {
                                          found = false;
                                      }
                                  });
                                  return found;
                              })
                          };
                      })
                      .filter(group => group.options.length > 0);
        }
    };

    getSuggestionValue = suggestion => {
        return suggestion.name;
    };

    renderSuggestion = suggestion => {
        return <span title={this.props.toolTip ? suggestion[this.props.toolTip] : null}>{suggestion.name}</span>;
    };

    renderSectionTitle = section => {
        return (
            <span class="title show" onClick={e => this.closeList(e)}>
                <strong>{section.group}</strong>
            </span>
        );
    };

    closeList = e => {
        let titleNode = e.currentTarget;
        let listNode = ReactDOM.findDOMNode(titleNode).parentNode.nextSibling;

        if (titleNode.classList.contains("hide")) {
            titleNode.classList.remove("hide");
            titleNode.classList.add("show");
        } else {
            titleNode.classList.remove("show");
            titleNode.classList.add("hide");
        }

        if (listNode.classList.contains("show")) {
            listNode.classList.remove("show");
        } else {
            listNode.classList.add("show");
        }
    };

    getSectionSuggestions = section => {
        return section.options;
    };

    // onChange = (event, { newValue, method }) => {
    //     this.setState({
    //         value: newValue
    //     });
    // };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { suggestions } = this.state;
        const { value, placeholder, type, style } = this.props;
        const inputProps = {
            placeholder: placeholder,
            value,
            onChange: this.props.onChange,
            type: type,
            style: style
        };

        return (
            <Autosuggest
                multiSection={this.props.multiSection}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                renderSectionTitle={this.renderSectionTitle}
                getSectionSuggestions={this.getSectionSuggestions}
                onSuggestionSelected={this.props.onSuggestionSelected}
                alwaysRenderSuggestions={this.props.alwaysRenderSuggestions}
                inputProps={inputProps}
            />
        );
    }
}

export default RotationAutosuggest;
