import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../common/withRouter";
import { connect } from "react-redux";
import Grid from "../../common/GridWrapper";
import Button from "../../common/ButtonWrapper";
import withStyles from "@mui/styles/withStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "../../common/TypographyWrapper";
import CircularProgress from "@mui/material/CircularProgress";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BackArrowIcon from "@mui/icons-material/ArrowBack";
// import DownloadIcon from "@mui/icons-material/FileDownload";
import PrintIcon from "@mui/icons-material/Print";
import Add from "@mui/icons-material/AddCircle";
import Remove from "@mui/icons-material/RemoveCircle";

import AppContainer from "../../common/AppContainer";
import * as navActions from "../../common/actions";
import { createSelector } from "../../common/orm";
import { setValue, getValue } from "../../../api/utils";
import { apiFetch } from "../../../api/fetch";

import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, Cell } from "recharts";

import saveAs from "file-saver";

import * as constants from "./constants";
import { FarmHpiSurvey } from "../models";

const getFarmHpiSurvey = FarmHpiSurvey.selectByUrlId(survey => ({
    farm_id: survey.farm.id,
    farm_name: survey.farm.name,
    ...survey.ref
}));

const allCrops = createSelector(schema => {
    return schema.Crops.all()
        .orderBy("name")
        .toModelArray()
        .map(c => ({
            id: c.id,
            name: c.name
        }));
});

const styles = theme => ({
    buttonContainer: {
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "flex-end",
        width: "100%"
    },
    buttonItem: {
        paddingRight: 8,
        paddingLeft: 8,
        paddingTop: 4,
        paddingBottom: 4,
        fontSize: 16
    },
    buttonIcon: {
        fontSize: 20
    },
    sectionTitle: {
        backgroundColor: theme.palette.secondary.light,
        fontSize: 30,
        padding: 12
    },
    expansionPanel: {
        boxShadow: "none"
    },
    expansionSummary: {
        backgroundColor: "#F7F6F6",
        border: "1px solid lightgrey"
    },
    descriptionContainer: {
        padding: 12,
        backgroundColor: "#F7F6F6"
    },
    descriptionText: {
        color: "#41A6FB",
        fontWeight: 600
    },
    improvementContainer: {
        display: "flex",
        width: "100%",
        flexWrap: "nowrap",
        paddingBottom: 10
    },
    improvementIcon: {
        paddingRight: 10,
        paddingTop: 5
    },
    improvementText: {
        flexGrow: 1
    },
    checkCircle: {
        color: "#00A2E5"
    },
    linkColor: {
        color: theme.palette.primary.main
    },
    customToolTip: {
        backgroundColor: "rgba(256,256,256,.6)",
        padding: "10px",
        border: "1px solid black",
        borderRadius: "4px"
    },
    progress: {
        color: "#4CAF50",
        position: "absolute",
        marginTop: 10,
        marginLeft: -50
    }
});

class CustomizedLabel extends Component {
    render() {
        const { x, y, value, myOffset } = this.props;
        const xOffset = myOffset ? myOffset : 0;
        return (
            <text x={x + xOffset} y={y + 20} dy={-4} fontSize="16" fontFamily="sans-serif" fill="#000">
                {value}%
            </text>
        );
    }
}

const CustomToolTip = ({ active, payload, label, classes }) => {
    if (active) {
        if (payload.length === 0) {
            let date = new Date(label);
            let timestamp = date.toLocaleDateString() + " " + date.toLocaleTimeString();
            return (
                <div className={classes.customToolTip}>
                    Timestamp: {timestamp}
                    <br />
                </div>
            );
        } else {
            return (
                <div className={classes.customToolTip}>
                    {payload[0]["payload"]["name"]}: {payload[0]["payload"]["value"]}%
                </div>
            );
        }
    }

    return null;
};

class FarmHpiResults extends Component {
    state = {
        expanded: { habitat_realized_potential: true },
        printReport: false
    };

    componentWillMount() {
        window.scrollTo(0, 0);
    }

    handleToggleOne = index => () => {
        const { expanded } = this.state;
        expanded[index] = !expanded[index];
        this.setState({ expanded });
    };

    get_farm_percent_realized_hpi = () => {
        if (!this.props.farmHpiSurvey || !this.props.farmHpiSurvey.results) {
            return [];
        }

        const results = this.props.farmHpiSurvey.results;

        let scores = [];

        if (results && results.farm.aggregate_score) {
            scores.push({
                id: "hpi_overall",
                name: "Overall HPI",
                value: parseFloat((results.farm.aggregate_score * 100).toFixed(0))
            });
        }

        if (results && results.farm.cultivated) {
            scores.push({
                id: "hpi_cultivated",
                name: "Cultivated",
                value: parseFloat((results.farm.cultivated.farm_percent_realized_hpi * 100).toFixed(0))
            });
        }
        if (results && results.farm.noncultivated) {
            scores.push({
                id: "hpi_noncultivated",
                name: "Non-Cultivated",
                value: parseFloat((results.farm.noncultivated.farm_percent_realized_hpi * 100).toFixed(0))
            });
        }

        return scores;
    };

    get_farm_realized_level = farm_percent_realized_hpi_scores => {
        if (!this.props.farmHpiSurvey || !this.props.farmHpiSurvey.results) {
            return "";
        }

        const score = farm_percent_realized_hpi_scores.filter(s => s.name === "Overall HPI")[0]["value"];
        if (score > 80) {
            return "high";
        } else if (score >= 50 && score <= 80) {
            return "moderate";
        }
        return "low";
    };

    get_land_cover_percent_realized_hpi = () => {
        let scores = [];

        if (!this.props.farmHpiSurvey || !this.props.farmHpiSurvey.results) {
            return [];
        }

        const results = this.props.farmHpiSurvey.results;

        if (results.farm.noncultivated) {
            constants.landcover_groups.forEach(group => {
                if (getValue(results, "farm.noncultivated." + group.field)) {
                    scores.push({
                        id: group.field,
                        name: group.field === "buffer" ? "Edge Features" : group.name,
                        value: parseFloat(
                            (
                                getValue(results, "farm.noncultivated." + group.field + ".farm_percent_realized_hpi") *
                                100
                            ).toFixed(0)
                        )
                    });
                }
            });
        }

        if (results && results.farm.cultivated) {
            scores.push({
                id: "cultivated",
                name: "Cultivated",
                value: parseFloat((results.farm.cultivated.farm_percent_realized_hpi * 100).toFixed(0))
            });
        }

        return scores;
    };

    get_percent_realized_crop = () => {
        if (!this.props.farmHpiSurvey || !this.props.farmHpiSurvey.results) {
            return {};
        }

        var crop_objects = [...this.props.allCrops, { id: "999", name: "Other" }];

        const percent_realized_crop = this.props.farmHpiSurvey.results.cultivated.map(c => {
            let crop_obj = crop_objects.filter(crop => crop.id === c.crop_id)[0];
            return {
                id: [crop_obj.id],
                name: [crop_obj.name],
                value: parseFloat((c.farm_percent_realized_hpi * 100).toFixed(0))
            };
        });

        return percent_realized_crop;
    };

    tickFormatter(e) {
        return e + "%";
    }

    goBackToDataEntry = () => {
        this.props.history.push(`/farm/hpisurvey/${this.props.farmHpiSurvey.id}`);
    };

    downloadData = () => {
        const survey = this.props.farmHpiSurvey;

        const { authState } = this.props,
            { user } = authState;

        var rows = [
            ["Prepared For", user.first_name + " " + user.last_name],
            ["Farm", survey.farm_name],
            ["Year", survey.year]
        ];

        let csv_content = rows.map(e => e.join(",")).join("\n");

        var filename = "farm_year_farm_level_hpi.csv";
        var blob = new Blob([csv_content], { type: "text/plain;charset=utf-8" });
        saveAs(blob, filename);
    };

    printReport = farm_realized_level => {
        this.setState({ printReport: true });

        var crop_objects = [...this.props.allCrops, { id: "999", name: "Other" }];
        const survey = this.props.farmHpiSurvey;

        const { authState } = this.props,
            { user } = authState;

        var obj = {
            user: user.first_name + " " + user.last_name,
            farm_name: survey.farm_name,
            year: survey.year,
            location: [[survey.results.ecoregion.county.name, survey.results.ecoregion.state.name].join(", ")],
            aggregate: {
                overall: {
                    level: {
                        high: farm_realized_level === "high",
                        moderate: farm_realized_level === "moderate",
                        low: farm_realized_level === "low"
                    },
                    label: "Overall HPI",
                    value: survey.results.farm.aggregate_score,
                    color: constants.chart_colors["hpi_overall"]
                },
                cultivated: {
                    label: "Cultivated",
                    value: survey.results.farm.cultivated.farm_percent_realized_hpi,
                    color: constants.chart_colors["hpi_cultivated"]
                }
            },
            crop: {}
        };

        if (survey.results.farm.noncultivated) {
            setValue(obj, "aggregate.noncultivated.value", survey.results.farm.noncultivated.farm_percent_realized_hpi);
            setValue(obj, "aggregate.noncultivated.label", "Non-Cultivated");
            setValue(obj, "aggregate.noncultivated.color", constants.chart_colors["hpi_noncultivated"]);
        } else {
            setValue(obj, "aggregate.noncultivated", null);
        }

        constants.landcover_groups.forEach(landcover_group => {
            if (survey.results.farm.noncultivated) {
                const group_score = survey.results.farm.noncultivated[landcover_group.field];
                if (group_score) {
                    setValue(obj, ["landCoverType", landcover_group.field, "label"].join("."), landcover_group.name);
                    setValue(
                        obj,
                        ["landCoverType", landcover_group.field, "value"].join("."),
                        group_score["farm_percent_realized_hpi"]
                    );
                    setValue(
                        obj,
                        ["landCoverType", landcover_group.field, "color"].join("."),
                        constants.chart_colors[landcover_group.field]
                    );
                }
            }
        });

        setValue(obj, "landCoverType.cultivated", {
            label: "Cultivated",
            value: survey.results.farm.cultivated.farm_percent_realized_hpi,
            color: constants.chart_colors["hpi_cultivated"]
        });

        survey.results.cultivated.forEach(crop_result => {
            const crop_obj = crop_objects.filter(c => c.id === crop_result.crop_id)[0];
            setValue(obj, ["crop", crop_obj.id, "label"].join("."), crop_obj.name);
            setValue(obj, ["crop", crop_obj.id, "value"].join("."), crop_result.farm_percent_realized_hpi);
            setValue(obj, ["crop", crop_obj.id, "color"].join("."), constants.chart_colors[crop_obj.id]);
        });

        const options = {
            method: "POST",
            body: JSON.stringify(obj)
        };

        apiFetch("/v4/calc/FarmHPIReport", options)
            .then(response => response.json())
            .then(responseJson => {
                var link = document.createElement("a");
                link.href = responseJson["url"];
                link.download = "file.pdf";
                link.dispatchEvent(new MouseEvent("click"));

                this.setState({ printReport: false });
            })
            .catch(error => {
                this.setState({ printReport: false });
            });
    };

    render() {
        const { classes, navSelectPage, farmHpiSurvey } = this.props;

        const { printReport } = this.state;

        const farm_percent_realized_hpi_scores = this.get_farm_percent_realized_hpi();

        const farm_realized_level = this.get_farm_realized_level(farm_percent_realized_hpi_scores);

        const land_cover_percent_realized_hpi_scores = this.get_land_cover_percent_realized_hpi();

        const percent_realized_crop_scores = this.get_percent_realized_crop();

        const improving_your_score_data = {
            cultivated: farmHpiSurvey.results.cultivated,
            buffer: farmHpiSurvey.results.noncultivated.buffer,
            forest: farmHpiSurvey.results.noncultivated.forest,
            wetland: farmHpiSurvey.results.noncultivated.wetland,
            grassland: farmHpiSurvey.results.noncultivated.grassland,
            surfacewater: farmHpiSurvey.results.noncultivated.surfacewater
        };

        const title = farmHpiSurvey.year + " Farm-Level HPI for " + farmHpiSurvey.farm_name;

        return (
            <AppContainer
                authenticated
                color="field"
                title={title}
                pageTitle={`Results - ${title}`}
                crumbs={
                    <div>
                        <Link
                            className={classes.linkColor}
                            onClick={() => navSelectPage("farm_")}
                            to={"/farm/" + farmHpiSurvey.farm_id + "/edit"}>
                            {farmHpiSurvey.farm_name}
                        </Link>
                        &nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;{" "}
                        <span className={classes.crumbColor}>Farm-Level HPI Results</span>
                    </div>
                }>
                <Grid container spacing={24}>
                    <Grid item xs={12} className={classes.buttonContainer} style={{ "padding-bottom": "0px" }}>
                        <Button
                            variant="extendedFab"
                            className={classes.buttonItem}
                            onClick={() => this.goBackToDataEntry()}>
                            <BackArrowIcon className={classes.buttonIcon} />
                            &nbsp;Return to Data Entry
                        </Button>
                        {/* <Button
                            variant="extendedFab"
                            className={classes.buttonItem}
                            onClick={() => this.downloadData()}>
                            <DownloadIcon className={classes.buttonIcon} />&nbsp;Download Data
                        </Button> */}
                        <Button
                            variant="extendedFab"
                            className={classes.buttonItem}
                            onClick={() => this.printReport(farm_realized_level)}>
                            <PrintIcon className={classes.buttonIcon} /> &nbsp;Print Report
                        </Button>
                        {printReport && (
                            <>
                                <CircularProgress size={24} className={classes.progress} />
                            </>
                        )}
                    </Grid>

                    <Grid item xs={12} style={{ "padding-top": "0px" }}>
                        <Typography variant="headline">Assessing Your Farm's Habitat Potential</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Field to Market's Biodiversity Metric assesses the habitat potential, or the ability for
                            wildlife to flourish, in and around the working lands of your farm. Utilizing an index, this
                            assessment evaluates how your management decisions contribute to supporting wildlife
                            habitat.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Higher scores are desirable and indicate a greater potential to support wildlife habitat.
                            Separate scores are available for cultivated cropland and non-cultivated lands (e.g.
                            pasture, forestland and water as applicable) as well as an aggregated score for the whole
                            farm.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Scores greater than 80% demonstrate that your farm has maximized opportunities for
                            biodiversity to flourish. If your assessment, finds values between 50-80%, there are still
                            opportunities for your farm to contribute to biodiversity conservation. Whereas scores less
                            than 50% represent significant opportunities for improving habitat potential.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="display1" className={classes.sectionTitle}>
                            RESULTS
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Accordion
                            className={classes.expansionPanel}
                            expanded={this.state.expanded["habitat_realized_potential"]}
                            onClick={this.handleToggleOne("habitat_realized_potential")}>
                            <AccordionSummary className={classes.expansionSummary} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="title">
                                    Habitat Realized Potential (Aggregated Cultivated and Uncultivated Areas)
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            {farmHpiSurvey.farm_name} has realized{" "}
                                            {
                                                farm_percent_realized_hpi_scores.filter(
                                                    s => s.name === "Overall HPI"
                                                )[0]["value"]
                                            }
                                            % of its potential to support wildlife habitat across cultivated and
                                            non-cultivated lands. At 65%, your Farm-Level habitat potential is in the
                                            Moderate range (50-80%). Learn more about opportunities to improve habitat
                                            potential on your farm.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ display: "flex", "flex-wrap": "wrap" }}>
                                            <div style={{ flex: "0 0 auto" }}>
                                                <ResponsiveContainer
                                                    width={
                                                        farm_percent_realized_hpi_scores.length * 100 +
                                                        farm_percent_realized_hpi_scores.length * 4 * 2 +
                                                        40
                                                    }
                                                    height={350}>
                                                    <BarChart
                                                        layout="horizontal"
                                                        width="100%"
                                                        height={350}
                                                        data={farm_percent_realized_hpi_scores}
                                                        margin={{
                                                            top: 20,
                                                            right: 20,
                                                            bottom: 20,
                                                            left: -12
                                                        }}>
                                                        <CartesianGrid stroke="#f5f5f5" />
                                                        <YAxis domain={[0, 100]} tickFormatter={this.tickFormatter} />
                                                        <XAxis
                                                            width={180}
                                                            dx={-10}
                                                            interval={0}
                                                            dataKey="name"
                                                            type="category"
                                                        />
                                                        <Tooltip content={<CustomToolTip classes={classes} />} />
                                                        <Bar
                                                            // radius={[20, 20, 0, 0]}
                                                            dataKey="value"
                                                            label={<CustomizedLabel myOffset={20 - 4} />}>
                                                            {farm_percent_realized_hpi_scores.map(
                                                                farm_percent_realized_hpi_score => {
                                                                    return (
                                                                        <Cell
                                                                            fill={
                                                                                constants.chart_colors[
                                                                                    farm_percent_realized_hpi_score[
                                                                                        "id"
                                                                                    ]
                                                                                ]
                                                                            }
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                        </Bar>
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                            <div style={{ flex: "1 1 250px" }}>
                                                <Typography variant="headline">
                                                    {constants.farm_realized_levels[farm_realized_level]["title"]}
                                                </Typography>
                                                <Typography variant="body1">
                                                    {constants.farm_realized_levels[farm_realized_level]["text"]}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                        <Accordion className={classes.expansionPanel}>
                            <AccordionSummary className={classes.expansionSummary} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="title">Habitat Realized Potential by Land Cover Type</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            Opportunities to maximize habitat potential vary by land cover types.
                                            Analyze opportunities to improve biodiversity by comparing realized habitat
                                            potential across crops.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ResponsiveContainer
                                            width={
                                                land_cover_percent_realized_hpi_scores.length * 100 +
                                                land_cover_percent_realized_hpi_scores.length * 4 * 2 +
                                                40
                                            }
                                            height={350}>
                                            <BarChart
                                                layout="horizontal"
                                                width={land_cover_percent_realized_hpi_scores.length * 100 + 40}
                                                height={350}
                                                data={land_cover_percent_realized_hpi_scores}
                                                margin={{
                                                    top: 20,
                                                    right: 20,
                                                    bottom: 20,
                                                    left: -12
                                                }}>
                                                <CartesianGrid stroke="#f5f5f5" />
                                                <YAxis domain={[0, 100]} tickFormatter={this.tickFormatter} />
                                                <XAxis
                                                    width={180}
                                                    dx={-10}
                                                    interval={0}
                                                    dataKey="name"
                                                    type="category"
                                                />
                                                <Tooltip content={<CustomToolTip classes={classes} />} />
                                                <Bar
                                                    // radius={[20, 20, 0, 0]}
                                                    dataKey="value"
                                                    label={<CustomizedLabel myOffset={20 - 4} />}>
                                                    {land_cover_percent_realized_hpi_scores.map(
                                                        land_cover_percent_realized_hpi_score => {
                                                            return (
                                                                <Cell
                                                                    fill={
                                                                        constants.chart_colors[
                                                                            land_cover_percent_realized_hpi_score["id"]
                                                                        ]
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )}
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                        <Accordion className={classes.expansionPanel}>
                            <AccordionSummary className={classes.expansionSummary} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="title">
                                    Realized Habitat Potential - Cultivated Areas by Crop
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            Consider what opportunities different crop rotations and management
                                            decisions offer to maximize habitat potential by comparing performance
                                            across your farm's cultivated acreage.
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <ResponsiveContainer
                                            width={
                                                percent_realized_crop_scores.length * 100 +
                                                percent_realized_crop_scores.length * 4 * 2 +
                                                40
                                            }
                                            height={350}>
                                            <BarChart
                                                layout="horizontal"
                                                width={percent_realized_crop_scores.length * 100 + 40}
                                                height={500}
                                                data={percent_realized_crop_scores}
                                                margin={{
                                                    top: 20,
                                                    right: 20,
                                                    bottom: 20,
                                                    left: -12
                                                }}>
                                                <CartesianGrid stroke="#f5f5f5" />
                                                <YAxis domain={[0, 100]} tickFormatter={this.tickFormatter} />
                                                <XAxis
                                                    width={180}
                                                    dx={-10}
                                                    interval={0}
                                                    dataKey="name"
                                                    type="category"
                                                />
                                                <Tooltip content={<CustomToolTip classes={classes} />} />
                                                <Bar
                                                    // radius={[20, 20, 0, 0]}
                                                    dataKey="value"
                                                    label={<CustomizedLabel myOffset={20 - 4} />}>
                                                    {percent_realized_crop_scores.map(percent_realized_crop_score => {
                                                        return (
                                                            <Cell
                                                                fill={
                                                                    constants.chart_colors[
                                                                        percent_realized_crop_score["id"]
                                                                    ]
                                                                }
                                                            />
                                                        );
                                                    })}
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="display1" className={classes.sectionTitle}>
                            LEARNING
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Accordion className={classes.expansionPanel}>
                                    <AccordionSummary
                                        className={classes.expansionSummary}
                                        expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="title">
                                            Why is Biodiversity important on my farm?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={8}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" gutterBottom>
                                                    Farmland is valued for more than being the place where food, feed,
                                                    fiber and fuel are grown. Most often nestled in rural communities
                                                    where hunting, fishing, camping and other outdoor activities are
                                                    enjoyed, farms can support a great deal of biodiversity. In many
                                                    ways, protecting biodiversity on and near farms is also protecting
                                                    the rural way of life. Of the eight environmental outcomes measured
                                                    in the Fieldprint Platform, biodiversity is probably the most
                                                    visible and easiest for consumers to understand.
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    Farms can be excellent habitat for wildlife, offering opportunities
                                                    for wildlife to forage, nest and breed, both on land and in the
                                                    water. Although the term “forage” may invoke images of deer
                                                    destroying cornfields or redwing blackbirds ruining sorghum yields,
                                                    providing food for wildlife does not necessarily imply crop losses.
                                                    Non-cultivated areas of the farm can provide excellent forage to a
                                                    variety of wildlife without risking damage to crops.
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    Practices that promote biodiversity often have other agronomic and
                                                    environmental benefits, such as preventing soil erosion and
                                                    protecting water quality. Native trees and shrubs planted as field
                                                    windbreaks to reduce wind erosion also offer cover for nesting and
                                                    breeding birds. Grass waterways installed to reduce gulley erosion
                                                    can be great habitat and forage for gamebirds and pollinators.
                                                    Riparian buffers installed to remove nutrients and sediment from
                                                    surface runoff or tile drainage can be planted with native species
                                                    of trees, shrubs, grasses and forbs.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12}>
                                <Accordion className={classes.expansionPanel}>
                                    <AccordionSummary
                                        className={classes.expansionSummary}
                                        expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="title">Improving Your Score</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={8}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" gutterBottom>
                                                    While these general recommendations identify opportunities within
                                                    each land cover type to promote biodiversity, consult with your
                                                    trusted adviser on recommendations tailored to your farm's unique
                                                    topography, climatic conditions and crop rotation.
                                                </Typography>
                                            </Grid>

                                            {[
                                                "cultivated",
                                                "buffer",
                                                "forest",
                                                "grassland",
                                                "surfacewater",
                                                "wetland"
                                            ].map(type => {
                                                return (
                                                    <>
                                                        {improving_your_score_data[type] && (
                                                            <Grid item xs={12}>
                                                                <Accordion className={classes.expansionPanel}>
                                                                    <AccordionSummary
                                                                        className={classes.expansionSummary}
                                                                        expandIcon={
                                                                            this.state.expanded[type] ? (
                                                                                <Remove />
                                                                            ) : (
                                                                                <Add />
                                                                            )
                                                                        }
                                                                        onClick={this.handleToggleOne(type)}>
                                                                        <Typography variant="title">
                                                                            {
                                                                                constants.improvement_section_headers[
                                                                                    type
                                                                                ]
                                                                            }
                                                                        </Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Typography variant="body1">
                                                                            <ul>
                                                                                {constants.improvements[type].map(i => {
                                                                                    return <li>{i}</li>;
                                                                                })}
                                                                            </ul>
                                                                        </Typography>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Grid>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12}>
                                <Accordion className={classes.expansionPanel}>
                                    <AccordionSummary
                                        className={classes.expansionSummary}
                                        expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="title">
                                            How does the farm-level biodiversity assessment differ from the field-level
                                            biodiversity assessment?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={8}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" gutterBottom>
                                                    The farm-level biodiversity assessment considers both cultivated and
                                                    non-cultivated areas of the farm, whereas field-level assessment
                                                    only measures the potential for wildlife habitat in cultivated
                                                    fields. Field-level biodiversity is affected by changes in land use,
                                                    pest management, residue management and irrigation for crop
                                                    cultivation. Like cultivated areas, non-cultivated areas of the
                                                    farm, such as streams, ponds, forest, grassland and edge-of-field
                                                    areas can be managed in ways that provide food, cover and nesting
                                                    areas for wildlife, including pollinators.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12}>
                                <Accordion className={classes.expansionPanel}>
                                    <AccordionSummary
                                        className={classes.expansionSummary}
                                        expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="title">
                                            What additional resources are available to explore opportunities to improve
                                            my farm’s habitat potential?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={8}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" gutterBottom>
                                                    Multiple members of Field to Market provide resources and guidance
                                                    for creating and protecting wildlife habitat:
                                                </Typography>

                                                <Typography variant="body1" gutterBottom>
                                                    <ul>
                                                        <li>
                                                            <a
                                                                target="_blank"
                                                                href="https://www.fsa.usda.gov/programs-and-services/conservation-programs/conservation-reserve-program/index"
                                                                rel="noopener noreferrer">
                                                                USDA-Farm Service Agency Conservation Reserve Program
                                                            </a>{" "}
                                                            is a voluntary program that pays landowners and operators to
                                                            retire environmentally sensitive land from cropland
                                                            production and convert it to upland game wildlife habitat.
                                                        </li>
                                                        <li>
                                                            Many conservation districts across the U.S. work with
                                                            landowners to restore and protect resources that support
                                                            wildlife. Find your district and representative{" "}
                                                            <a
                                                                target="_blank"
                                                                href="https://www.nacdnet.org/general-resources/conservation-district-directory"
                                                                rel="noopener noreferrer">
                                                                here
                                                            </a>
                                                            .
                                                        </li>
                                                        <li>
                                                            Several edge of field practices that benefit wildlife were
                                                            described by Purdue University in{" "}
                                                            <a
                                                                target="_blank"
                                                                href="https://www.extension.purdue.edu/extmedia/FNR/FNR-193-W.pdf"
                                                                rel="noopener noreferrer">
                                                                The Basics of Managing Wildlife on Agricultural Lands
                                                            </a>{" "}
                                                            by Brian J. MacGowan and Brian K. Miller, Department of
                                                            Forestry and Natural Resources, Purdue University, West
                                                            Lafayette, IN.
                                                        </li>
                                                        <li>
                                                            <a
                                                                target="_blank"
                                                                href="https://www.ducks.org/conservation/how-du-conserves-wetlands-and-waterfowl"
                                                                rel="noopener noreferrer">
                                                                Ducks Unlimited's conservation programs
                                                            </a>{" "}
                                                            have always had a strong biological foundation. That science
                                                            and research tradition continues today with hundreds of
                                                            studies to address the habitat needs of waterfowl. DU has
                                                            embraced an approach of constant monitoring and evaluation
                                                            which allows for continual refinement of its habitat
                                                            programs. In the end, such an approach ensures that each and
                                                            every dollar invested in conservation programs is used as
                                                            effectively and efficiently as possible. Land protection is
                                                            a critical tool by which Ducks Unlimited conserves{" "}
                                                            <a
                                                                target="_blank"
                                                                href="https://www.ducks.org/conservation/waterfowl-habitat"
                                                                rel="noopener noreferrer">
                                                                waterfowl habitat
                                                            </a>{" "}
                                                            throughout North America to learn more, visit the Ducks
                                                            Unlimited website{" "}
                                                            <a
                                                                target="_blank"
                                                                href="https://www.ducks.org/conservation/land-protection"
                                                                rel="noopener noreferrer">
                                                                here
                                                            </a>
                                                            .
                                                        </li>
                                                        <li>
                                                            <a
                                                                target="_blank"
                                                                href="https://pheasantsforever.org/getdoc/d68502f8-71ec-423c-a8a0-dbaf1e2851b2/Farm-Bill-Biologists.aspx"
                                                                rel="noopener noreferrer">
                                                                Pheasants Forever and Quail Forever Farm Bill Biologists
                                                            </a>{" "}
                                                            are specialized consultants in conservation programs and
                                                            habitat planning. The purpose of Farm Bill Biologists is to
                                                            assist landowners in designing, developing, and funding
                                                            habitat improvements on private lands. Pheasants Forever and
                                                            Quail Forever Farm Bill Biologists possess the knowledge of
                                                            federal, state, and local programs to assist landowners in
                                                            finding the right program to meet their personal habitat and
                                                            land use goals. Through a unique partnership, Farm Bill
                                                            Biologists are located in local USDA service centers in
                                                            priority habitat areas throughout the pheasant and quail
                                                            range.
                                                        </li>
                                                        <li>
                                                            Pheasants Forever and Quail Forever{" "}
                                                            <a
                                                                target="_blank"
                                                                href="https://pheasantsforever.org/getdoc/f4d2be16-a87d-44f2-9394-5df40eba9584/Habitat-Specialists1.aspx"
                                                                rel="noopener noreferrer">
                                                                Habitat Specialists
                                                            </a>{" "}
                                                            do the boots-on-the-ground work that is upland habitat
                                                            creation and improvement. In select areas of the country,
                                                            they are available for hire to complete wildlife habitat
                                                            projects and wildlife habitat maintenance. In other areas,
                                                            Pheasants Forever has partnered with state wildlife agencies
                                                            to place Habitat Specialists in areas where they work on
                                                            public land habitat management and improvement.
                                                        </li>
                                                    </ul>
                                                </Typography>

                                                <Typography variant="body1" gutterBottom>
                                                    We consider protecting pollinators as a critical opportunity that
                                                    creates habitat for both natural pollinators and many small game
                                                    species. There are several educational resources available through
                                                    USDA and their partners, a library of these resources can be found
                                                    in the{" "}
                                                    <a
                                                        target="_blank"
                                                        href="https://www.pollinator.org/learning-center/farming-ranching"
                                                        rel="noopener noreferrer">
                                                        Pollinator Partnership Learning Center for Farming and Ranching
                                                    </a>
                                                    .
                                                </Typography>

                                                <Typography variant="body1" gutterBottom>
                                                    <ul>
                                                        <li>
                                                            <a
                                                                target="_blank"
                                                                href="https://www.nrcs.usda.gov/wps/portal/nrcs/main/national/plantsanimals/pollinate/farmers/"
                                                                rel="noopener noreferrer">
                                                                Print and other USDA- Natural Resources Conservation
                                                                Service resources
                                                            </a>{" "}
                                                            to help you learn more about the importance of pollinators
                                                            to agriculture and creating bee friendly habitats.
                                                        </li>
                                                        <li>
                                                            <a
                                                                target="_blank"
                                                                href="https://xerces.org/pollinator-resource-center/general"
                                                                rel="noopener noreferrer">
                                                                Science-based information
                                                            </a>{" "}
                                                            from The Xerces Society on creating pollinator habitats.
                                                        </li>
                                                    </ul>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

FarmHpiResults = connect(
    (state, ownProps) => ({
        farmHpiSurvey: getFarmHpiSurvey(state, ownProps),
        syncState: state.sync,
        authState: state.auth,
        allCrops: allCrops(state)
    }),
    {
        ...navActions
    }
)(FarmHpiResults);

export default withStyles(styles)(withRouter(FarmHpiResults));
