import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import { FormField } from "react-form";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";

const styles = theme => ({
    grid: {
        width: "50%",
        marginTop: "4%"
    },
    button: {
        "& button": {
            border: 0
        },
        "& button:hover": {
            backgroundColor: "rgba(60, 138, 199, 0.08)"
        }
    }
});

class DatePickerWrapper extends Component {
    // The first commit of @material-ui/core
    //const [selectedDate, setSelectedDate] = React.useState(new Date());

    handleDateChange = date => {
        // setSelectedDate(date);

        const { fieldApi, eventHandle } = this.props;
        const { setValue } = fieldApi;

        if (date) {
            setValue(date._d.toLocaleDateString());

            if (eventHandle) eventHandle(date._d.toLocaleDateString());
        }
    };

    render() {
        const { fieldApi, classes, ...rest } = this.props;
        var value = fieldApi.getValue();

        return (
            <MuiDatePicker
                value={value !== undefined ? value : null}
                onChange={this.handleDateChange}
                KeyboardButtonProps={{
                    "aria-label": "change date"
                }}
                DialogProps={{ className: classes.button }}
                okLabel={""}
                cancelLabel={""}
                leftArrowIcon={<ArrowBack />}
                rightArrowIcon={<ArrowForward />}
                autoOk={true} //autoselect date on acceptance of component
                refuse={rest.required ? value : ""}
                variant={"contained"}
                {...rest}
            />
        );
    }
}

const DatePicker = FormField(DatePickerWrapper);

export default withStyles(styles)(DatePicker);
