import React, { Component } from "react";
import { Form } from "react-form";
import withRouter from "../../../common/withRouter";
import { connect } from "react-redux";
import classNames from "classnames";

import Button from "../../../common/ButtonWrapper";
import { Dialog, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "../../../common/TypographyWrapper";

import withStyles from "@mui/styles/withStyles";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import Select from "../../../common/Select";
import Switch from "../../../common/Switch";
import { getValue, setValue } from "../../../../api/utils";

import { MAKE_OPTIONS, SURFACEWATER } from "../../../../api/constants";
import { createSelector } from "../../../common/orm";

import { FarmHpiSurvey, FarmHpiCultivatedLandQuestionnaire } from "../../models";

const allCultivatedLandQuestionnaires = createSelector(schema => {
    return schema.FarmHpiCultivatedLandQuestionnaire.all()
        .toModelArray()
        .map(q => ({ ...q._fields }));
});

const styles = {
    dialogMin: {
        minWidth: "400px"
    },
    flex: {
        flex: 1
    },
    button: {
        float: "right"
    },
    gutterTop: {
        marginTop: ".6em"
    },
    minWidth: {
        minWidth: 600
    },
    cancelBorder: {
        border: "1px solid"
    },
    buttonMargin: {
        marginRight: 24,
        marginBottom: 16
    }
};

class AddCropDialog extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            crop: null,
            use_default: false,
            submitClicked: false
        };
    }

    handleSwitchChange = a => {
        console.log("--> handleSwitchChange");
        this.setState({ use_default: a });
    };

    handleCropChange = value => {
        this.setState({
            crop_id: value
        });
    };

    getFieldOptions = () => {
        var field_ids = [];
        this.props.cropyears.forEach(c => {
            if (field_ids.indexOf(c.field.id) === -1) {
                field_ids.push(c.field.id);
            }
        });
        var field_options = [];
        field_ids.forEach(fid => {
            var field_name = this.props.allFields.filter(f => f.id === fid)[0]["name"];
            this.props.cropyears
                .filter(c => c.field.id === fid)
                .forEach(c => {
                    var id = c.id;
                    var name = field_name + " - " + c.year;
                    field_options.push({ id: id, name: name });
                });
        });
        return field_options;
    };

    errorValidator = values => {
        console.log("errorValidator");

        const isRequired = path => {
            let val = getValue(values, path);
            setValue(valObj, path, val || val === false ? null : "Required");
        };

        var valObj = {};
        isRequired("crop_id");
        if (getValue(values, "use_default") === true) {
            isRequired("cropyear_id");
        }
        return valObj;
    };

    errorValidatorForDefault = values => {
        console.log("--> errorValidator");

        var required = 0;
        var missing = 0;

        const validateArea = path => {
            required += 1;
            let val = getValue(values, path);
            if (!val) {
                setValue(valObj, path, "Required");
                missing += 1;
                return;
            }
            const number = parseFloat(val);
            if (number <= 0) {
                setValue(valObj, path, "Must be greater than 0 ac.");
                return;
            }
            setValue(valObj, path, null);
        };

        const validateAreaConverted = path => {
            required += 1;
            let val = getValue(values, path);
            if (!val) {
                setValue(valObj, path, "Required");
                missing += 1;
                return;
            }
            const number = parseFloat(val);
            if (number <= 0) {
                setValue(valObj, path, "Must be greater than or equal to 0 ac.");
                return;
            }
            setValue(valObj, path, null);
        };

        const validateTrueFalse = path => {
            required += 1;
            let val = getValue(values, path);
            if (val === null) {
                missing += 1;
                setValue(valObj, path, "Required");
                return;
            }
            setValue(valObj, path, null);
        };

        const isRequired = path => {
            required += 1;
            let val = getValue(values, path);
            if (!val) {
                missing += 1;
                setValue(valObj, path, "Required");
                return;
            }
            setValue(valObj, path, null);
        };

        var valObj = {};

        validateArea("plantable_area");
        validateTrueFalse("conversion");

        if (getValue(values, "conversion") === true) {
            isRequired("previous_land_cover");
            validateAreaConverted("field_area_converted");
        }

        isRequired("rotation_practice");
        isRequired("tillage_practice");
        isRequired("cover_crop");
        validateTrueFalse("nutrient_management_plan");
        isRequired("pest_management");
        isRequired("tile_drainage");
        validateTrueFalse("majority_irrigated");

        if (getValue(values, "majority_irrigated") === true) {
            isRequired("water_source");

            if (SURFACEWATER.includes(values.water_source) === true) {
                validateTrueFalse("irrigation_conservation");
            } else {
                setValue(valObj, "irrigation_conservation", null);
            }
        }

        return {
            required: required,
            missing: missing
        };
    };

    handleSaveClick = values => {
        console.log("handleSaveClick");

        function pad(num, len) {
            return Array(len + 1 - num.toString().length).join("0") + num;
        }

        var save_values = {
            survey: this.props.farmHpiSurvey.id,
            farm_id: this.props.farmHpiSurvey.farm.id,
            crop_id: values.crop_id
        };

        try {
            let allCQ = this.props.allCQ;
            allCQ.forEach(q => {
                if (q["survey_id"] === undefined) {
                    q["survey_id"] = q["survey"];
                }
            });

            let code = "01";
            let codes = allCQ.filter(q => q.survey_id === this.props.farmHpiSurvey.id).map(c => parseInt(c.code));
            if (codes.length > 0) {
                code =
                    codes.sort(function (a, b) {
                        return b - a;
                    })[0] + 1;
                code = pad(code, 2);
            }

            save_values["code"] = code;

            if (values["use_default"] === true) {
                const cropyear = this.props.cropyears.filter(c => c.id === values.cropyear_id)[0];

                let plantable_area = cropyear.field.size;
                var conversion = getValue(cropyear, "biodiversity.land_cover_change");
                var previous_land_cover = getValue(cropyear, "biodiversity.previous_land_cover");
                var field_area_converted = getValue(cropyear, "biodiversity.field_area_converted");
                var wildlife_habitat_ids =
                    getValue(cropyear, "biodiversity.wildlife_habitat_ids") === null ||
                    getValue(cropyear, "biodiversity.wildlife_habitat_ids") === ""
                        ? null
                        : getValue(cropyear, "biodiversity.wildlife_habitat_ids").join(",");

                //  Rotation practice has different options for alfalfa.  If just one of the crops (new, default) is alfalfa,
                //  set the rotation practice to null and add 1 to missing.
                var rotation_practice = null;
                var alfalfa_cnt = [values.crop_id, cropyear.crop].filter(e => e === "8").length;
                if (alfalfa_cnt === 0 || alfalfa_cnt === 2) {
                    rotation_practice = getValue(cropyear, "biodiversity.rotation_practice");
                }
                if (alfalfa_cnt === 1) {
                    // do nothing
                    rotation_practice = null;
                }
                if (alfalfa_cnt === 2) {
                    rotation_practice = getValue(cropyear, "biodiversity.rotation_practice");
                }

                var tillage_practice = getValue(cropyear, "biodiversity.tillage_class");
                var cover_crop = getValue(cropyear, "biodiversity.cover_crop") === true ? "1" : "3";
                var nutrient_management_plan = getValue(cropyear, "biodiversity.nutrient_management_plan");
                var nutrient_4_rs =
                    getValue(cropyear, "biodiversity.nutrient_4_rs") === null ||
                    getValue(cropyear, "biodiversity.nutrient_4_rs") === ""
                        ? null
                        : getValue(cropyear, "biodiversity.nutrient_4_rs").join(",");

                let pest_management = getValue(cropyear, "waterquality.pest_management_id");
                let tile_drainage = getValue(cropyear, "waterquality.tile_drainage");

                let majority_irrigated = values.crop_id === "39" ? true : getValue(cropyear, "is_irrigated");
                let water_source = null;
                let irrigation_conservation = null;

                if (majority_irrigated) {
                    water_source = cropyear.activities.filter(a => a.type === "irrigation")[0].ref.irrigation.wateruse
                        .water_source;
                    if (SURFACEWATER.includes(water_source)) {
                        irrigation_conservation = cropyear.activities.filter(a => a.type === "irrigation")[0].ref
                            .irrigation.biodiversity.irrigation_conservation;
                    }
                }

                save_values = {
                    ...save_values,
                    plantable_area: plantable_area,
                    conversion: conversion,
                    previous_land_cover: previous_land_cover,
                    field_area_converted: field_area_converted,
                    wildlife_habitat: wildlife_habitat_ids,
                    rotation_practice: rotation_practice,
                    tillage_practice: tillage_practice,
                    cover_crop: cover_crop,
                    nutrient_management_plan: nutrient_management_plan,
                    nutrient_4_rs: nutrient_4_rs,
                    pest_management: pest_management,
                    tile_drainage: tile_drainage,
                    majority_irrigated: majority_irrigated,
                    water_source: water_source,
                    irrigation_conservation: irrigation_conservation
                };

                var isComplete = this.errorValidatorForDefault(save_values);
                save_values["missing"] = isComplete.missing;
                save_values["required"] = isComplete.required;
                save_values["completed"] = isComplete.missing === 0;
            } else {
                save_values["missing"] = 1;
                save_values["required"] = 1;
                save_values["completed"] = false;
            }
        } catch (err) {
            console.log(err);
        }

        let promises = [];
        promises.push(this.props.ormFarmHpiSurveyUpdate({ id: this.props.farmHpiSurvey.id, results: null }));
        promises.push(this.props.ormFarmHpiCultivatedLandQuestionnaireCreateRemoteFirst(save_values));
        Promise.all(promises).then(results => {
            this.props.onSaveClick(results[1]);
        });
    };

    handleCancelClick = () => {
        this.props.onCancelClick();
    };

    componentWillMount() {
        const allCQ = this.props.allCQ.filter(q => q.id === this.props.questionnaire_id);
        this.setState({
            allCQ: allCQ
        });
    }

    render() {
        const { classes, open, allCrops } = this.props;
        const currentCropIds = this.props.farmHpiSurvey.cultivated.map(q => q.crop_id);
        var crops = allCrops.map(c => ({ id: c.id, name: c.name }));
        crops = crops.filter(c => currentCropIds.indexOf(c.id) === -1);

        var field_options = this.getFieldOptions();

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Form
                    getApi={el => (this.formApi = el)}
                    dontValidateOnMount="true"
                    validateOnSubmit="true"
                    validateError={values => this.errorValidator(values)}
                    defaultValues={{ use_default: false, crop_id: "", cropyear_id: "" }}
                    onSubmit={values => this.handleSaveClick(values)}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Toolbar>
                                <Typography variant="title" className={classes.flex}>
                                    Add Crop
                                </Typography>
                                <IconButton onClick={() => this.handleCancelClick()} size="large">
                                    <Close />
                                </IconButton>
                            </Toolbar>
                            <DialogContent className={classes.dialogMin}>
                                <DialogContentText>
                                    <Select
                                        field="crop_id"
                                        label="Crop"
                                        options={MAKE_OPTIONS(crops)}
                                        fullWidth
                                        margin="normal"
                                        eventHandle={value => this.handleCropChange(value)}
                                    />

                                    <Switch
                                        field="use_default"
                                        name="switch"
                                        label="Would you like to default answers from a field you have entered into the platform? The answers you provide for this crop are generalized for all fields for that crop in the survey year. Copying data from an existing field simply pre-populates the data from a particular field to save data entry time."
                                        eventHandle={value => this.handleSwitchChange(value)}
                                    />

                                    {this.state.use_default === true && (
                                        <Select
                                            field="cropyear_id"
                                            label="Field to use as default"
                                            options={MAKE_OPTIONS(field_options)}
                                            fullWidth
                                            margin="normal"
                                            disabled={!this.state.use_default}
                                        />
                                    )}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => this.handleCancelClick()}
                                    color="primary"
                                    className={classNames(classes.cancelBorder, classes.buttonMargin)}>
                                    Cancel
                                </Button>

                                <Button type="submit" className={classes.buttonMargin} variant="raised" color="primary">
                                    Save
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Form>
            </Dialog>
        );
    }
}

AddCropDialog = connect(
    (state, ownProps) => ({
        allCQ: allCultivatedLandQuestionnaires(state)
    }),
    {
        ...FarmHpiSurvey.actions,
        ...FarmHpiCultivatedLandQuestionnaire.actions
    }
)(AddCropDialog);

export default withStyles(styles)(withRouter(AddCropDialog));
