import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import withRouter from "../common/withRouter";
import { Dialog, DialogContent } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "../common/TypographyWrapper";
import Close from "@mui/icons-material/CloseOutlined";
import ContentCopyIcon from "@mui/icons-material/FileCopyOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import TemplateLibraryTable from "./tables/TemplateLibraryTable";
import * as utils from "./CropManagementUtils";

const styles = {
    flex: {
        flex: 1
    },
    contentPadding: {
        padding: "24px"
    },
    toolbarBackground: {
        backgroundColor: "#ff8300"
    },
    inlineHeader: {
        padding: 4,
        backgroundColor: "#0096d6",
        color: "white",
        fontSize: 16
    }
};

class PreviewRotation extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    prepEvents(rotationSystem) {
        var events = [];
        var that = this;

        function getOperationTip(id) {
            if (that.props.operations) {
                let matches = that.props.operations.filter(operation => operation.id.toString() === id.toString());
                if (matches.length > 0) {
                    return matches[0].opNotes;
                }
            }
            return null;
        }

        if (rotationSystem && rotationSystem.events) {
            var temp_events = utils.updateRotationEventsWithNewRealDates(
                rotationSystem["events"],
                rotationSystem["firstDate"]
            );

            events = temp_events.map((event, i) => ({
                id: i,
                index: event["index"] === undefined ? utils.GetGUID() : event["index"],
                date: event["date"],
                day: [event["date"].split("-")[1], event["date"].split("-")[2]].join("-"),
                year: "Year " + parseInt(event["date"].split("-")[0]).toString(),
                operationName: event.operation ? event.operation.name : "",
                operationId: event.operation ? event.operation.id : "",
                stir: event.operation ? event.operation.stir : "",
                crop: event.crop ? event.crop.name : "",
                yield: event.crop ? event.crop.defaultYield : "",
                yieldUnit: event.crop ? event.crop.yieldUnit : "",
                residue: event.residue ? event.residue.name : "",
                residueAmount: event.residue ? event.operation.resAdded : "",
                operationTip: event.operation ? getOperationTip(event.operation.id) : ""
            }));

            // sort by date
            events.sort(function (a, b) {
                a = a.date.split("-").join("");
                b = b.date.split("-").join("");
                return a > b ? 1 : a < b ? -1 : 0;
            });
        }
        return events;
    }

    render() {
        console.log("--> PreviewRotation");
        const { classes, rotationSystem, showCopy, inline } = this.props;
        const events = this.prepEvents(rotationSystem);
        const rotationSystemName = rotationSystem && rotationSystem.name ? `(${rotationSystem.name})` : "";

        const columnData = [
            { id: "year", numeric: false, disablePadding: false, label: "Year", allowSort: false },
            { id: "day", numeric: false, disablePadding: false, label: "Day", allowSort: false },
            { id: "operationName", numeric: false, disablePadding: false, label: "Operation Name", allowSort: false },
            !inline && { id: "crop", numeric: false, disablePadding: false, label: "Crop", allowSort: false },
            !inline && { id: "residue", numeric: false, disablePadding: false, label: "Residue", allowSort: false },
            inline && {
                id: "cropOrResidue",
                numeric: false,
                disablePadding: false,
                label: "Crop / Residue",
                allowSort: false
            },
            { id: "residueAmount", numeric: false, disablePadding: false, label: "Residue Amount", allowSort: false }
        ].filter(col => !!col);

        if (inline) {
            return (
                <>
                    <Typography variant="title" className={classes.inlineHeader}>
                        Rotation System {rotationSystemName}
                    </Typography>
                    <TemplateLibraryTable
                        orderBy={""}
                        useEmptyRows={false}
                        data={events.map(event => ({
                            ...event,
                            cropOrResidue: event.crop || event.residue
                        }))}
                        columnData={columnData}
                        noRecordsMessage={"No rotation events."}
                        toolTips={[{ column: "operationName", tip: "operationTip" }]}
                        compact
                        noPagination
                    />
                </>
            );
        }

        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={"100%"}>
                <Toolbar>
                    <Typography variant="title" className={classes.flex}>
                        Preview Rotation System
                    </Typography>
                    {showCopy === true && (
                        <Tooltip title="Copy">
                            <IconButton
                                onClick={() => this.props.onSaveManagementClick(rotationSystem.id)}
                                size="large">
                                <ContentCopyIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                    )}

                    <Tooltip title="Close">
                        <IconButton onClick={() => this.props.onPreviewCancelClick()} size="large">
                            <Close color="primary" />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
                <DialogContent className={classes.contentPadding}>
                    Rotation system name: {rotationSystemName}
                    <TemplateLibraryTable
                        orderBy={""}
                        useEmptyRows={false}
                        data={events}
                        columnData={columnData}
                        noRecordsMessage={"No rotation events."}
                        toolTips={[{ column: "operationName", tip: "operationTip" }]}
                    />
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withRouter(PreviewRotation));
