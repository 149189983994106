import React, { Component } from "react";
import { Form } from "react-form";
import withRouter from "../../common/withRouter";
import { connect } from "react-redux";

import Button from "../../common/ButtonWrapper";
import { Dialog, DialogContent, DialogContentText } from "@mui/material";
import Grid from "../../common/GridWrapper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "../../common/TypographyWrapper";

import withStyles from "@mui/styles/withStyles";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import { FarmSurvey } from "../models";
import TextField from "../../common/TextField";
import { createSelector } from "../../common/orm";
import Switch from "../../common/Switch";
import Select from "../../common/Select";
import { getValue, setValue } from "../../../api/utils";

const getFarmSurvey = createSelector(
    (state, ownProps) => ownProps.farm_id,
    (session, farm_id) => {
        return session.FarmSurvey.filter({ farm: farm_id }).toModelArray();
    }
);

const styles = {
    dialogMin: {
        minWidth: "400px"
    },
    flex: {
        flex: 1
    },
    button: {
        float: "right"
    },
    gutterTop: {
        marginTop: ".6em"
    },
    error: {
        marginTop: ".6em",
        color: "red"
    },
    minWidth: {
        minWidth: 600
    }
};

class AddSaiSurvey extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            year: null,
            showMultiple: false,
            invalidCropYear: true,
            showCopyFields: false
        };
    }

    addSurvey(values) {
        const { farm_id, ormFarmSurveyCreate, history, farmSurvey } = this.props;
        var survey_id;

        if (values.switch && values.defaultyear) {
            const previous_survey = farmSurvey.find(s => s.id === values.defaultyear);
            const new_survey = {};

            setValue(new_survey, "status", getValue(previous_survey, "status"));
            setValue(new_survey, "fsa", getValue(previous_survey, "fsa"));
            setValue(new_survey, "q_1", getValue(previous_survey, "q_1"));
            setValue(new_survey, "q_2", getValue(previous_survey, "q_2"));
            setValue(new_survey, "q_3", getValue(previous_survey, "q_3"));
            setValue(new_survey, "q_4", getValue(previous_survey, "q_4"));
            setValue(new_survey, "q_5", getValue(previous_survey, "q_5"));
            setValue(new_survey, "q_6", getValue(previous_survey, "q_6"));
            setValue(new_survey, "q_7", getValue(previous_survey, "q_7"));
            setValue(new_survey, "q_8", getValue(previous_survey, "q_8"));
            setValue(new_survey, "q_9", getValue(previous_survey, "q_9"));
            setValue(new_survey, "q_10", getValue(previous_survey, "q_10"));
            setValue(new_survey, "q_11", getValue(previous_survey, "q_11"));
            setValue(new_survey, "q_12", getValue(previous_survey, "q_12"));
            setValue(new_survey, "q_13", getValue(previous_survey, "q_13"));
            setValue(new_survey, "q_14", getValue(previous_survey, "q_14"));
            setValue(new_survey, "q_15", getValue(previous_survey, "q_15"));
            setValue(new_survey, "q_16", getValue(previous_survey, "q_16"));
            setValue(new_survey, "q_17", getValue(previous_survey, "q_17"));
            setValue(new_survey, "q_18", getValue(previous_survey, "q_18"));
            setValue(new_survey, "q_19", getValue(previous_survey, "q_19"));
            setValue(new_survey, "q_20", getValue(previous_survey, "q_20"));
            setValue(new_survey, "q_21", getValue(previous_survey, "q_21"));
            setValue(new_survey, "q_22", getValue(previous_survey, "q_22"));
            setValue(new_survey, "q_1_comment", getValue(previous_survey, "q_1_comment"));
            setValue(new_survey, "q_2_comment", getValue(previous_survey, "q_2_comment"));
            setValue(new_survey, "q_3_comment", getValue(previous_survey, "q_3_comment"));
            setValue(new_survey, "q_4_comment", getValue(previous_survey, "q_4_comment"));
            setValue(new_survey, "q_5_comment", getValue(previous_survey, "q_5_comment"));
            setValue(new_survey, "q_6_comment", getValue(previous_survey, "q_6_comment"));
            setValue(new_survey, "q_7_comment", getValue(previous_survey, "q_7_comment"));
            setValue(new_survey, "q_8_comment", getValue(previous_survey, "q_8_comment"));
            setValue(new_survey, "q_9_comment", getValue(previous_survey, "q_9_comment"));
            setValue(new_survey, "q_10_comment", getValue(previous_survey, "q_10_comment"));
            setValue(new_survey, "q_11_comment", getValue(previous_survey, "q_11_comment"));
            setValue(new_survey, "q_12_comment", getValue(previous_survey, "q_12_comment"));
            setValue(new_survey, "q_13_comment", getValue(previous_survey, "q_13_comment"));
            setValue(new_survey, "q_14_comment", getValue(previous_survey, "q_14_comment"));
            setValue(new_survey, "q_15_comment", getValue(previous_survey, "q_15_comment"));
            setValue(new_survey, "q_16_comment", getValue(previous_survey, "q_16_comment"));
            setValue(new_survey, "q_17_comment", getValue(previous_survey, "q_17_comment"));
            setValue(new_survey, "q_18_comment", getValue(previous_survey, "q_18_comment"));
            setValue(new_survey, "q_19_comment", getValue(previous_survey, "q_19_comment"));
            setValue(new_survey, "q_20_comment", getValue(previous_survey, "q_20_comment"));
            setValue(new_survey, "q_21_comment", getValue(previous_survey, "q_21_comment"));
            setValue(new_survey, "q_22_comment", getValue(previous_survey, "q_22_comment"));

            const nY = {
                farm: farm_id,
                year: values.year
            };

            // Later properties overwrite earlier properties with the same name.
            const payload = { ...new_survey, ...nY };
            survey_id = ormFarmSurveyCreate(payload);
        } else {
            survey_id = ormFarmSurveyCreate({
                farm: farm_id,
                year: values.year
            });
        }

        history.push(`/farm/${farm_id}/survey/${survey_id}`);
    }

    errorValidator = values => {
        const validateYear = year => {
            if (!year) {
                return "Required";
            }
            if (year.length !== 4) {
                return "Invalid year";
            }
            const number = parseFloat(year);
            if (number < 1900 || number > 2100) {
                return "Invalid year";
            }
            if (number % 1 !== 0) {
                return "Invalid year";
            }
            return null;
        };
        var valObj = {
            year: validateYear(values.year)
        };

        return valObj;
    };

    checkSurvey = value => {
        const { farmSurvey, farm_fields } = this.props;

        const addComponent = this;
        if (addComponent.formApi) {
            const year = value;
            if (year) {
                const mALength = farmSurvey.filter(mA => mA.year === year);
                if (mALength.length > 0) {
                    addComponent.setState({
                        showMultiple: true
                    });
                } else {
                    addComponent.setState({
                        showMultiple: false
                    });
                }
                addComponent.setState({
                    invalidCropYear: true
                });
                farm_fields.forEach(function (field) {
                    field.cropyears.forEach(function (cropyear) {
                        if (cropyear.year === year && cropyear.is_final) {
                            addComponent.setState({
                                invalidCropYear: false
                            });
                        }
                    });
                });
            } else {
                addComponent.setState({ showMultiple: false, invalidCropYear: false });
            }
        } else {
            addComponent.setState({ showMultiple: false, invalidCropYear: false });
        }
    };

    showCopyFields = value => {
        this.setState({ showCopyFields: value });
    };

    /* componentDidUpdate() {
        this.checkSurvey();
    } */

    render() {
        const { classes, open, returnDialog, farmSurvey } = this.props;
        const { showMultiple, showCopyFields, invalidCropYear } = this.state;

        const yearOptions = farmSurvey.map(row => ({
            label: row.year,
            value: row.id
        }));

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="title" className={classes.flex}>
                        Add Questionnaire
                    </Typography>
                    <IconButton
                        onClick={() => {
                            this.setState({ showMultiple: false, invalidCropYear: false });
                            returnDialog();
                        }}
                        size="large">
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent className={classes.dialogMin}>
                    <DialogContentText>
                        <Form
                            getApi={el => (this.formApi = el)}
                            dontValidateOnMount="true"
                            validateOnSubmit="true"
                            validateError={this.errorValidator}
                            /*defaultValues={{
                                year: new Date().getFullYear().toString()
                            }}*/
                            onSubmit={values => this.addSurvey(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <TextField
                                                field="year"
                                                label="Year"
                                                fullWidth
                                                eventHandle={this.checkSurvey}
                                                type="number"
                                                margin="normal"
                                            />
                                            {farmSurvey.length > 0 && (
                                                <Switch
                                                    field="switch"
                                                    name="switch"
                                                    eventHandle={value => this.showCopyFields(value)}
                                                    label="Would you like to input answers from a previous year?"
                                                />
                                            )}
                                            {showCopyFields && (
                                                <Select
                                                    field="defaultyear"
                                                    label="Year"
                                                    options={yearOptions}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            )}
                                            {showMultiple && (
                                                <Typography gutterBottom className={classes.error}>
                                                    Error: Questionnaire for this year already exists.
                                                </Typography>
                                            )}
                                            {invalidCropYear && (
                                                <Typography gutterBottom className={classes.error}>
                                                    Error: Finalized crop year for this year does not exist.
                                                </Typography>
                                            )}
                                            {!showMultiple && !invalidCropYear && (
                                                <Button
                                                    type="submit"
                                                    variant="raised"
                                                    color="primary"
                                                    className={classes.button}>
                                                    Create
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

AddSaiSurvey = connect(
    (state, ownProps) => ({
        farmSurvey: getFarmSurvey(state, ownProps)
    }),
    {
        ...FarmSurvey.actions
    }
)(AddSaiSurvey);

export default withStyles(styles)(withRouter(AddSaiSurvey));
