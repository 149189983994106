import React, { Component } from "react";
import Prompt from "../../common/Prompt";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Grid from "../../common/GridWrapper";
import { Stepper, Step, StepLabel, StepButton } from "@mui/material";
import LandTypes from "./1_LandTypes";
import LandTypeQuestionaire from "./2_LandTypeQuestionaire";
import Review from "./3_Review";
import AppContainer from "../../common/AppContainer";
import * as navActions from "../../common/actions";
import { createSelector } from "../../common/orm";
import { FarmHpiSurvey, SURVEY_STATUS } from "../models";
import { CropYear, FieldActivity } from "../../field/fieldactivities/models";
import { getValue } from "../../../api/utils";

const getFarmHpiSurvey = FarmHpiSurvey.selectByUrlId(survey => ({
    cultivated: survey.cultivatedlandquestionnaire.toModelArray().map(f => ({ ...f._fields })),
    buffer: survey.riparianquestionnaire.toModelArray().map(f => ({ ...f._fields }))[0],
    forest: survey.forestquestionnaire.toModelArray().map(f => ({ ...f._fields }))[0],
    grassland: survey.grasslandquestionnaire.toModelArray().map(f => ({ ...f._fields }))[0],
    surfacewater: survey.surfacewaterquestionnaire.toModelArray().map(f => ({ ...f._fields }))[0],
    wetland: survey.wetlandquestionnaire.toModelArray().map(f => ({ ...f._fields }))[0],
    ...survey._fields,
    farm: survey.farm.ref
}));

const getCropYears = createSelector(
    (state, ownProps) => ownProps.match.params["id"],
    (session, survey_id) => {
        var ordering = {},
            sortOrder = ["planting", "irrigation", "application", "manure", "harvest"];
        for (var i = 0; i < sortOrder.length; i++) {
            ordering[sortOrder[i]] = i;
        }

        const farm_id = survey_id.split("-")[0];
        console.log(session.CropYear.all());
        var cropyears = session.CropYear.orderBy("desc", "year")
            .toModelArray()
            .map(cropYear => ({
                crop_name: cropYear.crop.name,
                field_name: cropYear.field.name,
                farm_name: cropYear.field.farm.name,
                farm_id: cropYear.field.farm.id,
                activities: cropYear.activities.filter(a => a.type === "irrigation").toModelArray(),
                ...cropYear.ref,
                field: cropYear.field._fields
            }));
        cropyears = cropyears.filter(c => c.farm_id === farm_id);
        return cropyears;
    }
);

const allCrops = createSelector(schema => {
    return schema.Crops.all()
        .orderBy("name")
        .toModelArray()
        .map(c => ({
            id: c.id,
            name: c.name
        }));
});

const allFields = createSelector(schema => {
    return schema.Field.all()
        .orderBy("name")
        .toModelArray()
        .map(f => ({
            ...f._fields
        }));
});

const styles = theme => ({
    stepRoot: {
        backgroundColor: "#eeeeee",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2)
    },
    pointer: {
        cursor: "pointer",
        "& > span:first-child": {
            cursor: "pointer"
        },
        borderBottom: "8px solid transparent",
        marginBottom: -8,
        paddingRight: "8px !important",
        paddingLeft: "8px !important",
        "&:hover": {
            borderBottom: "8px solid lightgray"
        }
    },
    stepColor: {
        "& svg path": {
            color: theme.palette.secondary.main // + " !important",
        }
    },
    lessMargin: {
        "& span": {
            marginTop: "8px !important"
        }
    },
    embedNumber: {
        left: "52%",
        position: "absolute",
        zIndex: 1299,
        top: "-10%",
        borderRadius: "50%",
        width: "12px",
        height: "12px",
        padding: "1px",
        background: "#ff0000",
        border: "1px solid #fff",
        textAlign: "center",
        color: "#fff",
        fontSize: 10,
        fontWeight: 700
    },
    linkColor: {
        color: "#808080"
    },
    crumbColor: {
        color: theme.palette.primary.main
    }
});

function getSteps() {
    return ["Land Types", "Questionnaire by Land Type", "Review"];
}

class FarmHpiSurveyPage extends Component {
    state = {
        unsavedFields: false,
        step: 0,
        selectedLandtypes: [],
        noncultivated_land_surveys: [],
        cultivated_land_surveys: []
    };

    /* Stepper Events */
    totalSteps = () => {
        return getSteps().length;
    };

    isLastStep(step) {
        return step === this.totalSteps() - 1;
    }

    handleLandtypesChange = landtypes => {
        this.setState({ selectedLandtypes: landtypes });
    };

    handleNext = () => {
        this.setState({ step: this.state.step + 1 });
    };

    handleUnsavedFields = unsavedFields => {
        this.setState({ unsavedFields });
    };

    handleStep = (step, fromStepper) => {
        console.log(this.activeForm);
        this.setState({ step: step });
    };

    componentDidUpdate(prevProps) {
        this.loadCropYears(prevProps);
    }

    componentDidMount() {
        this.loadCropYears({});
    }

    loadCropYears(prevProps) {
        const { cropYears, ormCropYearLoadDetail, ormFieldActivityLoadDetail, syncState } = this.props;

        cropYears.forEach(c => {
            if (syncState.ready && !getValue(c, "synced")) {
                ormCropYearLoadDetail(c.id);

                if (c.activities) {
                    c.activities.forEach(function (activity) {
                        ormFieldActivityLoadDetail(activity.id);
                    });
                }
            }
        });
    }

    render() {
        const { classes, history, farmHpiSurvey, allCrops, allFields, cropYears, ormFarmHpiSurveyUpdate } = this.props;

        const { unsavedFields, step, noncultivated_land_surveys, cultivated_land_surveys } = this.state;

        const steps = getSteps();

        var allCropsIncludingOther = JSON.parse(JSON.stringify(allCrops));
        allCropsIncludingOther.push({ id: "999", name: "Other" });

        var synced = true;

        return (
            <AppContainer
                authenticated
                synced={!synced}
                handleUnsavedFields={this.handleUnsavedFields}
                color="field"
                title={farmHpiSurvey.year + " Farm-Level HPI Survey for " + farmHpiSurvey.farm.name}>
                <Prompt
                    when={unsavedFields}
                    message="You have entered data that has not been saved in our system yet. Do you want to leave without finishing?"
                />
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Stepper nonLinear className={classes.stepRoot} alternativeLabel activeStep={this.state.step}>
                            {steps.map((label, index) => {
                                return (
                                    <Step key={label} className={classes.pointer}>
                                        {SURVEY_STATUS(farmHpiSurvey, index) ? (
                                            <div />
                                        ) : (
                                            <div className={classes.embedNumber}>!</div>
                                        )}

                                        <StepButton
                                            onClick={() => this.handleStep(index, true)}
                                            completed={SURVEY_STATUS(farmHpiSurvey, index)}>
                                            <StepLabel
                                                classes={{
                                                    labelContainer: classes.lessMargin,
                                                    iconContainer: classes.stepColor
                                                }}>
                                                {label}
                                            </StepLabel>
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        <div>
                            {step === 0 && (
                                <LandTypes
                                    onRef={el => (this.activeForm = el)}
                                    farmHpiSurvey={farmHpiSurvey}
                                    handleNext={this.handleNext}
                                    history={history}
                                    selectedLandtypes={this.state.selectedLandtypes}
                                    handleLandtypesChange={this.handleLandtypesChange}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                />
                            )}

                            {step === 1 && (
                                <LandTypeQuestionaire
                                    onRef={el => (this.activeForm = el)}
                                    farmHpiSurvey={farmHpiSurvey}
                                    handleNext={this.handleNext}
                                    history={history}
                                    selectedLandtypes={this.state.selectedLandtypes}
                                    cropyears={cropYears.filter(c => c.farm_id === farmHpiSurvey.farm.id)}
                                    noncultivated_land_surveys={noncultivated_land_surveys}
                                    cultivated_land_surveys={cultivated_land_surveys}
                                    allCrops={allCropsIncludingOther}
                                    allFields={allFields}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                />
                            )}

                            {step === 2 && (
                                <Review
                                    onRef={el => (this.activeForm = el)}
                                    farmHpiSurvey={farmHpiSurvey}
                                    handleStep={this.handleStep}
                                    history={history}
                                    noncultivated_land_surveys={noncultivated_land_surveys}
                                    cultivated_land_surveys={cultivated_land_surveys}
                                    allCrops={allCropsIncludingOther}
                                    ormFarmHpiSurveyUpdate={ormFarmHpiSurveyUpdate}
                                    cropyears={cropYears.filter(
                                        c => c.year === farmHpiSurvey.year && c.farm_id === farmHpiSurvey.farm.id
                                    )}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

FarmHpiSurveyPage = connect(
    (state, ownProps) => ({
        farmHpiSurvey: getFarmHpiSurvey(state, ownProps),
        cropYears: getCropYears(state, ownProps),
        allCrops: allCrops(state),
        allFields: allFields(state),
        syncState: state.sync,
        authState: state.auth
    }),
    {
        ...navActions,
        ...CropYear.actions,
        ...FieldActivity.actions,
        ...FarmHpiSurvey.actions
    }
)(FarmHpiSurveyPage);

export default withStyles(styles)(FarmHpiSurveyPage);
