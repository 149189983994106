import React, { Component } from "react";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "../../../common/ButtonWrapper";
import Grid from "../../../common/GridWrapper";
import Typography from "../../../common/TypographyWrapper";

import withStyles from "@mui/styles/withStyles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import Applications from "./Applications";
import { Fertilizer, ApplicationTimes, DominantApplicationMethods } from "./models";
import { ACTIVITY_TYPES } from "../models";
import Select from "../../../common/Select";
import Snackbar from "../../../common/Snackbar";
import TextField from "../../../common/TextField";
import WarningDialog from "../../../common/WarningDialog";
import { createSelector } from "../../../common/orm";
import { MAKE_OPTIONS, RICE, CUSTOM_BLEND, CUSTOM_RATE } from "../../../../api/constants";
import { setValue, getValue } from "../../../../api/utils";

const allApplicationTimes = ApplicationTimes.selectAll();
const allDominantApplicationMethods = DominantApplicationMethods.selectAll();
const getFertilizers = createSelector(
    (state, ownProps) => ownProps.fieldActivity.id,
    (session, fieldId) => {
        return session.Fertilizer.filter({ activity: fieldId }).toRefArray();
    }
);

const styles = theme => ({
    floatRight: {
        float: "right"
    },
    hide: {
        "& label, & div": {
            display: "none"
        }
    }
});

class FertilizingCommercial extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            deleteDialogOpen: false,
            deleteDialogOpen2: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null,
            snackbarOpen: false
        };

        this.addFertilizer = this.addFertilizer.bind(this);
    }

    updateFieldActivity(values, silent) {
        const { id } = this.props.fieldActivity;
        var isCompleted = this.errorValidator(values, this.props.cropYear, false);

        if (silent === "silent") {
            return;
        }

        this.props.ormFieldActivityUpdate({
            id: id,
            ...values,
            extrainfo: {
                completed: isCompleted[0] === 0,
                missing: isCompleted[0],
                required: isCompleted[1]
            }
        });
        this.props.ormCropYearUpdateLocalOnly({
            id: this.props.cropYear.id,
            metrics: null
        });
        this.props.ormCropYearUpdate({
            id: this.props.cropYear.id,
            metrics: null
        });
        this.props.handleUnsavedFields(false);

        // Reapply required label to any field not yet filled in by adding a nonexistant value
        this.setState({ submitClicked: false, snackbarOpen: true });
        this.form.addValue("foo", "bar");
    }

    deleteFieldActivity = (field, name) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: field,
            deleteDialogText: "Are you sure you wish to permanently delete " + name + "?"
        });
    };

    addFertilizer(nutrient_applied) {
        if (!nutrient_applied) {
            this.setState({ deleteDialogOpen2: true });
            return;
        }
        const { id } = this.props.fieldActivity;
        this.props.ormFertilizerCreate({
            activity: id
        });
        const component = this;
        setTimeout(function () {
            component.form.addValue("silent", "set");
            component.form.submitForm("silent"); // Refresh validator upon adding
        }, 200);
    }

    errorValidator = (values, cY, hardRequire, fertOnly) => {
        const { fertilizers } = this.props;
        var numberRequired = 0; // Keep track of the actual number of required fields
        const isRequired = path => {
            numberRequired++;
            let val = getValue(values, path);
            if (!val) {
                setValue(valObj, path, "Required");
                return;
            }
            if (
                fertilizers.length < 1 &&
                parseFloat(getValue(values, "application.energyuse.fumigant_count")) === 0 &&
                parseFloat(getValue(values, "application.energyuse.growthregulator_count")) === 0 &&
                parseFloat(getValue(values, "application.energyuse.fungicide_count")) === 0 &&
                parseFloat(getValue(values, "application.energyuse.insecticide_count")) === 0 &&
                parseFloat(getValue(values, "application.energyuse.herbicide_count")) === 0 &&
                parseFloat(getValue(values, "application.energyuse.harvestaid_count")) === 0
            ) {
                setValue(valObj, path, "You must have at least one fertilizer application or crop protectant");
                return;
            }
            setValue(valObj, path, null);
        };
        // These fields aren't required, but the server returns an error if you try send an empty string
        // Should be fine since these are defaulted to 0 when the trip is created
        const validateNumber = path => {
            numberRequired++;
            let val = getValue(values, path);
            const number = parseFloat(val);
            if (!val && val !== 0) {
                setValue(valObj, path, "Required");
                return;
            }
            if (number < 0) {
                setValue(valObj, path, "Invalid");
                return;
            }
            if (number % 1 !== 0) {
                setValue(valObj, path, "Round to the nearest whole number");
                return;
            }
            setValue(valObj, path, null);
        };
        const validateFertilizer = path => {
            numberRequired++;
            if (fertilizers.length > 0) {
                var errorhit = false;
                fertilizers.forEach(function (fert) {
                    const fertilizer_id = getValue(fert, "energyuse.fertilizer_id");
                    const fertilizer_rate = getValue(fert, "energyuse.fertilizer_rate");
                    const bnitrogen = getValue(fert, "energyuse.customblend.nitrogen");
                    const bphosphorus = getValue(fert, "energyuse.customblend.phosphorus");
                    const bpotassium = getValue(fert, "energyuse.customblend.potassium");
                    const rnitrogen = getValue(fert, "energyuse.customrate.nitrogen");
                    const rphosphorus = getValue(fert, "energyuse.customrate.phosphorus");
                    const rpotassium = getValue(fert, "energyuse.customrate.potassium");
                    // If no Product Type
                    // or no Product Amount if not custom rate
                    // or no N/P2O5/K2O if custom
                    if (
                        !fertilizer_id ||
                        (fertilizer_id !== CUSTOM_RATE && !fertilizer_rate) ||
                        (fertilizer_id === CUSTOM_BLEND && (!bnitrogen || !bphosphorus || !bpotassium)) ||
                        (fertilizer_id === CUSTOM_RATE && (!rnitrogen || !rphosphorus || !rpotassium))
                    ) {
                        setValue(valObj, path, "All fertilizer fields are required");
                        errorhit = true;
                        return;
                    }
                });
                if (errorhit) return;
            }
            setValue(valObj, path, null);
        };
        var valObj = {};
        if (!fertOnly) {
            isRequired("application.waterquality.application_timing");
            if (fertilizers.length > 0) {
                isRequired("application.waterquality.application_method_id");
            }
            validateNumber("application.energyuse.fumigant_count");
            validateNumber("application.energyuse.growthregulator_count");
            validateNumber("application.energyuse.fungicide_count");
            validateNumber("application.energyuse.insecticide_count");
            validateNumber("application.energyuse.herbicide_count");
            if (cY.crop === RICE) validateNumber("application.energyuse.harvestaid_count");
        }

        validateFertilizer("fert_local_only");

        if (hardRequire) {
            // hardRequire = actual validation
            // FIXME: v2.X of react-forms has a submitting attribute on the formapi, but doesn't appear to be functional
            // V3.X seems to be a lot of work to upgrade
            // We are simulating a custom state (submitClicked) to know if its actually submiting
            // If it is submitting ignore the validator, submit, and move on
            if (this.state.submitClicked) {
                Object.keys(valObj).forEach(function (key) {
                    if (valObj[key] !== null && typeof valObj[key] === "object") {
                        // Also check child objects
                        Object.keys(valObj[key]).forEach(function (childKey) {
                            if (valObj[key][childKey] !== null && typeof valObj[key][childKey] === "object") {
                                Object.keys(valObj[key][childKey]).forEach(function (childKey2) {
                                    valObj[key][childKey][childKey2] = null;
                                });
                            }
                        });
                    }
                });
            }
            return valObj;
        }

        // If we are doing the final save of the form track how many fields are missing
        var missing = 0;
        Object.keys(valObj).forEach(function (key) {
            if (valObj[key] !== null && typeof valObj[key] === "object") {
                // Also check child objects
                Object.keys(valObj[key]).forEach(function (childKey) {
                    if (valObj[key][childKey] !== null && typeof valObj[key][childKey] === "object") {
                        Object.keys(valObj[key][childKey]).forEach(function (childKey2) {
                            if (valObj[key][childKey][childKey2] !== null) missing++;
                        });
                    }
                });
            } else if (valObj[key] !== null) missing++;
        });

        return [missing, numberRequired];
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        this.props.onRef(this);
        setTimeout(function () {
            handleUnsavedFields(false);
        }, 100);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    deleteConfirmation = action => {
        console.log();
        const { fertilizerCount } = this.props;

        this.props.ormFieldActivityDelete(action);

        //  if deleting the only fertilizer, reset the n2o survey values on the cropyear
        if (fertilizerCount === 1) {
            const greenhouse = {
                n2o_survey_soybean: null,
                n2o_survey_takesurvey: null,
                n2o_survey_level: "0",
                n2o_survey_file: null
            };

            this.props.ormCropYearUpdate({
                id: this.props.cropYear.id,
                metrics: null,
                greenhouse: greenhouse
            });
        } else {
            this.props.ormCropYearUpdate({
                id: this.props.cropYear.id,
                metrics: null
            });
        }

        this.setState({ deleteDialogOpen: false });
    };

    render() {
        const {
            classes,
            fieldActivity,
            fertilizers,
            ormFertilizerUpdate,
            ormFertilizerDelete,
            applicationTimes,
            applicationMethods,
            handleUnsavedFields,
            cropYear,
            ormCropYearUpdateLocalOnly,
            ormFertilizerUpdateLocalOnly
        } = this.props;
        const { deleteDialogOpen, deleteDialogText, deleteDialogConfirmAction, snackbarOpen, deleteDialogOpen2 } =
            this.state;

        const nutrient_applied = getValue(cropYear, "waterquality.nutrient_applied");
        return (
            <Form
                getApi={el => (this.form = el)}
                key={fieldActivity.id}
                dontValidateOnMount={false}
                validateOnSubmit={false}
                defaultValues={fieldActivity}
                formDidUpdate={() => handleUnsavedFields(true)}
                validateError={values => this.errorValidator(values, cropYear, true)}
                onSubmitFailure={(errors, formApi) => {
                    // This only occurs when switching steppers from step 3
                    // The errorValidator is indeed returning null for every valobj however it still has the old errors in finishSubmission
                    // https://github.com/react-tools/react-form/blob/v2.16.3/src/components/ReduxForm.js
                    // Something to do with calling submitform from two parent components above? (3_FieldActivites -> Save Operations)
                    // Skip the validation and go straight to the orm update

                    // EDIT: Apparently it also occurs anytime you save the form with validation errors
                    // We do actually want to save it except when submitform.silent
                    // No way to determine if it is silent here except to set a value...
                    // Getting really sick of all these react form bugs
                    if (formApi.values.silent) {
                        setValue(formApi.values, "silent", null);
                    } else {
                        this.updateFieldActivity(formApi.values);
                    }
                }}
                onSubmit={(values, silent) => this.updateFieldActivity(values, silent)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <WarningDialog
                            confirmAction={() => this.deleteConfirmation(deleteDialogConfirmAction)}
                            cancelAction={() => this.setState({ deleteDialogOpen: false })}
                            open={deleteDialogOpen}
                            title="Delete Commercial Fertilizer"
                            text={deleteDialogText}
                        />
                        <WarningDialog
                            noActions
                            onClose={() => this.setState({ deleteDialogOpen2: false })}
                            open={deleteDialogOpen2}
                            title="Add Commercial Fertilizer"
                            text={
                                'In order to add a Commercial Fertilizer, please answer "Yes" to the question "Did you apply fertilizer (either organic or inorganic source) this crop year?" in the Management section.'
                            }
                        />
                        <Grid container spacing={24}>
                            <Grid item xs={6} md={5} lg={4} xl={3}>
                                <Select
                                    field="application.waterquality.application_timing"
                                    label="Application timing"
                                    help="Select the option that best represents the time during the crop year for your application of fertilizers and/or crop protectant chemicals. The option selected will determine if the system considers that the energy used in the pass over the field is captured in another operation (with planter, with irrigation) or should be separately calculated as a pass over the field independent of planting or irrigation operations."
                                    options={MAKE_OPTIONS(applicationTimes)}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            {fertilizers.length > 0 && (
                                <Grid item xs={6} md={5} lg={4} xl={3}>
                                    <Select
                                        field="application.waterquality.application_method_id"
                                        label="Fertilizer application method"
                                        help="Select the method used during this trip to apply commercial fertilizer."
                                        options={MAKE_OPTIONS(applicationMethods)}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid container spacing={24}>
                            <Grid item xs={12} md={11} lg={10} xl={9}>
                                <Typography gutterBottom>
                                    Fertilizer applications made during this trip. Add a record for each application.
                                </Typography>
                                {fertilizers.map(object => (
                                    <Applications
                                        ormFertilizerUpdate={ormFertilizerUpdate}
                                        ormFertilizerDelete={ormFertilizerDelete}
                                        ormFertilizerUpdateLocalOnly={ormFertilizerUpdateLocalOnly}
                                        ormCropYearUpdateLocalOnly={ormCropYearUpdateLocalOnly}
                                        cropYear={cropYear}
                                        application={object}
                                        parentValidator={formApi}
                                    />
                                ))}
                                <TextField field="fert_local_only" label="Fertilizers" className={classes.hide} />
                                <Button
                                    onClick={() => this.addFertilizer(nutrient_applied)}
                                    className={classes.floatRight}
                                    color="primary">
                                    Add Fertilizer Application <AddCircleOutlineIcon />
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={24} sx={{ maxWidth: { xs: 650, lg: 832 } }}>
                                    <Grid item xs={12}>
                                        <Typography variant="title">Spraying Operations</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={2}>
                                        <TextField
                                            type="number"
                                            field="application.energyuse.herbicide_count"
                                            label="Herbicide(s)"
                                            help="Enter the number of different herbicide products applied to the field for this trip."
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={2}>
                                        <TextField
                                            type="number"
                                            field="application.energyuse.insecticide_count"
                                            label="Insecticide(s)"
                                            help="Enter the number of different insecticide products applied to the field for this trip."
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={2}>
                                        <TextField
                                            type="number"
                                            field="application.energyuse.fungicide_count"
                                            label="Fungicide(s)"
                                            help="Enter the number of different fungicide products applied to the field for this trip."
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={2}>
                                        <TextField
                                            type="number"
                                            field="application.energyuse.growthregulator_count"
                                            label="Growth regulator aid(s)"
                                            help="Enter the number of different growth regulator products applied to the field during this trip."
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} lg={2}>
                                        <TextField
                                            type="number"
                                            field="application.energyuse.fumigant_count"
                                            label="Fumigant aid(s)"
                                            help="Enter the number of different soil fumigant products applied to the field for this trip."
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                    {cropYear.crop === RICE && (
                                        <Grid item xs={12} sm={6} md={3} lg={2}>
                                            <TextField
                                                type="number"
                                                field="application.energyuse.harvestaid_count"
                                                label="Harvest aid(s)"
                                                help="Enter the number of different harvest aid products applied to the field during this trip."
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    color="primary"
                                    onClick={() =>
                                        this.deleteFieldActivity(
                                            fieldActivity.id,
                                            ACTIVITY_TYPES[fieldActivity.type] + " " + fieldActivity.number
                                        )
                                    }>
                                    Delete Application Trip
                                </Button>
                                <Button
                                    type="submit"
                                    variant="raised"
                                    color="primary"
                                    className={classes.floatRight}
                                    onClick={() => this.setState({ submitClicked: true })}>
                                    Save Application Trip
                                </Button>
                            </Grid>
                            <Snackbar
                                success
                                onClose={this.handleSnackbarClose}
                                open={snackbarOpen}
                                section="application trip operation"
                            />
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

FertilizingCommercial = connect(
    (state, ownProps) => ({
        fertilizers: getFertilizers(state, ownProps),
        applicationTimes: allApplicationTimes(state, ownProps),
        applicationMethods: allDominantApplicationMethods(state, ownProps)
    }),
    {
        ...Fertilizer.actions
    }
)(FertilizingCommercial);

export default withStyles(styles)(FertilizingCommercial);
