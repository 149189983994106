import React, { Component } from "react";
import classNames from "classnames";
import { ThemeProvider, StyledEngineProvider, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import PageHeader from "./inputs/PageHeader";
import PageFooter from "./inputs/PageFooter";
import { Table, TableBody, TableRow } from "@mui/material/";
import CustomTableCell from "../common/TableCell";
import EnhancedTableHead from "../common/EnhancedTableHead";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    LabelList,
    LineChart,
    Line,
    Legend,
    Pie,
    PieChart,
    Cell,
    CartesianGrid,
    Label
} from "recharts";
import ProjectMap from "./ProjectMap";
import { round } from "../field/analysis/StaticData";

// Custom CSS classes
const styles = theme => ({
    root: {
        padding: 8,
        fontSize: 11
    },
    page: {
        height: "11in",
        width: "8.5in",
        overflow: "hidden",
        position: "relative",
        pageBreakAfter: "always"
    },
    content: {
        width: "6.5in",
        paddingLeft: "1in",
        paddingRight: "1in",
        overflow: "hidden"
    },
    column: {
        position: "absolute",
        top: "1.4in",
        width: "3.625in",
        height: "9in",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start"
    },
    metric: {
        marginBottom: 24
    },
    "@media screen": {
        page: {
            border: "1px solid #ccc",
            marginTop: "1em"
        }
    },
    table: {
        padding: "8px 16px",
        //boxShadow: "5px 5px 15px rgba(0,0,0,0.4)",
        borderRadius: 8
    },
    tableBorder: {
        "& table, th, td": {
            border: "1px solid black",
            borderCollapse: "collapse",
            padding: "0.3px 4px"
        }
    },
    rightAlign: {
        textAlign: "right"
    },
    greyBackground: {
        "& td": {
            backgroundColor: "#444",
            color: "white",
            fontWeight: "bold"
        }
    },
    popupHeader: {
        borderBottom: "1px solid lightgray",
        marginTop: 8,
        marginBottom: 8
    },
    reportHeader: {
        textAlign: "center",
        color: "#505050",
        fontSize: 24
    },
    reportSubHeader: {
        textAlign: "center",
        color: "#505050",
        marginTop: 24,
        marginBottom: 4
    },
    reportText: {
        textAlign: "center",
        color: "#505050"
    }
});

const themeOverrides = {
    MuiTypography: {
        title: {
            fontSize: 15
        },
        body2: {
            fontSize: 13,
            lineHeight: "1.4em"
        }
    },
    MuiTableCell: {
        body: {
            fontSize: "13px !important"
        },
        head: {
            fontSize: "13px !important"
        }
    }
};

function getSelectedProjectBenchmarkGroupData(project, selectedProjectBenchmarkGroup) {
    var benchmark_order = [
        "landUse",
        "soilConservation",
        "soilCarbon",
        "irrigationWaterUse",
        "energyUse",
        "greenhouseGas",
        "waterQuality",
        "biodiversity"
    ];

    var project_benchmarks = project.project_benchmark_group.filter(group => {
        return group.label === selectedProjectBenchmarkGroup;
    })[0]["project_benchmarks"];

    var data = [];
    benchmark_order.forEach(function (benchmark_metric_code) {
        var project_benchmark = project_benchmarks.filter(
            b => benchmark_metric_code === b.metric_label.split("|")[1].trim()
        )[0];
        if (project_benchmark) {
            var metric_code = project_benchmark.metric_label.split("|")[1].trim();
            var metric_label = project_benchmark.metric_label.split("|")[0].trim();

            var value = round(project_benchmark.value, metric_code);
            if (metric_code === "biodiversity") {
                value = project_benchmark.value * 100;
                value = value.toFixed(1);
            }
            var metric =
                project_benchmark.units === "dimensionless"
                    ? metric_label
                    : metric_label + " (" + project_benchmark.units + ")";
            if (metric_code === "biodiversity") {
                metric = metric_label + " (%)";
            }
            data.push({
                id: project_benchmark.id,
                value: value,
                metric: metric,
                metric_code: metric_code
            });
        }
    });
    return data;
}

function getCalculatedProjectBenchmarks(project) {
    var options = project.project_benchmark_group.map(group => ({
        irrigated: group.irrigated,
        crop_id: group.crop_id,
        crop_label: group.crop_label,
        id: group.label,
        value: group.label,
        key: group.label,
        label: group.label
    }));
    // options.unshift({label: <b>Clear Filter</b>, value: null, key: null});
    return options;
}

class SummarySection extends Component {
    colors = [
        "#6a3d9a",
        "#ff7f00",
        "#e31a1c",
        "#33a02c",
        "#1f78b4",
        "#b15928",
        "#cab2d6",
        "#fdbf6f",
        "#fb9a99",
        "#b2df8a",
        "#a6cee3",
        "#ffff99"
    ];
    colors_lighter = [
        "#c3aade",
        "#ffcc99",
        "#f4a3a4",
        "#a3e59f",
        "#99cbed",
        "#e9ba9f",
        "#eae0ef",
        "#fee5c5",
        "#fdd7d6",
        "#e0f2d0",
        "#dbebf4",
        "#ffffd6"
    ];

    renderTable2 = () => {
        const { project } = this.props;
        if (!project.project_data) return [];

        // First group all the same crops+year+farmer
        var dataGrouped = [];
        project.project_data
            .filter(x => x.is_final && x.year > 2017)
            .forEach(function (d) {
                var hit = false;
                dataGrouped.forEach(function (dG) {
                    if (
                        d.year === dG.year &&
                        d.crop_label === dG.crop_label &&
                        d.crop_user_id === dG.crop_user_id &&
                        d.farm_name === dG.farm_name &&
                        d.is_irrigated === dG.irrigated
                    ) {
                        hit = true;
                        dG.field_acres = dG.field_acres + d.field_acres;
                        if (d.field_acres === d.managed_acres) {
                            dG.managed_acres = dG.managed_acres + d.managed_acres;
                        }

                        if (
                            d.field_label !== dG.field_label &&
                            (!dG.field_list || !dG.field_list.includes(d.field_label))
                        ) {
                            dG.unique_fields = dG.unique_fields + 1;
                            if (!dG.field_list) dG.field_list = [];
                            dG.field_list.push(d.field_label);
                        }
                    }
                });
                if (!hit) {
                    var obj = Object.assign({}, d);
                    obj.unique_farms = 1;
                    obj.unique_fields = 1;
                    obj.irrigated = d.is_irrigated;
                    dataGrouped.push(obj);
                }
            });

        // Next - If field_acres is less than 10% of managed_acres use field_acres otherwise use greater value
        dataGrouped.forEach(function (dG) {
            var percent = dG.field_acres / dG.managed_acres;
            if (percent < 0.1 || percent > 10 || dG.field_acres > dG.managed_acres) {
                dG.managed_acres_calc = dG.field_acres;
            } else {
                dG.managed_acres_calc = dG.managed_acres;
            }
        });

        // Then group farmers together on year+crop
        var dataFinal = [];
        dataGrouped.forEach(function (dG) {
            var hit = false;
            dataFinal.forEach(function (dF) {
                if (dG.year === dF.year && dG.crop_label === dF.crop_label && dG.irrigated === dF.irrigated) {
                    hit = true;
                    if (!dF.farmer_ids.includes(dG.crop_user_id)) {
                        dF.farmer_ids.push(dG.crop_user_id);
                    }
                    dF.unique_farmers = dF.farmer_ids.length;
                    dF.managed_acres = dF.managed_acres + dG.managed_acres;
                    dF.managed_acres_calc = dF.managed_acres_calc + dG.managed_acres_calc;
                    dF.field_acres = dF.field_acres + dG.field_acres;
                    dF.unique_farms = dF.unique_farms + dG.unique_farms;
                    dF.unique_fields = dF.unique_fields + dG.unique_fields;
                }
            });
            if (!hit) {
                var obj = Object.assign({}, dG);
                obj.unique_farmers = 1;
                obj.farmer_ids = [dG.crop_user_id];
                obj.irrigated = dG.irrigated;
                dataFinal.push(obj);
            }
        });
        dataFinal = dataFinal.sort(function (a, b) {
            return a.crop_id - b.crop_id || b.is_irrigated - a.is_irrigated || b.year - a.year;
        });
        return dataFinal;
    };

    renderTable = () => {
        const { project } = this.props;
        if (!project.project_data) return [];

        // Total Entered = field_acres
        // Total Managed = managed_acres
        // Total Enrollled = managed_acres_calc

        var dataGrouped = [];
        project.project_data
            .filter(x => x.is_final && x.year > 2017)
            .forEach(function (d) {
                var hit = false;
                dataGrouped.forEach(function (dG) {
                    if (
                        d.year === dG.year &&
                        d.crop_label === dG.crop_label &&
                        d.crop_user_id === dG.crop_user_id &&
                        d.farm_name === dG.farm_name
                    ) {
                        hit = true;
                        dG.field_acres = dG.field_acres + d.field_acres;
                        if (d.field_acres === d.managed_acres) {
                            dG.managed_acres = dG.managed_acres + d.managed_acres;
                        }

                        if (
                            d.field_label !== dG.field_label &&
                            (!dG.field_list || !dG.field_list.includes(d.field_label))
                        ) {
                            dG.unique_fields = dG.unique_fields + 1;
                            if (!dG.field_list) dG.field_list = [];
                            dG.field_list.push(d.field_label);
                        }
                    }
                });
                if (!hit) {
                    var obj = Object.assign({}, d);
                    obj.unique_farms = 1;
                    obj.unique_fields = 1;
                    dataGrouped.push(obj);
                }
            });

        // Next - If field_acres is less than 10% of managed_acres use field_acres otherwise use greater value
        dataGrouped.forEach(function (dG) {
            var percent = dG.field_acres / dG.managed_acres;
            if (percent < 0.1 || percent > 10 || dG.field_acres > dG.managed_acres) {
                dG.managed_acres_calc = dG.field_acres;
            } else {
                dG.managed_acres_calc = dG.managed_acres;
            }
        });

        // Then group farmers together on year+crop
        var dataFinal = [];
        dataGrouped.forEach(function (dG) {
            var hit = false;
            dataFinal.forEach(function (dF) {
                if (dG.year === dF.year) {
                    hit = true;
                    dF.managed_acres = dF.managed_acres + dG.managed_acres;
                    dF.field_acres = dF.field_acres + dG.field_acres;
                    dF.managed_acres_calc = dF.managed_acres_calc + dG.managed_acres_calc;
                    dF.unique_farms = dF.unique_farms + dG.unique_farms;
                    dF.unique_fields = dF.unique_fields + dG.unique_fields;
                }
            });
            if (!hit) {
                var obj = Object.assign({}, dG);
                obj.unique_farmers = 1;
                dataFinal.push(obj);
            }
        });
        dataFinal.sort(function (a, b) {
            return a.year - b.year;
        });
        return dataFinal;
    };

    RenderCusomizedLegend = props => {
        const { payload } = props;
        return <Typography style={{ textAlign: "center" }}>{payload[0].value}</Typography>;
    };

    RenderCusomizedLegendMargin = props => {
        const { payload } = props;
        return (
            <Typography style={{ fontWeight: "bold", textAlign: "center", marginBottom: 16 }}>
                {payload[0].value}
            </Typography>
        );
    };

    labelDot = props => {
        const { cx, cy, width, height, payload } = props;

        return <circle r={4} cx={cx} cy={cy} width={width} height={height} fill={payload.stroke}></circle>;
    };

    renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
        const RADIAN = Math.PI / 180;
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x}
                y={y}
                style={{ fontWeight: "bold" }}
                fill="black"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    bioLabel = ({ viewBox, offset, text, value, fill }) => {
        const { x, y, width, height } = viewBox;
        const sign = height >= 0 ? 1 : -1;
        return (
            <text
                x={x + width / 2}
                style={{ fontWeight: "bold" }}
                y={y - sign * offset}
                fill={fill}
                verticalAnchor="end"
                textAnchor="middle">
                {value}%
            </text>
        );
    };

    farmFieldsLabel = props => {
        const { index, value, viewBox, offset } = props;
        const { x, y, width, height } = viewBox;
        const sign = height >= 0 ? 1 : -1;

        return (
            <text
                fill={this.colors[index]}
                style={{ fontWeight: "bold" }}
                textAnchor="middle"
                verticalAnchor="end"
                x={x + width / 2}
                y={y - sign * offset - 1}>
                {value.toLocaleString()}
            </text>
        );
    };

    farmFieldsLabel2 = (props, data, years) => {
        const { value, viewBox, offset } = props;
        const { x, y, width, height } = viewBox;
        const sign = height >= 0 ? 1 : -1;

        var d = data.find(d => d.value === value);

        return (
            <text
                fill={this.colors[years.indexOf(d.year.toString())]}
                style={{ fontWeight: "bold" }}
                textAnchor="middle"
                verticalAnchor="end"
                x={x + width / 2}
                y={y - sign * offset - 1}>
                {value.toLocaleString()}
            </text>
        );
    };

    CandyBar = props => {
        const { x: oX, y: oY, width: oWidth, height: oHeight, fill } = props;

        let x = oX;
        let y = oHeight < 0 ? oY + oHeight : oY;
        let width = oWidth;
        let height = Math.abs(oHeight);

        return <rect fill={fill} mask="url(#mask-stripe)" x={x} y={y} width={width} height={height} />;
    };

    cons_code = val => {
        switch (val) {
            case "12":
                return "Access Control";
            case "16":
                return "Alley Cropping";
            case "17":
                return "Amending Soil Properties with Gypsum Products";
            case "21":
                return "Anionic Polyacrylamide (PAM) Erosion Control";
            case "34":
                return "Constructed Wetland";
            case "35":
                return "Contour Buffer Strips";
            case "7":
                return "Contour Farming";
            case "8":
                return "Contour Orchard and Other Perennial Crops";
            case "208":
                return "Denitrifying Bioreactor";
            case "46":
                return "Dike";
            case "47":
                return "Diversion";
            case "48":
                return "Drainage Water Management";
            case "56":
                return "Field Border";
            case "57":
                return "Filter Strip 20 ft Min Width";
            case "58":
                return "Filter Strip 30 ft Min Width";
            case "67":
                return "Grade Stabilization Structure";
            case "68":
                return "Grassed Waterway";
            case "72":
                return "Hedgerow Planting";
            case "87":
                return "Irrigation System, Tail Water Recovery";
            case "88":
                return "Irrigation Water Management";
            case "105":
                return "Mulching with Natural Materials";
            case "106":
                return "Mulching with Plastic Materials";
            case "1":
                return "Pond Sealing or Lining, Bentonite Treatment";
            case "3":
                return "Pond Sealing or Lining, Compacted Clay Treatment";
            case "10":
                return "Pond Sealing or Lining, Flexible Membrane";
            case "11":
                return "Pond Sealing or Lining, Soil Dispersant Treatment";
            case "139":
                return "Residue and Tillage Management, No-Till";
            case "140":
                return "Residue and Tillage Management, Reduced Tillage";
            case "142":
                return "Riparian Forest Buffer";
            case "143":
                return "Riparian Herbaceous Cover";
            case "150":
                return "Sediment Basin";
            case "152":
                return "Short Term Storage of Animal Waste and Byproducts";
            case "156":
                return "Stormwater Runoff Control";
            case "160":
                return "Stripcropping";
            case "161":
                return "Structure for Water Control";
            case "167":
                return "Terrace";
            case "175":
                return "Vegetative Barrier";
            case "180":
                return "Waste Storage Facility";
            case "184":
                return "Water and Sediment Control Basin";
            case "194":
                return "Windbreak/Shelterbelt Establishment";
            default:
                return "Other";
        }
    };

    render() {
        const { classes, project } = this.props;

        const columnData2 = [
            { id: "year", numeric: false, label: "Year" },
            { id: "crop_label", numeric: false, label: "Crop" },
            { id: "irrigated", numeric: false, label: "Irrigated?" },
            { id: "unique_farms", numeric: true, label: "Total Unique Farms" },
            { id: "unique_fields", numeric: true, label: "Total Unique Fields" },
            { id: "field_acres", numeric: true, label: "Total Entered Acres" },
            { id: "managed_acres_calc", numeric: true, label: "Total Enrolled Acres" }
        ];
        const columnData3 = [
            { id: "crop_label", numeric: false, label: "Crop" },
            { id: "a", numeric: true, label: "Land Use (acre / yield unit)" },
            { id: "b", numeric: true, label: "Soil Conservation (ton / acre / year)" },
            { id: "c", numeric: true, label: "Irrigation Water Use (acre_inch / yield unit)" },
            { id: "d", numeric: true, label: "Energy Use (btu / yield unit)" },
            { id: "e", numeric: true, label: "Greenhouse Gas (lbs_co2e / yield unit)" },
            { id: "f", numeric: true, label: "Water Quality" },
            { id: "g", numeric: true, label: "Biodiversity (%)" }
        ];
        const columnData3b = [
            { id: "crop_label", numeric: false, label: "Crop" },
            { id: "a", numeric: true, label: "Land Use (acre / yield unit)" },
            { id: "b", numeric: true, label: "Soil Conservation (ton / acre / year)" },
            { id: "d", numeric: true, label: "Energy Use (btu / yield unit)" },
            { id: "e", numeric: true, label: "Greenhouse Gas (lbs_co2e / yield unit)" },
            { id: "f", numeric: true, label: "Water Quality" },
            { id: "g", numeric: true, label: "Biodiversity (%)" }
        ];
        const columnData4 = [
            { id: "a", numeric: false, label: "" },
            { id: "b", numeric: false, label: "Count" },
            { id: "c", numeric: false, label: "Acres" }
        ];

        let farm_data = this.renderTable();
        var acre_chart = [];
        farm_data.forEach((f, index) => {
            acre_chart.push({
                year: f.year, //.toString(),
                field_acres: Math.round(f.field_acres),
                managed_acres: Math.round(f.managed_acres_calc),
                fill: this.colors[index]
            });
        });
        var line_chart = [];
        farm_data.forEach((f, index) => {
            line_chart.push({
                year: f.year, //.toString(),
                unique_farms: f.unique_farms,
                unique_fields: f.unique_fields,
                stroke: this.colors[index]
            });
        });

        var key;
        if (window.location.host.includes("fieldtomarket.org")) {
            key = "AIzaSyBeppehipaycm7a0WRPhbN9MTq4rpGWH4k";
        } else {
            key = "AIzaSyCqjdm8s3eVGfyYxAW_fHFy7_m0ERTSVQQ";
        }

        var years = [];
        project.project_data
            .filter(x => x.is_final && x.year > 2017)
            .forEach(pd => {
                if (!years.includes(pd.year)) years.push(pd.year);
            });
        years.sort(function (a, b) {
            return a - b;
        });
        var page_number = 8;
        var total_page_number = 9;

        let farm_table = this.renderTable2();
        project.crops_label.forEach(c_label => {
            var yearloop;
            if (
                farm_table.filter(a => a.crop_label === c_label && a.is_irrigated === true).length >= 1 &&
                farm_table.filter(a => a.crop_label === c_label && a.is_irrigated === false).length >= 1
            ) {
                yearloop = [true, false];
            } else {
                yearloop = [null];
            }

            yearloop.forEach(is_irr => {
                let filterFunction;
                if (is_irr === null) {
                    filterFunction = x => {
                        return x.is_final && x.crop_label === c_label && x.metrics && x.year > 2017;
                    };
                } else {
                    filterFunction = x => {
                        return (
                            x.is_final &&
                            x.crop_label === c_label &&
                            x.metrics &&
                            x.year > 2017 &&
                            x.is_irrigated === is_irr
                        );
                    };
                }

                if (project.project_data.filter(filterFunction).length > 1) {
                    total_page_number = total_page_number + 8;
                }

                years.forEach((y, index) => {
                    if (is_irr === null) {
                        filterFunction = x => {
                            return x.is_final && x.crop_label === c_label && x.metrics && x.year === y;
                        };
                    } else {
                        filterFunction = x => {
                            return (
                                x.is_final &&
                                x.crop_label === c_label &&
                                x.metrics &&
                                x.year === y &&
                                x.is_irrigated === is_irr
                            );
                        };
                    }
                    if (project.project_data.filter(filterFunction).length > 1) {
                        total_page_number = total_page_number + 3;
                    }
                });
            });
        });

        var sponsors = [];
        project.project_organization.forEach(po => {
            if (po.organization_role === "PS" && !sponsors.includes(po.organization_label))
                sponsors.push(po.organization_label);
        });
        var participants = [];
        project.project_participant.forEach(po => {
            if (po.participant_title === "PP" && !participants.includes(po.organization_label))
                participants.push(po.organization_label);
        });
        var crop_table = [];
        farm_table.forEach(po => {
            if (!crop_table.includes(po.crop_label)) crop_table.push(po.crop_label);
        });

        var show_irr_tab = false;
        const calculatedProjectBenchmarkGroups = project ? getCalculatedProjectBenchmarks(project) : [];
        calculatedProjectBenchmarkGroups.forEach(cp => {
            if (cp.is_irrigated) show_irr_tab = true;
        });

        var p_years = "";
        var p_date = "";
        if (project.project_benchmark_group[0] && project.project_benchmark_group[0].project_benchmarks[0]) {
            p_years = project.project_benchmark_group[0].project_benchmarks[0].years_included;
            let yourDate = new Date(project.project_benchmark_group[0].project_benchmarks[0].modify_date + " 12:00");
            p_date = yourDate.toLocaleDateString();
        }

        return (
            <>
                <div className={classes.page}>
                    <PageHeader project={project} />
                    <div className={classes.content}>
                        <h2 className={classes.reportHeader}>Fieldprint Project Report</h2>
                        <table style={{ width: "100%" }}>
                            <tr>
                                <td style={{ verticalAlign: "top", width: "50%" }}>
                                    <h3 className={classes.reportSubHeader}>SPONSORS</h3>
                                    {sponsors.map(a => {
                                        return <Typography className={classes.reportText}>{a}</Typography>;
                                    })}
                                    {sponsors.length === 0 && "-"}
                                </td>
                                <td style={{ verticalAlign: "top", width: "50%" }}>
                                    <h3 className={classes.reportSubHeader}>PARTNERS</h3>
                                    {participants.map(a => {
                                        return <Typography className={classes.reportText}>{a}</Typography>;
                                    })}
                                    {participants.length === 0 && (
                                        <Typography className={classes.reportText}>
                                            There are no other project partners for the project.
                                        </Typography>
                                    )}
                                </td>
                            </tr>
                        </table>
                        <table style={{ width: "100%" }}>
                            <tr>
                                <td style={{ verticalAlign: "top", width: "50%" }}>
                                    <h3 className={classes.reportSubHeader}>CROPS</h3>
                                    {project.crops_label.map(a => {
                                        return <Typography className={classes.reportText}>{a}</Typography>;
                                    })}
                                </td>
                                <td style={{ verticalAlign: "top", width: "50%" }}>
                                    <h3 className={classes.reportSubHeader}>YEARS</h3>
                                    <Typography className={classes.reportText}>
                                        {years.length > 1 ? years[0] + " - " + years[years.length - 1] : years[0]}
                                    </Typography>
                                </td>
                            </tr>
                        </table>
                        <h3 className={classes.reportSubHeader}>OVERVIEW</h3>
                        <Typography className={classes.reportText}>
                            <div dangerouslySetInnerHTML={{ __html: project.description }}></div>
                        </Typography>
                        <h3 className={classes.reportSubHeader}>OBJECTIVES</h3>
                        <Typography className={classes.reportText}>
                            <div>
                                Support the participating farmers in understanding the greenhouse gas emission impacts
                                of their current management decisions through use of the Fieldprint Platform by Year
                                One.
                            </div>
                        </Typography>
                        <Typography className={classes.reportText}>
                            <div>
                                Incentivize the adoption of nutrient management and soil health practices that reduce
                                greenhouse gas emissions (target reduction determination underway).
                            </div>
                        </Typography>
                        <h3 className={classes.reportSubHeader}>DISCLAIMER</h3>
                        <Typography className={classes.reportText} style={{ fontSize: 13 }}>
                            The report is for educational purposes only. Field to Market does not make any
                            representations, warranties or guarantees, express or implied, regarding the accuracy,
                            correctness, or completeness of the content or the services and products associated with the
                            Fieldprint Calculator, nor the applicability of its products for other purposes. It is your
                            sole responsibility to independently evaluate the accuracy, correctness or completeness of
                            the content and the services and products associated with Field to Market.
                        </Typography>
                    </div>
                    <PageFooter number={1} total={total_page_number} />
                </div>
                <div className={classes.page}>
                    <PageHeader project={project} />
                    <div className={classes.content}>
                        <h2 className={classes.reportHeader}>DATA QUALITY CONTROL CHECK</h2>
                        <Table>
                            <EnhancedTableHead
                                cellStyle={{ padding: "0px 6px", fontWeight: "bold" }}
                                columnData={columnData4}
                            />
                            <TableBody>
                                <TableRow style={{ height: 32 }} hover>
                                    <CustomTableCell style={{ padding: "0px 6px" }}>
                                        Crop Years where Data Status is Finalized
                                    </CustomTableCell>
                                    <CustomTableCell style={{ padding: "0px 6px", textAlign: "right" }}>
                                        {project.project_data
                                            .filter(x => x.is_final && x.year > 2017)
                                            .length.toLocaleString()}
                                    </CustomTableCell>
                                    <CustomTableCell style={{ padding: "0px 6px", textAlign: "right" }}>
                                        {Math.round(
                                            project.project_data
                                                .filter(x => x.is_final && x.year > 2017)
                                                .reduce((n, { field_acres }) => n + field_acres, 0)
                                        ).toLocaleString()}
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow style={{ height: 32 }} hover>
                                    <CustomTableCell style={{ padding: "0px 6px" }}>
                                        Crop Years where Data Status is Provisional
                                    </CustomTableCell>
                                    <CustomTableCell style={{ padding: "0px 6px", textAlign: "right" }}>
                                        {project.project_data
                                            .filter(x => !x.is_final && x.year > 2017)
                                            .length.toLocaleString()}
                                    </CustomTableCell>
                                    <CustomTableCell style={{ padding: "0px 6px", textAlign: "right" }}>
                                        {Math.round(
                                            project.project_data
                                                .filter(x => !x.is_final && x.year > 2017)
                                                .reduce((n, { field_acres }) => n + field_acres, 0)
                                        ).toLocaleString()}
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow style={{ height: 32 }} hover>
                                    <CustomTableCell style={{ padding: "0px 6px" }}>
                                        Crop Years that are Irrigated where Data Status is Finalized
                                    </CustomTableCell>
                                    <CustomTableCell style={{ padding: "0px 6px", textAlign: "right" }}>
                                        {project.project_data
                                            .filter(x => x.is_final && x.year > 2017 && x.is_irrigated)
                                            .length.toLocaleString()}
                                    </CustomTableCell>
                                    <CustomTableCell style={{ padding: "0px 6px", textAlign: "right" }}>
                                        {Math.round(
                                            project.project_data
                                                .filter(x => x.is_final && x.year > 2017 && x.is_irrigated)
                                                .reduce((n, { field_acres }) => n + field_acres, 0)
                                        ).toLocaleString()}
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow style={{ height: 32 }} hover>
                                    <CustomTableCell style={{ padding: "0px 6px" }}>
                                        Crop Years that are Non-Irrigated where Data Status is Finalized
                                    </CustomTableCell>
                                    <CustomTableCell style={{ padding: "0px 6px", textAlign: "right" }}>
                                        {project.project_data
                                            .filter(x => x.is_final && x.year > 2017 && !x.is_irrigated)
                                            .length.toLocaleString()}
                                    </CustomTableCell>
                                    <CustomTableCell style={{ padding: "0px 6px", textAlign: "right" }}>
                                        {Math.round(
                                            project.project_data
                                                .filter(x => x.is_final && x.year > 2017 && !x.is_irrigated)
                                                .reduce((n, { field_acres }) => n + field_acres, 0)
                                        ).toLocaleString()}
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow style={{ height: 32 }} hover>
                                    <CustomTableCell style={{ padding: "0px 6px" }}>
                                        Crop Years that do not have Metrics calculated
                                    </CustomTableCell>
                                    <CustomTableCell style={{ padding: "0px 6px", textAlign: "right" }}>
                                        {project.project_data
                                            .filter(x => x.year > 2017 && !x.metrics)
                                            .length.toLocaleString()}
                                    </CustomTableCell>
                                    <CustomTableCell style={{ padding: "0px 6px", textAlign: "right" }}>
                                        {Math.round(
                                            project.project_data
                                                .filter(x => x.year > 2017 && !x.metrics)
                                                .reduce((n, { field_acres }) => n + field_acres, 0)
                                        ).toLocaleString()}
                                    </CustomTableCell>
                                </TableRow>
                                <TableRow style={{ height: 32 }} hover>
                                    <CustomTableCell style={{ padding: "0px 6px" }}>
                                        Crop Years that have been calculated using older Metrics
                                    </CustomTableCell>
                                    <CustomTableCell style={{ padding: "0px 6px", textAlign: "right" }}>
                                        {project.project_data
                                            .filter(x => x.year > 2017 && x.metrics && x.metrics_ran !== "4.1")
                                            .length.toLocaleString()}
                                    </CustomTableCell>
                                    <CustomTableCell style={{ padding: "0px 6px", textAlign: "right" }}>
                                        {Math.round(
                                            project.project_data
                                                .filter(x => x.year > 2017 && x.metrics && x.metrics_ran !== "4.1")
                                                .reduce((n, { field_acres }) => n + field_acres, 0)
                                        ).toLocaleString()}
                                    </CustomTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Typography style={{ textAlign: "center", marginTop: 8 }}>
                            <i>See Data Quality Control Check Report for detailed statistics.</i>
                        </Typography>
                    </div>
                    <PageFooter number={2} total={total_page_number} />
                </div>
                <div className={classes.page}>
                    <PageHeader project={project} />
                    <div className={classes.content}>
                        <h2 className={classes.reportHeader}>PROJECT OVERVIEW</h2>
                        <div style={{ width: "100%", border: "1px solid", padding: 4 }}>
                            <h3 className={classes.popupHeader}>Entered Acres v. Enrolled Acres</h3>
                            <BarChart
                                width={625}
                                height={600}
                                margin={{ left: 10, right: 10, top: 15, bottom: 10 }}
                                data={acre_chart}>
                                <pattern
                                    id="pattern-stripe"
                                    width="8"
                                    height="8"
                                    patternUnits="userSpaceOnUse"
                                    patternTransform="rotate(45)">
                                    <rect width="4" height="8" transform="translate(0,0)" fill="white"></rect>
                                </pattern>
                                <mask id="mask-stripe">
                                    <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe)" />
                                </mask>
                                <CartesianGrid strokeDasharray="3 3" />
                                <Bar dataKey="field_acres">
                                    <LabelList
                                        dataKey="field_acres"
                                        position="top"
                                        style={{ fontWeight: "bold" }}
                                        formatter={value => new Intl.NumberFormat("en").format(value)}
                                    />
                                </Bar>
                                <Bar dataKey="managed_acres" shape={<this.CandyBar />}>
                                    <LabelList
                                        dataKey="managed_acres"
                                        position="top"
                                        style={{ fontWeight: "bold" }}
                                        formatter={value => new Intl.NumberFormat("en").format(value)}
                                    />
                                </Bar>
                                <XAxis dataKey="year">
                                    <Label
                                        style={{
                                            fontFamily: "'Source Sans Pro',sans-serif",
                                            fontSize: 16,
                                            color: "#666666"
                                        }}
                                        value="Project Year"
                                        offset={-5}
                                        position="insideBottom"
                                    />
                                </XAxis>
                                <YAxis
                                    label={{
                                        style: {
                                            fontFamily: "'Source Sans Pro',sans-serif",
                                            fontSize: 16,
                                            color: "#666666"
                                        },
                                        value: "Acres",
                                        angle: -90,
                                        offset: -5,
                                        position: "insideLeft"
                                    }}
                                    tickFormatter={value => new Intl.NumberFormat("en").format(value)}
                                />
                            </BarChart>
                        </div>
                        <div style={{ marginTop: 16, width: "calc(100% + 10px)" }}>
                            <Typography
                                style={{ textAlign: "center", padding: "16px 8px", backgroundColor: "#F0F0F0" }}>
                                The charts here represent the data associated with the Entered Acres (the first bar for
                                each year) and the Enrolled Acres (second bar for each year). Value for enrolled acres
                                at the project level is the total of a) the sum of the entered acres for each farmer
                                with less than 10% of their managed acres entered and b) the sum of the stated managed
                                acres for each farmer with more than 10% of their managed acres entered. The total
                                managed acres for a crop for a particular year is entered by farmers.
                            </Typography>
                        </div>
                    </div>
                    <PageFooter number={3} total={total_page_number} />
                </div>
                <div className={classes.page}>
                    <PageHeader project={project} />
                    <div className={classes.content}>
                        <h2 className={classes.reportHeader}>OVERVIEW REPORT</h2>
                        <div style={{ width: "100%", border: "1px solid", padding: 4 }}>
                            <h3 className={classes.popupHeader}>Fields</h3>
                            <LineChart
                                margin={{ left: 20, right: 20, top: 20, bottom: 15 }}
                                width={625}
                                height={275}
                                data={line_chart}>
                                <Line
                                    stroke="#aaa"
                                    isAnimationActive={false}
                                    dot={this.labelDot}
                                    type="monotone"
                                    dataKey="unique_fields">
                                    <LabelList dataKey="unique_fields" position="top" content={this.farmFieldsLabel} />
                                </Line>
                                <XAxis dataKey="year" axisLine={false}>
                                    <Label
                                        style={{
                                            fontFamily: "'Source Sans Pro',sans-serif",
                                            fontSize: 16,
                                            color: "#666666"
                                        }}
                                        value="Project Year"
                                        offset={-10}
                                        position="insideBottom"
                                    />
                                </XAxis>
                                <YAxis hide="true" domain={["dataMin", "dataMax"]} />
                            </LineChart>
                        </div>
                        <div style={{ marginTop: 16, marginBottom: 40, width: "calc(100% + 10px)" }}>
                            <Typography
                                style={{ textAlign: "center", padding: "16px 8px", backgroundColor: "#F0F0F0" }}>
                                The number of unique fields with at least one crop year of data, by year is compared
                                here.
                            </Typography>
                        </div>
                        <div style={{ width: "100%", border: "1px solid", padding: 4 }}>
                            <h3 className={classes.popupHeader}>Farms</h3>
                            <LineChart
                                margin={{ left: 20, right: 20, top: 20, bottom: 15 }}
                                width={625}
                                height={275}
                                data={line_chart}>
                                <Line
                                    stroke="#aaa"
                                    isAnimationActive={false}
                                    dot={this.labelDot}
                                    type="monotone"
                                    dataKey="unique_farms">
                                    <LabelList dataKey="unique_farms" position="top" content={this.farmFieldsLabel} />
                                </Line>
                                <XAxis dataKey="year" axisLine={false}>
                                    <Label
                                        style={{
                                            fontFamily: "'Source Sans Pro',sans-serif",
                                            fontSize: 16,
                                            color: "#666666"
                                        }}
                                        value="Project Year"
                                        offset={-10}
                                        position="insideBottom"
                                    />
                                </XAxis>
                                <YAxis hide="true" domain={["dataMin", "dataMax"]} />
                            </LineChart>
                        </div>
                        <div style={{ marginTop: 16, width: "calc(100% + 10px)" }}>
                            <Typography
                                style={{ textAlign: "center", padding: "16px 8px", backgroundColor: "#F0F0F0" }}>
                                The number of unique farms with at least one crop year of data, by year is compared
                                here.
                            </Typography>
                        </div>
                    </div>
                    <PageFooter number={4} total={total_page_number} />
                </div>
                <div className={classes.page}>
                    <PageHeader project={project} />
                    <div className={classes.content}>
                        <br />
                        <div style={{ height: 760, width: "100%", border: "1px solid" }}>
                            <ProjectMap
                                googleMapURL={
                                    "https://maps.googleapis.com/maps/api/js?key=" +
                                    key +
                                    "&v=3.exp&libraries=geometry,drawing,places"
                                }
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `100%` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                boundary={[project.boundary]}
                                fields={project.project_data.filter(x => x.is_final && x.year > 2017)}
                            />
                        </div>
                        <div style={{ marginTop: 16, width: "calc(100% + 10px)" }}>
                            <Typography
                                style={{ textAlign: "center", padding: "16px 8px", backgroundColor: "#F0F0F0" }}>
                                Map showing approximate location of entered fields. The rectangle on the map represents
                                a bounding box around all entered field boundaries. The circles on the map represent the
                                approximate location of the fields with a count of how many fields are in that location.
                            </Typography>
                        </div>
                    </div>
                    <PageFooter number={5} total={total_page_number} />
                </div>
                <div className={classes.page}>
                    <PageHeader project={project} />
                    <div className={classes.content}>
                        <br />
                        <div style={{ width: "100%", border: "1px solid", padding: 4 }}>
                            <Table>
                                <EnhancedTableHead
                                    cellStyle={{ padding: "0px 6px", fontWeight: "bold" }}
                                    columnData={columnData2}
                                />
                                <TableBody>
                                    {farm_table.map(a => {
                                        return (
                                            <TableRow style={{ height: 32 }} hover key={a.id}>
                                                <CustomTableCell style={{ padding: "0px 6px" }}>
                                                    {a.year}
                                                </CustomTableCell>
                                                <CustomTableCell style={{ padding: "0px 6px" }}>
                                                    {a.crop_label}
                                                </CustomTableCell>
                                                <CustomTableCell style={{ padding: "0px 6px" }}>
                                                    {a.irrigated ? "Irrigated" : "Rainfed"}
                                                </CustomTableCell>
                                                <CustomTableCell style={{ padding: "0px 6px" }}>
                                                    {a.unique_farms}
                                                </CustomTableCell>
                                                <CustomTableCell style={{ padding: "0px 6px" }}>
                                                    {a.unique_fields}
                                                </CustomTableCell>
                                                <CustomTableCell style={{ padding: "0px 6px" }}>
                                                    {Number(a.field_acres).toLocaleString(undefined, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })}
                                                </CustomTableCell>
                                                <CustomTableCell style={{ padding: "0px 6px" }}>
                                                    {Number(a.managed_acres_calc).toLocaleString(undefined, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })}
                                                </CustomTableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                        <div style={{ marginTop: 8, width: "calc(100% + 10px)" }}>
                            <Typography style={{ textAlign: "center", padding: "8px 8px", backgroundColor: "#F0F0F0" }}>
                                The statistics in the table encompass crop years{" "}
                                {years.length > 1 ? years[0] + " - " + years[years.length - 1] : years[0]} with data on
                                the following:&nbsp;
                                {crop_table.map(a => {
                                    return <>{a}, </>;
                                })}
                                and their seasons. Note that these totals can change as farmers associate and dissociate
                                fields from the project. Value for enrolled acres at the project level is the total of
                                a) the sum of the entered acres for each farmer with less than 10% of their managed
                                acres entered and b) the sum of the stated managed acres for each farmer with more than
                                10% of their managed acres entered. The total managed acres for a crop for a particular
                                year is entered by farmers.
                            </Typography>
                        </div>
                    </div>
                    <PageFooter number={6} total={total_page_number} />
                </div>
                <div className={classes.page}>
                    <PageHeader project={project} />
                    <div className={classes.content}>
                        <h2 className={classes.reportHeader}>PROJECT BENCHMARKS</h2>
                        <div style={{ width: "100%", border: "1px solid", padding: 4 }}>
                            <Table>
                                <EnhancedTableHead
                                    cellStyle={{ padding: "0px 6px", fontWeight: "bold" }}
                                    columnData={show_irr_tab ? columnData3 : columnData3b}
                                />
                                <TableBody>
                                    {calculatedProjectBenchmarkGroups.map(a => {
                                        const selectedProjectBenchmarkGroupData = getSelectedProjectBenchmarkGroupData(
                                            project,
                                            a.label
                                        );
                                        return (
                                            <TableRow style={{ height: 32 }} hover key={a.id}>
                                                <CustomTableCell style={{ padding: "0px 6px" }}>
                                                    {a.crop_label + " " + (a.irrigated ? "Irrigated" : "Rainfed")}
                                                </CustomTableCell>
                                                <CustomTableCell style={{ padding: "0px 6px" }}>
                                                    {
                                                        selectedProjectBenchmarkGroupData.find(
                                                            b => b.metric_code === "landUse"
                                                        ).value
                                                    }
                                                </CustomTableCell>
                                                <CustomTableCell style={{ padding: "0px 6px" }}>
                                                    {
                                                        selectedProjectBenchmarkGroupData.find(
                                                            b => b.metric_code === "soilConservation"
                                                        ).value
                                                    }
                                                </CustomTableCell>
                                                {show_irr_tab && (
                                                    <CustomTableCell style={{ padding: "0px 6px" }}>
                                                        {selectedProjectBenchmarkGroupData.find(
                                                            b => b.metric_code === "irrigationWaterUse"
                                                        )
                                                            ? selectedProjectBenchmarkGroupData.find(
                                                                  b => b.metric_code === "irrigationWaterUse"
                                                              ).value
                                                            : "-"}
                                                    </CustomTableCell>
                                                )}
                                                <CustomTableCell style={{ padding: "0px 6px" }}>
                                                    {
                                                        selectedProjectBenchmarkGroupData.find(
                                                            b => b.metric_code === "energyUse"
                                                        ).value
                                                    }
                                                </CustomTableCell>
                                                <CustomTableCell style={{ padding: "0px 6px" }}>
                                                    {
                                                        selectedProjectBenchmarkGroupData.find(
                                                            b => b.metric_code === "greenhouseGas"
                                                        ).value
                                                    }
                                                </CustomTableCell>
                                                <CustomTableCell style={{ padding: "0px 6px" }}>
                                                    {
                                                        selectedProjectBenchmarkGroupData.find(
                                                            b => b.metric_code === "waterQuality"
                                                        ).value
                                                    }
                                                </CustomTableCell>
                                                <CustomTableCell style={{ padding: "0px 6px" }}>
                                                    {
                                                        selectedProjectBenchmarkGroupData.find(
                                                            b => b.metric_code === "biodiversity"
                                                        ).value
                                                    }
                                                </CustomTableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                        <div style={{ marginTop: 8, width: "calc(100% + 10px)" }}>
                            <Typography style={{ textAlign: "center" }}>Last Updated: {p_date}</Typography>
                            <Typography style={{ textAlign: "center" }}>Years Included: {p_years}</Typography>
                            <Typography
                                style={{
                                    marginTop: 16,
                                    textAlign: "center",
                                    padding: "8px 8px",
                                    backgroundColor: "#F0F0F0"
                                }}>
                                A Project Benchmark is a value for each metric calculated based on the current
                                performance of fields and growers enrolled in a Project. Project Benchmarks should be
                                used with growers in the Project as comparison points to evaluate how their scores
                                relate to those of other growers in the same Project. Project Benchmarks are crop and
                                project specific and calculated separately for both irrigated and non-irrigated
                                production. A Project must have a minimum of ten farmer participants before Project
                                Benchmarks are calculated. Project Benchmarks are calculated on a running or cumulative
                                basis starting in Year 1 and with a maximum of a five-year average (using the most
                                recent years). Note that a Project Benchmark cannot be calculated for Soil Carbon.
                            </Typography>
                        </div>
                    </div>
                    <PageFooter number={7} total={total_page_number} />
                </div>
                {project.crops_label.map(c_label => {
                    let yearloop2;
                    if (
                        farm_table.filter(a => a.crop_label === c_label && a.is_irrigated === true).length >= 1 &&
                        farm_table.filter(a => a.crop_label === c_label && a.is_irrigated === false).length >= 1
                    ) {
                        yearloop2 = [true, false];
                    } else {
                        yearloop2 = [null];
                    }
                    var jsx = [];
                    yearloop2.forEach(is_irr2 => {
                        var irr_string = "";
                        if (is_irr2 !== null && is_irr2 === true) irr_string = "IRRIGATED";
                        else if (is_irr2 !== null && is_irr2 === false) irr_string = "RAINFED";
                        var land_use = [];
                        var energy_use = [];
                        var energy_use2 = [];
                        var water_quality = [];
                        var soil_conservation = [];
                        var erosions = [];
                        var soil_carbon = [];
                        var irrigation = [];
                        var ghg = [];
                        var ghg2 = [];
                        var biodiv = [];
                        var subyears = [];
                        var land_use_unit = "";
                        var energy_use_unit = "";
                        var energy_use_unit2 = "";
                        var irrigation_unit = "";
                        var ghg_unit = "";
                        var ghg_unit2 = "";
                        var number_of_fields = 0;
                        years.forEach((y, index) => {
                            let filterFunction2;
                            if (is_irr2 === null) {
                                filterFunction2 = x => {
                                    return x.is_final && x.crop_label === c_label && x.metrics && x.year === y;
                                };
                            } else {
                                filterFunction2 = x => {
                                    return (
                                        x.is_final &&
                                        x.crop_label === c_label &&
                                        x.metrics &&
                                        x.year === y &&
                                        x.is_irrigated === is_irr2
                                    );
                                };
                            }
                            var total_production = 0;
                            var total_area = 0;
                            var temp_land_use = 0;
                            var temp_energy_use = 0;
                            var temp_energy_use2 = 0;
                            var temp_water_quality = 0;
                            var temp_soil_conservation = 0;
                            var temp_water_erosion = [];
                            var temp_wind_erosion = [];
                            var temp_soil_carbon = 0;
                            var temp_irrigation = 0;
                            var temp_ghg = 0;
                            var temp_ghg2 = 0;
                            var temp_biodiv = 0;
                            project.project_data.filter(filterFunction2).forEach(pd => {
                                var tp =
                                    pd.metrics.Calculator.result.metadata.boundaryAcres.value *
                                    pd.metrics.Calculator.result.cropyears[0].landUse.yieldAdjusted.value;
                                total_production += tp;
                                var ar = pd.metrics.Calculator.result.metadata.boundaryAcres.value;
                                total_area += ar;
                                temp_land_use +=
                                    tp * pd.metrics.Calculator.result.cropyears[0].landUse.fieldprintResult.value;
                                land_use_unit = pd.metrics.Calculator.result.cropyears[0].landUse.fieldprintResult.unit;
                                temp_energy_use +=
                                    tp * pd.metrics.Calculator.result.cropyears[0].energyUse.fieldprintResult.value;
                                temp_energy_use2 +=
                                    ar * pd.metrics.Calculator.result.cropyears[0].energyUse.totalEnergy.value;
                                energy_use_unit =
                                    pd.metrics.Calculator.result.cropyears[0].energyUse.fieldprintResult.unit;
                                energy_use_unit2 = pd.metrics.Calculator.result.cropyears[0].energyUse.totalEnergy.unit;
                                temp_water_quality +=
                                    ar * pd.metrics.Calculator.result.cropyears[0].waterQuality.fieldprintResult.value;
                                temp_soil_conservation +=
                                    ar *
                                    pd.metrics.Calculator.result.cropyears[0].soilConservation.fieldprintResult.value;
                                temp_water_erosion.push(
                                    pd.metrics.Calculator.result.cropyears[0].soilConservation.fieldprintWater.value
                                );
                                temp_wind_erosion.push(
                                    pd.metrics.Calculator.result.cropyears[0].soilConservation.fieldprintWind.value
                                );

                                temp_soil_carbon +=
                                    ar * pd.metrics.Calculator.result.cropyears[0].soilCarbon.fieldprintResult.value;

                                temp_ghg +=
                                    tp * pd.metrics.Calculator.result.cropyears[0].greenhouseGas.fieldprintResult.value;
                                ghg_unit =
                                    pd.metrics.Calculator.result.cropyears[0].greenhouseGas.fieldprintResult.unit;
                                temp_ghg2 +=
                                    ar * pd.metrics.Calculator.result.cropyears[0].greenhouseGas.totalGreenhouse.value;
                                ghg_unit2 =
                                    pd.metrics.Calculator.result.cropyears[0].greenhouseGas.totalGreenhouse.unit;

                                temp_biodiv +=
                                    ar * pd.metrics.Calculator.result.cropyears[0].biodiversity.fieldprintResult.value;
                                if (pd.metrics.Calculator.result.cropyears[0].irrigationWaterUse) {
                                    temp_irrigation +=
                                        ar *
                                        pd.metrics.Calculator.result.cropyears[0].irrigationWaterUse.fieldprintResult
                                            .value;
                                    irrigation_unit =
                                        pd.metrics.Calculator.result.cropyears[0].irrigationWaterUse.fieldprintResult
                                            .unit;
                                }
                                number_of_fields++;
                            });
                            if (total_production > 0) {
                                subyears.push(y);
                                land_use.push({
                                    year: parseInt(y),
                                    value: Math.round((temp_land_use / total_production) * 10000) / 10000,
                                    fill: this.colors[index]
                                });
                                energy_use.push({
                                    year: parseInt(y),
                                    value: Math.round(temp_energy_use / total_production),
                                    fill: this.colors[index]
                                });
                                energy_use2.push({
                                    year: parseInt(y),
                                    value: Math.round(temp_energy_use2 / total_area),
                                    fill: this.colors[index]
                                });
                                water_quality.push({
                                    year: parseInt(y),
                                    value: Math.round((temp_water_quality / total_area) * 100) / 100,
                                    fill: this.colors[index]
                                });
                                soil_conservation.push({
                                    year: parseInt(y),
                                    value: Math.round((temp_soil_conservation / total_area) * 100) / 100,
                                    fill: this.colors[index]
                                });
                                erosions[y] = [
                                    {
                                        name: "Water",
                                        value:
                                            temp_water_erosion.reduce((a, b) => a + b, 0) / temp_water_erosion.length,
                                        fill: this.colors[index]
                                    },
                                    {
                                        name: "Wind",
                                        value: temp_wind_erosion.reduce((a, b) => a + b, 0) / temp_wind_erosion.length,
                                        fill: this.colors[index]
                                    }
                                ];
                                soil_carbon.push({
                                    year: parseInt(y),
                                    value: Math.round((temp_soil_carbon / total_area) * 100) / 100,
                                    fill: this.colors[index]
                                });
                                ghg.push({
                                    year: parseInt(y),
                                    value: Math.round((temp_ghg / total_production) * 10) / 10,
                                    fill: this.colors[index]
                                });
                                ghg2.push({
                                    year: parseInt(y),
                                    value: Math.round((temp_ghg2 / total_area) * 10) / 10,
                                    fill: this.colors[index]
                                });
                                biodiv.push({
                                    year: parseInt(y),
                                    value: Math.round((temp_biodiv / total_area) * 100 * 10) / 10,
                                    fill: this.colors[index]
                                });
                                irrigation.push({
                                    year: parseInt(y),
                                    value: Math.round((temp_irrigation / total_area) * 100) / 100,
                                    fill: this.colors[index]
                                });
                            }
                        });

                        var show_irrigation = false;
                        if (irrigation.filter(x => x.value > 0).length > 0) show_irrigation = true;
                        var usesub = false;
                        if (ghg_unit.includes("2")) {
                            usesub = true;
                        }

                        if (land_use.length > 0 && number_of_fields > 1) {
                            jsx.push(
                                <>
                                    <div className={classes.page}>
                                        <PageHeader project={project} subyears={subyears} />
                                        <div className={classes.content}>
                                            <h2
                                                className={classes.reportHeader}
                                                style={{ fontSize: 32, marginTop: 400 }}>
                                                METRIC AVERAGES FOR {c_label.toUpperCase()} {irr_string}
                                            </h2>
                                        </div>
                                        <PageFooter number={page_number + 1} total={total_page_number} />
                                    </div>
                                    <div className={classes.page}>
                                        <PageHeader project={project} subyears={subyears} />
                                        <div className={classes.content}>
                                            <h2 className={classes.reportHeader}>
                                                {irr_string} {c_label.toUpperCase()} METRIC AVERAGES
                                            </h2>
                                            <div style={{ width: "100%", border: "1px solid", padding: 4 }}>
                                                <h3 className={classes.popupHeader}>Land Use</h3>
                                                <BarChart
                                                    width={625}
                                                    height={240}
                                                    margin={{ top: 15, left: 15, right: 0, bottom: 15 }}
                                                    data={land_use}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="year">
                                                        <Label
                                                            style={{
                                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                                fontSize: 16,
                                                                color: "#666666"
                                                            }}
                                                            value="Project Year"
                                                            offset={-10}
                                                            position="insideBottom"
                                                        />
                                                    </XAxis>
                                                    <YAxis
                                                        dataKey="value"
                                                        label={{
                                                            style: {
                                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                                fontSize: 16,
                                                                color: "#666666"
                                                            },
                                                            value: land_use_unit,
                                                            angle: -90,
                                                            position: "insideBottomLeft"
                                                        }}
                                                    />
                                                    <Bar
                                                        dataKey="value"
                                                        name={
                                                            "Acres of land to produce a unit of crop (" +
                                                            land_use_unit +
                                                            ")"
                                                        }
                                                        fill={null}>
                                                        <LabelList
                                                            style={{ fontWeight: "bold" }}
                                                            dataKey="value"
                                                            position="top"
                                                        />
                                                    </Bar>
                                                </BarChart>
                                            </div>
                                            <div
                                                style={{ marginTop: 16, marginBottom: 40, width: "calc(100% + 10px)" }}>
                                                <Typography
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "16px 8px",
                                                        backgroundColor: "#F0F0F0"
                                                    }}>
                                                    This chart shows the trend in land being used per unit of crop in
                                                    production. The trend we aspire for is to encourage less land usage
                                                    per unit of crop in acres.
                                                </Typography>
                                            </div>
                                            <div style={{ width: "100%", border: "1px solid", padding: 4 }}>
                                                <h3 className={classes.popupHeader}>Soil Carbon</h3>
                                                <BarChart
                                                    width={625}
                                                    height={240}
                                                    margin={{ top: 15, left: 15, right: 0, bottom: 15 }}
                                                    data={soil_carbon}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="year">
                                                        <Label
                                                            style={{
                                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                                fontSize: 16,
                                                                color: "#666666"
                                                            }}
                                                            value="Project Year"
                                                            offset={-10}
                                                            position="insideBottom"
                                                        />
                                                    </XAxis>
                                                    <YAxis
                                                        dataKey="value"
                                                        /*domain={[-1, 1]}*/ label={{
                                                            style: {
                                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                                fontSize: 16,
                                                                color: "#666666"
                                                            },
                                                            value: "NRCS Soil Condition Index",
                                                            angle: -90,
                                                            position: "insideBottomLeft"
                                                        }}
                                                    />
                                                    <Bar
                                                        dataKey="value"
                                                        name={
                                                            "Acres of land to produce a unit of crop (" +
                                                            land_use_unit +
                                                            ")"
                                                        }
                                                        fill={null}>
                                                        <LabelList
                                                            style={{ fontWeight: "bold" }}
                                                            dataKey="value"
                                                            position="top"
                                                        />
                                                    </Bar>
                                                </BarChart>
                                            </div>
                                            <div style={{ marginTop: 16, width: "calc(100% + 10px)" }}>
                                                <Typography
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "16px 8px",
                                                        backgroundColor: "#F0F0F0"
                                                    }}>
                                                    The chart shows the trend in soil carbon across project years based
                                                    on the Soil Conditioning Index (SCI). A positive value indicates
                                                    increasing soil carbon and a negative value indicates depletion of
                                                    soil carbon.
                                                </Typography>
                                            </div>
                                        </div>
                                        <PageFooter number={page_number + 2} total={total_page_number} />
                                    </div>
                                    <div className={classes.page}>
                                        <PageHeader project={project} subyears={subyears} />
                                        <div className={classes.content}>
                                            <h2 className={classes.reportHeader}>
                                                {irr_string} {c_label.toUpperCase()} METRIC AVERAGES
                                            </h2>
                                            <div style={{ width: "100%", border: "1px solid", padding: 4 }}>
                                                <h3 className={classes.popupHeader}>Energy Use ({energy_use_unit})</h3>
                                                <BarChart
                                                    width={625}
                                                    height={250}
                                                    margin={{ top: 15, left: 15, right: 0, bottom: 15 }}
                                                    data={energy_use}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="year">
                                                        <Label
                                                            style={{
                                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                                fontSize: 16,
                                                                color: "#666666"
                                                            }}
                                                            value="Project Year"
                                                            offset={-10}
                                                            position="insideBottom"
                                                        />
                                                    </XAxis>
                                                    <YAxis
                                                        dataKey="value"
                                                        label={{
                                                            style: {
                                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                                fontSize: 16,
                                                                color: "#666666"
                                                            },
                                                            value: energy_use_unit,
                                                            angle: -90,
                                                            offset: -5,
                                                            position: "insideBottomLeft"
                                                        }}
                                                        tickFormatter={value =>
                                                            new Intl.NumberFormat("en").format(value)
                                                        }
                                                    />
                                                    <Bar dataKey="value" fill={null}>
                                                        <LabelList
                                                            dataKey="value"
                                                            position="top"
                                                            style={{ fontWeight: "bold" }}
                                                            formatter={value =>
                                                                new Intl.NumberFormat("en").format(value)
                                                            }
                                                        />
                                                    </Bar>
                                                </BarChart>
                                            </div>
                                            <div
                                                style={{ marginTop: 20, marginBottom: 40, width: "calc(100% + 10px)" }}>
                                                <Typography
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "16px 8px",
                                                        backgroundColor: "#F0F0F0"
                                                    }}>
                                                    This chart shows the trend in energy usage per unit of crop in
                                                    production. The trend we aspire is to encourage less energy usage
                                                    per unit of crop in BTU.
                                                </Typography>
                                            </div>
                                            <div style={{ width: "100%", border: "1px solid", padding: 4 }}>
                                                <h3 className={classes.popupHeader}>Energy Use ({energy_use_unit2})</h3>
                                                <BarChart
                                                    width={625}
                                                    height={250}
                                                    margin={{ top: 15, left: 35, right: 0, bottom: 15 }}
                                                    data={energy_use2}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="year">
                                                        <Label
                                                            style={{
                                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                                fontSize: 16,
                                                                color: "#666666"
                                                            }}
                                                            value="Project Year"
                                                            offset={-10}
                                                            position="insideBottom"
                                                        />
                                                    </XAxis>
                                                    <YAxis
                                                        dataKey="value"
                                                        label={{
                                                            style: {
                                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                                fontSize: 16,
                                                                color: "#666666"
                                                            },
                                                            value: energy_use_unit2,
                                                            angle: -90,
                                                            offset: -20,
                                                            position: "insideBottomLeft"
                                                        }}
                                                        tickFormatter={value =>
                                                            new Intl.NumberFormat("en").format(value)
                                                        }
                                                    />
                                                    <Bar dataKey="value" fill={null}>
                                                        <LabelList
                                                            dataKey="value"
                                                            position="top"
                                                            style={{ fontWeight: "bold" }}
                                                            formatter={value =>
                                                                new Intl.NumberFormat("en").format(value)
                                                            }
                                                        />
                                                    </Bar>
                                                </BarChart>
                                            </div>
                                            <div style={{ marginTop: 20, width: "calc(100% + 10px)" }}>
                                                <Typography
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "16px 8px",
                                                        backgroundColor: "#F0F0F0"
                                                    }}>
                                                    This chart shows the trend in energy usage per acre in production.
                                                    The trend we aspire is to encourage less energy usage per acre in
                                                    BTU.
                                                </Typography>
                                            </div>
                                        </div>
                                        <PageFooter number={page_number + 3} total={total_page_number} />
                                    </div>
                                    <div className={classes.page}>
                                        <PageHeader project={project} subyears={subyears} />
                                        <div className={classes.content}>
                                            <h2 className={classes.reportHeader}>
                                                {irr_string} {c_label.toUpperCase()} METRIC AVERAGES
                                            </h2>
                                            <div style={{ width: "100%", border: "1px solid", padding: 4 }}>
                                                <h3 className={classes.popupHeader}>Water Quality</h3>
                                                <BarChart
                                                    width={625}
                                                    height={260}
                                                    margin={{ top: 15, left: 15, right: 0, bottom: 15 }}
                                                    data={water_quality}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="year">
                                                        <Label
                                                            style={{
                                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                                fontSize: 16,
                                                                color: "#666666"
                                                            }}
                                                            value="Project Year"
                                                            offset={-10}
                                                            position="insideBottom"
                                                        />
                                                    </XAxis>
                                                    <YAxis
                                                        dataKey="value"
                                                        width={30}
                                                        label={{
                                                            style: {
                                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                                fontSize: 16,
                                                                color: "#666666"
                                                            },
                                                            value: "Nutrient Loss Pathways",
                                                            angle: -90,
                                                            position: "insideBottomLeft"
                                                        }}
                                                        domain={[0, 4]}
                                                    />
                                                    <Bar
                                                        dataKey="value"
                                                        name="Number of nutrient loss pathways migigated out of four pathways"
                                                        fill={null}>
                                                        <LabelList
                                                            style={{ fontWeight: "bold" }}
                                                            dataKey="value"
                                                            position="top"
                                                        />
                                                    </Bar>
                                                </BarChart>
                                            </div>
                                            <div
                                                style={{ marginTop: 16, marginBottom: 40, width: "calc(100% + 10px)" }}>
                                                <Typography
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "16px 8px",
                                                        backgroundColor: "#F0F0F0"
                                                    }}>
                                                    This chart shows the number of nutrient loss pathways that were
                                                    mitigated out of four pathways.
                                                </Typography>
                                            </div>
                                            <div style={{ width: "100%", border: "1px solid", padding: 4 }}>
                                                <h3 className={classes.popupHeader}>Soil Conservation</h3>
                                                <BarChart
                                                    width={625}
                                                    height={260}
                                                    margin={{ top: 15, left: -10, right: 0, bottom: 15 }}
                                                    data={soil_conservation}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="year">
                                                        <Label
                                                            style={{
                                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                                fontSize: 16,
                                                                color: "#666666"
                                                            }}
                                                            value="Project Year"
                                                            offset={-10}
                                                            position="insideBottom"
                                                        />
                                                    </XAxis>
                                                    <YAxis
                                                        dataKey="value"
                                                        label={{
                                                            style: {
                                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                                fontSize: 16,
                                                                color: "#666666"
                                                            },
                                                            value: "Tons of Soil Loss Per Acre",
                                                            angle: -90,
                                                            offset: 25,
                                                            position: "insideBottomLeft"
                                                        }}
                                                    />
                                                    <Bar
                                                        dataKey="value"
                                                        name="Tons of soil loss per acre per year"
                                                        fill={null}>
                                                        <LabelList
                                                            style={{ fontWeight: "bold" }}
                                                            dataKey="value"
                                                            position="top"
                                                        />
                                                    </Bar>
                                                </BarChart>
                                            </div>
                                            <div style={{ marginTop: 20, width: "calc(100% + 10px)" }}>
                                                <Typography
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "16px 8px",
                                                        backgroundColor: "#F0F0F0"
                                                    }}>
                                                    This chart shows the trend for loss of soil per acre, per year.
                                                </Typography>
                                            </div>
                                        </div>
                                        <PageFooter number={page_number + 4} total={total_page_number} />
                                    </div>
                                    <div className={classes.page}>
                                        <PageHeader project={project} subyears={subyears} />
                                        <div className={classes.content}>
                                            <h2 className={classes.reportHeader}>
                                                {irr_string} {c_label.toUpperCase()} METRIC AVERAGES
                                            </h2>

                                            <div style={{ width: "100%", border: "1px solid", padding: 4 }}>
                                                <h3 className={classes.popupHeader}>
                                                    GHG Emissions (
                                                    {usesub ? (
                                                        <span>
                                                            {ghg_unit.split("2")[0]}
                                                            <sub>2</sub>
                                                            {ghg_unit.split("2")[1]}
                                                        </span>
                                                    ) : (
                                                        <span>{ghg_unit}</span>
                                                    )}
                                                    )
                                                </h3>
                                                <BarChart
                                                    width={625}
                                                    height={250}
                                                    margin={{ top: 15, left: 5, right: 0, bottom: 15 }}
                                                    data={ghg}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="year">
                                                        <Label
                                                            style={{
                                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                                fontSize: 16,
                                                                color: "#666666"
                                                            }}
                                                            value="Project Year"
                                                            offset={-10}
                                                            position="insideBottom"
                                                        />
                                                    </XAxis>
                                                    <YAxis
                                                        dataKey="value"
                                                        label={{
                                                            style: {
                                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                                fontSize: 16,
                                                                color: "#666666"
                                                            },
                                                            value: ghg_unit,
                                                            angle: -90,
                                                            offset: 20,
                                                            position: "insideBottomLeft"
                                                        }}
                                                        tickFormatter={value =>
                                                            new Intl.NumberFormat("en").format(value)
                                                        }
                                                    />
                                                    <Bar dataKey="value" fill={null}>
                                                        <LabelList
                                                            dataKey="value"
                                                            position="top"
                                                            style={{ fontWeight: "bold" }}
                                                            formatter={value =>
                                                                new Intl.NumberFormat("en").format(value)
                                                            }
                                                        />
                                                    </Bar>
                                                </BarChart>
                                            </div>
                                            <div
                                                style={{ marginTop: 16, marginBottom: 40, width: "calc(100% + 10px)" }}>
                                                <Typography
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "16px 8px",
                                                        backgroundColor: "#F0F0F0"
                                                    }}>
                                                    The chart shows the trend in GHG emissions in pounds of carbon
                                                    dioxide equivalent emitted per unit of crop production.
                                                </Typography>
                                            </div>
                                            <div style={{ width: "100%", border: "1px solid", padding: 4 }}>
                                                <h3 className={classes.popupHeader}>
                                                    GHG Emissions (
                                                    {usesub ? (
                                                        <span>
                                                            {ghg_unit2.split("2")[0]}
                                                            <sub>2</sub>
                                                            {ghg_unit2.split("2")[1]}
                                                        </span>
                                                    ) : (
                                                        <span>{ghg_unit2}</span>
                                                    )}
                                                    )
                                                </h3>
                                                <BarChart
                                                    width={625}
                                                    height={250}
                                                    margin={{ top: 15, left: 15, right: 0, bottom: 15 }}
                                                    data={ghg2}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="year">
                                                        <Label
                                                            style={{
                                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                                fontSize: 16,
                                                                color: "#666666"
                                                            }}
                                                            value="Project Year"
                                                            offset={-10}
                                                            position="insideBottom"
                                                        />
                                                    </XAxis>
                                                    <YAxis
                                                        dataKey="value"
                                                        label={{
                                                            style: {
                                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                                fontSize: 16,
                                                                color: "#666666"
                                                            },
                                                            value: ghg_unit2,
                                                            angle: -90,
                                                            position: "insideBottomLeft"
                                                        }}
                                                        tickFormatter={value =>
                                                            new Intl.NumberFormat("en").format(value)
                                                        }
                                                    />
                                                    <Bar dataKey="value" fill={null}>
                                                        <LabelList
                                                            dataKey="value"
                                                            position="top"
                                                            style={{ fontWeight: "bold" }}
                                                            formatter={value =>
                                                                new Intl.NumberFormat("en").format(value)
                                                            }
                                                        />
                                                    </Bar>
                                                </BarChart>
                                            </div>
                                            <div style={{ marginTop: 16, width: "calc(100% + 10px)" }}>
                                                <Typography
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "16px 8px",
                                                        backgroundColor: "#F0F0F0"
                                                    }}>
                                                    The chart shows the trend in GHG emissions in pounds of carbon
                                                    dioxide equivalent emitted per land unit.
                                                </Typography>
                                            </div>
                                        </div>
                                        <PageFooter number={page_number + 5} total={total_page_number} />
                                    </div>
                                    <div className={classes.page}>
                                        <PageHeader project={project} subyears={subyears} />
                                        <div className={classes.content}>
                                            <h2 className={classes.reportHeader}>
                                                {irr_string} {c_label.toUpperCase()} METRIC AVERAGES
                                            </h2>
                                            <div style={{ width: "100%", border: "1px solid", padding: 4 }}>
                                                <h3 className={classes.popupHeader}>Biodiversity</h3>
                                                <BarChart
                                                    width={625}
                                                    height={255}
                                                    margin={{ left: 15, right: 20, top: 15, bottom: 15 }}
                                                    data={biodiv}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <Bar
                                                        dataKey="value"
                                                        name="Total % realized habitat potential index of cultivated land">
                                                        <LabelList
                                                            dataKey="value"
                                                            position="top"
                                                            content={this.bioLabel}
                                                        />
                                                    </Bar>
                                                    <XAxis dataKey="year">
                                                        <Label
                                                            style={{
                                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                                fontSize: 16,
                                                                color: "#666666"
                                                            }}
                                                            value="Project Year"
                                                            offset={-15}
                                                            position="insideBottom"
                                                        />
                                                    </XAxis>
                                                    <YAxis
                                                        unit="%"
                                                        domain={[0, 100]}
                                                        label={{
                                                            style: {
                                                                fontFamily: "'Source Sans Pro',sans-serif",
                                                                fontSize: 16,
                                                                color: "#666666"
                                                            },
                                                            value: "Realized Habitat Potential Index",
                                                            angle: -90,
                                                            offset: -4,
                                                            position: "insideBottomLeft"
                                                        }}
                                                    />
                                                </BarChart>
                                            </div>
                                            <div style={{ marginTop: 16, width: "calc(100% + 10px)" }}>
                                                <Typography
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "16px 8px",
                                                        backgroundColor: "#F0F0F0"
                                                    }}>
                                                    This chart shows the trend of Total % realized habitat potential
                                                    index of cultivated land by project year.
                                                </Typography>
                                            </div>
                                            {show_irrigation && (
                                                <>
                                                    <br />
                                                    <div style={{ width: "100%", border: "1px solid", padding: 4 }}>
                                                        <h3 className={classes.popupHeader}>Irrigation Water Use</h3>
                                                        <BarChart
                                                            width={625}
                                                            height={255}
                                                            margin={{ top: 15, left: 5, right: 0, bottom: 15 }}
                                                            data={irrigation}>
                                                            <CartesianGrid strokeDasharray="3 3" />
                                                            <XAxis dataKey="year">
                                                                <Label
                                                                    style={{
                                                                        fontFamily: "'Source Sans Pro',sans-serif",
                                                                        fontSize: 16,
                                                                        color: "#666666"
                                                                    }}
                                                                    value="Project Year"
                                                                    offset={-10}
                                                                    position="insideBottom"
                                                                />
                                                            </XAxis>
                                                            <YAxis
                                                                dataKey="value"
                                                                label={{
                                                                    style: {
                                                                        fontFamily: "'Source Sans Pro',sans-serif",
                                                                        fontSize: 16,
                                                                        color: "#666666"
                                                                    },
                                                                    value: irrigation_unit,
                                                                    angle: -90,
                                                                    offset: 5,
                                                                    position: "insideBottomLeft"
                                                                }}
                                                                tickFormatter={value =>
                                                                    new Intl.NumberFormat("en").format(value)
                                                                }
                                                            />
                                                            <Bar dataKey="value" fill={null}>
                                                                <LabelList
                                                                    dataKey="value"
                                                                    position="top"
                                                                    style={{ fontWeight: "bold" }}
                                                                    formatter={value =>
                                                                        new Intl.NumberFormat("en").format(value)
                                                                    }
                                                                />
                                                            </Bar>
                                                        </BarChart>
                                                    </div>
                                                    <div style={{ marginTop: 16, width: "calc(100% + 10px)" }}>
                                                        <Typography
                                                            style={{
                                                                textAlign: "center",
                                                                padding: "16px 8px",
                                                                backgroundColor: "#F0F0F0"
                                                            }}>
                                                            The chart shows the trend of the amount of water, measured
                                                            in acre-inches (ac-in) required to produce a unit of crop
                                                            (pound, bushel, etc.). Lower numbers are desirable,
                                                            indicating less water required to produce a unit of crop.
                                                        </Typography>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <PageFooter number={page_number + 6} total={total_page_number} />
                                    </div>
                                    <div className={classes.page}>
                                        <PageHeader project={project} breakdowns />
                                        <div className={classes.content}>
                                            <h2
                                                className={classes.reportHeader}
                                                style={{ fontSize: 32, marginTop: 400 }}>
                                                METRIC BREAKDOWNS FOR {c_label.toUpperCase()} {irr_string}
                                            </h2>
                                        </div>
                                        <PageFooter number={page_number + 7} total={total_page_number} />
                                    </div>
                                </>
                            );
                            page_number = page_number + 8;
                            var years_reverse = years.slice().reverse();
                            years_reverse.forEach(y => {
                                let filterFunction2;
                                if (is_irr2 === null) {
                                    filterFunction2 = x => {
                                        return x.is_final && x.crop_label === c_label && x.metrics && x.year === y;
                                    };
                                } else {
                                    filterFunction2 = x => {
                                        return (
                                            x.is_final &&
                                            x.crop_label === c_label &&
                                            x.metrics &&
                                            x.year === y &&
                                            x.is_irrigated === is_irr2
                                        );
                                    };
                                }
                                var ghg_1 = [];
                                var ghg_2 = [];
                                var ghg_3 = [];
                                var ghg_4 = [];
                                var ghg_5 = [];
                                var ghg_6 = [];
                                var ghg_7 = [];
                                var ghg_8 = [];
                                var ghg_9 = [];
                                var ghg_10 = [];
                                var ghg_11 = [];
                                var ghg_12 = [];
                                var ghg_13 = [];
                                var ghg_14 = [];
                                var ghg_15 = [];
                                var ghg_16 = [];
                                var ghg_17 = [];
                                var ghg_18 = [];
                                var ghg_19 = [];
                                var ghg_20 = [];
                                var ghg_total1 = [];
                                var ghg_total2 = [];
                                var energy_1 = [];
                                var energy_2 = [];
                                var energy_3 = [];
                                var energy_4 = [];
                                var energy_5 = [];
                                var energy_6 = [];
                                var energy_7 = [];
                                var energy_8 = [];
                                var energy_9 = [];
                                var energy_10 = [];
                                var energy_11 = [];
                                var energy_12 = [];
                                var energy_13 = [];
                                var energy_14 = [];
                                var application_1 = [];
                                var application_2 = [];
                                var application_3 = [];
                                var application_4 = [];
                                var application_5 = [];
                                var application_6 = [];
                                var application_7 = [];
                                var application_8 = [];
                                var application_9 = [];
                                var application_10 = [];
                                var application_11 = [];
                                var application_12 = [];
                                var application_13 = [];
                                var application_14 = [];
                                var application_15 = [];
                                var application_16 = [];
                                var application_17 = [];
                                var application_18 = [];
                                var application_19 = [];
                                var application_20 = [];
                                var surface_p = 0;
                                var subsurface_p = 0;
                                var surface_n = 0;
                                var subsurface_n = 0;
                                var tillage_1 = 0;
                                var tillage_2 = 0;
                                var tillage_3 = 0;
                                var total_acres = 0;
                                var cover_acres = 0;
                                var nutrient_acres = 0;
                                var codes = [];
                                var punit = "";
                                var crops_loop = project.project_data.filter(filterFunction2);
                                if (crops_loop.length > 1) {
                                    crops_loop.forEach(pd => {
                                        ghg_total1.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.totalGreenhouse
                                                .value
                                        );
                                        ghg_total2.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.fieldprintResult
                                                .value
                                        );
                                        ghg_1.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.management
                                                .greenhouse.value
                                        );
                                        ghg_2.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.management
                                                .fieldprintResult.value
                                        );
                                        punit =
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.management.fieldprintResult.unit
                                                .split(" / ")[1]
                                                .toUpperCase();
                                        ghg_3.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.application
                                                .greenhouse.value
                                        );
                                        ghg_4.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.application
                                                .fieldprintResult.value
                                        );
                                        ghg_5.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.manureLoading
                                                .greenhouse.value
                                        );
                                        ghg_6.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.manureLoading
                                                .fieldprintResult.value
                                        );
                                        ghg_7.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.seed.greenhouse
                                                .value
                                        );
                                        ghg_8.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.seed
                                                .fieldprintResult.value
                                        );
                                        ghg_9.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.irrigation
                                                .greenhouse.value
                                        );
                                        ghg_10.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.irrigation
                                                .fieldprintResult.value
                                        );
                                        ghg_11.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.postHarvest
                                                .greenhouse.value
                                        );
                                        ghg_12.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.postHarvest
                                                .fieldprintResult.value
                                        );
                                        ghg_13.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.transportation
                                                .greenhouse.value
                                        );
                                        ghg_14.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.transportation
                                                .fieldprintResult.value
                                        );
                                        ghg_15.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.nitrousOxide
                                                .greenhouse.value
                                        );
                                        ghg_16.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.nitrousOxide
                                                .fieldprintResult.value
                                        );
                                        ghg_17.push(0);
                                        ghg_18.push(0);
                                        ghg_19.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.residueBurning
                                                .greenhouse.value
                                        );
                                        ghg_20.push(
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.residueBurning
                                                .fieldprintResult.value
                                        );
                                        energy_1.push(
                                            pd.metrics.Calculator.result.cropyears[0].energyUse.management.energy.value
                                        );
                                        energy_2.push(
                                            pd.metrics.Calculator.result.cropyears[0].energyUse.management
                                                .fieldprintResult.value
                                        );
                                        energy_3.push(
                                            pd.metrics.Calculator.result.cropyears[0].energyUse.application.energy.value
                                        );
                                        energy_4.push(
                                            pd.metrics.Calculator.result.cropyears[0].energyUse.application
                                                .fieldprintResult.value
                                        );
                                        energy_5.push(
                                            pd.metrics.Calculator.result.cropyears[0].energyUse.manureLoading.energy
                                                .value
                                        );
                                        energy_6.push(
                                            pd.metrics.Calculator.result.cropyears[0].energyUse.manureLoading
                                                .fieldprintResult.value
                                        );
                                        energy_7.push(
                                            pd.metrics.Calculator.result.cropyears[0].energyUse.seed.energy.value
                                        );
                                        energy_8.push(
                                            pd.metrics.Calculator.result.cropyears[0].energyUse.seed.fieldprintResult
                                                .value
                                        );
                                        energy_9.push(
                                            pd.metrics.Calculator.result.cropyears[0].energyUse.irrigation.energy.value
                                        );
                                        energy_10.push(
                                            pd.metrics.Calculator.result.cropyears[0].energyUse.irrigation
                                                .fieldprintResult.value
                                        );
                                        energy_11.push(
                                            pd.metrics.Calculator.result.cropyears[0].energyUse.postHarvest.energy.value
                                        );
                                        energy_12.push(
                                            pd.metrics.Calculator.result.cropyears[0].energyUse.postHarvest
                                                .fieldprintResult.value
                                        );
                                        energy_13.push(
                                            pd.metrics.Calculator.result.cropyears[0].energyUse.transportation.energy
                                                .value
                                        );
                                        energy_14.push(
                                            pd.metrics.Calculator.result.cropyears[0].energyUse.transportation
                                                .fieldprintResult.value
                                        );
                                        if (
                                            pd.metrics.Calculator.result.cropyears[0].greenhouseGas.application
                                                .seedTreatment
                                        ) {
                                            application_1.push(
                                                pd.metrics.Calculator.result.cropyears[0].greenhouseGas.application
                                                    .seedTreatment.greenhouse.value
                                            );
                                            application_2.push(
                                                pd.metrics.Calculator.result.cropyears[0].greenhouseGas.application
                                                    .seedTreatment.fieldprintResult.value
                                            );
                                            application_3.push(
                                                pd.metrics.Calculator.result.cropyears[0].greenhouseGas.application
                                                    .fertilizers.greenhouse.value
                                            );
                                            application_4.push(
                                                pd.metrics.Calculator.result.cropyears[0].greenhouseGas.application
                                                    .fertilizers.fieldprintResult.value
                                            );
                                            application_5.push(
                                                pd.metrics.Calculator.result.cropyears[0].greenhouseGas.application
                                                    .protectants.greenhouse.value
                                            );
                                            application_6.push(
                                                pd.metrics.Calculator.result.cropyears[0].greenhouseGas.application
                                                    .protectants.fieldprintResult.value
                                            );
                                            application_7.push(
                                                pd.metrics.Calculator.result.cropyears[0].greenhouseGas.application
                                                    .fieldOps.greenhouse.value
                                            );
                                            application_8.push(
                                                pd.metrics.Calculator.result.cropyears[0].greenhouseGas.application
                                                    .fieldOps.fieldprintResult.value
                                            );
                                            application_9.push(
                                                pd.metrics.Calculator.result.cropyears[0].greenhouseGas.application.lime
                                                    .greenhouse.value
                                            );
                                            application_10.push(
                                                pd.metrics.Calculator.result.cropyears[0].greenhouseGas.application.lime
                                                    .fieldprintResult.value
                                            );
                                            application_11.push(
                                                pd.metrics.Calculator.result.cropyears[0].energyUse.application
                                                    .seedTreatment.energy.value
                                            );
                                            application_12.push(
                                                pd.metrics.Calculator.result.cropyears[0].energyUse.application
                                                    .seedTreatment.fieldprintResult.value
                                            );
                                            application_13.push(
                                                pd.metrics.Calculator.result.cropyears[0].energyUse.application
                                                    .fertilizers.energy.value
                                            );
                                            application_14.push(
                                                pd.metrics.Calculator.result.cropyears[0].energyUse.application
                                                    .fertilizers.fieldprintResult.value
                                            );
                                            application_15.push(
                                                pd.metrics.Calculator.result.cropyears[0].energyUse.application
                                                    .protectants.energy.value
                                            );
                                            application_16.push(
                                                pd.metrics.Calculator.result.cropyears[0].energyUse.application
                                                    .protectants.fieldprintResult.value
                                            );
                                            application_17.push(
                                                pd.metrics.Calculator.result.cropyears[0].energyUse.application.fieldOps
                                                    .energy.value
                                            );
                                            application_18.push(
                                                pd.metrics.Calculator.result.cropyears[0].energyUse.application.fieldOps
                                                    .fieldprintResult.value
                                            );
                                            application_19.push(
                                                pd.metrics.Calculator.result.cropyears[0].energyUse.application.lime
                                                    .energy.value
                                            );
                                            application_20.push(
                                                pd.metrics.Calculator.result.cropyears[0].energyUse.application.lime
                                                    .fieldprintResult.value
                                            );
                                        }
                                        total_acres += pd.field_acres;
                                        if (pd.metrics.Calculator.result.cropyears[0].waterQuality.lossPathway) {
                                            if (
                                                pd.metrics.Calculator.result.cropyears[0].waterQuality.lossPathway
                                                    .surfaceP.ratio >= 1
                                            )
                                                surface_p += pd.field_acres;
                                            if (
                                                pd.metrics.Calculator.result.cropyears[0].waterQuality.lossPathway
                                                    .subsurfaceP.ratio >= 1
                                            )
                                                subsurface_p += pd.field_acres;
                                            if (
                                                pd.metrics.Calculator.result.cropyears[0].waterQuality.lossPathway
                                                    .surfaceN.ratio >= 1
                                            )
                                                surface_n += pd.field_acres;
                                            if (
                                                pd.metrics.Calculator.result.cropyears[0].waterQuality.lossPathway
                                                    .subsurfaceN.ratio >= 1
                                            )
                                                subsurface_n += pd.field_acres;
                                        }
                                        if (pd.metrics.Calculator.payload) {
                                            if (
                                                pd.metrics.Calculator.payload.cropyear.biodiversity.tillage_class ===
                                                "1"
                                            )
                                                tillage_1++;
                                            else if (
                                                pd.metrics.Calculator.payload.cropyear.biodiversity.tillage_class ===
                                                "2"
                                            )
                                                tillage_2++;
                                            else if (
                                                pd.metrics.Calculator.payload.cropyear.biodiversity.tillage_class ===
                                                "3"
                                            )
                                                tillage_3++;
                                            if (pd.metrics.Calculator.payload.cropyear.biodiversity.cover_crop)
                                                cover_acres += pd.field_acres;
                                            if (
                                                pd.metrics.Calculator.payload.cropyear.biodiversity
                                                    .nutrient_management_plan
                                            )
                                                nutrient_acres += pd.field_acres;
                                            if (
                                                pd.metrics.Calculator.payload.cropyear.waterquality.conservation_codes
                                            ) {
                                                pd.metrics.Calculator.payload.cropyear.waterquality.conservation_codes.forEach(
                                                    cc => {
                                                        codes.push(cc);
                                                    }
                                                );
                                            }
                                        }
                                    });
                                }
                                if (ghg_1.length > 0) {
                                    var ghg_total_red1 =
                                        Math.round((ghg_total1.reduce((a, b) => a + b, 0) / ghg_total1.length) * 100) /
                                        100;
                                    var ghg_total_red2 =
                                        Math.round((ghg_total2.reduce((a, b) => a + b, 0) / ghg_total2.length) * 1000) /
                                        1000;
                                    var ghg_1_total =
                                        Math.round((ghg_1.reduce((a, b) => a + b, 0) / ghg_1.length) * 10) / 10;
                                    var ghg_2_total =
                                        Math.round((ghg_2.reduce((a, b) => a + b, 0) / ghg_2.length) * 10) / 10;
                                    var ghg_3_total =
                                        Math.round((ghg_3.reduce((a, b) => a + b, 0) / ghg_3.length) * 10) / 10;
                                    var ghg_4_total =
                                        Math.round((ghg_4.reduce((a, b) => a + b, 0) / ghg_4.length) * 10) / 10;
                                    var ghg_5_total =
                                        Math.round((ghg_5.reduce((a, b) => a + b, 0) / ghg_5.length) * 10) / 10;
                                    var ghg_6_total =
                                        Math.round((ghg_6.reduce((a, b) => a + b, 0) / ghg_6.length) * 10) / 10;
                                    var ghg_7_total =
                                        Math.round((ghg_7.reduce((a, b) => a + b, 0) / ghg_7.length) * 10) / 10;
                                    var ghg_8_total =
                                        Math.round((ghg_8.reduce((a, b) => a + b, 0) / ghg_8.length) * 10) / 10;
                                    var ghg_9_total =
                                        Math.round((ghg_9.reduce((a, b) => a + b, 0) / ghg_9.length) * 10) / 10;
                                    var ghg_10_total =
                                        Math.round((ghg_10.reduce((a, b) => a + b, 0) / ghg_10.length) * 10) / 10;
                                    var ghg_11_total =
                                        Math.round((ghg_11.reduce((a, b) => a + b, 0) / ghg_11.length) * 10) / 10;
                                    var ghg_12_total =
                                        Math.round((ghg_12.reduce((a, b) => a + b, 0) / ghg_12.length) * 10) / 10;
                                    var ghg_13_total =
                                        Math.round((ghg_13.reduce((a, b) => a + b, 0) / ghg_13.length) * 10) / 10;
                                    var ghg_14_total =
                                        Math.round((ghg_14.reduce((a, b) => a + b, 0) / ghg_14.length) * 10) / 10;
                                    var ghg_15_total =
                                        Math.round((ghg_15.reduce((a, b) => a + b, 0) / ghg_15.length) * 10) / 10;
                                    var ghg_16_total =
                                        Math.round((ghg_16.reduce((a, b) => a + b, 0) / ghg_16.length) * 10) / 10;
                                    var ghg_17_total =
                                        Math.round((ghg_17.reduce((a, b) => a + b, 0) / ghg_17.length) * 10) / 10;
                                    var ghg_18_total =
                                        Math.round((ghg_18.reduce((a, b) => a + b, 0) / ghg_18.length) * 10) / 10;
                                    var ghg_19_total =
                                        Math.round((ghg_19.reduce((a, b) => a + b, 0) / ghg_19.length) * 10) / 10;
                                    var ghg_20_total =
                                        Math.round((ghg_20.reduce((a, b) => a + b, 0) / ghg_20.length) * 10) / 10;
                                    var energy_1_total = Math.round(
                                        energy_1.reduce((a, b) => a + b, 0) / energy_1.length
                                    );
                                    var energy_2_total = Math.round(
                                        energy_2.reduce((a, b) => a + b, 0) / energy_2.length
                                    );
                                    var energy_3_total = Math.round(
                                        energy_3.reduce((a, b) => a + b, 0) / energy_3.length
                                    );
                                    var energy_4_total = Math.round(
                                        energy_4.reduce((a, b) => a + b, 0) / energy_4.length
                                    );
                                    var energy_5_total = Math.round(
                                        energy_5.reduce((a, b) => a + b, 0) / energy_5.length
                                    );
                                    var energy_6_total = Math.round(
                                        energy_6.reduce((a, b) => a + b, 0) / energy_6.length
                                    );
                                    var energy_7_total = Math.round(
                                        energy_7.reduce((a, b) => a + b, 0) / energy_7.length
                                    );
                                    var energy_8_total = Math.round(
                                        energy_8.reduce((a, b) => a + b, 0) / energy_8.length
                                    );
                                    var energy_9_total = Math.round(
                                        energy_9.reduce((a, b) => a + b, 0) / energy_9.length
                                    );
                                    var energy_10_total = Math.round(
                                        energy_10.reduce((a, b) => a + b, 0) / energy_10.length
                                    );
                                    var energy_11_total = Math.round(
                                        energy_11.reduce((a, b) => a + b, 0) / energy_11.length
                                    );
                                    var energy_12_total = Math.round(
                                        energy_12.reduce((a, b) => a + b, 0) / energy_12.length
                                    );
                                    var energy_13_total = Math.round(
                                        energy_13.reduce((a, b) => a + b, 0) / energy_13.length
                                    );
                                    var energy_14_total = Math.round(
                                        energy_14.reduce((a, b) => a + b, 0) / energy_14.length
                                    );

                                    const surface_p_pie = [
                                        { name: "Surface Phosphorus", value: surface_p / total_acres },
                                        { name: "", value: 1 - surface_p / total_acres }
                                    ];
                                    const subsurface_p_pie = [
                                        { name: "Subsurface Phosphorus", value: subsurface_p / total_acres },
                                        { name: "", value: 1 - subsurface_p / total_acres }
                                    ];
                                    const surface_n_pie = [
                                        { name: "Surface Nitrogen", value: surface_n / total_acres },
                                        { name: "", value: 1 - surface_n / total_acres }
                                    ];
                                    const subsurface_n_pie = [
                                        { name: "Subsurface Nitrogen", value: subsurface_n / total_acres },
                                        { name: "", value: 1 - subsurface_n / total_acres }
                                    ];
                                    const tillage_pie = [
                                        { name: "Conventional or Intensive", value: tillage_1 },
                                        { name: "Reduced", value: tillage_2 },
                                        { name: "No-Till, Strip Till or Conservation", value: tillage_3 }
                                    ];
                                    const _this = this;
                                    const occurrences = codes.reduce(function (acc, curr) {
                                        var string = _this.cons_code(curr);
                                        // eslint-disable-next-line no-sequences
                                        return acc[string] ? ++acc[string] : (acc[string] = 1), acc;
                                    }, {});
                                    let sortable = [];
                                    for (var v in occurrences) {
                                        sortable.push([v, occurrences[v]]);
                                    }
                                    sortable.sort(function (a, b) {
                                        return a[1] - b[1];
                                    });
                                    jsx.push(
                                        <>
                                            <div className={classes.page}>
                                                <PageHeader project={project} breakdowns />
                                                <div className={classes.content}>
                                                    <h2 className={classes.reportHeader}>
                                                        {y} {irr_string} {c_label.toUpperCase()} METRIC BREAKDOWNS
                                                    </h2>
                                                    <div className={classNames(classes.table, classes.tableBorder)}>
                                                        <h3 className={classes.popupHeader}>
                                                            GHG BREAKDOWN
                                                            <span style={{ fontStyle: "italitc", float: "right" }}>
                                                                POUNDS OF CO<sub>2</sub>E
                                                            </span>
                                                        </h3>
                                                        <table style={{ width: "100%" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>GHG EMISSIONS COMPONENTS</th>
                                                                    <th>ACRE</th>
                                                                    <th>{punit}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Management Energy Emissions</td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_1_total.toLocaleString()}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_2_total.toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Application Energy Emissions</td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_3_total.toLocaleString()}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_4_total.toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;Seed Treatment Energy
                                                                        Emissions
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_1.length > 0
                                                                            ? (
                                                                                  Math.round(
                                                                                      (application_1.reduce(
                                                                                          (a, b) => a + b,
                                                                                          0
                                                                                      ) /
                                                                                          application_1.length) *
                                                                                          10
                                                                                  ) / 10
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_2.length > 0
                                                                            ? (
                                                                                  Math.round(
                                                                                      (application_2.reduce(
                                                                                          (a, b) => a + b,
                                                                                          0
                                                                                      ) /
                                                                                          application_2.length) *
                                                                                          10
                                                                                  ) / 10
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;Fertilizer Energy
                                                                        Emissions
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_3.length > 0
                                                                            ? (
                                                                                  Math.round(
                                                                                      (application_3.reduce(
                                                                                          (a, b) => a + b,
                                                                                          0
                                                                                      ) /
                                                                                          application_3.length) *
                                                                                          10
                                                                                  ) / 10
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_4.length > 0
                                                                            ? (
                                                                                  Math.round(
                                                                                      (application_4.reduce(
                                                                                          (a, b) => a + b,
                                                                                          0
                                                                                      ) /
                                                                                          application_4.length) *
                                                                                          10
                                                                                  ) / 10
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;Protectant Energy
                                                                        Emissions
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_5.length > 0
                                                                            ? (
                                                                                  Math.round(
                                                                                      (application_5.reduce(
                                                                                          (a, b) => a + b,
                                                                                          0
                                                                                      ) /
                                                                                          application_5.length) *
                                                                                          10
                                                                                  ) / 10
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_6.length > 0
                                                                            ? (
                                                                                  Math.round(
                                                                                      (application_6.reduce(
                                                                                          (a, b) => a + b,
                                                                                          0
                                                                                      ) /
                                                                                          application_6.length) *
                                                                                          10
                                                                                  ) / 10
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;Field Ops Energy
                                                                        Emissions
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_7.length > 0
                                                                            ? (
                                                                                  Math.round(
                                                                                      (application_7.reduce(
                                                                                          (a, b) => a + b,
                                                                                          0
                                                                                      ) /
                                                                                          application_7.length) *
                                                                                          10
                                                                                  ) / 10
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_8.length > 0
                                                                            ? (
                                                                                  Math.round(
                                                                                      (application_8.reduce(
                                                                                          (a, b) => a + b,
                                                                                          0
                                                                                      ) /
                                                                                          application_8.length) *
                                                                                          10
                                                                                  ) / 10
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;Lime Energy Emissions
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_9.length > 0
                                                                            ? (
                                                                                  Math.round(
                                                                                      (application_9.reduce(
                                                                                          (a, b) => a + b,
                                                                                          0
                                                                                      ) /
                                                                                          application_9.length) *
                                                                                          10
                                                                                  ) / 10
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_10.length > 0
                                                                            ? (
                                                                                  Math.round(
                                                                                      (application_10.reduce(
                                                                                          (a, b) => a + b,
                                                                                          0
                                                                                      ) /
                                                                                          application_10.length) *
                                                                                          10
                                                                                  ) / 10
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Manure Loading Energy Emissions</td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_5_total.toLocaleString()}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_6_total.toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Seed Energy Emissions</td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_7_total.toLocaleString()}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_8_total.toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Irrigation Energy Emissions</td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_9_total.toLocaleString()}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_10_total.toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Post-Harvest Energy Emissions</td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_11_total.toLocaleString()}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_12_total.toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Transportation Energy Emissions</td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_13_total.toLocaleString()}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_14_total.toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                                <tr className={classes.greyBackground}>
                                                                    <td>ENERGY SUBTOTAL</td>
                                                                    <td className={classes.rightAlign}>
                                                                        {(
                                                                            ghg_1_total +
                                                                            ghg_3_total +
                                                                            ghg_5_total +
                                                                            ghg_7_total +
                                                                            ghg_9_total +
                                                                            ghg_11_total +
                                                                            ghg_13_total
                                                                        ).toLocaleString()}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {(
                                                                            ghg_2_total +
                                                                            ghg_4_total +
                                                                            ghg_6_total +
                                                                            ghg_8_total +
                                                                            ghg_10_total +
                                                                            ghg_12_total +
                                                                            ghg_14_total
                                                                        ).toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Soil N2O Emissions</td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_15_total.toLocaleString()}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_16_total.toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Methane</td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_17_total.toLocaleString()}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_18_total.toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Residue Burning Emissions</td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_19_total.toLocaleString()}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {ghg_20_total.toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            backgroundColor:
                                                                                this.colors[years.indexOf(y)],
                                                                            color: "white",
                                                                            fontWeight: "bold"
                                                                        }}>
                                                                        TOTAL GHG EMISSIONS
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            backgroundColor:
                                                                                this.colors[years.indexOf(y)],
                                                                            color: "white",
                                                                            fontWeight: "bold"
                                                                        }}
                                                                        className={classes.rightAlign}>
                                                                        {ghg_total_red1.toLocaleString()}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            backgroundColor:
                                                                                this.colors[years.indexOf(y)],
                                                                            color: "white",
                                                                            fontWeight: "bold"
                                                                        }}
                                                                        className={classes.rightAlign}>
                                                                        {ghg_total_red2.toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <br />
                                                    <div className={classNames(classes.table, classes.tableBorder)}>
                                                        <h3 className={classes.popupHeader}>
                                                            ENERGY BREAKDOWN{" "}
                                                            <span style={{ fontStyle: "italitc", float: "right" }}>
                                                                BTU
                                                            </span>
                                                        </h3>
                                                        <table style={{ width: "100%" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>ENERGY USE COMPONENTS</th>
                                                                    <th>ACRE</th>
                                                                    <th>{punit}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Management Energy Emissions</td>
                                                                    <td className={classes.rightAlign}>
                                                                        {energy_1_total.toLocaleString()}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {energy_2_total.toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Application Energy Emissions</td>
                                                                    <td className={classes.rightAlign}>
                                                                        {energy_3_total.toLocaleString()}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {energy_4_total.toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;Seed Treatment Energy
                                                                        Emissions
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_11.length > 0
                                                                            ? Math.round(
                                                                                  application_11.reduce(
                                                                                      (a, b) => a + b,
                                                                                      0
                                                                                  ) / application_11.length
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_12.length > 0
                                                                            ? Math.round(
                                                                                  application_12.reduce(
                                                                                      (a, b) => a + b,
                                                                                      0
                                                                                  ) / application_12.length
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;Fertilizer Energy
                                                                        Emissions
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_13.length > 0
                                                                            ? Math.round(
                                                                                  application_13.reduce(
                                                                                      (a, b) => a + b,
                                                                                      0
                                                                                  ) / application_13.length
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_14.length > 0
                                                                            ? Math.round(
                                                                                  application_14.reduce(
                                                                                      (a, b) => a + b,
                                                                                      0
                                                                                  ) / application_14.length
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;Protectant Energy
                                                                        Emissions
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_15.length > 0
                                                                            ? Math.round(
                                                                                  application_15.reduce(
                                                                                      (a, b) => a + b,
                                                                                      0
                                                                                  ) / application_15.length
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_16.length > 0
                                                                            ? Math.round(
                                                                                  application_16.reduce(
                                                                                      (a, b) => a + b,
                                                                                      0
                                                                                  ) / application_16.length
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;Field Ops Energy
                                                                        Emissions
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_17.length > 0
                                                                            ? Math.round(
                                                                                  application_17.reduce(
                                                                                      (a, b) => a + b,
                                                                                      0
                                                                                  ) / application_17.length
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_18.length > 0
                                                                            ? Math.round(
                                                                                  application_18.reduce(
                                                                                      (a, b) => a + b,
                                                                                      0
                                                                                  ) / application_18.length
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;Lime Energy Emissions
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_19.length > 0
                                                                            ? Math.round(
                                                                                  application_19.reduce(
                                                                                      (a, b) => a + b,
                                                                                      0
                                                                                  ) / application_19.length
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {application_20.length > 0
                                                                            ? Math.round(
                                                                                  application_20.reduce(
                                                                                      (a, b) => a + b,
                                                                                      0
                                                                                  ) / application_20.length
                                                                              ).toLocaleString()
                                                                            : "-"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Manure Loading Energy Emissions</td>
                                                                    <td className={classes.rightAlign}>
                                                                        {energy_5_total.toLocaleString()}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {energy_6_total.toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Seed Energy Emissions</td>
                                                                    <td className={classes.rightAlign}>
                                                                        {energy_7_total.toLocaleString()}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {energy_8_total.toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Irrigation Energy Emissions</td>
                                                                    <td className={classes.rightAlign}>
                                                                        {energy_9_total.toLocaleString()}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {energy_10_total.toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Post-Harvest Energy Emissions</td>
                                                                    <td className={classes.rightAlign}>
                                                                        {energy_11_total.toLocaleString()}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {energy_12_total.toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Transportation Energy Emissions</td>
                                                                    <td className={classes.rightAlign}>
                                                                        {energy_13_total.toLocaleString()}
                                                                    </td>
                                                                    <td className={classes.rightAlign}>
                                                                        {energy_14_total.toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            backgroundColor:
                                                                                this.colors[years.indexOf(y)],
                                                                            color: "white",
                                                                            fontWeight: "bold"
                                                                        }}>
                                                                        TOTAL ENERGY
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            backgroundColor:
                                                                                this.colors[years.indexOf(y)],
                                                                            color: "white",
                                                                            fontWeight: "bold"
                                                                        }}
                                                                        className={classes.rightAlign}>
                                                                        {(
                                                                            energy_1_total +
                                                                            energy_3_total +
                                                                            energy_5_total +
                                                                            energy_7_total +
                                                                            energy_9_total +
                                                                            energy_11_total +
                                                                            energy_13_total
                                                                        ).toLocaleString()}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            backgroundColor:
                                                                                this.colors[years.indexOf(y)],
                                                                            color: "white",
                                                                            fontWeight: "bold"
                                                                        }}
                                                                        className={classes.rightAlign}>
                                                                        {(
                                                                            energy_2_total +
                                                                            energy_4_total +
                                                                            energy_6_total +
                                                                            energy_8_total +
                                                                            energy_10_total +
                                                                            energy_12_total +
                                                                            energy_14_total
                                                                        ).toLocaleString()}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <PageFooter number={page_number} total={total_page_number} />
                                            </div>
                                            <div className={classes.page}>
                                                <PageHeader project={project} breakdowns />
                                                <div className={classes.content}>
                                                    <h2 className={classes.reportHeader}>
                                                        {y} {irr_string} {c_label.toUpperCase()} METRIC BREAKDOWNS
                                                    </h2>
                                                    <h3
                                                        className={classNames(
                                                            classes.popupHeader,
                                                            classes.reportSubHeader
                                                        )}>
                                                        Water Quality Breakdown
                                                    </h3>
                                                    <br />
                                                    <div>
                                                        <PieChart
                                                            width={300}
                                                            height={170}
                                                            style={{ display: "inline" }}>
                                                            <Legend content={this.RenderCusomizedLegend} />
                                                            <Pie
                                                                data={surface_p_pie}
                                                                label={this.renderCustomizedLabel}
                                                                labelLine={false}
                                                                outerRadius={70}
                                                                isAnimationActive={false}>
                                                                {surface_p_pie.map((entry, index) => (
                                                                    <Cell
                                                                        key={`cell-${index}`}
                                                                        fill={
                                                                            index === 0
                                                                                ? this.colors[years.indexOf(y)]
                                                                                : this.colors_lighter[years.indexOf(y)]
                                                                        }
                                                                    />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                        <PieChart
                                                            width={300}
                                                            height={170}
                                                            style={{ display: "inline" }}>
                                                            <Legend content={this.RenderCusomizedLegend} />
                                                            <Pie
                                                                data={subsurface_p_pie}
                                                                label={this.renderCustomizedLabel}
                                                                labelLine={false}
                                                                outerRadius={70}
                                                                isAnimationActive={false}>
                                                                {subsurface_p_pie.map((entry, index) => (
                                                                    <Cell
                                                                        key={`cell-${index}`}
                                                                        fill={
                                                                            index === 0
                                                                                ? this.colors[years.indexOf(y)]
                                                                                : this.colors_lighter[years.indexOf(y)]
                                                                        }
                                                                    />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                        <PieChart
                                                            width={300}
                                                            height={170}
                                                            style={{ display: "inline" }}>
                                                            <Legend content={this.RenderCusomizedLegend} />
                                                            <Pie
                                                                data={surface_n_pie}
                                                                label={this.renderCustomizedLabel}
                                                                labelLine={false}
                                                                outerRadius={70}
                                                                isAnimationActive={false}>
                                                                {surface_n_pie.map((entry, index) => (
                                                                    <Cell
                                                                        key={`cell-${index}`}
                                                                        fill={
                                                                            index === 0
                                                                                ? this.colors[years.indexOf(y)]
                                                                                : this.colors_lighter[years.indexOf(y)]
                                                                        }
                                                                    />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                        <PieChart
                                                            width={300}
                                                            height={170}
                                                            style={{ display: "inline" }}>
                                                            <Legend content={this.RenderCusomizedLegend} />
                                                            <Pie
                                                                data={subsurface_n_pie}
                                                                label={this.renderCustomizedLabel}
                                                                labelLine={false}
                                                                outerRadius={70}
                                                                isAnimationActive={false}>
                                                                {subsurface_n_pie.map((entry, index) => (
                                                                    <Cell
                                                                        key={`cell-${index}`}
                                                                        fill={
                                                                            index === 0
                                                                                ? this.colors[years.indexOf(y)]
                                                                                : this.colors_lighter[years.indexOf(y)]
                                                                        }
                                                                    />
                                                                ))}
                                                            </Pie>
                                                        </PieChart>
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginTop: 16,
                                                            marginBottom: 40,
                                                            width: "calc(100% + 10px)"
                                                        }}>
                                                        <Typography
                                                            style={{
                                                                textAlign: "center",
                                                                padding: "16px 8px",
                                                                backgroundColor: "#F0F0F0"
                                                            }}>
                                                            Percent of total entered acres that are mitigating the
                                                            nutrient loss pathway.
                                                        </Typography>
                                                    </div>
                                                    <h3
                                                        className={classNames(
                                                            classes.popupHeader,
                                                            classes.reportSubHeader
                                                        )}>
                                                        Soil Loss Breakdown
                                                    </h3>
                                                    {erosions[y] && (
                                                        <>
                                                            <br />
                                                            <div>
                                                                <PieChart
                                                                    width={600}
                                                                    height={160}
                                                                    style={{ display: "inline" }}>
                                                                    <Legend verticalAlign="bottom" align="center" />
                                                                    <Pie
                                                                        data={erosions[y]}
                                                                        label={this.renderCustomizedLabel}
                                                                        labelLine={false}
                                                                        outerRadius={70}
                                                                        isAnimationActive={false}>
                                                                        {erosions[y].map((entry, index) => (
                                                                            <Cell
                                                                                key={`cell-${index}`}
                                                                                fill={
                                                                                    index === 0
                                                                                        ? entry.fill
                                                                                        : this.colors_lighter[
                                                                                              this.colors.indexOf(
                                                                                                  entry.fill
                                                                                              )
                                                                                          ]
                                                                                }
                                                                            />
                                                                        ))}
                                                                    </Pie>
                                                                </PieChart>
                                                            </div>
                                                            <div style={{ marginTop: 16, width: "calc(100% + 10px)" }}>
                                                                <Typography
                                                                    style={{
                                                                        textAlign: "center",
                                                                        padding: "16px 8px",
                                                                        backgroundColor: "#F0F0F0"
                                                                    }}>
                                                                    This chart contrasts the percentage of soil loss to
                                                                    erosion from water versus wind across project years.
                                                                </Typography>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <PageFooter number={page_number + 1} total={total_page_number} />
                                            </div>
                                            <div className={classes.page}>
                                                <PageHeader project={project} breakdowns />
                                                <div className={classes.content}>
                                                    <h2 className={classes.reportHeader}>
                                                        {y} {irr_string} {c_label.toUpperCase()} METRIC BREAKDOWNS
                                                    </h2>
                                                    <h3
                                                        className={classNames(
                                                            classes.popupHeader,
                                                            classes.reportSubHeader
                                                        )}>
                                                        Conservation Practices
                                                    </h3>
                                                    <br />
                                                    <table style={{ borderSpacing: "16px 0" }}>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    marginLeft: 0,
                                                                    marginRight: 16,
                                                                    padding: 8,
                                                                    width: "30%",
                                                                    backgroundColor: "#F0F0F0"
                                                                }}>
                                                                <Typography
                                                                    style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: 24,
                                                                        color: this.colors[years.indexOf(y)]
                                                                    }}>
                                                                    {Math.round(total_acres).toLocaleString()}
                                                                </Typography>
                                                                <Typography
                                                                    style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: 15
                                                                    }}>
                                                                    Total Entered Acres
                                                                </Typography>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    verticalAlign: "top",
                                                                    marginLeft: 16,
                                                                    marginRight: 16,
                                                                    padding: 8,
                                                                    width: "35%",
                                                                    backgroundColor: "#F0F0F0"
                                                                }}>
                                                                <Typography
                                                                    style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: 24,
                                                                        color: this.colors[years.indexOf(y)]
                                                                    }}>
                                                                    {Math.round((cover_acres / total_acres) * 100)}% (
                                                                    {Math.round(cover_acres).toLocaleString()})
                                                                </Typography>
                                                                <Typography
                                                                    style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: 15
                                                                    }}>
                                                                    Of Total Acres With Cover Crop
                                                                </Typography>
                                                            </td>
                                                            <td
                                                                style={{
                                                                    verticalAlign: "top",
                                                                    marginLeft: 16,
                                                                    padding: 8,
                                                                    width: "35%",
                                                                    backgroundColor: "#F0F0F0"
                                                                }}>
                                                                <Typography
                                                                    style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: 24,
                                                                        color: this.colors[years.indexOf(y)]
                                                                    }}>
                                                                    {Math.round((nutrient_acres / total_acres) * 100)}%
                                                                    ({Math.round(nutrient_acres).toLocaleString()})
                                                                </Typography>
                                                                <Typography
                                                                    style={{
                                                                        fontWeight: "bold",
                                                                        fontSize: 15
                                                                    }}>
                                                                    Of Total Acres With Nutrient Management Plan
                                                                </Typography>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <br />
                                                    <h3>Predominant Tillage Practices</h3>
                                                    {tillage_1 > 0 || tillage_2 > 0 || tillage_3 > 0 ? (
                                                        <div>
                                                            <PieChart width={500} height={200} margin={{ top: -20 }}>
                                                                <Pie
                                                                    data={tillage_pie}
                                                                    label={this.renderCustomizedLabel}
                                                                    labelLine={false}
                                                                    innerRadius={45}
                                                                    outerRadius={85}
                                                                    isAnimationActive={false}>
                                                                    {tillage_pie.map((entry, index) => (
                                                                        <Cell
                                                                            key={`cell-${index}`}
                                                                            fill={
                                                                                index === 0
                                                                                    ? this.colors[years.indexOf(y)]
                                                                                    : index === 1
                                                                                      ? this.colors_lighter[
                                                                                            years.indexOf(y)
                                                                                        ]
                                                                                      : "#eee"
                                                                            }
                                                                        />
                                                                    ))}
                                                                </Pie>
                                                                <Legend
                                                                    layout="vertical"
                                                                    verticalAlign="middle"
                                                                    align="right"
                                                                />
                                                            </PieChart>
                                                            <Typography style={{ marginLeft: 16, marginTop: -16 }}>
                                                                % of Total Acres in Each Tillage Class
                                                            </Typography>
                                                        </div>
                                                    ) : (
                                                        <Typography>No tillage practices found.</Typography>
                                                    )}
                                                    <br />
                                                    <h3>Top 3 Ocurrences of Edge of Field Practices</h3>
                                                    {sortable.length > 0 ? (
                                                        <>
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        verticalAlign: "top",
                                                                        marginLeft: 16,
                                                                        marginRight: 16,
                                                                        padding: 8,
                                                                        width: "25%",
                                                                        display: "inline-block",
                                                                        backgroundColor: "#F0F0F0"
                                                                    }}>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: 24,
                                                                            color: this.colors[years.indexOf(y)]
                                                                        }}>
                                                                        {sortable.length > 0
                                                                            ? sortable[sortable.length - 1][1] +
                                                                              " FIELDS"
                                                                            : "-"}
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: 15
                                                                        }}>
                                                                        {sortable.length > 0
                                                                            ? sortable[sortable.length - 1][0]
                                                                            : "N/A"}
                                                                    </Typography>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        verticalAlign: "top",
                                                                        marginLeft: 16,
                                                                        marginRight: 16,
                                                                        padding: 8,
                                                                        width: "25%",
                                                                        display: "inline-block",
                                                                        backgroundColor: "#F0F0F0"
                                                                    }}>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: 24,
                                                                            color: this.colors[years.indexOf(y)]
                                                                        }}>
                                                                        {sortable.length > 1
                                                                            ? sortable[sortable.length - 2][1] +
                                                                              " FIELDS"
                                                                            : "-"}
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: 15
                                                                        }}>
                                                                        {sortable.length > 1
                                                                            ? sortable[sortable.length - 2][0]
                                                                            : "N/A"}
                                                                    </Typography>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        verticalAlign: "top",
                                                                        marginLeft: 16,
                                                                        marginRight: 16,
                                                                        padding: 8,
                                                                        width: "25%",
                                                                        display: "inline-block",
                                                                        backgroundColor: "#F0F0F0"
                                                                    }}>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: 24,
                                                                            color: this.colors[years.indexOf(y)]
                                                                        }}>
                                                                        {sortable.length > 2
                                                                            ? sortable[sortable.length - 3][1] +
                                                                              " FIELDS"
                                                                            : "-"}
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                            fontSize: 15
                                                                        }}>
                                                                        {sortable.length > 2
                                                                            ? sortable[sortable.length - 3][0]
                                                                            : "N/A"}
                                                                    </Typography>
                                                                </div>
                                                                <br />
                                                                <br />
                                                                <Typography
                                                                    style={{
                                                                        textAlign: "center",
                                                                        width: "80%",
                                                                        marginRight: "auto",
                                                                        marginLeft: "auto"
                                                                    }}>
                                                                    Note: Number indicates if the practice exists on the
                                                                    field in this year. This does not imply it was a new
                                                                    practice this year.
                                                                </Typography>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <Typography>No edge of field practices found.</Typography>
                                                    )}
                                                </div>
                                                <PageFooter number={page_number + 2} total={total_page_number} />
                                            </div>
                                        </>
                                    );
                                    page_number = page_number + 3;
                                }
                            });
                        }
                    });
                    return jsx;
                })}
                <div className={classes.page}>
                    <PageHeader project={project} />
                    <div className={classes.content}>
                        <br />
                        <div>
                            <h3 className={classes.popupHeader}>LAND USE</h3>
                            <Typography style={{ fontSize: 15 }}>
                                The Land Use metric is an efficiency metric that uses a simple equation to account for
                                the planted area used to produce a crop. Land use efficiency is a measure of the amount
                                of land (acres) used to produce a unit of crop (bushels, pounds, etc.) Lower scores are
                                desirable and indicate greater land use efficiency.
                            </Typography>
                        </div>
                        <br />
                        <div>
                            <h3 className={classes.popupHeader}>SOIL CONSERVATION</h3>
                            <Typography style={{ fontSize: 15 }}>
                                The Soil Conservation Metric is a measure of soil lost to erosion from water and wind,
                                calculated using USDA NRCS models and reported to the user as tons of soil lost per
                                acre. Lower numbers are desirable and indicate less soil lost from erosion per acre. A
                                Soil Erosion Fieldprint Score of 0 would indicate that no soil was lost in that year.
                            </Typography>
                        </div>
                        <br />
                        <div>
                            <h3 className={classes.popupHeader}>SOIL CARBON</h3>
                            <Typography style={{ fontSize: 15 }}>
                                Soil Carbon Metric utilizes the Soil Conditioning Index (SCI) a qualitative, directional
                                measure of how soil carbon is impacted by the organic matter and crop residue on your
                                field, the soil lost to wind and water erosion, and soil impacting characteristics of
                                your field operations. The SCI returns a value between -1 and 1 for each field. A
                                positive value indicates increasing soil carbon, a neutral value (between -0.05 and
                                0.05) indicates maintaining soil carbon, and a negative value indicates depletion of
                                soil carbon. The magnitude of the index reflects confidence in the directionality and
                                does not indicate a higher or lower quantity of carbon in the soil.
                            </Typography>
                        </div>
                        <br />
                        <div>
                            <h3 className={classes.popupHeader}>WATER QUALITY</h3>
                            <Typography style={{ fontSize: 15 }}>
                                The Water Quality Metric uses the Stewardship Tool for Environmental Performance (STEP),
                                developed by NRCS, to assess how likely a field is to lose nutrients to waterways. Based
                                on soil properties and local climate characteristics, STEP assigns a Field Sensitivity
                                Score to each field that represents the potential for nutrient losses, either by runoff
                                beyond the edge of the field (surface loss) or leaching below the rootzone (subsurface
                                loss), for each of four Loss Pathways: Surface P (Phosphorus), Subsurface P, Surface N
                                (Nitrogen), and Subsurface N. STEP then assigns mitigation points for management
                                practices that impact nutrient loss (a Risk Mitigation Score (RMS)).
                            </Typography>
                            <br />
                            <Typography style={{ fontSize: 15 }}>
                                The final metric score for each nutrient loss pathway is a ratio of how effective
                                management practices are at mitigating nutrient loss (Risk Mitigation score (RMS)) to
                                how sensitive the field is to nutrient loss based (Field Sensitivity Score (FSS)). If
                                the ratio is 1 or higher, the basic level of risk mitigation for excessive nutrient loss
                                has been met. If the ratio is below 1, excessive nutrient loss is likely, and producers
                                should discuss potential mitigation practices with their advisors.
                            </Typography>
                        </div>
                    </div>
                    <PageFooter number={page_number} total={total_page_number} />
                </div>
                <div className={classes.page}>
                    <PageHeader project={project} />
                    <div className={classes.content}>
                        <div style={{ marginTop: 15 }}>
                            <h3 className={classes.popupHeader}>IRRIGATION WATER USE</h3>
                            <Typography style={{ fontSize: 15 }}>
                                The Irrigation Water Use Metric is an efficiency metric that uses a simple equation to
                                account for the amount of water used to achieve an incremental increase in crop yield.
                                Irrigation Water Use (IWU) is expressed in the Fieldprint Platform as the amount of
                                water, measured in acre-inches (ac-in) required to produce a unit of crop (pound,
                                bushel, etc.). Lower numbers are desirable, indicating less water required to produce a
                                unit of crop.
                            </Typography>
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <h3 className={classes.popupHeader}>ENERGY USE</h3>
                            <Typography style={{ fontSize: 15 }}>
                                The Energy Use Metric calculates all energy used in the production of the crop in one
                                year from pre-planting activities through to the first point of sale. The Energy Use
                                metric includes direct energy used for operating equipment, pumping irrigation water,
                                grain drying and transport as well as embedded energy, which is required to produce crop
                                inputs like seeds, fertilizers and crop protectants. Lower numbers are desirable and
                                indicate less energy used to produce a unit of crop.
                            </Typography>
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <Typography style={{ fontSize: 15 }}>
                                Want to learn more? Download{" "}
                                <a
                                    className={classes.learnMoreLink}
                                    href="https://fieldtomarket.org/media/2018/06/FTM_Harnessing-Sustainability-Insights_WEB.pdf">
                                    Harnessing Sustainability Insights and Unleashing Opportunity (PDF)
                                </a>{" "}
                                from Field to Market. The guide explains the environmental, economic and community-level
                                importance of each sustainability indicator; how it is measured by the Fieldprint
                                Platform; the field characteristics and management practices used to calculate
                                sustainability outcomes encapsulated in a Fieldprint Analysis; and the top ways to
                                improve your results. To learn more about Field to Market, visit{" "}
                                <a href="https://www.fieldtomarket.org" className={classes.learnMoreLink}>
                                    www.fieldtomarket.org
                                </a>
                                .
                            </Typography>
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <h3 className={classes.popupHeader}>BIODIVERSITY</h3>
                            <Typography style={{ fontSize: 15 }}>
                                The Biodiversity Metric is a measure of the potential capacity of the field to support
                                diverse ecosystems. It is an index metric calculated based on both inherent land
                                properties and land management. Biodiversity is assessed using the Habitat Potential
                                Index (HPI). HPI scores range from 0-100 and measure the level of opportunity to improve
                                or maximize habitat potential. Higher scores are desirable and indicate a greater
                                potential to support wildlife habitat. Scores less than 50% represent significant
                                opportunities for improving habitat potential, whereas values of 50-80% indicate
                                moderate realized potential and scores greater than 80% demonstrate farms that have
                                maximized opportunities for biodiversity to flourish.
                            </Typography>
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <h3 className={classes.popupHeader}>GREENHOUSE GAS</h3>
                            <Typography style={{ fontSize: 15 }}>
                                The Greenhouse Gas (GHG) Emissions Metric calculates the total emissions from four main
                                sources – energy use, nitrous oxide emissions from soils, methane emissions (rice only)
                                and emissions from residue burning. Greenhouse gas emissions are reported as pounds of
                                carbon dioxide equivalent (CO<sub>2</sub>e) per crop unit produced (e.g. bushels or
                                pounds). “CO<sub>2</sub>e” simply means the N<sub>2</sub>O and CH<sub>4</sub> emissions
                                are converted to the equivalent amount of CO<sub>2</sub>, to provide a common unit of
                                all emissions in one measure, which is comparable over time and influenced by all the
                                actions a farmer takes. Low scores are desirable and indicate less greenhouse gas
                                emitted per unit of crop yield.
                            </Typography>
                        </div>
                    </div>
                    <PageFooter number={page_number + 1} total={total_page_number} />
                </div>
            </>
        );
    }
}

SummarySection = withStyles(styles)(SummarySection);

export default function ReportLayout({ project }) {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider
                theme={outerTheme => ({ ...outerTheme, overrides: { ...outerTheme.overrides, ...themeOverrides } })}>
                <SummarySection project={project} />
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
