import React, { Component } from "react";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "../../common/ButtonWrapper";
import Grid from "../../common/GridWrapper";
import Typography from "../../common/TypographyWrapper";

import withStyles from "@mui/styles/withStyles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import {
    TileDrainage,
    ConservationPractices,
    WildlifeHabitat,
    IntegratedPestManagement,
    FieldActivity,
    LandConversionTypes,
    Nutrient4RS,
    RiceWaterRegime,
    ManagementTechniques,
    PMultiYearApp,
    PSoilTestLevel,
    CROP_YEAR_COMPLETED
} from "./models";
import SubquestionIcon from "../../common/icons/Subquestion";
import CheckboxGroup from "../../common/CheckboxGroup";
import CheckboxGroupCons from "../../common/CheckboxGroupCons";
import RadioGroup from "../../common/RadioGroup";
import Select from "../../common/Select";
import HelpLabel from "../../common/HelpLabel";
import Snackbar from "../../common/Snackbar";
import TextField from "../../common/TextField";
import { createSelector } from "../../common/orm";
import { YES_NO_OPTIONS, RICE, MAKE_OPTIONS } from "../../../api/constants";
import { setValue, getValue } from "../../../api/utils";
import { isCompleted } from "./fieldActivitiesUtils";

const allTileDrainage = TileDrainage.selectAll();
const allConservationPractices = ConservationPractices.selectAll();
const allWildlifeHabitats = WildlifeHabitat.selectAll();
const allLandConversionTypes = LandConversionTypes.selectAll();
const allNutrient4RS = Nutrient4RS.selectAll();
const allRiceWaterRegime = RiceWaterRegime.selectAll();
const allManagementTechniques = ManagementTechniques.selectAll();
const allPMultiYearApp = PMultiYearApp.selectAll();
const allPSoilTestLevel = PSoilTestLevel.selectAll();
const activeIntegratedPestManagement = IntegratedPestManagement.selectAll();

const getFertilizers = createSelector(
    (state, ownProps) => ownProps.cropYear,
    (session, cropYear) => {
        return session.Fertilizer.filter(a => a.activity.includes(cropYear.id)).toRefArray();
    }
);

const getFieldActivities = createSelector(
    (state, ownProps) => ownProps.cropYear,
    (session, cropYear) => {
        var ordering = {},
            sortOrder = ["planting", "irrigation", "application", "manure", "harvest"];
        for (var i = 0; i < sortOrder.length; i++) {
            ordering[sortOrder[i]] = i;
        }
        return session.FieldActivity.filter({ cropyear: cropYear.id })
            .orderBy(["type", "code"])
            .toRefArray()
            .sort((a, b) => ordering[a.type] - ordering[b.type] || a.code.match(/\d+/)[0] - b.code.match(/\d+/)[0]);
    }
);

const getIrrigationFieldActivities = createSelector(
    (state, ownProps) => ownProps.cropYear,
    (session, cropYear) => {
        return session.FieldActivity.filter({
            cropyear: cropYear.id,
            type: "irrigation"
        }).toRefArray();
    }
);
const getHarvestFieldActivities = createSelector(
    (state, ownProps) => ownProps.cropYear,
    (session, cropYear) => {
        return session.FieldActivity.filter({
            cropyear: cropYear.id,
            type: "harvest"
        }).toRefArray();
    }
);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    divider: {
        margin: "8px 0 8px 0"
    },
    linkColor: {
        color: "#808080"
    },
    marginBottom: {
        marginBottom: "0.35em"
    },
    button: {
        marginRight: theme.spacing(1)
    },
    subQuestion: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(2)
    },
    gutterTop: {
        marginTop: ".6em"
    },
    noButton: {
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            WebkitAppearance: "none"
        }
    }
});

const LIME_UNIT_MSG = "Enter amount in units of ton/acre.  If measured in lbs/acre, divide by 2,000.",
    N_CARRY_OVER_MSG = "Typically the carry over value does not exceed 275 lbs/ac.";

class ManagementQuestions extends Component {
    state = {
        snackbarOpen: true
    };
    constructor(props, context) {
        super(props, context);
        this.isCompleted = isCompleted.bind(this);
    }

    updateCropYear(values, fromStepper) {
        const { id } = this.props.cropYear;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }

        let manureFertilizers = this.props.fieldActivities.filter(fA => fA.type === "manure");

        var isCompleted = this.isCompleted(
            values,
            this.props.cropYear,
            false,
            this.props.fertilizers,
            manureFertilizers.length,
            this.state.submitClicked
        );

        const c = getValue(values, "extrainfo.completed");
        const m = getValue(values, "extrainfo.missing");
        const r = getValue(values, "extrainfo.required");
        setValue(values, "metrics", null);

        //#666 Set crop year to provisional if missing
        if ((c && c[0] === false) || (c && c[2] === false) || isCompleted[0] !== 0) {
            setValue(values, "is_final", false);
        } else {
            setValue(values, "is_final", true);
        }

        this.props.ormCropYearUpdate({
            id: id,
            ...values,
            extrainfo: {
                completed: {
                    0: c ? c[0] : undefined,
                    1: isCompleted[0] === 0,
                    2: c ? c[2] : undefined
                },
                missing: {
                    0: m ? m[0] : undefined,
                    1: isCompleted[0],
                    2: m ? m[2] : undefined
                },
                required: {
                    0: r ? r[0] : undefined,
                    1: isCompleted[1],
                    2: r ? r[2] : undefined
                }
            }
        });

        this.props.handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (this.state.draftClick) {
                const { fieldId } = this.props;
                this.props.history.push("/field/" + fieldId);
            } else {
                this.props.handleNext();
            }
        }
    }

    localIsCompleted = (values, cY, hardRequire) => {
        let manureFertilizers = this.props.fieldActivities.filter(fA => fA.type === "manure");

        return isCompleted(
            values,
            cY,
            hardRequire,
            this.props.fertilizers,
            manureFertilizers.length,
            this.state.submitClicked
        );
    };

    warningValidator(values) {
        const warnings = {};

        const lime_applied = getValue(values, "energyuse.lime_applied");
        if (lime_applied === "true" || lime_applied === true) {
            const path = "energyuse.lime_amount",
                amount = parseFloat(getValue(values, path));
            if (amount > 7) {
                setValue(warnings, path, LIME_UNIT_MSG);
            } else {
                setValue(warnings, path, null);
            }
        }

        const npath = "waterquality.n_carry_over",
            nCarryOver = parseFloat(getValue(values, npath));

        if (nCarryOver > 275) {
            setValue(warnings, npath, N_CARRY_OVER_MSG);
        }

        return warnings;
    }

    updateValues(values) {
        Object.entries(values).forEach(([field, value]) => {
            this.form.setValue(field, value);
        });
    }

    landConversionChange = () => {
        this.updateValues({
            "biodiversity.previous_land_cover": null,
            "biodiversity.field_area_converted": null
        });
    };

    applyLimeChange = () => {
        this.updateValues({
            "energyuse.lime_year_applied": null,
            "energyuse.lime_years": null,
            "energyuse.lime_amount": null
        });
    };

    applySulfateChange = () => {
        this.updateValues({
            "greenhouse.sulfur_rate": null
        });
    };

    applyNAPChange = () => {
        this.applyNitrogenChange();
        this.applyPhosphorusChange();
        this.updateValues({
            "waterquality.nitrogen_applied": null,
            "waterquality.phosphorus_applied": null
        });
    };

    applyNitrogenChange = () => {
        this.updateValues({
            "waterquality.n_carry_over": null
        });
    };

    applyPhosphorusChange = () => {
        this.applySoilTestChange();
        this.updateValues({
            "waterquality.p_multi_year_app": null,
            "waterquality.p_soil_test_level": null
        });
    };

    applySoilTestChange = () => {
        const pDrawDownIds = this.props.managementTechniques
                .filter(t => t.applicability.includes("excessive_phosphorus"))
                .map(t => t.id),
            techniques = this.form.getValue("waterquality.management_techniques") || [];

        this.updateValues({
            "waterquality.management_techniques": techniques.filter(tid => !pDrawDownIds.includes(tid))
        });
    };

    irrigatedChange = (value, cropYearId) => {
        const {
            cropYear,
            irrigationFieldActivity,
            harvestFieldActivity,
            ormFieldActivityDelete,
            ormFieldActivityCreate,
            ormFieldActivityUpdate,
            ormCropYearUpdate
        } = this.props;

        if (irrigationFieldActivity[0]) {
            ormFieldActivityDelete(irrigationFieldActivity[0].id);
        }
        if (value === "true" || value === true) {
            ormFieldActivityCreate({
                cropyear: cropYearId,
                type: "irrigation",
                order: 3
            });
            if (harvestFieldActivity[0]) {
                var mis, req;
                if (harvestFieldActivity[0].extrainfo) {
                    mis = harvestFieldActivity[0].extrainfo.missing + 1;
                    req = harvestFieldActivity[0].extrainfo.required + 1;
                } else {
                    mis = 0;
                    req = 0;
                }
                if (harvestFieldActivity[0].extrainfo)
                    ormFieldActivityUpdate({
                        id: harvestFieldActivity[0].id,
                        extrainfo: {
                            completed: false,
                            missing: mis,
                            required: req
                        }
                    });
                const c = getValue(cropYear, "extrainfo.completed");
                const m = getValue(cropYear, "extrainfo.missing");
                const r = getValue(cropYear, "extrainfo.required");
                this.form.setValue("extrainfo.completed", {
                    0: c ? c[0] : undefined,
                    1: c ? c[1] : undefined,
                    2: false
                });

                ormCropYearUpdate({
                    id: cropYearId,
                    extrainfo: {
                        completed: {
                            0: c ? c[0] : undefined,
                            1: c ? c[1] : undefined,
                            2: false
                        },
                        missing: {
                            0: m ? m[0] : undefined,
                            1: m ? m[1] : undefined,
                            2: m ? m[2] : undefined
                        },
                        required: {
                            0: r ? r[0] : undefined,
                            1: r ? r[1] : undefined,
                            2: r ? r[2] : undefined
                        }
                    }
                });
            }
        }
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        this.props.onRef(this);

        document.getElementById("quickFacts").scrollIntoView();

        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function () {
            handleUnsavedFields(false);
        }, 100);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    render() {
        const {
            classes,
            cropYear,
            tileDrainage,
            wildlifeHabitat,
            conservationPractices,
            integratedPestManagement,
            fieldName,
            landConversionTypes,
            nutrient4RS,
            riceWaterRegime,
            managementTechniques,
            pMultiYearApp,
            pSoilTestLevel,
            handleUnsavedFields
        } = this.props;

        var wildlifeHabitatOpt = wildlifeHabitat.filter(wH => wH.crop_id === cropYear.crop);
        if (wildlifeHabitatOpt.length === 0) wildlifeHabitatOpt = wildlifeHabitat.filter(wH => !wH.crop_id);

        // Only run validation if users have been here before
        const miss = getValue(cropYear, "extrainfo.missing");
        const isMissing = CROP_YEAR_COMPLETED(cropYear, 1, true);

        const getFormValue = name => (this.form ? this.form.getValue(name) : getValue(cropYear, name)),
            isTrue = name => {
                const value = getFormValue(name);
                return value === true || value === "true";
            };

        const hasLime = isTrue("energyuse.lime_applied"),
            hasSulfur = isTrue("greenhouse.has_sulfur"),
            hasNutrient = isTrue("waterquality.nutrient_applied"),
            hasNitrogen = isTrue("waterquality.nitrogen_applied"),
            hasPhosphorus = isTrue("waterquality.phosphorus_applied"),
            hasLandConversion = isTrue("biodiversity.land_cover_change"),
            excessPhosphorus = getFormValue("waterquality.p_soil_test_level") === "1";

        const nManagementTechniques = managementTechniques.filter(t => t.applicability.includes("nitrogen")),
            pManagementTechniques = managementTechniques.filter(t => t.applicability.includes("phosphorus")),
            pDrawDown = managementTechniques.filter(t => t.applicability.includes("excessive_phosphorus"));

        return (
            <Form
                getApi={el => (this.form = el)}
                key={cropYear.id}
                dontValidateOnMount={!isMissing ? false : true}
                validateOnSubmit={!isMissing ? false : true}
                defaultValues={cropYear.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                validateError={values => this.localIsCompleted(values, cropYear, true)}
                validateWarning={values => this.warningValidator(values)}
                onSubmit={(values, fromStepper) => this.updateCropYear(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="display3" gutterBottom>
                                    Management Information
                                </Typography>
                                <Typography>
                                    Field management practices are collected for a variety metric calculations including
                                    energy use, greenhouse gas and water quality. Please answer the questions below.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                                {cropYear.crop !== RICE && (
                                    <RadioGroup
                                        eventHandle={value => this.irrigatedChange(value, cropYear.id)}
                                        fullWidth
                                        field="is_irrigated"
                                        name="is_irrigated"
                                        options={YES_NO_OPTIONS}
                                        alignment={true}
                                        label="Is this crop irrigated?"
                                    />
                                )}
                                <RadioGroup
                                    field="soils.has_wind_barriers"
                                    name="windbarrier"
                                    options={YES_NO_OPTIONS}
                                    alignment={true}
                                    fullWidth
                                    label="Does the field have a feature that would qualify as a wind barrier based on NRCS standards?"
                                    help="NRCS defines a windbreak or shelter belt as closely spaced trees and/or shrubs planted perpendicular to the prevailing winds. This will impact the soil erosion score."
                                />
                                <Select
                                    label="Tile drainage system characteristics"
                                    field="waterquality.tile_drainage"
                                    options={MAKE_OPTIONS(tileDrainage)}
                                    help={
                                        <div>
                                            A tile drainage system is a system of sub-surface pipes that drain
                                            gravitational (excess) water not held within the soil pores.
                                            <br />
                                            <br />
                                            Standard density tiles are typically spaced 60-100 feet apart. High density
                                            tile drainage is typically less than 60 feet spacing between tiles.
                                        </div>
                                    }
                                    fullWidth
                                    margin="normal"
                                />
                                {cropYear.crop === RICE && (
                                    <Select
                                        field="greenhouse.water_regime"
                                        label="Please select the appropriate water regime used for your rice production"
                                        options={MAKE_OPTIONS(riceWaterRegime)}
                                        help={
                                            <div>
                                                Continuously flooded: no periods of no inundation.
                                                <br />
                                                <br />
                                                Single aeration: field is not inundated for a period of time once over
                                                the growing season.
                                                <br />
                                                <br />
                                                Multiple aerations: field is not inundated for a period of time on
                                                multiple occasions throughout the growing season.
                                            </div>
                                        }
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="display3" className={classes.gutterTop}>
                                    Nutrient and Fertilizer Application Practices
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                                <RadioGroup
                                    field="biodiversity.nutrient_management_plan"
                                    name="nmp"
                                    options={YES_NO_OPTIONS}
                                    fullWidth
                                    alignment={true}
                                    label="Are you implementing an NRCS approved nutrient management plan? This will affect your biodiversity score."
                                    help="NRCS can review and approve Nutrient Management Plans."
                                />
                                <CheckboxGroup
                                    field="biodiversity.nutrient_4_rs"
                                    label="If you do not have an approved nutrient management plan, which of the 4Rs of nutrient stewardship do you believe you follow? This will affect your biodiversity score."
                                    help={
                                        <div>
                                            Note the 4R nutrient stewardship principles are the same globally, but how
                                            they are used locally varies.
                                            <br />
                                            <br />
                                            RIGHT SOURCE: Ensure a balanced supply of essential nutrients, considering
                                            both naturally available sources and the characteristics of specific
                                            products, in plant available forms.
                                            <br />
                                            <br />
                                            RIGHT RATE: Assess and make decisions based on soil nutrient supply and
                                            plant demand.
                                            <br />
                                            <br />
                                            RIGHT TIME: Assess and make decisions based on the dynamics of crop uptake,
                                            soil supply, nutrient loss risks, and field operation logistics.
                                            <br />
                                            <br />
                                            RIGHT PLACE: Address root-soil dynamics and nutrient movement and manage
                                            spatial variability within the field to meet site-specific crop needs and
                                            limit potential losses from the field.
                                        </div>
                                    }
                                    row={true}
                                    options={MAKE_OPTIONS(nutrient4RS)}
                                />
                                <RadioGroup
                                    eventHandle={this.applyLimeChange}
                                    alignment={true}
                                    field="energyuse.lime_applied"
                                    name="applylime"
                                    options={YES_NO_OPTIONS}
                                    fullWidth
                                    label="Did you apply lime for this crop?"
                                    help="Limestone or chaulk derived soil additives to increase pH and reduce acidity to improve nutrient utilization."
                                />
                                {hasLime && (
                                    <Grid container wrap="nowrap">
                                        <Grid xs={1} className={classes.subQuestion}>
                                            <SubquestionIcon />
                                        </Grid>
                                        <Grid xs={11}>
                                            <TextField
                                                type="number"
                                                inputProps={{ step: 1 }}
                                                className={classes.noButton}
                                                field="energyuse.lime_year_applied"
                                                label="What year was the lime applied?"
                                                help="Lime refers to limestone or chaulk-derived soil additives that reduce soil acidity."
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {hasLime && (
                                    <Grid container wrap="nowrap">
                                        <Grid xs={1} className={classes.subQuestion}>
                                            <SubquestionIcon />
                                        </Grid>
                                        <Grid xs={11}>
                                            <TextField
                                                type="number"
                                                inputProps={{ step: 1 }}
                                                field="energyuse.lime_years"
                                                label="Estimated number of years lime between lime applications"
                                                help="The energy associated with application will be amortized over this duration."
                                                fullWidth
                                                units="yr"
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {hasLime && (
                                    <Grid container wrap="nowrap">
                                        <Grid xs={1} className={classes.subQuestion}>
                                            <SubquestionIcon />
                                        </Grid>
                                        <Grid xs={11}>
                                            <TextField
                                                type="number"
                                                inputProps={{ step: 0.01 }}
                                                field="energyuse.lime_amount"
                                                label={
                                                    <div>
                                                        Lime amount in <b>tons per acre</b>
                                                    </div>
                                                }
                                                units="ton/acre"
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {cropYear.crop === RICE && (
                                    <RadioGroup
                                        field="greenhouse.has_sulfur"
                                        name="applysulfate"
                                        options={YES_NO_OPTIONS}
                                        fullWidth
                                        eventHandle={this.applySulfateChange}
                                        alignment={true}
                                        label="Did you apply sulfate?"
                                    />
                                )}
                                {hasSulfur && (
                                    <Grid container wrap="nowrap">
                                        <Grid xs={1} className={classes.subQuestion}>
                                            <SubquestionIcon />
                                        </Grid>
                                        <Grid xs={11}>
                                            <TextField
                                                type="number"
                                                inputProps={{ step: 0.1 }}
                                                field="greenhouse.sulfur_rate"
                                                label="Sulfur fertilizer product amount in pounds per acre"
                                                help="The total pounds of sulfur applied is used to estimate the amount of energy used to apply the product."
                                                units="lbs/ac"
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                <RadioGroup
                                    field="waterquality.nutrient_applied"
                                    name="nutrient_applied"
                                    options={YES_NO_OPTIONS}
                                    fullWidth
                                    eventHandle={this.applyNAPChange}
                                    alignment={true}
                                    label="Did you apply fertilizer (either organic or inorganic source) this crop year?"
                                    help="Was commercial fertilizer or manure applied for the analyzed crop?"
                                />
                                {hasNutrient && (
                                    <>
                                        <RadioGroup
                                            field="waterquality.nitrogen_applied"
                                            name="nitrogen_applied"
                                            options={YES_NO_OPTIONS}
                                            fullWidth
                                            eventHandle={this.applyNitrogenChange}
                                            alignment={true}
                                            label="Did you apply nitrogen this crop year?"
                                            help="Was commercial fertilizer or manure applied containing nitrogen for the analyzed crop?"
                                        />
                                        {hasNitrogen && (
                                            <>
                                                <Grid container wrap="nowrap">
                                                    <Grid xs={1} className={classes.subQuestion}>
                                                        <SubquestionIcon />
                                                    </Grid>
                                                    <Grid xs={11}>
                                                        <TextField
                                                            type="number"
                                                            inputProps={{ step: 0.1 }}
                                                            field="waterquality.n_carry_over"
                                                            label="What was the approximate soil nitrogen carry-over from last season?"
                                                            help={
                                                                <>
                                                                    The platform uses a default carry-over soil nitrogen
                                                                    concentration of 50 lb/ac, which affects the water
                                                                    quality metric. If you have more accurate
                                                                    information to estimate this value (e.g., fall soil
                                                                    nitrate test, nitrogen balance estimate), please
                                                                    override the default.
                                                                </>
                                                            }
                                                            helperText="If you do not have this information, you can use a default of 50 lbs/ac."
                                                            units="lbs/ac"
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {nManagementTechniques.length > 0 && (
                                                    <Grid container wrap="nowrap">
                                                        <Grid xs={1} className={classes.subQuestion}>
                                                            <SubquestionIcon />
                                                        </Grid>
                                                        <Grid xs={11}>
                                                            <CheckboxGroup
                                                                label="Nutrient management techniques"
                                                                help="Did you apply fertilizer following the 4R principles and techniques noted?"
                                                                field="waterquality.management_techniques"
                                                                options={MAKE_OPTIONS(nManagementTechniques)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </>
                                        )}
                                        <RadioGroup
                                            field="waterquality.phosphorus_applied"
                                            name="phosphorus_applied"
                                            options={YES_NO_OPTIONS}
                                            fullWidth
                                            eventHandle={this.applyPhosphorusChange}
                                            alignment={true}
                                            label="Did you apply phosphorus this crop year?"
                                            help="Was commercial fertilizer or manure applied containing phosphorus for the analyzed crop?"
                                        />
                                        {hasPhosphorus && (
                                            <>
                                                <Grid container wrap="nowrap">
                                                    <Grid xs={1} className={classes.subQuestion}>
                                                        <SubquestionIcon />
                                                    </Grid>
                                                    <Grid xs={11}>
                                                        <Select
                                                            field="waterquality.p_multi_year_app"
                                                            label="Was the application intended to supply phosphorus to successive crops in multiple years? If so, how many years?"
                                                            help="Often phosphorus is applied to carryover for subsequent crops in rotation."
                                                            options={MAKE_OPTIONS(pMultiYearApp)}
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {pManagementTechniques.length > 0 && (
                                                    <Grid container wrap="nowrap">
                                                        <Grid xs={1} className={classes.subQuestion}>
                                                            <SubquestionIcon />
                                                        </Grid>
                                                        <Grid xs={11}>
                                                            <CheckboxGroup
                                                                label="Nutrient management techniques"
                                                                help="Did you apply fertilizer following the 4R principles and techniques noted?"
                                                                field="waterquality.management_techniques"
                                                                options={MAKE_OPTIONS(pManagementTechniques)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                <Grid container wrap="nowrap">
                                                    <Grid xs={1} className={classes.subQuestion}>
                                                        <SubquestionIcon />
                                                    </Grid>
                                                    <Grid xs={11}>
                                                        <Select
                                                            field="waterquality.p_soil_test_level"
                                                            label="Did you conduct a soil test within the past four years on this field to analyze phosphorus amounts? If so, what was the phosphorus level of the soil?"
                                                            help="Phosphorous application thresholds for the water quality metric are different depending on previous soil tests."
                                                            options={MAKE_OPTIONS(pSoilTestLevel)}
                                                            eventHandle={this.applySoilTestChange}
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {excessPhosphorus && (
                                                    <Grid container wrap="nowrap">
                                                        <Grid xs={1} className={classes.subQuestion} />
                                                        <Grid xs={1} className={classes.subQuestion}>
                                                            <SubquestionIcon />
                                                        </Grid>
                                                        <Grid xs={10}>
                                                            <CheckboxGroup
                                                                label="Your results were 'Excessive/very high'.  Indicate if you implement either of the following two phosphorus drawdown strategies."
                                                                help={
                                                                    'Soil test levels in the "Draw Down" range (10ppm P > ML) are more than adequate for top crop production.  No additional P is needed and no response to applied P should be expected.'
                                                                }
                                                                field="waterquality.management_techniques"
                                                                options={MAKE_OPTIONS(pDrawDown)}
                                                                mutex={pDrawDown.map(t => t.id)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="display3" className={classes.gutterTop}>
                                    Pest Management
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                                <Select
                                    field="waterquality.pest_management_id"
                                    label="Do you utilize an integrated pest management (IPM) plan?"
                                    help={
                                        <div>
                                            Like the 4Rs, the idea of IPM is global while its local implementation will
                                            vary. IMP may utilize approaches listed below. This will affect your
                                            biodiversity score.
                                            <br />
                                            <br />
                                            Do you use scouting rather than prophylactic approaches to pest management?
                                            <br />
                                            <br />
                                            Do you use a mix of mechanical, biological and chemical control methods?
                                            <br />
                                            <br />
                                            Do you choose practices that attempt to minimizing risk to non-target
                                            organisms such as native pollinators?
                                        </div>
                                    }
                                    options={MAKE_OPTIONS(integratedPestManagement)}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="display3" className={classes.gutterTop}>
                                    Land and Habitat Management
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                                <RadioGroup
                                    eventHandle={this.landConversionChange}
                                    alignment={true}
                                    field="biodiversity.land_cover_change"
                                    name="landconversion"
                                    options={YES_NO_OPTIONS}
                                    fullWidth
                                    label="Was any part of this field converted from a use other than cropland within 5 years of the entered crop year?"
                                    help="This refers to conversion of land from non-cultivated uses (e.g. pasture, range, natural habitat) to cultivated cropland in the past five (5) years."
                                />
                                {hasLandConversion && (
                                    <Grid container wrap="nowrap">
                                        <Grid xs={1} className={classes.subQuestion}>
                                            <SubquestionIcon />
                                        </Grid>
                                        <Grid item xs={7} style={{ marginRight: 8 }}>
                                            <Select
                                                field="biodiversity.previous_land_cover"
                                                label="Type of land converted"
                                                options={MAKE_OPTIONS(landConversionTypes)}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                type="number"
                                                inputProps={{ step: 0.1 }}
                                                field="biodiversity.field_area_converted"
                                                label="Acres of land converted"
                                                units="acre"
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                <CheckboxGroup
                                    field="biodiversity.wildlife_habitat_ids"
                                    label="Do you practice any season enhancements for wildlife habitat? (select all that apply)"
                                    help="Practices that increase the amount of food, breeding or nesting habitat available to native pollinators and/or wildlife. This may include planting of pollinator habitat, scheduling mowing to avoid bird nesting seasons."
                                    row={true}
                                    options={MAKE_OPTIONS(wildlifeHabitatOpt)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="display3" className={classes.gutterTop}>
                                    Conservation Practices
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <CheckboxGroupCons
                                    field="waterquality.conservation_codes"
                                    label={
                                        <HelpLabel
                                            inputLabel="Select one or more water conservation practices used that pertain to this field."
                                            helpText="Hover over the practices for descriptions. These impact the water quality metric."
                                        />
                                    }
                                    practices={conservationPractices}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.rightAlign}>
                                <Button
                                    variant="raised"
                                    type="submit"
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to {fieldName} Dashboard
                                </Button>
                                <Button
                                    variant="raised"
                                    type="submit"
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}
                                    className={classes.button}>
                                    Save and Proceed to Operations Data <ChevronRightIcon />
                                </Button>
                            </Grid>
                            {!isMissing && (
                                <Snackbar
                                    error
                                    count={miss[1]}
                                    onClose={this.handleSnackbarClose}
                                    open={this.state.snackbarOpen}
                                />
                            )}
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

ManagementQuestions = connect(
    (state, ownProps) => ({
        tileDrainage: allTileDrainage(state),
        conservationPractices: allConservationPractices(state, ownProps),
        irrigationFieldActivity: getIrrigationFieldActivities(state, ownProps),
        harvestFieldActivity: getHarvestFieldActivities(state, ownProps),
        integratedPestManagement: activeIntegratedPestManagement(state),
        wildlifeHabitat: allWildlifeHabitats(state),
        landConversionTypes: allLandConversionTypes(state),
        nutrient4RS: allNutrient4RS(state),
        riceWaterRegime: allRiceWaterRegime(state),
        managementTechniques: allManagementTechniques(state),
        pMultiYearApp: allPMultiYearApp(state),
        pSoilTestLevel: allPSoilTestLevel(state),
        fertilizers: getFertilizers(state, ownProps),
        fieldActivities: getFieldActivities(state, ownProps)
    }),
    FieldActivity.actions
)(ManagementQuestions);

export default withStyles(styles)(ManagementQuestions);
